import { reaction } from 'mobx';

import config from '../../configuration/config';
import ProductionEnvironment from '../../LSF/env/production';
import AppStore from '../../LSF/stores/AppStore';

import { formatError } from '../ResponseErrorFormatter';

const basicInterfaces = [
    "panel",
    "side-column",
    // "predictions:menu",
    // "completions:menu",
    // "controls",
    // "completions:menu",
    // "submit",
    // "update",
    // "completions:add-new",
    // "completions:delete",
];

//eslint-disable-next-line
const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

class LabelStudioHandler {

    constructor() {
        this.store = null;
        this.originalCompletionSelectedListeners = [];
        this.mediaPlayingListeners = [];
    }

    __initStore(params) {
        if (this.store === null) {
            const configuredParams = ProductionEnvironment.configureApplication(params);
            const appStore = AppStore.create(params, configuredParams);
            appStore.initializeStore(params);
            window.Htx = appStore;

            // This reaction is added to the listen to the changes in the selected completion and set the flag
            // original completion selected accordingly.
            reaction(
                () => appStore.completionStore.selected,
                selectedCompletion => {
                    const originalSelected = selectedCompletion ? selectedCompletion.original : true;
                    this.originalCompletionSelectedListeners.forEach(lst => lst(originalSelected));
                }
            );

            reaction(
                () => appStore.completionStore.mediaPlaying,
                value => {
                    this.mediaPlayingListeners.forEach(lst => lst(value))
                }
            );

            this.store = appStore;
        }
        else {
            //Setting visual interfaces.
            this.store.setInterfaces(params.interfaces);

            //Setting xml configuration
            this.store.assignConfig(params.config);

            //Setting task data.
            this.store.assignTask(params.task);

            //Setting completions data.
            this.store.resetState();
            this.store.initializeStore(params);
        }
    }

    __signUrls(completionID, data, review) {
        let jsonData = data;
        Object.entries(jsonData).forEach(([key, value]) => {

            if (value.startsWith('<video')) {
                const startIndex = value.search('src=') + 5;
                const endIndex = value.substring(startIndex).search(' ');

                const oldSrcURL = value.substr(startIndex, endIndex - 1)

                if (urlRegex.test(oldSrcURL) && oldSrcURL.startsWith('https://storage.cloud.google.com/')) {
                    const newSrcUrl = `${config.lxt_backend}contributor/completions/${completionID}/files?field=${key}&source=1&role=${review ? 'rv' : 'dp'}&nested=${true}`
                    jsonData[key] = value.replace(oldSrcURL, newSrcUrl)
                }

            }
            else {
                if (urlRegex.test(value) && value.startsWith('https://storage.cloud.google.com/')) {
                    jsonData[key] = `${config.lxt_backend}contributor/completions/${completionID}/files?field=${key}&source=1&role=${review ? 'rv' : 'dp'}`
                }
            }
        })
        return jsonData;
    }

    checkSubstringInXMLConfig(completionData, substring) {
        return completionData?.config && completionData?.config?.includes(substring);
    }


    getStore() {
        return this.store;
    }

    initStoreWithPreviewData(xmlConfig, taskData) {
        try {
            const params = {
                interfaces: ["side-column"],
                config: xmlConfig,
                task: {
                    id: 1,
                    data: JSON.stringify(taskData)
                },
                completions: [{ result: [] }],
                predictions: []
            };

            this.__initStore(params);
        } catch (e) {
            throw new Error(formatError(e, 'Failed To Refresh View With Xml Configuration!'));
        }
    }



    addMediaPlayingListener(listener) {
        this.mediaPlayingListeners.push(listener);
    }

    removeMediaPlayingListener(listener) {
        this.mediaPlayingListeners.pop(listener);
    }

    addOriginalCompletionSelectedListener(listener) {
        this.originalCompletionSelectedListeners.push(listener);
    }

    removeOriginalCompletionSelectedListener(listner) {
        this.originalCompletionSelectedListeners.pop(listner);
    }



    // Converts the structure of the retrieved completion from the back end to match the structure of completions
    // expected by the LSF studio components and loads the store with the formatted object.
    loadLdpCompletion(ldpCompletion, review = false) {
        return new Promise((resolve, reject) => {
            try {

                // Initializing the current completion with PK id which is different than
                // the regular id of the completion which is overwritten by the LSF components.
                let lsfCompletion = { pk: ldpCompletion.id };

                // Setting the result of the current completion either from the draft result or from the result
                // of the current completion.
                if (ldpCompletion.draftResult)
                    lsfCompletion.result = ldpCompletion.draftResult;
                else if (ldpCompletion.result)
                    lsfCompletion.result = ldpCompletion.result;
                else if (ldpCompletion.predictions && Array.isArray(ldpCompletion.predictions) && ldpCompletion.predictions.length > 0) {
                    lsfCompletion.result = ldpCompletion.predictions[0].result;
                }
                else
                    lsfCompletion.result = [];

                // Setting the flag whehter to display new completion in the view or the pk of the
                // completion in the previous completions panel.
                if (review)
                    lsfCompletion.sentUserGenerate = true;
                else
                    lsfCompletion.userGenerate = true;

                //Marking the current completion as the original completion.
                //Original means, that it's the one proccessed/reviewed by contributor.
                lsfCompletion.original = true;

                // Merging the previous completions with the current completions.
                let allCompletions = ldpCompletion.prevCompletions && Array.isArray(ldpCompletion.prevCompletions) ?
                    [...ldpCompletion.prevCompletions.map(pc => ({ pk: pc.id, result: pc.result, original: false })), lsfCompletion] :
                    [lsfCompletion];

                // Converting the url to the redirect link which gets the signed link of the resource.
                ldpCompletion.task.data = this.__signUrls(ldpCompletion.id, ldpCompletion.task.data, review);

                // The following part is not needed since the result of the first prediction will be displayed directly in the completion.
                // // The interfaces which are going to be displayed to the user.
                // const selectedInterfaces = [...basicInterfaces];
                // // Adding predictions menu and completions menu if there are any predictions.
                // // In this case completions is required with predictions in order to enable the user
                // // to go back to the completion he/she is working on after viewing the prediction.
                // if (ldpCompletion.predictions && Array.isArray(ldpCompletion.predictions) && ldpCompletion.predictions.length > 0)
                //     selectedInterfaces.push("completions:menu", "predictions:menu");
                // // Adding the completions menu if there are previous results to enable users to view previous completions.
                // else if (ldpCompletion.prevCompletions && Array.isArray(ldpCompletion.prevCompletions) && ldpCompletion.prevCompletions.length > 0)
                //     selectedInterfaces.push("completions:menu");

                // The interfaces which are going to be displayed to the user.
                const selectedInterfaces = [...basicInterfaces];
                // Adding the completions menu if there are previous results to enable users to view previous completions.
                if (ldpCompletion.prevCompletions && Array.isArray(ldpCompletion.prevCompletions) && ldpCompletion.prevCompletions.length > 0)
                    selectedInterfaces.push("completions:menu");

                // The following part is not needed since the result of the first prediction will be displayed directly in the completion.
                // // Setting the original flag to false in the predictions not to enable the submit buttons.
                // const predictions = ldpCompletion.predictions && Array.isArray(ldpCompletion.predictions) ?
                //     ldpCompletion.predictions.map(p => ({ ...p, original: false })) :
                //     [];

                const params = {
                    ...ldpCompletion,

                    interfaces: selectedInterfaces,

                    task: {
                        ...ldpCompletion.task,
                        data: JSON.stringify(ldpCompletion.task.data),
                    },

                    completions: allCompletions,

                    predictions: [],
                }

                this.__initStore(params);

                resolve(ldpCompletion);
            }
            catch (e) {
                reject({ message: `Failed to refresh view for the completion ${ldpCompletion.id}. ${e.message}.` });
            }
        });
    }

    serializeSelectedCompletionResult() {
        return new Promise((resolve, reject) => {
            try {
                // NOTE: It is safe to act on the selected since this function is called upon submit, and submit is disabled when the original
                // completion is not selected.
                const c = this.store.completionStore.selected;
                if (c.validate()) {
                    //store.submitCompletion();
                    const result = this.store.completionStore.selected.serializeCompletion();
                    resolve(result);
                } else {
                    reject({ message: `Transcription is missing.` })
                }
            }
            catch (e) {
                reject({ message: `Failed to serialize result for the completion. ${e.message}.` })
            }
        })
    }

    completionHasResult() {
        if (this.store) {
            // NOTE: It is safe to act on the selected since this function is called upon submit, and submit is disabled when the original
            // completion is not selected.
            const completion = this.store.completionStore.selected;
            const completionResult = completion.serializeCompletion();
            const result = completionResult.length > 0;
            return result;
        }
        else {
            return false;
        }
    }

    completionHasValidResult() {
        // NOTE: It is safe to act on the selected since this function is called upon submit, and submit is disabled when the original
        // completion is not selected.
        if (this.store) {
            const completion = this.store.completionStore.selected;
            const result = completion.validate();
            return result;
        }
        else {
            return false;
        }
    }

    getSelectedCompletionResult() {
        if (this.store && this.store.completionStore && this.store.completionStore.selected) {
            const result = this.store.completionStore.selected.serializeCompletion();
            return result;
        }
        else {
            return null;
        }
    }

    getOriginalCompletionResult() {

        if (!(this.store && this.store.completionStore))
            return null;

        const originalCompletion = this.store.completionStore.completions.find(comp => comp.original);
        if (!originalCompletion)
            return null;

        // Making sure results has changed by comparing it the previous saved result.
        const originalResult = originalCompletion.serializeCompletion();
        return originalResult;
    }
}


export default LabelStudioHandler;
