import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Badge, Dropdown, Button, Result } from 'antd';
import { NotificationOutlined, LoadingOutlined } from '@ant-design/icons';

import NotificationCard from './NotificationCard';
import AudioFileNotification from './AudioFileNotification';
import EmptyNotificationsPlaceHolder from '../EmptyNotificationsPlaceHolder';
import useNotificationsContext, { NotificationsContextProvider } from '../../../contexts/NotificationsContext';
import { UPLOAD_PROCESS_STATUS_CODES } from '../../../Constants';
import { NOTIFICATIONS_PATH } from '../../../routes';
import "./NotificationsDropDown.scss"
import { uploadFileNotificationRender, useUploadList } from "./UploadFileNotification";

const NOTIFICATIONS_REFRES_PERIOD = 1000 * 60;      //60 seconds
const DROPDOWN_NOTIFICATIONS_CRITERIA = {
	cleared: false,
	skip: 0,
	limit: 3,
}

const NotificationsDropdownMenu = (props) => {
	return (
		<NotificationsContextProvider>
			<NotificationsDropdownMenuInternal {...props} />
		</NotificationsContextProvider>
	)
}

const ListHeader = ({ loading }) => {
	return (
		<div className='notifications-dropdown-header'>
			<div className='notifications-dropdown-title'>
				<span>Notifications</span>
			</div>
			{loading && <LoadingOutlined />}
		</div>
	)
}

const SeeAllButton = () => {
	const history = useHistory();

	return (
		<Button
			type='link'
			style={{ width: '100%', height: '50px' }}
			onClick={() => history.push(NOTIFICATIONS_PATH)}>
			See All
		</Button>
	)
}

const ErrorMarker = () => {
	return (
		<Result
			status="error"
			title='Failed To Retrieve Notifications!'
			style={{ width: '300px' }}
		/>
	)
}

const NotificationsDropdownMenuInternal = () => {
	const location = useLocation();
	const uploadList = useUploadList();
	const { toUploadCompletionDataTasks } = useSelector(state => state.audio);

	const {
		notifications,
		notificationsLoading,
		notificationsCount,
		notificationsError,
		fetchNotifications,
		cleanContext,
	} = useNotificationsContext();

	const [state, setState] = useState({
		showNotificationsCount: true,
		lastPathname: location.pathname,
	});

	useEffect(() => {

		// Fetching the notifications on load and starting the periodic process of refreshing the notifications list.
		fetchNotifications(DROPDOWN_NOTIFICATIONS_CRITERIA);

		let notificationsRefreshID = setInterval(() => fetchNotifications(DROPDOWN_NOTIFICATIONS_CRITERIA), NOTIFICATIONS_REFRES_PERIOD);

		return () => {
			cleanContext();
			clearInterval(notificationsRefreshID);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		// Refreshing the call to refresh the count of the notification when the user navigated out of the
		// notifications page.
		if (state.lastPathname === NOTIFICATIONS_PATH && location.pathname !== NOTIFICATIONS_PATH) {
			fetchNotifications(DROPDOWN_NOTIFICATIONS_CRITERIA);
		}

		setState(ps => ({
			...ps,
			showNotificationsCount: location.pathname !== NOTIFICATIONS_PATH,
			lastPathname: location.pathname,
		}));

		// eslint-disable-next-line
	}, [location]);


	// Preparing the completion entries to display notification cards for.
	let uploadCompletionEntries = toUploadCompletionDataTasks ? Object.entries(toUploadCompletionDataTasks) : [];
	uploadCompletionEntries = uploadCompletionEntries.filter(
		([_, completionEntry]) => completionEntry.status !== UPLOAD_PROCESS_STATUS_CODES.NotStarted
	);


	// The drop down menu elements.
	const menuOverlay = (
		<Menu style={{ paddingTop: '0px' }}>

			<ListHeader loading={notificationsLoading} />

			{notificationsError && <ErrorMarker />}

			{/** Displaying notifications for <Upload /> */}
			{/*{uploadFileNotificationRender(uploadList.pendingUploadTaskList)}*/}

			{/** Displaying notifications for ADC */}
			{!notificationsError && uploadCompletionEntries.length > 0 && uploadCompletionEntries.map(([completionID, entry]) =>
				<Menu.Item key={completionID} onClick={e => e.preventDefault()}>
					<AudioFileNotification completionID={completionID} completionEntry={entry} />
				</Menu.Item>
			)}

			{/** Displaying LDP notification system */}
			{!notificationsError && notifications.map(n =>
				<Menu.Item key={n.id} onClick={e => e.preventDefault()}>
					<NotificationCard notification={n} />
				</Menu.Item>
			)}

			{/* Displaying the empty notifications place holder */}
			{notifications.length === 0  && !notificationsError &&
				<EmptyNotificationsPlaceHolder width='250px' />
			}

			{/* All Notifications Page Button */}
			<Menu.Item key='seeAll'>
				<SeeAllButton />
			</Menu.Item>

		</Menu>
	)

	const showCount = state.showNotificationsCount && !notificationsError;

	const displayCount = notificationsCount
		+ uploadCompletionEntries.length
		+ (uploadList?.activeUploadTaskCount ?? 0);

	return (
		<Dropdown overlay={menuOverlay} trigger={['click']} className='notifications-list'>
			<Badge count={showCount ? displayCount : null} dot={notificationsError}>
				<NotificationOutlined className="notifications-icon" />
			</Badge>
		</Dropdown>
	)
}

export default NotificationsDropdownMenu;
