import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';
import { CommentOutlined } from '@ant-design/icons';

import EmptyPlaceHolder from '../../../Common/EmptyPlaceHolder';
import SingleComment from './SingleComment';


const CommentList = ({ comments }) => {

    const isEmptyList = !comments || (Array.isArray(comments) && comments.length === 0);

    return (
        <Fragment>

            {/* The comment list */}
            {!isEmptyList &&
                comments
                    .sort((a, b) => (new Date(a.createdOn)) - (new Date(b.createdOn)))
                    .map(comment =>
                        <SingleComment
                            key={nanoid()}
                            comment={comment}
                        />)
            }

            {/* The empty place holder */}
            {isEmptyList &&
                <EmptyPlaceHolder
                    icon={<CommentOutlined />}
                    text='No comments were added at the moment.'
                />
            }

        </Fragment>
    )
}

const areEqual = (prev, next) => {
    let result = prev.comments === next.comments
    return result;
}

export default React.memo(CommentList, areEqual);
