import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Button, Modal, Spin } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

import ErrorPage from '../../Error/ErrorPage';
import useCTProjectsContext from '../../../contexts/CTProjectsContext';
import useCompletionContext from '../../../contexts/CompletionContext';
import { CONTRIBUTOR_PROJECTS_PATH } from "../../../routes";

const EmptyPlaceHolder = 'Not Available';
const DonePlaceHolder = 'All Completions Are Done';

const CompletionInfo = () => {

    const {
        isLoading: projectLoading,
        error: projectError,
        selectedProject,
        fetchSingleProject,
    } = useCTProjectsContext();

    const {
        error: completionError,
        completion,
        hasNext,
    } = useCompletionContext();

    const { projectId } = useParams();
    const history = useHistory();

    useEffect(() => {
        fetchSingleProject(projectId);
        //eslint-disable-next-line
    }, [])

    let projectID = EmptyPlaceHolder;
    let projectName = EmptyPlaceHolder;

    if (selectedProject && !projectError) {
        projectID = selectedProject.id ? selectedProject.id : EmptyPlaceHolder;
        projectName = selectedProject.internalName ? selectedProject.internalName : EmptyPlaceHolder;
    }

    let completionIDLabel = EmptyPlaceHolder;
    let dataProcessorId = undefined;
    let reviewerId = undefined;

    if (!hasNext) completionIDLabel = DonePlaceHolder;
    else if (completion && !completionError) {
        completionIDLabel = completion.id;
        dataProcessorId = completion.dataProcessorId;
        reviewerId = completion.reviewerId ? completion.reviewerId : undefined;
    }

    const returnToProjectsList = () => history.push(CONTRIBUTOR_PROJECTS_PATH);
    return (
        <Spin spinning={projectLoading}>
            <ErrorPage
                error={projectError}
                redirect={{ text: 'Return To Projects', action: returnToProjectsList }}
            />

            {selectedProject && !projectError &&
                <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                    {selectedProject && <h3>Project ID:  {projectID}</h3>}
                    {selectedProject && <h3>Project Name:  {projectName}</h3>}
                    {selectedProject && <h3>Completion ID:  {completionIDLabel}</h3>}
                    {selectedProject && dataProcessorId && <h3>Data Processor ID:  {dataProcessorId}</h3>}
                    {selectedProject && reviewerId && <h3>Reviewer ID:  {reviewerId}</h3>}
                    {completion && completion.handlingTimeDP && <h3>Processing Time: {completion.handlingTimeDP}</h3>}
                </div>
            }
        </Spin>
    )
}

const CompletionInfoModal = ({ visible, onDone }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    return (
        <Modal
            title={<span><FileTextOutlined /> Completion Info</span>}
            width={isTabletOrMobile ? '100%' : '80%'}
            visible={visible}
            onCancel={onDone}
            footer={[
                <Button type='primary' key="ok" onClick={onDone}> Ok </Button>
            ]}>
            <CompletionInfo />
        </Modal>
    )
}

export { CompletionInfo as default, CompletionInfoModal }
