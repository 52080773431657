import React from 'react';
import { Form } from 'antd';

import LabelStudioConfigurationView from '../../Common/LabelStudioConfigurationView';

const { Item } = Form;

const ConfigInfo = () => {
    return (
        <Item
            name="xmlConfig"
            rules={[
                {
                    required: true,
                    message: "Please enter project xml configuration!",
                },
            ]}
            wrapperCol={{ flex: 1 }}
        >
            <LabelStudioConfigurationView />
        </Item>
    )
}

export default ConfigInfo;