import { useDispatch } from 'react-redux';
import { Row, Col, Tooltip, Button } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import ErrorAlert from "../../../Error/ErrorAlert";
import useSocketContext from '../../../../contexts/SocketContext';
import { initDowloadProjectFilesSession } from '../../../../store/downloadCenterSlice';


const RetryPreparingArchives = ({ archivesRetrievalError, projectID }) => {

    const dispatch = useDispatch();
    const {
        initAppSocket
    } = useSocketContext();

    const retryPreparingArchives = () => {
        initAppSocket();
        dispatch(initDowloadProjectFilesSession({ "projectID": projectID }))
    }

    return (
        <Row align="middle" gutter={[32]}>
            <Col span={20}>
                <ErrorAlert error={archivesRetrievalError} />
            </Col>

            <Col span={4}>
                {/**Retry preparing archives */}
                <Tooltip title="Retry Preparing Links" placement="rightBottom">
                    <Button
                        onClick={retryPreparingArchives}
                        icon={<ReloadOutlined />}
                        type="link"
                        shape="round"
                        size="large"
                    />
                </Tooltip>
            </Col>
        </Row>
    )
}

export default RetryPreparingArchives;