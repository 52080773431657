import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adhocApi } from '../adhoc';
import { downloadFile } from '../../components/Common/Utils';
import { message, Modal } from 'antd';
import { IMPORT_ADHOC_STATUS } from '../../Constants';
import { createErrorContent } from './helpers/helpers';
import BackgroundJobWorkingError from '../../components/Error/BackgroundJobWorkingError';


export const useAdhocDelete = ({ onError }) => {
    const queryClient = new useQueryClient()

    const mutateDelete = useMutation({
        mutationFn: adhocApi.deleteAdhocEntery,
        mutationKey: 'adhocDelete',
        onSuccess: () => {
            message.success("Adhoc record has been deleted successfuly", 3);
            queryClient.invalidateQueries({ queryKey: ['adhocs'] });
            queryClient.invalidateQueries({ queryKey: ['cost'] });
        },
        onError
    });

    return mutateDelete
}

export const useAdhocExport = ({ selectedProject, onError }) => {

    const mutateExport = useMutation({
        mutationFn: adhocApi.exportToFile,
        mutationKey: ['adhocExport', selectedProject?.id],
        onSuccess: ({ data }) => {
            downloadFile({
                file: data,
                projectId: selectedProject?.id,
                projectName: selectedProject?.name,
                fileType: "text/csv",
                fileExtension: "csv"
            })
        },
        onError
    });

    return mutateExport
}

export const useAdhocImport = ({ onError }) => {
    const queryClient = new useQueryClient()

    const mutateImport = useMutation({
        mutationFn: adhocApi.importFromFile,
        mutationKey: ['adhocImport'],
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['adhocs'] });
            queryClient.invalidateQueries({ queryKey: ['cost'] });
        },
        onError
    });

    return mutateImport
}

export const useAdhocCreate = ({ onError }) => {
    const queryClient = new useQueryClient()

    const mutateCreate = useMutation({
        mutationFn: adhocApi.createAdhocEntery,
        mutationKey: 'adhocCreate',
        onSuccess: () => {
            message.success("Adhoc task has been created successfuly", 3);
            queryClient.invalidateQueries({ queryKey: ['adhocs'] });
            queryClient.invalidateQueries({ queryKey: ['cost'] });
        },
        onError
    });

    return mutateCreate
}

export const useAdhocUpdate = ({ onError }) => {
    const queryClient = new useQueryClient()

    const mutateUpdate = useMutation({
        mutationFn: adhocApi.updateAhocEntery,
        mutationKey: 'adhocUpdate',
        onSuccess: () => {
            message.success("Adhoc record has been updated successfuly", 3);
            queryClient.invalidateQueries({ queryKey: ['adhocs'] });
            queryClient.invalidateQueries({ queryKey: ['cost'] });
        },
        onError
    });

    return mutateUpdate
}

export const useAdhocCheckImport = ({ onError }) => {
    const queryClient = new useQueryClient()

    const queryData = useMutation({
        mutationFn: adhocApi.getImportStatus,
        mutationKey: ['adhocImportResult'],
        onSuccess: (data) => {
            if (data?.statusCode === IMPORT_ADHOC_STATUS.success) {
                message.success("Import successful!");
                queryClient.invalidateQueries({ queryKey: ['adhocs'] })
            }
            else if (data?.statusCode === IMPORT_ADHOC_STATUS.failure) {
                const errorContent = createErrorContent(data.error);
                Modal.error(errorContent)
            }
        },
        retry: (failureCount, error) => {
            if (error instanceof BackgroundJobWorkingError) { return true; }
            return false;
        },
        onError
    });

    return queryData;
}
