import React from "react";
import { Result } from "antd";

function Success({ active, title, subTitle, ...props }) {
  if (!active) {
    return null;
  }

  return <Result status="success" title={title} subTitle={subTitle} {...props} />;
}

export default Success;
