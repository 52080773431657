import React from "react";
import { Collapse, Row, Col, Spin } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { DOWNLOAD_AUDIO_FILES_PRJECT_STATUS } from '../../../../Constants';
import DownloadAll from "./DownloadAll";
import ArchiveLinks from "./ArchiveLinks";
import RetryPreparingArchives from "./RetryPreparingArchives";

const { Panel } = Collapse;


const ItemToDownload = ({ projectID, projectEntry }) => {
    const projectArchiveLinks = projectEntry.archiveLinks;
    const projectStatus = projectEntry.status;
    const archivesRetrievalError = projectEntry.error;

    return (
        <Collapse
            ghost
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={['1']}
        >
            <Panel
                key="1"
                header={<span>Project {projectID}</span>}
                extra={
                    <Spin
                        spinning={
                            !(projectStatus === DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Done ||
                                projectStatus === DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed ||
                                archivesRetrievalError
                            )
                        }
                    />
                }>

                {/**Show Retry Button */}
                {(archivesRetrievalError || (DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed === projectStatus)) &&
                    <RetryPreparingArchives
                        archivesRetrievalError={archivesRetrievalError}
                        projectID={projectID}
                    />}

                {!archivesRetrievalError &&
                    projectArchiveLinks &&
                    projectArchiveLinks.length > 0 &&
                    DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed !== projectStatus &&

                    <Row align="middle" gutter={[32]}>
                        {/**Archive links */}
                        <Col span={15}>
                            <ArchiveLinks
                                projectArchiveLinks={projectArchiveLinks}
                                projectID={projectID}
                            />
                        </Col>

                        {/**Download All button */}
                        <Col span={4}>
                            <DownloadAll
                                projectArchiveLinks={projectArchiveLinks}
                                projectStatus={projectStatus}
                            />
                        </Col>
                    </Row>
                }
            </Panel>
        </Collapse>
    )
}

export default ItemToDownload;