import React from "react";
import { observer, inject } from "mobx-react";
import { types, getRoot } from "mobx-state-tree";

import ObjectBase from "./Base";
import ProcessAttrsMixin from "../../mixins/ProcessAttrs";
import ObjectTag from "../../components/Tags/Object";
import Registry from "../../core/Registry";
import AudioRecorder from "../../components/AudioRecord/AudioRecorder";


/**
 * Record tag records audio through web
 * @example
 * <!-- Audio transcription -->
 * <View>
 *  <Header value="$utterance"/>
 *  <Record name="text"/>
 * </View>
 * @name Record
 * @param {string} name of the element
 * @param {string} value of the element
 */

const TagAttrs = types.model({
    name: types.identifier,
    minsnr: types.maybeNull(types.string),
    maxsnr: types.maybeNull(types.string),
    loudness: types.maybeNull(types.string),
    truepeak: types.maybeNull(types.string),
    leadingdelay: types.optional(types.string, "0"),
    trailingdelay: types.optional(types.string, "0"),
    delayinstruction: types.maybeNull(types.string),
});

const Model = types
    .model({
        type: "audio",
        _value: types.optional(types.string, ""),
        playing: types.optional(types.boolean, false),
    })
    .views(self => ({
        get completion() {
            return getRoot(self).completionStore.selected;
        },
    }))
    .actions(self => ({
        fromStateJSON(obj, fromModel) {
            if (obj.value.choices) {
                self.completion.names.get(obj.from_name).fromStateJSON(obj);
            }

            if (obj.value.text) {
                self.completion.names.get(obj.from_name).fromStateJSON(obj);
            }
        },

        handlePlay() {
            self.playing = !self.playing;
            getRoot(self).completionStore.setMediaPlaying(self.playing);
        },
    }));

const RecordModel = types.compose("RecordModel", Model, TagAttrs, ProcessAttrsMixin, ObjectBase);

const HtxAudioView = ({ item }) => {
    return (
        <ObjectTag item={item}>
            <AudioRecorder
                utterance={item._value}
                tagID={item.name}
                togglePlaying={item.handlePlay}
                attrs={item}
            />
        </ObjectTag>
    );
};

const HtxRecord = inject("store")(observer(HtxAudioView));

Registry.addTag("record", RecordModel, HtxRecord);
Registry.addObjectType(RecordModel);

export { RecordModel, HtxRecord };