import React, { useCallback, useState } from 'react';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    message,
    Space
} from 'antd';
import { formatError } from '../../../../contexts/ResponseErrorFormatter';
import ErrorModal from '../../../Error/ErrorModal';
import { usePMProjectSingleContext } from '../../../../contexts/PMPrjSingleContext';
import { getFormattedUserLabel } from '../../../Common/Utils';
import { useAdhocCreate } from '../../../../api/mutations/useAdhocMutations';

const { TextArea } = Input;
const { Item } = Form;

const MINIMUM_RATE = 0.001;

const AdhocForm = () => {
    const [form] = Form.useForm();

    const {
        contributors,
        contributorsLoading,
        fetchContributors,
        contributorsCriteria,
        selectedProject,
    } = usePMProjectSingleContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleCancel = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const { mutate: mutateCreate, isLoading, error: createError, isError: isCreateError } = useAdhocCreate({ onError: showModal });

    const optionsSource = contributorsLoading ? [] : contributors.map(u => ({ label: getFormattedUserLabel(u), value: parseInt(u.id), key: u.id }));

    const fetchAssignedAdhocContributors = useCallback(() => {
        fetchContributors({
            showOnlyAssigned: true,
            assignedAH: true
        })
    }, [fetchContributors])

    const onSearchChanged = useCallback((txt) => {
        fetchContributors({ ...contributorsCriteria, search: txt.trim() })
    }, [contributorsCriteria, fetchContributors]);

    const handleAddTask = useCallback((values) => {
        const adhocAddedTaskData = structuredClone(values);

        if (values?.hours && values?.minutes) {
            const duration = `${values.hours.toString()}:${values.minutes.toString()}`;

            adhocAddedTaskData["duration"] = duration

            delete adhocAddedTaskData.hours
            delete adhocAddedTaskData.minutes
        }

        mutateCreate({ projectId: selectedProject?.id, adhocData: adhocAddedTaskData });
        form.resetFields()
    }, [mutateCreate, selectedProject?.id, form]);

    const handleAddTaskFailed = useCallback((errorInfo) => {
        message.error('Some Project Fields Are Missing! Please Check Error Messages', 3);
    }, []);

    const isIntegrWithNoDecimalNumber = useCallback((_, value) => {
        const regexPattern = /^[1-9]\d*$/;

        if (value && !regexPattern.test(value)) {
            return Promise.reject('Value must have integres only with no decimals!');
        }
        return Promise.resolve();
    }, []);

    return (
        <>
            {isCreateError &&
                <ErrorModal
                    error={formatError(createError, 'Failed To Create Task!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}
            <Form
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 18,
                }}
                layout="vertical"
                style={{
                    maxWidth: 800,
                }}
                onFinish={handleAddTask}
                onFinishFailed={handleAddTaskFailed}
                form={form}
            >
                <Item
                    label="Contributor"
                    rules={[
                        {
                            required: true,
                            message: "Please select a contributor!",
                        },
                    ]}
                    name="contributorId"
                >
                    <Select
                        placeholder="Select a contributor..."
                        showSearch
                        allowClear
                        loading={contributorsLoading}
                        options={optionsSource}
                        onSearch={onSearchChanged}
                        filterOption={() => true}
                        onFocus={fetchAssignedAdhocContributors}
                    />
                </Item>
                <Item
                    label="Description"
                    rules={[
                        {
                            required: true,
                            message: "Please type a description for the task!",
                        },
                    ]}
                    name="description"
                >
                    <TextArea rows={4} placeholder="Type a description for the task..." />
                </Item>
                <Item
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: "Please select the task's type!",
                        },
                    ]}
                    name="taskType"
                >
                    <Select placeholder="Select a type...">
                        <Select.Option value="testing">Testing</Select.Option>
                        <Select.Option value="hiring">Hiring</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                    </Select>
                </Item>
                <Item
                    shouldUpdate={(prevValues, currentValues) => prevValues.taskType !== currentValues.taskType}
                    noStyle
                >
                    {({ getFieldValue }) =>
                        getFieldValue('taskType') === 'other' ? (
                            <Item
                                label="Other Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter a type for the task!",
                                    }
                                ]}
                                name="otherType"
                            >
                                <Input placeholder="Type task's type" />
                            </Item>
                        ) : null
                    }
                </Item>
                <Item
                    label="Unit Type"
                    rules={[
                        {
                            required: true,
                            message: "Please select the task's unit!",
                        },
                    ]}
                    name="unitType"
                >
                    <Select placeholder="Select a unit...">
                        <Select.Option value="fixed">Fixed</Select.Option>
                        <Select.Option value="hourly">Hourly</Select.Option>
                    </Select>
                </Item>
                <Space size={'large'}>
                    <Item
                        shouldUpdate={(prevValues, currentValues) => prevValues.unitType !== currentValues.unitType}
                        noStyle
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('unitType') === 'hourly' ? (
                                <Item
                                    label="Hours"
                                    shouldUpdate={(prevValues, currentValues) => prevValues.unitType !== currentValues.unitType}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please set the number of hours required for the task!",
                                        },
                                        {
                                            validator: isIntegrWithNoDecimalNumber
                                        }
                                    ]}
                                    name="hours"
                                >
                                    <InputNumber min={0} />
                                </Item>
                            ) : null
                        }
                    </Item>
                    <Item
                        shouldUpdate={(prevValues, currentValues) => prevValues.unitType !== currentValues.unitType}
                        noStyle
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('unitType') === 'hourly' ? (
                                <Item
                                    label="Minutes"
                                    shouldUpdate={(prevValues, currentValues) => prevValues.unitType !== currentValues.unitType}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please set the number of minutes required for the task!",
                                        },
                                        {
                                            validator: isIntegrWithNoDecimalNumber
                                        }
                                    ]}
                                    name="minutes"
                                >
                                    <InputNumber min={0} max={59} />
                                </Item>
                            ) : null
                        }
                    </Item>
                </Space>
                <Item
                    label="Rate"
                    rules={[
                        {
                            required: true,
                            message: "Please set the task's rate!",
                        },
                        {
                            validator: (_, value) => {
                                if (value && value < MINIMUM_RATE) {
                                    return Promise.reject(`Value must be greater than or equal ${MINIMUM_RATE}!`);
                                }
                                return Promise.resolve();

                            }
                        }
                    ]}
                    name="rate"
                >
                    <InputNumber />
                </Item>
                <Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Add</Button>
                </Item>
            </Form>
        </>
    );
};

export default AdhocForm;