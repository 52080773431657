import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Space } from "antd";

import UtteranceViewer from './UtteranceViewer';
import RecordedAudio from './RecordedAudio';
import { ReactMediaRecorder } from "./ReactMediaRecorder";
import ErrorAlert from '../../../components/Error/ErrorAlert';
import { RecordTagAttrsSchema } from './schema';
import RecorderInstruction from './RecorderInstruction';

const createDefaultInstruction = (leadingDelay, trailingDelay) => {
  const instruction = [];

  if (parseInt(leadingDelay) > 0) {
    const leadingDelayText = `Please remain quiet for the next ${Math.round(leadingDelay / 1000)} seconds
    while we collect room ambient sound. You'll see a visual countdown timer to help you track the time.`;
    instruction.push(leadingDelayText);
  }

  if (parseInt(trailingDelay) > 0) {
    const trailingDelayText = `After you stop recording, please remain quiet for
    ${Math.round(trailingDelay / 1000)} more seconds.`;
    instruction.push(trailingDelayText);
  }

  return instruction;
};

const AudioRecorder = ({ attrs }) => {
  const {
    _value: utterance,
    name: tagID,
    minsnr,
    maxsnr,
    loudness,
    truepeak,
    leadingdelay,
    trailingdelay,
    delayinstruction,
    handlePlay,
  } = attrs;

  const { toUploadCompletionDataTasks, activeCompletionID } = useSelector(state => state.audio)

  const completionEntry = (activeCompletionID && toUploadCompletionDataTasks && activeCompletionID in toUploadCompletionDataTasks) ?
    toUploadCompletionDataTasks[activeCompletionID] :
    {};

  const [checkedInstruction, setCheckedInstruction] = useState([]);
  const [isInstructionVisible, setIsInstructionVisible] = useState(true);

  const delayInstructionAttributeUsed = Boolean(delayinstruction?.trim());
  const delayInstructionArray = delayInstructionAttributeUsed ?
  delayinstruction?.trim()?.split(';').filter(Boolean)
  : createDefaultInstruction(leadingdelay, trailingdelay);

  const hasInstruction = Boolean(delayInstructionArray.length);
  const shouldDisplayInstruction = hasInstruction && isInstructionVisible;

  const handleOnCheck = useCallback((checkedValues) => {
    setCheckedInstruction(checkedValues)
  }, []);

  const hideInstruction = useCallback(() => {
    if(hasInstruction) {
      setIsInstructionVisible(false);
    }
  }, [hasInstruction]);

  const showInstruction = useCallback(() => {
    if(hasInstruction) {
      setIsInstructionVisible(true);
      setCheckedInstruction([]);
    }
  }, [hasInstruction]);

  const validationResult = RecordTagAttrsSchema.validateSafe({
      minsnr,
      maxsnr,
      loudness,
      truepeak,
      leadingdelay,
      trailingdelay,
    }, { aboutEarly: true });

  const hasValidationError = !validationResult.isValid;
  const userhasCheckedInstruction = Boolean(delayInstructionArray.length === checkedInstruction.length);

  return (
    <>
      {utterance && <UtteranceViewer utterance={utterance} />}

      {hasValidationError && (
          <ErrorAlert error={{
            title: 'Validation Error',
            details: validationResult.errors
          }} />
      )}

      {!hasValidationError && (
        <Space
          direction="vertical"
          size="large"
          style={{
            display: 'flex',
            marginTop: '40px',
            alignItems: 'center',
            marginBottom: '40px',
          }}
        >

          {!completionEntry.review &&
          <>
            {shouldDisplayInstruction && (
              <RecorderInstruction
                instruction={delayInstructionArray}
                onCheckInstruction={handleOnCheck}
              />
            )}

              <ReactMediaRecorder
								leadingDelay={leadingdelay}
								trailingDelay={trailingdelay}
								tagID={tagID}
								currentRecUrl={completionEntry.url}
								instructionChecked={userhasCheckedInstruction}
								hasInstruction={hasInstruction}
								hideRecordingInstruction={hideInstruction}
								showRecordingInstruction={showInstruction}
								setMediaPlaying={handlePlay}
							/>
          </>
          }

					<RecordedAudio
						audioURL={completionEntry.url}
						review={completionEntry.review}
						setMediaPlaying={handlePlay}
					/>
				</Space>
			)}
		</>
	);
};

export default AudioRecorder;