import { Space } from "antd";
import Completions from "../../LSF/components/Completions/Completions";
import Predictions from "../../LSF/components/Predictions/Predictions";
import SideColumn from "../../LSF/components/SideColumn/SideColumn";
import { useMediaQuery } from 'react-responsive'

import styles from "./LabelStudioView.scss";

const CompletionResults = ({ store }) => {
    const cs = store.completionStore;
    const { settings } = store;
    const stMenu = settings.bottomSidePanel ? styles.menubsp : styles.menu;
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    return (
        <Space direction="vertical" className={isTabletOrMobile ? "center-cards" : ''}>
            <div className={stMenu + " ls-menu "} style={{ border: '10 solid black' }}>
                {store.hasInterface("completions:menu") && <Completions store={store} />}
                {store.hasInterface("predictions:menu") && <Predictions store={store} />}
                {store.hasInterface("side-column") && !cs.viewingAllCompletions && !cs.viewingAllPredictions && (
                    <SideColumn store={store} isTabletOrMobile={isTabletOrMobile} />
                )}
            </div>
        </Space>

    )
}

export default CompletionResults;