import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Alert, Space, Input, Spin } from "antd";

import Content from "../AntdWrappers/Content/Content";
import FormItem from "../AntdWrappers/FormItem/FormItem";
import Form from "../AntdWrappers/Form/Form";

import { useAuthContext } from '../../contexts/AuthContext';
import ErrorAlert from '../Error/ErrorAlert';
import { ROOT_PATH } from '../../routes';


const ResetPassword = () => {

  const {
    user, resetPassword, error, isLoading, success, clearSuccessMessage, clearError,
  } = useAuthContext();

  useEffect(() => {
    //Required to clear the success message which could be set by another component
    //and clear the current user because no signed user will enter this page.
    clearSuccessMessage();
    //eslint-disable-next-line
  }, []);

  const history = useHistory();

  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 16,
    },
  };

  const handleRestPassword = (values) => {
    resetPassword(values.email);
  }

  const handleBackToSignIn = () => {
    clearSuccessMessage();
    clearError();
    history.push(ROOT_PATH);
  }

  return (
    <Content alignVertically>

      {/* Redirecting the user back to root if the user already signed in and this will take him to the proper landing page to his. */}
      {user && <Redirect to={ROOT_PATH} />}

      {!user &&
        <Spin spinning={isLoading}>
          <Space align="center" direction="vertical">

            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleRestPassword}
              border>

              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
                validateTrigger={"onBlur"}>
                <Input />
              </FormItem>

              <FormItem {...tailLayout}>
                <Button type="primary" htmlType="submit">Reset password</Button>
              </FormItem>

            </Form>

            <Button
              type='link'
              onClick={handleBackToSignIn}>
              Back To Sign In
            </Button>

            {success && success.isSuccess && <Alert message={success.message} type="success" />}

            <ErrorAlert error={error} />

          </Space>
        </Spin>
      }

    </Content>)
}

export default ResetPassword;
