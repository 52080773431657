import React from 'react';
import { Row, Col, Space, Typography, Divider } from 'antd';
import { RightOutlined, NotificationFilled } from '@ant-design/icons';
import { formatRoute } from "react-router-named-routes";

import ClearNotification from '../../Page/NotificationPageCard/ClearNotification';
import CardActionButton from '../../Page/NotificationPageCard/CardActionButton';

import TextHiglighter from '../../TextHiglighter';
import { COMPLETION_DP_PATH_ID } from '../../../../routes';
import { NOT_AVAIALBLE, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import NotificationDate from '../../NotificationDate';

const { Text } = Typography;

const CompletionRejected = ({ notification }) => {
    const content = notification.content;
    const projectName = <TextHiglighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />

    const goToProject = () => {
        window.open(formatRoute(COMPLETION_DP_PATH_ID, { projectId: content.project_id, compId: content.completion_id }))
    }

    return (
        <div>
            <Row gutter={[4, 16]}>
                <Col span={23}>
                    <Space direction="vertical">
                        <Text>A completion from project {projectName} was rejected.</Text>
                        <NotificationDate date={notification?.createdOn} />
                    </Space>
                </Col>
                <Col span={1}>
                    <NotificationFilled style={{ color: NOTIFICATIONS_CARDS_COLORS.CompletionRejection }} />
                </Col>
            </Row>
            <Divider style={{ margin: '8px 0' }} />
            <Row >
                <Col span={16}>
                    <CardActionButton
                        key='goToDp'
                        icon={<RightOutlined style={{ fontSize: "80%" }} />}
                        text={"Process"}
                        onClick={goToProject}>
                    </CardActionButton>
                </Col>

                <Col span={8}>
                    <ClearNotification notificationID={notification.id} />
                </Col>
            </Row>
        </div >
    )
}

export default CompletionRejected;