import { Form, DatePicker } from "antd";

const { Item } = Form;

const DateOfBirth = () => {
    const dateFormat = 'DD/MM/YYYY';

    return (
        <Item
            label="Birth Date"
            name="dateOfBirth"
            rules={[
                {
                    required: true,
                    message: "Please set your date of birth!",
                },
            ]}>
            <DatePicker
                format={dateFormat}
            />
        </Item>

    );
};

export default DateOfBirth