
import { Divider } from "antd";
import ProjectField from "../helper/ProjectField";
import ProjectFieldSelect from "../helper/ProjectFieldSelect";
import { factoryUnitsAndCostOptions } from "../../../Create/UnitAndCost/helper";

const ProjectUnitsAndCost = ({ selectedProject }) => {

    const projectUnitsAndCostoptions = factoryUnitsAndCostOptions({ projectService: selectedProject.service });
    const isEditable = selectedProject.allowCostFieldsEdit;

    return (
        <>
            <Divider>Units & Cost</Divider>

            {/*Data Processing Unit & Cost */}
            <ProjectFieldSelect
                labelText='Data Processing Unit:'
                fieldName='dataProcessingCostUnit'
                editable={isEditable}
                options={projectUnitsAndCostoptions} />
            <ProjectField labelText='Data Processing Cost:' fieldName='dataProcessingCost' editable={isEditable} />

            {/* Reviewer Unit & Cost */}
            <ProjectFieldSelect
                labelText='Review Unit:'
                fieldName='reviewCostUnit'
                editable={isEditable}
                options={projectUnitsAndCostoptions} />
            <ProjectField labelText='Review Cost:' fieldName='reviewCost' editable={isEditable} />

        </>
    )
}

export default ProjectUnitsAndCost;