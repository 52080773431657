import React, { Fragment, useState } from 'react';
import { Button, Row, Col } from "antd";
import { FileTextOutlined, CommentOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { CompletionInfoModal } from './CompletionInfo'
import { GuidelinesModal } from '../Guidelines';
import { CommentsModal } from './Comments/CommentsSection';


import '../../Styles/Button.scss';
import { useMediaQuery } from 'react-responsive'

const CompletionControlPanel = ({ contributorType }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })
    const [state, setState] = useState({
        showGuidelines: false,
        showCompletionInfo: false,
        showComments: false,
    })

    const handleOnShowCommentsModal = (value) => setState(ps => ({ ...ps, showComments: value }))
    const handleOnShowGuidelinesModal = (value) => setState(ps => ({ ...ps, showGuidelines: value }))
    const handleOnShowCompletionInfoModal = (value) => setState(ps => ({ ...ps, showCompletionInfo: value }))

    return (
        <Fragment>

            <GuidelinesModal
                visible={state.showGuidelines}
                onDone={() => handleOnShowGuidelinesModal(false)}
            />

            <CompletionInfoModal
                visible={state.showCompletionInfo}
                onDone={() => handleOnShowCompletionInfoModal(false)}
            />

            <CommentsModal
                visible={state.showComments}
                onDone={() => handleOnShowCommentsModal(false)}
                contributorType={contributorType}
            />

            {isTabletOrMobile &&
                <div
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        width: "100%",
                        height: "50px",
                        padding: "10px",
                        borderTop: "1px solid #E7E7E7"
                    }}>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col span={8}>
                            <Button shape='circle' size='large' icon={<FileTextOutlined />} onClick={() => handleOnShowGuidelinesModal(true)} />
                        </Col>
                        <Col span={8}>
                            <Button shape='circle' size='large' icon={<InfoCircleOutlined />} onClick={() => handleOnShowCompletionInfoModal(true)} />
                        </Col>
                        <Col span={3}>
                            <Button shape='circle' size='large' icon={<CommentOutlined />} onClick={() => handleOnShowCommentsModal(true)} />
                        </Col>
                    </Row>
                </div>
            }

        </Fragment>
    )
}

export default CompletionControlPanel;
