import React from "react";
import { Link } from "react-router-dom";
import { Input, Button } from "antd";

import { RESET_PASSWORD_PATH, SEND_INVITATION_PATH } from '../../../routes';
import Form from "../../AntdWrappers/Form/Form";
import FormItem from "../../AntdWrappers/FormItem/FormItem";
import "../Auth.scss"

function LoginWithEmailPassword({ signInByEmailAndPassword }) {

  const onSubmit = ({ email, password }) => {
		signInByEmailAndPassword(email, password);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Form
      {...layout}
      name="basic"
      onFinish={onSubmit}
    >
      <FormItem
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
        validateTrigger={"onBlur"}
      >
        <Input autoComplete="new-password" />
      </FormItem>

      <FormItem
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </FormItem>

      <FormItem {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Sign in
        </Button>
      </FormItem>

      <div className="auth-footer">
        <Link to={RESET_PASSWORD_PATH}>Forgot Password?!</Link>
        <div>
          <span>New user? </span><Link to={SEND_INVITATION_PATH}>Sign up</Link>
        </div>
      </div>
    </Form>
  );
}

export default LoginWithEmailPassword;
