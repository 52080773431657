import React from "react";
import { inject, observer } from "mobx-react";
import { types } from "mobx-state-tree";
import ObjectTag from "../../components/Tags/Object";
import Registry from "../../core/Registry";
import { TextAreaLabelShortCuts } from "../../components/TextAreaLabels/TextAreaLabelShortCuts";

const TagAttrs = types.model({
  alias: types.identifier,
  value: types.string,
  background: types.maybeNull(types.string),
  place: types.enumeration(['before', 'after', 'wrap', 'insert']),
});

const Model = types.model("TextAreaLabelShortCutsModal", {
  type: "textarealabelsshortcuts"
});

const TextAreaLabelShortCutsModal = types.compose(
  "TextAreaLabelShortCutsModal",
  Model,
  TagAttrs
);

const HtxTextAreaLabelShortCutsView = observer(({ item }) => {
  return (
    <ObjectTag item={item}>
      <TextAreaLabelShortCuts {...item} />
    </ObjectTag>
  );
});

const HtxTextAreaLabelShortCuts = inject("store")(
  HtxTextAreaLabelShortCutsView
);

Registry.addTag(
  "textarealabel",
  TextAreaLabelShortCutsModal,
  HtxTextAreaLabelShortCuts
);
Registry.addObjectType(TextAreaLabelShortCutsModal);

export { TextAreaLabelShortCutsModal, HtxTextAreaLabelShortCuts };
