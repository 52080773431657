import { Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive';

import CardActionButton from './CardActionButton';
import RestoreNotification from './RestoreNotification';
import ClearNotification from './ClearNotification';
import CardHeader from './CardHeader';

import TextHilighter from '../../TextHiglighter';
import { COMPLETION_DP_PATH, COMPLETION_RV_PATH } from '../../../../routes';
import { TYPE_DATA_PROCESSOR, TYPE_REVIEWER, NOT_AVAIALBLE, ROLES_TRANSLATOR, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import NotificationDate from '../../NotificationDate';

const ProjectAssignmentCard = ({ notification }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    const content = notification.content;
    const projectName = <TextHilighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />;
    const projectID = <TextHilighter text={content.project_id ? content.project_id : NOT_AVAIALBLE} />;
    const role = <TextHilighter text={content.role ? ROLES_TRANSLATOR[content.role] : NOT_AVAIALBLE} />;
    const cardActions = [];

    const goToProject = () => {
        if (content.role === TYPE_DATA_PROCESSOR)
            window.open(formatRoute(COMPLETION_DP_PATH, { projectId: content.project_id }))
        else if (content.role === TYPE_REVIEWER)
            window.open(formatRoute(COMPLETION_RV_PATH, { projectId: content.project_id }))
    }

    if (content.role === TYPE_DATA_PROCESSOR) {
        cardActions.push(
            <CardActionButton
                key='goToDp'
                text={`Process ${isTabletOrMobile ? '' : 'Project'}`}
                icon={<RightOutlined />}
                onClick={goToProject}
            />)
    }
    else if (content.role === TYPE_REVIEWER) {
        cardActions.push(
            <CardActionButton
                key='goToRV'
                text={`Review ${isTabletOrMobile ? '' : 'Project'}`}
                icon={<RightOutlined />}
                onClick={goToProject}
            />)
    }

    if (!notification.cleared)
        cardActions.push(<ClearNotification notificationID={notification.id} />);
    else
        cardActions.push(<RestoreNotification notificationID={notification.id} />);

    return (
        <Card className="notification-card" actions={cardActions}>

            <CardHeader text={'New Project Assignment'} color={NOTIFICATIONS_CARDS_COLORS.ProjectAssignment} />

            <p>You have been assigned as a {role} to the project {projectName} with ID {projectID}.</p>
            <NotificationDate date={notification?.createdOn} />
        </Card>
    )
}

export default ProjectAssignmentCard