import React, { Fragment, useState, useEffect } from 'react';
import { Input } from 'antd';

import XmlConfigurationEditor from './XmlConfigurationEditor';
import LabelStudioView from '../LabelStudioView/LabelStudioView';
import Error from "../Error/Error";
import ErrorBoundary from '../Error/ErrorBoundary';
import LabelStudioHandler from '../../contexts/helpers/LabelStudioHandler';
import { sanitizeText } from './RichGuidelinesEditor';


const DEFAULT_XML = `<View>
<Image name="image" value="$image"/>
<RectangleLabels name="label" toName="image">
  <Label value="Airplane" background="green"/>
  <Label value="Car" background="blue"/>
</RectangleLabels>
</View>`;

const DEFAULT_TEST_DATA = {
    text: `{
    "image": "https://app.heartex.ai/static/samples/sample.jpg"
}`,
    json: { "image": "https://app.heartex.ai/static/samples/sample.jpg" },
    jsonError: false
};

const lsfHandler = new LabelStudioHandler();

const sanitizeXMLAndTestData = async (text) => {
    const cleanText = await sanitizeText((text));
    return prepareText(cleanText);
}

const prepareText = (inputText) => {
    const [key, value] = inputText.split('":');
    const processedValue = replaceQuotes(value);

    return `${key}":${processedValue}`;
};

const replaceQuotes = (originalValue) => {
    const replacedValue = originalValue.replace(/"/g, "'");
    const [firstOccurrence, lastOccurrence] = getQuoteIndices(originalValue);

    return replaceAt(replacedValue, [firstOccurrence, lastOccurrence], '"');
};

const getQuoteIndices = (value) => {
    const indices = [];
    let startIndex = value.indexOf('"');

    while (startIndex !== -1) {
        indices.push(startIndex);
        startIndex = value.indexOf('"', startIndex + 1);
    }

    return [indices[0], indices[indices.length - 1]];
};

const replaceAt = (text, indices, replacement) => {
    let result = text;
    for (const index of indices.reverse()) {
        result = result.slice(0, index) + replacement + result.slice(index + 1);
    }
    return result;
};

const InterfacePreview = ({ config, data, jsonError }) => {

    const [state, setState] = useState({
        error: null,
    })

    useEffect(() => {
        try {
            setState(ps => ({ ...ps, error: null }));
            lsfHandler.initStoreWithPreviewData(config, data);
        }
        catch (error) {
            setState(ps => ({ ...ps, error }))
        }
        //eslint-disable-next-line
    }, [config, data])

    const store = lsfHandler.getStore();

    return (
        <Fragment>
            <Error active={state.error || jsonError} title="Invalid xml configuration or input json data format!" />
            {store && config && !state.error && !jsonError &&
                <ErrorBoundary>
                    <LabelStudioView store={store} columView={true} />
                </ErrorBoundary>
            }
        </Fragment>
    );
}

const LabelStudioConfigurationView = ({ value, onChange }) => {

    const [testData, setTestData] = useState(DEFAULT_TEST_DATA);

    useEffect(() => {
        if (value === undefined || value === null)
            onChange(DEFAULT_XML);

        // eslint-disable-next-line
    }, [value]);

    const handleTestDataOnChange = async (evt) => {

        const text = evt.target.value;
        try {
            const cleanText = process.env.REACT_APP_SANITIZATION === 'true' ? await sanitizeXMLAndTestData(text) : text;
            let parsedData = JSON.parse(cleanText)

            setTestData({ text: cleanText, json: parsedData, jsonError: false })
        }
        catch (e) {
            setTestData({ text, json: {}, jsonError: true });
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>

            {/* Left part of the view containing the xml editor and the json test data. */}
            <div style={{ flex: '1', marginRight: '10px' }}>

                <h4>Configuration</h4>
                <XmlConfigurationEditor
                    value={value}
                    onChange={onChange}
                />

                <div style={{ marginTop: '10px' }}>
                    <h4>Test Data</h4>
                    <Input.TextArea
                        rows={3}
                        value={testData.text}
                        onChange={handleTestDataOnChange}
                    />
                </div>

            </div>

            {/* The right part containing LSF studio component */}
            <div style={{ flex: '2' }}>
                <h4>Interface Layout</h4>
                <InterfacePreview
                    config={value === null || value === undefined ? DEFAULT_XML : value}
                    data={testData.json}
                    jsonError={testData.jsonError}
                />
            </div>

        </div>
    )
}

export default LabelStudioConfigurationView;