import React, { useEffect, Fragment, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Divider, Spin, Button, Modal } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

import { RichGuidelinesDisplay, sanitizeText } from '../Common/RichGuidelinesEditor';
import ErrorPage from '../Error/ErrorPage';
import useCTProjectsContext from '../../contexts/CTProjectsContext';
import { CONTRIBUTOR_PROJECTS_PATH } from "../../routes";

const Guidelines = ({ hideHeader, projectID }) => {

    const {
        isLoading,
        error,
        selectedProject,
        fetchSingleProject
    } = useCTProjectsContext();

    const { projectId } = useParams();
    const prjID = projectID ? projectID : projectId
    const history = useHistory();
    const [state, setState] = useState({
        cleanGuidelines: '',
    })

    useEffect(() => {
        fetchSingleProject(prjID);
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedProject && selectedProject.guidelines)
            sanitizeText(selectedProject.guidelines)
                .then(cleanText => setState(ps => ({ ...ps, cleanGuidelines: cleanText })));
        else
            setState(ps => ({ ...ps, cleanGuidelines: '' }));

    }, [selectedProject])


    const returnToProjectsList = () => history.push(CONTRIBUTOR_PROJECTS_PATH);


    return (
        <Spin spinning={isLoading}>
            {!hideHeader &&
                <Fragment>
                    {selectedProject && <h3>Project ID:  {selectedProject.id}</h3>}
                    {selectedProject && <h3>Project Name:  {selectedProject.internalName}</h3>}

                    <Divider />

                    <h1>Guidelines</h1>
                </Fragment>
            }

            <ErrorPage
                error={error}
                redirect={{ text: 'Return To Projects', action: returnToProjectsList }} />

            {!error && selectedProject && selectedProject.guidelines &&
                <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                    <RichGuidelinesDisplay value={state.cleanGuidelines} />
                </div>
            }

            {selectedProject && !selectedProject.guidelines && !error &&
                <h1>Guidelines are not available for this project.</h1>}

        </Spin>
    )
}

const GuidelinesModal = ({ visible, onDone, projectId }) => {
    return (
        <Modal
            title={<span><FileTextOutlined /> Guidelines</span>}
            width='100%'
            visible={visible}
            onCancel={onDone}
            footer={[
                <Button type='primary' key="ok" onClick={onDone}> Ok </Button>
            ]}>
            <Guidelines hideHeader={true} projectID={projectId} />
        </Modal>
    )
}

export { Guidelines as default, GuidelinesModal }
