import React, { useContext } from 'react';
import { Col, Button, Space, message } from 'antd'
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons'
import { isEmptyEditorValue } from '../../../Common/RichGuidelinesEditor';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';

const ConsentControlButtons = ({ setState, state }) => {
    const {
        errorUpdate,
        addConsentAgreement,
    } = useContext(PMPrjSingleContext);

    const { editMode, editedConsent, originalConsent } = state;

    const handleOnEditConsentBtnClick = () => {
        setState(ps => ({
            ...ps,
            editMode: true,
            // Resetting the editor value to the latest saved consent in the database.
            editedConsent: {
                ...originalConsent
            },
        }))
    };

    const handleOnUpdateClick = async () => {

        const isEmpty = isEmptyEditorValue(editedConsent.content);

        if (isEmpty) {
            message.warning("Please enter consent agreement.");
            return;
        }

        if (editedConsent.agree.trim() === '' || editedConsent.disagree.trim() === '') {
            message.warning("Please enter missing data.");
            return;
        }

        const consentData = {
            content: editedConsent.content,
            agree: editedConsent.agree.trim(),
            disagree: editedConsent.disagree.trim()
        }

        if (JSON.stringify(editedConsent) === JSON.stringify(originalConsent)) {
            message.warning("Please update consent data.");
            return;
        } else {
            addConsentAgreement(consentData)
                .then(() => {
                    if (!errorUpdate) {
                        setState(ps => ({ ...ps, editMode: false }));
                        message.success('Consent updated successfully.');
                    }
                })
                .catch(e => {
                    //Do nothing the error is reported in the context.
                });
            setState(ps => ({
                ...ps,
                activateAgreement: true,
            }))
        }


    }

    const handleOnCancelClick = () => {
        setState(ps => ({
            ...ps,
            editMode: false,
            editedConsent: {
                ...editedConsent
            }
        }));
    }

    return (
        <Col>
            {!editMode &&
                <Button
                    size='small'
                    type='primary'
                    ghost={editMode}
                    onClick={handleOnEditConsentBtnClick}>
                    <EditOutlined /> Edit Consent
                </Button>
            }

            {editMode &&
                <Space>
                    <Button
                        size='small'
                        type='primary'
                        ghost={editMode}
                        icon={<SaveOutlined />}
                        onClick={handleOnUpdateClick}>
                        Save
                    </Button>

                    <Button
                        size='small'
                        type='danger'
                        ghost
                        icon={<CloseOutlined />}
                        onClick={handleOnCancelClick}>
                        Cancel
                    </Button>
                </Space>
            }
        </Col>
    )



}

export default ConsentControlButtons;