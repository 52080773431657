import React from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const XmlConfigurationEditor = ({ value, onChange }) => {
    return (
        <AceEditor
            placeholder="XML Config"
            mode="xml"
            theme="tomorrow"
            fontSize={14}
            highlightActiveLine={true}
            value={value}
            onChange={onChange}
            setOptions={{
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
        />
    )
}

export default XmlConfigurationEditor
