import { lxtBackend } from '../../../api/utils';

export const queryGetLatestServiceAgreements = async () => {
	const url = `service-agreements/latest`;
	const resp = await lxtBackend.get(url);
	return resp.data;
};

export const queryGetServiceAgreementsForContributor = async ({ userId }) => {
	const url = `service-agreements/consents/${userId}`;
	const resp = await lxtBackend.get(url);
	return resp.data;
};

export const signLatestServiceAgreement = async (payload) => {
	const { agreed } = payload;
	const url = `service-agreements/latest`;
	const resp = await lxtBackend.post(url, { agreed });
	return resp.data;
};
