import { usePMProjectSingleContext } from '../../../../contexts/PMPrjSingleContext';
import { IfFeature } from "../../../../lib/featureToggles";
import ErrorModal from '../../../Error/ErrorModal';

import ProjectUnitsAndCost from './ProjectInfoComponents/ProjectUnitsAndCost';
import ProjectDates from './ProjectInfoComponents/ProjectDates';
import ProjectCompletionsOptions from './ProjectInfoComponents/ProjectCompletionsOptions';
import CompletionSkipAndRejectReasons from './ProjectInfoComponents/CompletionSkipAndRejectReasons';
import ProjectDetails from './ProjectInfoComponents/ProjectDetails';

const ProjectInfo = () => {

    const { selectedProject, errorUpdate, clearUpdateError } = usePMProjectSingleContext();

    return (
        <>
            <ErrorModal error={errorUpdate} onDone={clearUpdateError} />
            <ProjectDetails />

            <ProjectDates />

            <ProjectCompletionsOptions selectedProject={selectedProject} />

            <IfFeature isEnabled feature="project-unit-and-cost">
                <ProjectUnitsAndCost selectedProject={selectedProject} />
            </IfFeature>

            <CompletionSkipAndRejectReasons />
        </>
    )
}

export default ProjectInfo;
