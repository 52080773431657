import { Modal, Button } from 'antd';
import { CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { nanoid } from 'nanoid';

const errorIcon = <CloseCircleFilled style={{ color: 'red', marginRight: '10px' }} />;
const infoIcon = <InfoCircleFilled style={{ color: '#1890ff', marginRight: '10px' }} />

const ErrorModal = ({ error, moreInfo, onDone, visible = true }) => {

    const hasError = Boolean(error);

    return (
        <Modal
            title={<span style={{ fontSize: '1.5rem' }}> {errorIcon} Error Occurred</span>}
            visible={visible && error && error.status !== 401}
            onCancel={onDone}
            footer={[<Button type='primary' key="OK" onClick={onDone}>Ok</Button>]}
        >
            <div style={{ fontSize: '1.2rem', marginBottom: '10px' }}>{error?.title}</div>

            {hasError &&
                <ul>
                    {error.details
                        .map(detail =>
                            <li
                                key={nanoid()}
                                style={{ marginBottom: '10px', }}
                            >
                                {detail}
                            </li>
                        )}
                </ul>
            }

            {/* A custom message displayed above the error */}
            {moreInfo && <div style={{ marginTop: '30px' }}>{infoIcon} {moreInfo}</div>}
        </Modal>
    )
}

export default ErrorModal;
