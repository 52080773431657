import React, { useState, useContext, useCallback } from 'react';
import { Row, Col, Button, Input, InputNumber, Form, Switch, message } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import FieldLabel from '../../../../Common/FieldLabel';
import { TrueIcon, FalseIcon } from '../../../../Common/CustomIcons';
import PMPrjSingleContext from '../../../../../contexts/PMPrjSingleContext';
import { LABEL_SPAN, VALUE_SPAN } from './Constants';
import y from '../../../../../lib/yupExtended';


const ProjectField = ({ labelText, fieldName, editable, calculatedValue }) => {
    const { selectedProject, updateProjectValue } = useContext(PMPrjSingleContext);
    const fieldValue = fieldName ? selectedProject[fieldName] : calculatedValue;

    const isCostInput = fieldName === "dataProcessingCost" || fieldName === "reviewCost";
    const numberInputMinValue = isCostInput ? 0 : 1;
    const numberSchema = isCostInput ?
        y.number().required('Please input a number!').maxDecimalPlaces(3)
        : y.number().integer('Value must be an integer (no decimal places allowed)!').required('Please input a number!');

    const validateCostInput = useCallback((value) => {
        try {
            y.number().maxDecimalPlaces(3).validateSync(value);
            return true;
        } catch (error) {
            const errorMessage = error.errors[0];
            message.warning(errorMessage);
            return false;
        }
    }, []);

    const validateTaskCompletionsInput = useCallback((value) => {
        try {
            y.number().integer("Value must be an integer (no decimal places allowed)!").validateSync(value)
            return true;
        } catch (error) {
            const errorMessage = error.errors[0];
            message.warning(errorMessage);
            return false;
        }
    }, []);

    const numberInputValidationRules = [
        {
            validator: async (_, value) => {
                try {
                    await numberSchema.validate(value);
                } catch (error) {
                    throw new Error(error.message);
                }
            },
        },
    ];

    const [state, setState] = useState({
        editMode: false,
        inputValue: fieldValue
    })

    const onFieldChanged = () => {
        const trimmedInputValue = typeof (state.inputValue) === "string" ? state.inputValue.trim() : state.inputValue;

        if (!trimmedInputValue || trimmedInputValue === '' || trimmedInputValue < 0) {
            message.warning('Invalid value. Project is not updated!');
            setState(ps => ({
                ...ps,
                editMode: false
            }))
            return;
        }

        if (trimmedInputValue === fieldValue) {
            message.warning('Value not updated!')
            setState(ps => ({
                ...ps,
                editMode: false
            }))
            return;
        }

        if (typeof fieldValue === 'number' && isNaN(Number(trimmedInputValue))) {
            message.warning('Submitted value is not a number!');
            setState(ps => ({
                ...ps,
                editMode: false
            }))
            return;
        }

        if (isCostInput && !validateCostInput(trimmedInputValue)) {
            setState(ps => ({
                ...ps,
                editMode: false
            }))
            return;
        }

        if (!isCostInput && !validateTaskCompletionsInput(trimmedInputValue)) {
            setState(ps => ({
                ...ps,
                editMode: false
            }))
            return;
        }

        updateProjectValue(fieldName, trimmedInputValue);
        setState(ps => ({
            ...ps,
            editMode: false
        }))
    }

    return (
        <Row style={{ marginBottom: '20px' }} align='middle'>

            {/* The label of the field */}
            <Col span={LABEL_SPAN}><FieldLabel text={labelText} /></Col>

            {/* Display value of the field */}
            {!state.editMode &&
                <Col span={VALUE_SPAN}>

                    {(typeof fieldValue === 'string' || typeof fieldValue === 'number') &&
                        <span> {fieldValue}</span>
                    }

                    {typeof fieldValue === 'boolean' && (fieldValue ? <TrueIcon /> : <FalseIcon />)}

                    {(fieldValue === undefined || fieldValue === '' || fieldValue === null) && 'Not Available'}

                    {/* The pen edit button */}
                    {editable &&
                        <Button type='text' onClick={() => setState(ps => ({ ...ps, editMode: true }))}
                        >
                            <EditOutlined />
                        </Button>
                    }

                </Col>
            }

            {/* The edit form */}
            {
                state.editMode &&
                <Col span={VALUE_SPAN}>

                    {/* Handling string types */}
                    {(typeof fieldValue === 'string' || fieldValue === undefined || fieldValue === '' || fieldValue === null) &&
                        <Form onFinish={onFieldChanged}>
                            <Form.Item
                                name="newValue"
                                initialValue={fieldValue}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please input the new field value!',
                                    },
                                ]}>
                                <Input
                                    placeholder={fieldValue}
                                    autoFocus={true}
                                    value={state.inputValue}
                                    onChange={e => setState(ps => ({ ...ps, inputValue: e.target.value }))}
                                    onBlur={onFieldChanged} />
                            </Form.Item>
                        </Form>
                    }

                    {typeof fieldValue === 'number' &&
                        <Form onFinish={onFieldChanged}>
                            <Form.Item
                                name="newValue"
                                initialValue={fieldValue}
                                rules={numberInputValidationRules}>
                                <InputNumber
                                    min={numberInputMinValue}
                                    placeholder={fieldValue}
                                    autoFocus={true}
                                    value={state.inputValue}
                                    onChange={e => setState(ps => ({ ...ps, inputValue: e }))}
                                    onBlur={onFieldChanged} />
                            </Form.Item>
                        </Form>
                    }

                    {typeof fieldValue === 'boolean' &&
                        <Switch
                            autoFocus={true}
                            defaultChecked={fieldValue}
                            onChange={e => {
                                if (e !== fieldValue)
                                    updateProjectValue(fieldName, e);
                                setState(ps => ({
                                    ...ps,
                                    editMode: false
                                }))
                            }}
                            onBlur={() => setState(ps => ({ ...ps, editMode: false }))}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    }

                </Col>
            }

        </Row >
    )
}

export default ProjectField;
