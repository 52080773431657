export const ROLE_DATA_PROCESSOR = 'dp';
export const ROLE_DATA_REVIEWER = 'rv';
export const ROLE_PROJECT_MANAGER = "pm"
export const ROLE_CONTRIBUTOR = "contributor"

export const PROJ_STATUS_ACTIVE = 0;
export const PROJ_STATUS_COMPLETED = 1;
export const PROJ_STATUS_CANCELLED = 2;

export const COMPLETION_STATUS_NEW = 0;
export const COMPLETION_STATUS_IN_PROGRESS = 1;
export const COMPLETION_STATUS_READY = 2;
export const COMPLETION_STATUS_IN_REVIEW = 3;
export const COMPLETION_STATUS_COMPLETED = 4;

export const COMPLETION_STATUS_VALUES = {
    [COMPLETION_STATUS_NEW]: 'New',
    [COMPLETION_STATUS_IN_PROGRESS]: 'In Progress',
    [COMPLETION_STATUS_READY]: 'Ready',
    [COMPLETION_STATUS_IN_REVIEW]: 'In Review',
    [COMPLETION_STATUS_COMPLETED]: 'Completed',
}

export const COMPLETION_STATUS_RESET_VALUES = {
    [COMPLETION_STATUS_NEW]: 'New',
    [COMPLETION_STATUS_IN_PROGRESS]: 'In Progress',
    [COMPLETION_STATUS_READY]: 'Ready',
    [COMPLETION_STATUS_IN_REVIEW]: 'In Review',
}


export const COMPLETION_RV_ACTION = {
    Accept: 1,
    AcceptWithModifications: 3,
    Reject: 2,
    SaveDraft: 4,
}

export const COMPLETION_DP_ACTION = {
    Submit: 1,
    SaveDraft: 2,
    Skip: 3,
}

export const COMPLETION_RV_SUBMIT_STATUS = {
    Accept: 0,
    AcceptWithModifications: 2,
    Reject: 1,
}

export const UPLOAD_PROCESS_STATUS_CODES = {
    NotStarted: "NotStarted",
    FetchingUrl: "FetchingUrl",
    UploadingFile: "UploadingFile",
    SubmittingResult: "SubmittingResult",
    Failure: "Failure",
    // No need for success as the completion upload entry is removed once it is successfully done.
}

export const DOWNLOAD_AUDIO_FILES_PRJECT_STATUS = {
    NotStarted: "NotStarted",
    PreparingArchives: "PreparingArchives",
    Done: "Done",
    Failed: "Failure",
}

export const SOCKET_DOWNLOAD_PROJECT_FILES_STATUS = {
    pending: 'PENDING',
    in_progress: 'IN_PROGRESS',
    completed: 'SUCCESS',
    failed: 'FAILURE'
}

export const SOCKET_TO_STATUS_TRANSLATOR = {
    [SOCKET_DOWNLOAD_PROJECT_FILES_STATUS.pending]: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.NotStarted,
    [SOCKET_DOWNLOAD_PROJECT_FILES_STATUS.in_progress]: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.PreparingArchives,
    [SOCKET_DOWNLOAD_PROJECT_FILES_STATUS.completed]: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Done,
    [SOCKET_DOWNLOAD_PROJECT_FILES_STATUS.failed]: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed,
}

export const UPLOAD_PROCESS_ERROR_STATUS_CODES = {
    UnexpectedError: 1,
    ValidationFailed: 2,
}

export const SPECIAL_TAGS = ["<Record", "<record", "<Upload", "<upload"]

export const UPLOAD_STATES = {
    Pending: 'Pending',
		Preparing: 'Preparing',
    Uploading: 'Uploading',
		Validating: 'Validating',
    Finished: 'Finished',
    Failed: 'Failed',
		Invalid: 'Invalid',
}

export const IMPORT_ADHOC_STATUS = {
    pending: 1,
    started: 2,
    retry: 3,
    failure: 4,
    success: 5,
}

export const EMPTY_TABLE_CELL = '-';

export const OTHER_EDUCATION_LEVEL_ID = 'OTHER_EDUCATION_LEVEL_ID';
