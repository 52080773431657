import React, { Fragment } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Button, Space } from "antd";

import ErrorPage from "../../Error/ErrorPage";
import ErrorModal from '../../Error/ErrorModal';
import Success from "./Success";

import useCompletionContext from '../../../contexts/CompletionContext';
import useCompletionTimerContext from '../../../contexts/CompletionTimerContext';
import { CONTRIBUTOR_PROJECTS_PATH } from "../../../routes";
import { CONTRIBUTOR_TYPE } from './LookupTables';
import { useMediaQuery } from 'react-responsive'

const TRANSLATION_TABLE = {
    [CONTRIBUTOR_TYPE.DATA_PROCESSOR]: 'processing',
    [CONTRIBUTOR_TYPE.REVIEWER]: 'review',
}

const CompletionViewStatus = ({ contributorType }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' })

    const history = useHistory();
    const { projectId } = useParams();

    const {
        hasNext,
        error,
        errorSaveResults,
        fetchNextCompletion,
        clearSaveResultsError
    } = useCompletionContext();

    const {
        error: timerError,
        clearError: clearTimerError,
    } = useCompletionTimerContext();

    const recheckReadyCompletions = () => {
        fetchNextCompletion(projectId, contributorType === CONTRIBUTOR_TYPE.REVIEWER)
    }

    const returnToProjectsList = () => history.push(CONTRIBUTOR_PROJECTS_PATH);

    return (
        <Fragment>

            {timerError && timerError.detail && timerError.detail.length !== 0 && <ErrorModal
                error={timerError}
                onDone={clearTimerError}
            />}

            {/* Fetch Completion Error Status */}
            {hasNext &&
                <ErrorPage
                    error={error}
                    redirect={
                        { text: 'Return To Projects', action: returnToProjectsList }
                    } />
            }

            {/* Save Result Error Status */}
            {hasNext &&
                <ErrorModal
                    error={errorSaveResults}
                    onDone={clearSaveResultsError} />
            }

            {/* No More Completion For The Project */}
            <Success
                active={!hasNext && !error}
                title={`There are no more completions ready for ${TRANSLATION_TABLE[contributorType]} in this project.`}
                extra={
                    <Space direction={isTabletOrMobile ? 'vertical' : 'horizontal'}>
                        <Button type="primary" onClick={returnToProjectsList}>Return To Projects</Button>
                        <Button type="primary" onClick={recheckReadyCompletions}>Recheck Ready Completions</Button>
                    </Space>
                }
            />

        </Fragment>
    )
}

export default CompletionViewStatus;
