import React, { useContext } from 'react';
import { Row } from 'antd'
import RichGuidelinesEditor, { RichGuidelinesDisplay } from '../../../Common/RichGuidelinesEditor';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';


const RichTextArea = ({ setState, state }) => {

    const {
        selectedProject,
    } = useContext(PMPrjSingleContext);

    const { editMode, editedConsent, originalConsent } = state;

    const handleOnEditorContentChange = value => {

        setState(ps => ({
            ...ps,
            editedConsent: {
                ...editedConsent,
                content: value
            },
        }))
    }

    return (
        <Row style={{ marginTop: '20px' }} direction='vertical'>
            {/**Consent Rich Text Area */}
            {selectedProject && editMode &&
                <RichGuidelinesEditor
                    value={editedConsent.content}
                    onChange={handleOnEditorContentChange}
                    placeholder="Please enter Consent Agrement..."
                />
            }

            {selectedProject && !editMode &&
                <RichGuidelinesDisplay
                    value={originalConsent.content}
                />
            }
        </Row>
    )

}

export default RichTextArea;