import React from 'react'

const EmptyPlaceHolder = ({ icon, text }) => {
    return (
        <div style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        }}>
            <p style={{ textAlign: 'center' }}>{text}</p>
            <div style={{ fontSize: '4rem' }}>{icon}</div>
        </div>
    )
}

export default EmptyPlaceHolder;
