import { Button } from "antd";
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive'

const ManuallyAssignedTasks = ({ completionIDs, onGoToCompletion }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: '1em'
        }}>
            {completionIDs.map(c =>
                <Button
                    key={nanoid()}
                    type='link'
                    className='btn-link'
                    style={{ width: isTabletOrMobile ? '50%' : '', padding: '0px' }}
                    onClick={() => onGoToCompletion(c)}
                >
                    {c}
                </Button>
            )}
        </div>
    )
}

export default ManuallyAssignedTasks;