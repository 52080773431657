import React, { useEffect } from 'react';
import { Select, Form, Spin } from 'antd';
import ErrorAlert from '../../Error/ErrorAlert';
import useOptionsContext from '../../../contexts/OptionsContext';
import { getFormattedUserLabel } from '../../Common/Utils';

const { Item } = Form;

const ProjectOwnersSelector = () => {

    const {
        isLoading,
        projectOwners,
        projectOwnersError,
        fetchProjectOwners
    } = useOptionsContext();

    useEffect(() => {
        fetchProjectOwners();
        //eslint-disable-next-line
    }, []);

    const searchProjectOwners = searchTxt => fetchProjectOwners(searchTxt.trim());
    const clearProjectOwners = () => fetchProjectOwners();

    return (
        <Spin spinning={isLoading}>
            <Item
                label="Owner"
                name="ownerId"
                rules={[
                    {
                        required: true,
                        message: "Please select owner!",
                    },
                ]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Search Project Owners..."
                    onSearch={searchProjectOwners}
                    options={projectOwners.map(owner => ({ label: getFormattedUserLabel(owner), value: owner.id, key: owner.id }))}
                    filterOption={() => true}
                    onClear={clearProjectOwners}
                />
            </Item>
            <ErrorAlert error={projectOwnersError} />
        </Spin>
    )
}

export default ProjectOwnersSelector;
