import styled from 'styled-components';
import { Typography } from 'antd';

const { Link } = Typography;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  &[href]:hover {
    color: #1890ff;
  }
`;

const CustomLink = ({ to, children, className }) => {
  const handleClick = (event) => {
    event.preventDefault();
    // Ensure the `to` path is absolute by adding a leading `/` if it's not already
    const absoluteUrl = to.startsWith('/') ? to : `/${to}`;
    window.location.replace(absoluteUrl);
  };

  return (
    <StyledLink href={to} onClick={handleClick} className={className}>
      {children}
    </StyledLink>
  );
};

export { CustomLink };
