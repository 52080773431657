import React from 'react';
import { Modal, Button } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const InfoModal = ({ title, message, show, onDone }) => (
    <Modal
        title={
            <span style={{ fontSize: '1.1rem' }}>
                <InfoCircleFilled style={{ color: '#1890ff', marginRight: '10px' }} />{title}
            </span>
        }
        visible={show}
        onCancel={onDone}
        footer={[<Button type='primary' key="OK" onClick={onDone}>Ok</Button>]}
    >
        {message}
    </Modal>
)

export default InfoModal