import { useQueries } from '@tanstack/react-query';

import {
	queryGetLatestServiceAgreements,
	queryGetServiceAgreementsForContributor,
} from '../api/serviceAgreements';

export function useHasContributorAgreedToLatestAgreement({ userId, isPM }) {
	const UseGetContributorServiceAgreementsListQueryKey =
		'service-agreements/contributor-agreements-list';
	const UseGetLatestServiceAgreementQueryKey = 'service-agreements/latest';

	const queriesToProcess = !isPM
		? [
				{
					queryKey: UseGetLatestServiceAgreementQueryKey,
					queryFn: queryGetLatestServiceAgreements,
					refetchOnWindowFocus: false,
				},
				{
					queryKey: UseGetContributorServiceAgreementsListQueryKey,
					queryFn: () => queryGetServiceAgreementsForContributor({ userId }),
					refetchOnWindowFocus: false,
				},
		  ]
		: [];

	const results = useQueries({
		queries: queriesToProcess,
	});

	const hasContributorSignedLatestAgreement =
		checkHasContributorSignedLatestAgreement({ queryResults: results });
	return hasContributorSignedLatestAgreement;
}

const checkHasContributorSignedLatestAgreement = ({ queryResults }) => {
	const [latestAgreement, contributorAgreementList] = queryResults;
	if (latestAgreement?.isSuccess && contributorAgreementList?.isSuccess) {
		return {
			hasContributorSignedLatestAgreement:
				!!contributorAgreementList?.data?.find(
					(agreement) =>
						agreement.agreed &&
						agreement.serviceAgreementId === latestAgreement?.data?.id,
				),
		};
	}
	return {};
};
