import React, { Fragment, useState, useEffect } from 'react';
import { AiOutlineFileExclamation } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';

const RecordedAudio = ({ audioURL, review, setMediaPlaying }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });

	useEffect(() => {
		// Resetting the flag when the audioURL property changes.
		setState((ps) => ({ ...ps, audioLinkError: false }));
		//eslint-disable-next-line
	}, [audioURL]);

	const [state, setState] = useState({
		audioLinkError: false,
	});

	const onAudioError = () => {
		if (review) setState((ps) => ({ ...ps, audioLinkError: true }));
	};

	const togglePlay = () => setMediaPlaying();

	return (
		<Fragment>
			{/* Allowing the user to listen to what it was just recorded or what is recorded by the processor */}
			{audioURL && !state.audioLinkError && (
				<audio
					controls
					src={audioURL}
					onPlay={togglePlay}
					onPause={togglePlay}
					style={{
						width: isTabletOrMobile ? '70vw' : '30vw',
						marginTop: '30px',
						marginBottom: '30px',
					}}
					onError={onAudioError}
				/>
			)}

			{/* Displaying an error message to the reviewer when the recorded audio url is not available. */}
			{((!audioURL && review) || state.audioLinkError) && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{state.audioLinkError && (
						<p>Audio is not avaiable at the moment, please try again later!</p>
					)}
					{!audioURL && <p>No audio is submitted for the completion!</p>}
					<AiOutlineFileExclamation style={{ fontSize: '3rem' }} />
				</div>
			)}
		</Fragment>
	);
};

export default RecordedAudio;
