import { Button, Tooltip } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { DOWNLOAD_AUDIO_FILES_PRJECT_STATUS } from '../../../../Constants';



const DownloadAll = ({ projectArchiveLinks, projectStatus }) => {

    const hanldeDownloadAllLinks = () => {
        for (let i = 0; i < projectArchiveLinks.length; i++) {
            const archiveLink = document.createElement('a');
            archiveLink.href = projectArchiveLinks[i];
            archiveLink.target = '_blank';

            (document.body || document.documentElement).appendChild(archiveLink);

            archiveLink.click ? archiveLink.click() : window.open(projectArchiveLinks[i])

            archiveLink.parentNode.removeChild(archiveLink);
        }
    }

    return (
        <Tooltip title="Download All" placement="rightBottom">
            <Button
                type="link"
                shape="round"
                size="large"
                disabled={!projectArchiveLinks || projectArchiveLinks.length === 0 || projectStatus !== DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Done ? true : false}
                icon={<DownloadOutlined />}
                onClick={hanldeDownloadAllLinks}
            />
        </Tooltip>
    )
}

export default DownloadAll;