import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Row, Col, Button, message, Tag, Space, Modal } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { isEqual } from 'lodash-es';

import TagsBox from '../../../../Common/TagsBox';
import FieldLabel from '../../../../Common/FieldLabel';
import PMPrjSingleContext from '../../../../../contexts/PMPrjSingleContext';
import { LABEL_SPAN, VALUE_SPAN } from './Constants';
import '../../../../Styles/Button.scss'

const OTHER_REASON_NAME = 'Other';

const getUniqueTagsValues = (values) => {
    const lowerCaseUniqueValues = new Set(values?.map((element) => element.toLowerCase()));
    const result = [];

    values?.forEach((element) => {
        if (lowerCaseUniqueValues.has(element.toLowerCase())) {
            result.push(element);
            lowerCaseUniqueValues.delete(element.toLowerCase());
        }
    });

    return result;
}

const ProjectFieldReasons = ({ labelText, fieldName }) => {

    const { selectedProject, updateProjectValue } = useContext(PMPrjSingleContext);

    const [state, setState] = useState({
        editMode: false,
        originalReasonNames: [],
        tagValues: [],
    });

    useEffect(() => {
        if (selectedProject) {
            const initialValues = selectedProject[fieldName] ? selectedProject[fieldName].map(v => v.name) : []
            setState(ps => ({
                ...ps,
                originalReasonNames: initialValues,
                tagValues: initialValues,
            }));
        }
        //eslint-disable-next-line
    }, [selectedProject])



    const handleOnEditButtonClick = () => {
        setState(ps => ({ ...ps, editMode: true }))
    }

    const handleOnTagsChange = newValues => {
        // Removing the empty entries.
        newValues = newValues.filter(v => v.trim());

        if (!selectedProject.allowCriticalFieldsEdit) {
            // Making sure that original reasons added before are included in the new values list.
            for (let orName of state.originalReasonNames)
                // Adding the original reasons to the new values if they don't exist.
                if (!newValues.includes(orName))
                    newValues.push(orName);
        }
        else if (!newValues.includes(OTHER_REASON_NAME)) {
            // Making sure other reason is included in the array.
            newValues.push(OTHER_REASON_NAME);
        }

        setState(ps => ({
            ...ps,
            tagValues: getUniqueTagsValues(newValues),
        }));
    }

    const handleOnEditCancel = () => {
        setState(ps => ({
            ...ps,
            editMode: false,
            // Restoring the original values in the tags value when cancel to remove any unsaved changes from the tags.
            tagValues: ps.originalReasonNames,
        }))
    }

    const handleOnEditSave = () => {
        // The following implementation is done based on the assumption that previously set reasons can't be removed from the UI.
        if (isEqual(state.originalReasonNames, state.tagValues))
            message.info('Value is not updated!');
        else if (!selectedProject.allowCriticalFieldsEdit) {
            Modal.confirm({
                title: 'Heads Up!',
                content: <Fragment>
                    <p>Once you add the new reason(s) you will not be able to remove them as some completions were already assigned.</p>
                    <p>Do you like to proceed?</p>
                </Fragment>,
                onOk() {
                    handleEditSaveConfirmed();
                },
                onCancel() {
                    // Do nothing.
                }
            })
        }
        else {
            handleEditSaveConfirmed();
        }
    }

    const handleEditSaveConfirmed = () => {
        const toSendValues = state.tagValues.map(v => ({ name: v, requires_comment: v === OTHER_REASON_NAME }));
        updateProjectValue(fieldName, toSendValues)
            .then(() => {
                setState(ps => ({ ...ps, editMode: false }));
            })
            .catch(e => {
                // Retrieve the old values when updting the project fails.
                setState(ps => ({
                    ...ps,
                    tagValues: ps.originalReasonNames,
                    editMode: false,
                }));
            })
    }

    const reasonsTagRenderer = ({ value, label, closable, onClose }) => {
        const isOriginal = state.originalReasonNames.includes(value);
        const isEditable = (!isOriginal || selectedProject.allowCriticalFieldsEdit) && value !== OTHER_REASON_NAME;
        return (
            <Tag color={isEditable ? '#081f2d' : 'grey'}
                closable={closable && isEditable}
                onClose={onClose}
						>
                <span style={{ fontSize: '12pt' }}>{value}</span>
            </Tag>
        );
    }



    return (
        <Row style={{ marginBottom: '20px' }} align='middle'>

            {/* The label of the field */}
            <Col span={LABEL_SPAN}>
                <FieldLabel text={labelText} />
            </Col>

            <Col span={VALUE_SPAN}>
                <TagsBox
                    color='##081f2d'
                    value={state.tagValues}
                    disabled={!state.editMode}
                    placeholder={state.tagValues.length > 0 ? '' : 'Not Set'}
                    size='medium'
                    allowClear={false}
                    tokenSeparators={[',']}
                    onChange={handleOnTagsChange}
                    tagRender={reasonsTagRenderer}
                    style={{ width: '100%' }}
                    autoFocus={true}
                />
            </Col>

            {/* The pen edit button */}
            {!state.editMode &&
                <Button type='text' onClick={handleOnEditButtonClick}>
                    <EditOutlined />
                </Button>
            }

            {state.editMode &&
                <Space style={{ marginLeft: '10px' }}>
                    <Button size="small" className='btn-success' onClick={handleOnEditSave}>
                        Save
                    </Button>
                    <Button size="small" type="primary" danger onClick={handleOnEditCancel}>
                        Cancel
                    </Button>
                </Space>
            }

        </Row >
    )
}

export default ProjectFieldReasons;

