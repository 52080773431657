import React, { Fragment, useEffect, useState } from 'react';
import { Button, Badge, Space, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

import ErrorPage from '../../Error/ErrorPage';
import EmptyNotificationsPlaceHolder from '../EmptyNotificationsPlaceHolder';
import LoadingNotificationsPlaceHolder from '../LoadingNotificationsPlaceHolder';
import NotificationPageCard from './NotificationPageCard/NotificationPageCard';
import useNotificationsContext from '../../../contexts/NotificationsContext';

const TabHeader = () => {

    const { notificationsCount, clearAllNotifications } = useNotificationsContext();
    const [state, setState] = useState({
        clearing: false,
    });

    const onClearAll = () => {
        setState(ps => ({ ...ps, clearing: true }));
        // If the notifications clear is successful, the card is cleared, so 
        // there is no need to set clearing back to false unless the operation
        // fails.
        clearAllNotifications()
            .catch(e => {
                setState(ps => ({ ...ps, clearing: false }));
                // For the user, the error will be that cards are not removed.
                console.log(e);
            });
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
            marginTop: '20px',
            marginRight: '20px'
        }}>
            <Space align='center'>
                <Tooltip title='Clear All'>
                    <Badge count={notificationsCount} >
                        <Button
                            type='dashed'
                            size='small'
                            loading={state.clearing}
                            onClick={onClearAll}>
                            <ClearOutlined />
                        </Button>
                    </Badge>
                </Tooltip>
            </Space>
        </div>
    )
}

const NotificationsPageTab = ({ forNewNotifications, isTabSelected }) => {

    const {
        notifications,
        notificationsCount,
        notificationsLoading,
        notificationsError,

        fetchNotifications,
        fetchMore,
        clearError,
    } = useNotificationsContext();

    const [state, setState] = useState({
        initialLoading: true,
    })

    useEffect(() => {
        if (isTabSelected) {
            clearError();
            setState(ps => ({ ...ps, initialLoading: true }));
            fetchNotifications({ cleared: !forNewNotifications, skip: 0 })
                .finally(() => setState(ps => ({ ...ps, initialLoading: false })));
        }
        // eslint-disable-next-line
    }, [isTabSelected]);

    // Covering the case when the user keeps clearing without reloading more.
    // The reload occurs when the user clears the 5 of them.
    useEffect(() => {
        if (notifications.length === 0 && notificationsCount !== 0) {
            setState(ps => ({ ...ps, initialLoading: true }));
            fetchNotifications({ cleared: !forNewNotifications, skip: 0 })
                .finally(() => setState(ps => ({ ...ps, initialLoading: false })));
        }
        // eslint-disable-next-line
    }, [notifications, notificationsCount]);

    const handleLoadMoreClick = () => {
        fetchMore();
    }

    return (
        <div>
            <ErrorPage error={notificationsError} />

            {state.initialLoading && <LoadingNotificationsPlaceHolder width='100%' loading={notificationsLoading} />}

            {(!notificationsLoading || !state.initialLoading) && !notificationsError && notifications.length === 0 && <EmptyNotificationsPlaceHolder width='100%' />}

            {!state.initialLoading &&
                <Fragment>

                    {forNewNotifications && notifications.length > 0 && <TabHeader />}

                    {notifications.map(n => <NotificationPageCard key={n.id} notification={n} />)}

                    <Button
                        style={{ height: '40px', width: '100%' }}
                        type='primary'
                        onClick={handleLoadMoreClick}
                        loading={notificationsLoading}
                    >
                        {notifications.length === 0 ? 'Refresh' : 'Load More'}
                    </Button>
                </Fragment>
            }

        </div>
    )
}

export default NotificationsPageTab;
