import { Form, Input } from "antd";
import { MultiSelectField } from "../../PM/SelectExt";
import useGetEducationOptions from "../../../api/queries/useGetEducationOptions";
import ErrorAlert from "../../Error/ErrorAlert";
import { formatError } from "../../../contexts/ResponseErrorFormatter";
import styled from "styled-components";

const { Item } = Form;

const OtherEducationField = styled(Input)`
    margin-top: 1rem;
`;

const EducationLevel = (props) => {

    const { onEducationLevelChange, educationLevel, otherEducation, onOtherEducationChange } = props;
    const { data: educationLevelOptions, error: educationLevelOptionsError } = useGetEducationOptions();

    const showDisplayOtherEducationField = Boolean(educationLevel === 10);

    return (
        <Item
            label="Highest Level of Education"
            name="education"
            rules={[
                {
                    required: !educationLevel || (educationLevel === 10 && !otherEducation),
                    message: "Please select your education level!",
                }
            ]}>
            <MultiSelectField
                selectedTags={educationLevel}
                onTagsChange={onEducationLevelChange}
                placeholder="Select your education...."
                selectMode="single"
                options={educationLevelOptions?.map( el => ({ label: el.name, value: el.id, key: el.id }) )}
            />
            {educationLevelOptionsError &&
                <ErrorAlert
                    error={formatError(educationLevelOptionsError, 'Failed To Retrieve Education Levels Options!')}
                />
            }
            {showDisplayOtherEducationField &&
                <OtherEducationField
                    placeholder="Please specify!"
                    onChange={onOtherEducationChange}
                    value={otherEducation}
                    allowClear
                />
            }
        </Item>
    )
}

export default EducationLevel;