import { observer, inject } from 'mobx-react';
import { types, getRoot } from 'mobx-state-tree';

import ObjectBase from './Base';
import ProcessAttrsMixin from '../../mixins/ProcessAttrs';
import ObjectTag from '../../components/Tags/Object';
import Registry from '../../core/Registry';

/**
 * Video tag plays a simple video file
 * @example
 * <View>
 *   <Video name="video" value="$video"/>
 * </View>
 * @example
 * <!-- Video classification -->
 * <View>
 *   <Video name="video" value="$video"/>
 *   <Choices name="type" toName="video" choice="single-radio">
 *     <Choice value="Positive" />
 *     <Choice value="Negative" />
 *   </Choices>
 * </View>
 * @name Video
 * @param {string} name of the element
 * @param {string} value of the element
 * @param {string} width width of video view
 * @param {boolean} controls video controls
 * @param {boolean} muted mute video sound
 * @param {boolean} syncaudio sync audio with video to play together
 */

const TagAttrs = types.model({
	name: types.identifier,
	value: types.maybeNull(types.string),
	width: types.optional(types.string, '100%'),
	controls: types.optional(types.boolean, true),
	muted: types.optional(types.boolean, false),
	syncaudio: types.optional(types.boolean, false),
});

const Model = types
	.model({
		type: 'video',
		_value: types.optional(types.string, ''),
		playing: types.optional(types.boolean, false),
	})
	.actions((self) => ({
		handlePlay() {
			self.playing = !self.playing;
			getRoot(self).completionStore.setMediaPlaying(self.playing);
		},
	}));

const VideoModel = types.compose(
	'VideoModel',
	Model,
	TagAttrs,
	ProcessAttrsMixin,
	ObjectBase,
);

const getFirstElementByTagName = (tagName) => {
	return document.getElementsByTagName(tagName)[0];
};

const HtxVideoView = ({ item }) => {
	if (!item._value) return null;

	return (
		<ObjectTag item={item}>
			<video
				src={item?._value}
				controls={item.syncaudio ? false : item.controls}
				width={item.width}
				muted={item.syncaudio ? true : item.muted}
				onPause={item?.handlePlay}
				onPlay={item?.handlePlay}
			/>

			{/* Used with AudioPlus configuration */}
			{item.syncaudio && (
				<img
					src=""
					alt=""
					onError={() => {
						const audio = getFirstElementByTagName('audio');
						const video = getFirstElementByTagName('video');

						audio.onseeked = () => (video.currentTime = audio.currentTime);
						audio.onplay = () => video.play();
						audio.onpause = () => video.pause();
					}}
				></img>
			)}
		</ObjectTag>
	);
};

const HtxVideo = inject('store')(observer(HtxVideoView));

Registry.addTag('video', VideoModel, HtxVideo);
Registry.addObjectType(VideoModel);

export { VideoModel, HtxVideo };
