import React from "react";

import { Typography } from "antd";
const { Text } = Typography;


const UtteranceViewer = ({ utterance }) => (
    < Text
        code
        style={{ fontSize: "28px" }
        }>
        {utterance}
    </Text >
)

export default UtteranceViewer;