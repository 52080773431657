import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Select, Spin, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";

import ErrorAlert from '../../../../Error/ErrorAlert';
import FieldLabel from '../../../../Common/FieldLabel';
import PMPrjSingleContext from '../../../../../contexts/PMPrjSingleContext';
import useOptionsContext from '../../../../../contexts/OptionsContext';
import { getFormattedUserLabel } from '../../../../Common/Utils';
import { LABEL_SPAN, VALUE_SPAN } from './Constants';

const ProjectFieldOwners = () => {

    const {
        selectedProject,
        selectedProjectOwner,
        updateProjectValue,
    } = useContext(PMPrjSingleContext);

    const {
        isLoading,
        projectOwnersError,
        projectOwners,
        fetchProjectOwners,
    } = useOptionsContext();

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (editMode) fetchProjectOwners();
        //eslint-disable-next-line
    }, [editMode]);

    const fieldText = selectedProjectOwner ? getFormattedUserLabel(selectedProjectOwner) : 'Not Available';

    const onFieldChanged = (newValue) => {
        if (newValue === null || newValue === undefined) {
            message.warning('Invalid value. Project is not updated!');
        }
        else if (newValue !== selectedProject['ownerId']) {
            updateProjectValue('ownerId', newValue);
        }
        setEditMode(false);
    }

    const onSearchChanged = searchTxt => fetchProjectOwners(searchTxt.trim());

    return (
        <Spin spinning={isLoading}>
            <Row style={{ marginBottom: '20px' }} align='middle'>

                {/* The label of the field */}
                <Col span={LABEL_SPAN}>
                    <FieldLabel text='Owner:' />
                </Col>

                {/* Display value of the field */}
                {!editMode &&
                    <Col span={VALUE_SPAN}>

                        <span>{fieldText}</span>

                        {/* The pen edit button */}
                        <Button type='text' onClick={() => setEditMode(true)}>
                            <EditOutlined />
                        </Button>
                    </Col>
                }

                {/* The edit form */}
                {editMode &&
                    <Col span={VALUE_SPAN}>
                        <Select
                            showSearch
                            allowClear
                            open
                            style={{ width: '100%' }}
                            placeholder={fieldText}

                            autoFocus={true}
                            onBlur={() => setEditMode(false)}
                            onSelect={onFieldChanged}

                            onSearch={onSearchChanged}
                            options={projectOwners.map(owner => ({ label: getFormattedUserLabel(owner), value: owner.id, key: owner.id }))}
                            filterOption={() => true}
                        />
                        <ErrorAlert error={projectOwnersError} />
                    </Col>
                }

            </Row >
        </Spin>
    )
}

export default ProjectFieldOwners;
