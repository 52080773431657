import React, { Fragment, useState, useEffect } from 'react';
import { Select, Button, Space, message, Row, Col } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import { getFormattedUserLabel } from '../../../Common/Utils';
import {
    COMPLETION_STATUS_VALUES,
    COMPLETION_STATUS_RESET_VALUES,
} from '../../../../Constants';
import ErrorModal from '../../../Error/ErrorModal';
import InfoModal from '../../../Common/InfoModal';
import FieldLabel from '../../../Common/FieldLabel';
import usePMCompletionsContext from '../../../../contexts/PMCompletionsContext';
import { usePMProjectSingleContext } from '../../../../contexts/PMPrjSingleContext';

const fieldStyle = { margin: '20px 0 20px 0' };
const labelSpan = 4;
const inputSpan = 12;
const buttonSpan = 3;
const rowGutter = 10;


const CompletionsAssignment = ({ isAssignBtnDisabled, isAssigningCompletions, assignContributorToCompletions, type }) => {

    const [state, setState] = useState({ selectedContributorID: null });
    const {
        contributors,
        contributorsLoading,
        contributorsFetchError,
        fetchContributors,
        clearContributorFetchError,
    } = usePMProjectSingleContext();

    const onContributorSelected = ctid => setState(ps => ({ ...ps, selectedContributorID: ctid }));
    const onContributorCleared = () => setState(ps => ({ ...ps, selectedContributorID: null }));
    const onSearchChanged = txt => fetchContributors({ search: txt.trim(), showOnlyAssigned: true });

    const onReloadContributorsClick = () => fetchContributors({ showOnlyAssigned: true });;
    const onAssignClicked = () => assignContributorToCompletions(state.selectedContributorID);

    const placeholderTxt = type === 'dp' ? "Search data processors..." : "Search reviewers...";
    const labelTxt = type === 'dp' ? 'Data Processor: ' : 'Reviewer: ';
    const btnClassName = type === 'dp' ? '' : 'btn-success';
    const rolePropertyName = type === 'dp' ? 'assignedDP' : 'assignedRV';
    const optionsSource = contributors
        .filter(ct => ct[rolePropertyName])
        .map(u => ({ label: getFormattedUserLabel(u), value: u.id, key: u.id }));

    return (
        <Fragment>
            <ErrorModal error={contributorsFetchError} onDone={clearContributorFetchError} />
            <Row style={fieldStyle} gutter={rowGutter}>

                <Col span={labelSpan}><FieldLabel text={labelTxt} /></Col>

                <Col span={inputSpan}>
                    <Select
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        style={{ width: '100%' }}
                        placeholder={placeholderTxt}
                        loading={contributorsLoading || isAssigningCompletions}
                        disabled={isAssigningCompletions}

                        value={state.selectedContributorID}
                        onSearch={onSearchChanged}
                        onSelect={onContributorSelected}
                        onClear={onContributorCleared}
                        options={optionsSource}
                        filterOption={() => true}
                    />
                </Col>

                <Col>
                    <Space align='start'>
                        <Button
                            type='dashed'
                            disabled={contributorsLoading || isAssigningCompletions}
                            onClick={onReloadContributorsClick}
                        >
                            <RedoOutlined />
                        </Button>

                        <Button
                            type='primary'
                            className={btnClassName}
                            disabled={isAssignBtnDisabled || contributorsLoading || isAssigningCompletions}
                            onClick={onAssignClicked}
                        >
                            Assign Completions
                        </Button>
                    </Space>
                </Col>

            </Row>
        </Fragment>
    )
}


const CompletionUpdate = ({ selectedCompletionIDs, refreshTable }) => {

    const { fetchContributors } = usePMProjectSingleContext();
    const {
        isAssigning,
        assignmentError,
        assignCompletionsToUser,
        clearAssignmentError,

        isChangingStatus,
        statusChangeError,
        updateCompletionsStatus,
        clearStatusChangeError,
    } = usePMCompletionsContext();

    const [state, setState] = useState({
        selectedStatus: null,
        failedToUpdateCompletions: null,
    })

    useEffect(() => {
        fetchContributors({ showOnlyAssigned: true });
        //eslint-disable-next-line
    }, []);

    const handleAssignDP = (dpID) => {
        if (dpID) {
            assignCompletionsToUser(dpID, selectedCompletionIDs, false)
                .then(failedCompletions => {
                    if (failedCompletions) {
                        setState(ps => ({ ...ps, failedToUpdateCompletions: failedCompletions }))
                    }
                    else {
                        message.success('Selected completions were assigned successfully to selected data processor.');
                    }
                    refreshTable();
                })
                .catch(e => {
                    //Do nothing, the error is handled by the modal.
                })
        }
        else {
            message.warning('Data Processor to assign completions to is not selected!');
        }
    }

    const handleAssignRV = (rvID) => {
        if (rvID) {
            assignCompletionsToUser(rvID, selectedCompletionIDs, true)
                .then(failedCompletions => {
                    if (failedCompletions) {
                        setState(ps => ({ ...ps, failedToUpdateCompletions: failedCompletions }))
                    }
                    else {
                        message.success('Selected completions were assigned successfully to selected reviewer.');
                    }
                    refreshTable();
                })
                .catch(e => {
                    //Do nothing, the error is handled by the modal.
                })
        }
        else {
            message.warning('Reviewer to assign completions to is not selected!');
        }
    }

    const handleStatusChange = () => {
        if (state.selectedStatus) {
            updateCompletionsStatus(selectedCompletionIDs, state.selectedStatus)
                .then(failedCompletions => {
                    if (failedCompletions) {
                        setState(ps => ({ ...ps, failedToUpdateCompletions: failedCompletions }))
                    }
                    else {
                        message.success('Completions status is updated successfully.');
                    }
                    refreshTable();
                })
                .catch(e => {
                    //Do nothing, the error is handled by the modal.
                })
        }
        else {
            message.warning('No status is selected!');
        }
    }

    return (
        <Fragment>

            <ErrorModal error={assignmentError} onDone={clearAssignmentError} />

            <ErrorModal error={statusChangeError} onDone={clearStatusChangeError} />
            <InfoModal
                show={state.failedToUpdateCompletions}
                title='Not All Completions Were Updated!'
                message={state.failedToUpdateCompletions ?
                    <div>
                        <p>Some completions were successfully updated, while the following completions failed.</p>
                        {state.failedToUpdateCompletions.map(f => <p key={f.id}>{`ID: ${f.id} Status: ${COMPLETION_STATUS_VALUES[f.status]}`}</p>)}
                    </div> :
                    ''
                }
                onDone={() => setState(ps => ({ ...ps, failedToUpdateCompletions: null }))}
            />

            {/* Data Processor Assign */}
            <CompletionsAssignment
                type='dp'
                isAssigningCompletions={isAssigning}
                isAssignBtnDisabled={selectedCompletionIDs.length === 0}
                assignContributorToCompletions={handleAssignDP}
            />

            {/* Reviewer assign */}
            <CompletionsAssignment
                type='rv'
                isAssigningCompletions={isAssigning}
                isAssignBtnDisabled={selectedCompletionIDs.length === 0}
                assignContributorToCompletions={handleAssignRV}
            />

            {/* Status Update */}
            <Row style={fieldStyle} gutter={rowGutter}>
                <Col span={labelSpan}><FieldLabel text='Reset Status To: ' /></Col>

                <Col span={inputSpan}>
                    <Select
                        allowClear
                        defaultActiveFirstOption={false}
                        style={{ width: '100%' }}
                        placeholder="Select Status..."
                        loading={isChangingStatus}
                        disabled={isChangingStatus}
                        value={state.selectedStatus}
                        onSelect={v => setState(ps => ({ ...ps, selectedStatus: v }))}
                        onClear={() => setState(ps => ({ ...ps, selectedStatus: null }))}
                        options={Object.entries(COMPLETION_STATUS_RESET_VALUES).map(([sk, sv]) => (
                            { label: sv, value: sk, key: sk }
                        ))}
                    />
                </Col>

                <Col span={buttonSpan}>
                    <Button
                        type='primary'
                        disabled={selectedCompletionIDs.length === 0 || isChangingStatus}
                        onClick={handleStatusChange}
                    >
                        Update
                    </Button>
                </Col>

            </Row>

        </Fragment>
    )
}

export default CompletionUpdate;
