import { Space } from "antd";
import CostInputSearch from "./CostInputSearch";
import ExportCostButton from "./ExportCostButton";
import ResetTableButton from "../../../Common/ResetTableButton";

const CostTableControlButtons = ({
    onSearch,
    querData,
    isExporting,
    onExportAll,
    onExportSelected,
    hasSelectedContributors,
    onReset,
    resetButtonIsDisabled
}) => {

    return (
        <Space style={{ display: 'flex', justifyContent: 'space-between', }}>

            <CostInputSearch
                onSearch={onSearch}
            />

            <Space>
                <ExportCostButton
                    disabled={!querData?.data?.count}
                    loading={isExporting}
                    onExportAll={onExportAll}
                    onExportSelected={onExportSelected}
                    hasSelectedContributors={hasSelectedContributors}
                />

                <ResetTableButton
                    tooltipText="Clear Selection, Sorting, and Search"
                    isDisabled={resetButtonIsDisabled}
                    onReset={onReset}
                />
            </Space>

        </Space>
    )
}

export default CostTableControlButtons;