import { useQuery } from "@tanstack/react-query";

import optionsApi from "./../options";
import { OTHER_EDUCATION_LEVEL_ID } from '../../Constants';


const fetchEducationLevels = async () => {
  const response = await optionsApi.getEducationOptions();
  const educationOptions = response.data;
  educationOptions.push({
    id: OTHER_EDUCATION_LEVEL_ID,
    name: 'Other'
  });

  return educationOptions;
}

const useGetEducationOptions = () => {
  return useQuery({
    queryKey: ['contributor/education'],
    queryFn: fetchEducationLevels,
    refetchOnWindowFocus:false
  })
};

export default useGetEducationOptions;