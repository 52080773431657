import React from 'react';
import { observer, inject } from 'mobx-react';
import { types, getRoot } from 'mobx-state-tree';
import ObjectTag from '../../components/Tags/Object';
import Upload from './../../components/Upload/Upload';
import Registry from '../../core/Registry';

/**
 * @example
 * <View>
 *  <Upload name="text" fileType="audio:mp3" minSize="10" maxSize="100" maxFiles="10" minFiles="5" duplicateProjectCheck="123"/>
 * </View>
 * @name  Upload
 * @param {string} name                         - name of the element
 * @param {string} fileType                     - uploaded file-type, can associate extensions with it too (ex: audio:mp3)
 * @param {number} [minSize=0.001]              - minimum size for file to be uploaded in Kilobytes
 * @param {number} [maxSize=1048576]            - maximum size for file to be uploaded in Kilobytes
 * @param {integer} [minFiles=1]                - minimum number of files to be uploaded
 * @param {integer} [maxFiles=1]                - maximum number of files to be uploaded
 * @param {boolean} [allowDiplicate=false]      - allowing/disallow upload of duplicate files in the same project
 * @param {integer} duplicateProjectCheck       - specify project ids that uploaded file will be checked it duplicity with them
 */

const TagAttrs = types.model({
	name: types.identifier,
	filetype: types.optional(types.string, '*'),
	minsize: types.optional(types.string, ''),
	maxsize: types.optional(types.string, ''),
	minfiles: types.optional(types.string, ''),
	maxfiles: types.optional(types.string, ''),
	allowduplicate: types.optional(types.boolean, false),
	duplicateprojectcheck: types.maybeNull(types.string),
	minsnr: types.maybeNull(types.string),
	maxsnr: types.maybeNull(types.string),
	loudness: types.maybeNull(types.string),
	truepeak: types.maybeNull(types.string),
});

const Model = types
	.model('UploadModel', {
		type: 'upload',
		playing: types.optional(types.boolean, false),
	})
	.actions((self) => ({
		handlePlay() {
			self.playing = !self.playing;
			getRoot(self).completionStore.setMediaPlaying(self.playing);
		},
	}));

const UploadModel = types.compose('UploadModel', Model, TagAttrs);

const HtxUploadView = observer(({ item }) => {
	return (
		<ObjectTag item={item}>
			<Upload attrs={item} togglePlay={item.handlePlay} />
		</ObjectTag>
	);
});

const HtxUpload = inject('store')(HtxUploadView);

Registry.addTag('upload', UploadModel, HtxUpload);
Registry.addObjectType(UploadModel);

export { UploadModel, HtxUpload };
