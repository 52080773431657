
const CopyRigths = () => {

    const copyRigthsStyle = {
        fontSize: "8px",
        position: 'fixed',
        bottom: '5px',
        right: 10,
        color: 'rgba(0, 0, 0, 0.45)'
    }

    return (
        <span style={copyRigthsStyle}>
            Location search is powered by
            <a
                style={{ color: 'rgba(0, 0, 0, 0.45)', margin: '0px 2px 0px 2px' }}
                target="_blank"
                rel="noreferrer"
                href='https://www.geoapify.com'>
                Geoapify
            </a>
            and data-soruce © OpenStreetMap
        </span>
    )
}

export default CopyRigths;