import y from '../../../../lib/yupExtended';

export const selectOptions = {
    completion: {
        value: 'completion',
        label: 'Completion',
    },
    minute: {
        value: 'minute',
        label: 'Minute',
    },
}

export const factoryUnitsAndCostOptions = ({ projectService }) => {
    const servicesRequireMinutes = [
        "Short form transcription",
        "Long form transcription",
        "Audio Data Collection",
        'Video Data Collection',
        'Audio labeling',
        'Audio categorization',
    ]

    const shouldRender = servicesRequireMinutes.includes(projectService);

    return [
        selectOptions.completion,
        shouldRender && selectOptions.minute,
    ].filter(Boolean)
}
