import { configureStore } from '@reduxjs/toolkit';
import audioReducer from './audioSlice';
import downloadCenterReducer from './downloadCenterSlice';
import uploadReducer from './uploadSlice';
import textAreaLabelsSlice from './textAreaLabelsSlice';

const store = configureStore({

    reducer: {
        audio: audioReducer,
        downloadCenter: downloadCenterReducer,
        upload: uploadReducer,
        textAreaLabels: textAreaLabelsSlice
    },

    middleware: (getDefaultMiddleware) =>
    {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['audio.toUploadCompletionDataTasks', 'upload.toUploadCompletionFiles'],
                ignoredActions: ['audio/setCompletionAudioBlob'],
                ignoredActionPaths: ['payload', 'meta.arg.fileFormData']
            }
        });
    }
});

export default store;