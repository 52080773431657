export const createErrorContent = (error) => {
    let config = null;

    if (error && error?.errorDetails?.length) {
        config = {
            title: 'Failed To Import!',
            content: (
                <ul>
                    {error.errorDetails.map((e, index) => <li key={index}>{e}</li>)}
                </ul>
            ),
        };
    }
    return config
}