import React from 'react';
import { LikeOutlined } from '@ant-design/icons';


const EmptyNotificationsPlaceHolder = ({ width }) => {
    return (
        <div style={{
            width: { width },
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        }}>
            <p style={{ textAlign: 'center' }}>No unread notifications at the moment!</p>
            <LikeOutlined style={{ fontSize: '3rem' }} />
        </div>
    )
}

export default EmptyNotificationsPlaceHolder;