import { Space } from "antd";

import RVControlPanel from "../Contributor/CompletionEditPage/RVControlPanel";
import DPControlPanel from "../Contributor/CompletionEditPage/DPControlPanel";
import SaveDraftButton from "../Contributor/CompletionEditPage/SaveDraftButton";
import { CONTRIBUTOR_TYPE } from "../Contributor/CompletionEditPage/LookupTables";

const ContributorActionButtons = ({ stopTimerBeforeCommitResult, contributorType }) => {
    return (
        <Space>
            <SaveDraftButton contributorType={contributorType} />
            {contributorType === CONTRIBUTOR_TYPE.DATA_PROCESSOR &&
                <DPControlPanel beforeCommittingResult={stopTimerBeforeCommitResult} />}
            {contributorType === CONTRIBUTOR_TYPE.REVIEWER &&
                <RVControlPanel beforeCommittingResult={stopTimerBeforeCommitResult} />}
        </Space>
    )
}

export default ContributorActionButtons;