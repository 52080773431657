import React, { useCallback, useState, useEffect } from 'react';

const TextViewer = ({ file,url }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
		if (url) {
			fetch(url)
        .then(async (response) =>
        {
          const text = await response.text();
          setContent(text);
        })
				.catch((error) => console.error('Error fetching text file:', error));
		}
	}, [url]);

	const handlefile = useCallback((file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file.originFileObj);
      reader.onload = () => {
				const text = reader.result;
        setContent(text);
      };
    }
  }, []);


  if (file && !url) {
    handlefile(file);
  }

	return <pre>{content}</pre>;
};

export default TextViewer;
