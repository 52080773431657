import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';

const CommaSeparatedFilter = forwardRef(({ onModelChange, getValue }, ref) => {
	const [filterText, setFilterText] = useState('');
	const [parsedValues, setParsedValues] = useState([]);
	const [selectedValues, setSelectedValues] = useState([]);

	// Update parsed values when filter text changes
	useEffect(() => {
		const values = filterText
			.split(',')
			.map((value) => value.trim())
			.filter(Boolean); // Remove empty strings
		setParsedValues(values);
		setSelectedValues(values); // Initially select all parsed values
		if (values.length > 0) {
			onModelChange(filterText);
		}
	}, [filterText, onModelChange]);

	// Handle input change
	const onFilterTextChange = (e) => {
		setFilterText(e.target.value);
	};

	// Handle checkbox selection
	const onCheckboxChange = (value) => {
		setSelectedValues((prevSelectedValues) => {
			if (prevSelectedValues.includes(value)) {
				return prevSelectedValues.filter((v) => v !== value);
			} else {
				return [...prevSelectedValues, value];
			}
		});
	};

	// Select all checkboxes
	const selectAll = () => {
		setSelectedValues(parsedValues);
	};

	// Deselect all checkboxes
	const deselectAll = () => {
		setSelectedValues([]);
	};

	const isFilterActive = useCallback(() => {
		return selectedValues.length > 0;
	}, [selectedValues]);

	const doesFilterPass = useCallback(
		(params) => {
			const { node } = params;
			const cellValue = getValue(node);

			// Check if the cell value is in the array of selected values
			if (selectedValues.length > 0) {
				return selectedValues.includes(cellValue?.toString());
			} else {
				return true;
			}
		},
		[selectedValues, getValue],
	);

	useGridFilter({
		isFilterActive,
		doesFilterPass,
	});

	return (
		<div style={{ maxHeight: '300px', overflowY: 'auto', padding: '5px' }}>
			<input
				type="text"
				className="ag-input-field-input ag-text-field-input"
				value={filterText}
				onChange={onFilterTextChange}
				placeholder="Enter comma-separated values..."
				style={{ width: '100%' }}
			/>
			<div>
				<small>e.g. value1, value2, value3</small>
			</div>
			<div>
				<button onClick={selectAll}>Select All</button>
				<button
					onClick={deselectAll}
					style={{ marginLeft: '10px', marginTop: '10px' }}
				>
					Deselect All
				</button>
			</div>
			<div style={{ maxHeight: '200px', overflowY: 'auto', marginTop: '10px' }}>
				{parsedValues.map((value) => (
					<div key={value}>
						<input
							type="checkbox"
							className="ag-checkbox-input"
							checked={selectedValues.includes(value)}
							onChange={() => onCheckboxChange(value)}
						/>
						<label style={{ marginLeft: '5px' }}>{value}</label>
					</div>
				))}
			</div>
		</div>
	);
});

export default CommaSeparatedFilter;
