import { Form, Input } from "antd"
const { Item } = Form

const FirstName = () => {
    return (
        <Item
            label="First Name"
            name="firstName"
            rules={[
                {
                    required: true,
                    message: "Please type your first name!",
                },
                {
                    min: 2,
                    message: "First Name length should be 2 characters or more!"
                },
                {
                    max: 255,
                    message: "First Name length should not be more than 255 characters!"
                },
                {
                    pattern: /^([-'a-zA-Z]\s?)+\s*$/g,
                    message: "First Name can only contain Latin letters, apostrophes or dashes"
                }
            ]}>
            <Input placeholder='First Name...' autoComplete="new-password" />
        </Item>
    )
}

export default FirstName;