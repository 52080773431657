import * as yup from 'yup';
import { featureToggle } from './featureToggles';

const isProfileEnhancementEnabled = featureToggle.isOn("contributor-profile-enhancement");
const isEducationEnabled = featureToggle.isOn("education-level");

const genderSchema = yup.string().oneOf(['male', 'female', 'other']);

export const locationSchema = yup.object().shape({
    city: yup.string().nullable(true),
    state: yup.string().nullable(true),
    country: yup.string().required(),
    formatted: yup.string().required(),
    lat: yup.number().required(),
    lon: yup.number().required()
});

export const nativeLangSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
});

export const educationLevelSchema = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
});

export const skillsSchema = yup.array().of(yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
})).min(1);

export const otherLanguagesSchema = yup.array().of(yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
}));

export const contributorProfileSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    gender: isProfileEnhancementEnabled ? genderSchema.nullable(true) : genderSchema.required(),
    dateOfBirth: isProfileEnhancementEnabled ? yup.date().nullable(true) : yup.date().required(),
    originLocation: isProfileEnhancementEnabled ? locationSchema.nullable(true) : locationSchema,
    currentLocation: locationSchema,
    skills: skillsSchema,
    otherLanguages: otherLanguagesSchema,
    nativeLanguage: nativeLangSchema,
    education: isEducationEnabled && educationLevelSchema
});


export const isContributorProfileComplete = (ctProfile) => contributorProfileSchema.isValidSync(ctProfile);