import React from "react";
import { Button } from "antd";
import { CloseOutlined, CheckOutlined, EditOutlined } from "@ant-design/icons";


const SaveButton = ({ onSave, disabled }) => {
    return (
        <Button
            type='link'
            onClick={onSave}
            disabled={disabled}
            icon={<CheckOutlined style={{ color: "#2CB280" }} />}
        />
    )
}

const CancelButton = ({ onCancel }) => {
    return (
        <Button
            type='link'
            danger
            onClick={onCancel}
            icon={<CloseOutlined />}
        />
    )
}

const EditButton = ({ onEdit, disabled }) => {
    return (
        <Button
            type='link'
            onClick={onEdit}
            disabled={disabled}
            icon={<EditOutlined />}
        />
    )
}

export { SaveButton, CancelButton, EditButton };