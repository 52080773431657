import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import GeoapifySelect from '../Common/GeoapifySelect';


const CurrentLocationSelect = ({ onChange, value }) => {

    const onSelectCurrentCity = (value) => {
        if (value) {
            const currentCityDetails = {
                city: value.properties.city || value.properties.state,
                state: value.properties.state,
                country: value.properties.country,
                formatted: value.properties.formatted,
                lat: value.properties.lat,
                lon: value.properties.lon
            };

            onChange(currentCityDetails);
        } else {
            onChange(null);
        }
    }

    return (
        <GeoapifySelect
            value={value?.formatted ? value?.formatted : ''}
            onChange={onSelectCurrentCity}
        />
    )
}

const CurrentLocationInput = ({ user, editMode, onChangeEditMode }) => {

    const [currentLocation, setCurrentLocation] = useState(null);

    const [isCurrentLocationEditable, setIsCurrentLocationEditable] = useState(false);

    const handleOnChange = useCallback((value) => {
        setCurrentLocation(value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsCurrentLocationEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const hasCurrentLocation = Boolean(user.currentLocation);
    const isEditable = isCurrentLocationEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Current City"
            value={
                (hasCurrentLocation && !isEditable) ?
                    user.currentLocation.formatted :
                    (isEditable) ?
                        <CurrentLocationSelect
                            onChange={handleOnChange}
                            value={currentLocation}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="currentLocation"
                    inputValue={currentLocation}
                    inputEditable={isCurrentLocationEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!currentLocation ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default CurrentLocationInput;