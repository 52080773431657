import React, { useState } from 'react';
import { Button, Tooltip, Input, message, Row, Col } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import useCompletionContext from '../../../../contexts/CompletionContext';
import useCommentsContext from './CommentsContext';

const CommentEditor = () => {

    const {
        updateComment,
    } = useCompletionContext();

    const {
        editedComment, // The comment that is currently being edited.
        setLoading,
        setError,
        deactivateEditMode,
    } = useCommentsContext();

    const [state, setState] = useState({
        commentInputText: editedComment.text,
    })

    const handleOnCommentChange = (e) => {
        const { value } = e.target;
        setState(ps => ({ ...ps, commentInputText: value }));
    }

    const handleOnEditSaveButtonClick = () => {
        const trimmedComment = state.commentInputText.trim();
        if (trimmedComment && editedComment) {

            // Setting the loading flag.
            setLoading(true);

            // Updating the comment in the backend.
            updateComment(editedComment.id, trimmedComment)
                .then(() => {
                    message.success("Comment was updated successfully");
                    deactivateEditMode();
                })
                .catch(e => {
                    setError(e);
                })
                .finally(() => {
                    setLoading(false);
                })

        }
        else {
            message.warning("Please add a comment to update!")
        }
    }

    const handleOnEditCancelButtonClick = () => {
        deactivateEditMode();
    }

    return (
        <Row align='middle'>
            <Col span={22}>
                <Input.TextArea
                    dir='auto'
                    style={{ borderRadius: "10px" }}
                    rows={1}
                    autoSize={true}
                    value={state.commentInputText}
                    onChange={handleOnCommentChange}
                />
            </Col>
            <Col>
                <Tooltip title='Save Changes'>
                    <Button
                        type="text"
                        size="middle"
                        disabled={state.commentInputText === editedComment.text}
                        style={{ color: state.commentInputText === editedComment.text ? "#d9d9d9" : "#2cb280" }}
                        icon={<CheckOutlined style={{ fontSize: '1.3rem' }} />}
                        onClick={handleOnEditSaveButtonClick}
                    />
                </Tooltip>
                <Tooltip title='Cancel Changes'>
                    <Button
                        type="text"
                        size="middle"
                        style={{ color: "red" }}
                        icon={<CloseOutlined style={{ fontSize: '1.3rem' }} />}
                        onClick={handleOnEditCancelButtonClick}
                    />
                </Tooltip>
            </Col>
        </Row>
    )
}

export default CommentEditor;