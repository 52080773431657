import React, { createContext, useState, useContext } from 'react';
import usersApi from '../api/users';
import { formatError } from './ResponseErrorFormatter';

const PMUsersContext = createContext(null);

const EMPTY_CRITERIA = {
    search: '',
    role: null,
    isActive: null,
    skills: null,
    otherLanguages: null,
    nativeLanguage: null,
    skip: 0,
    limit: 10,
    orderColumn: null,
    orderAscending: true,
}

const PMUsersContextProvider = ({ children }) => {

    const [state, setState] = useState({
        users: [],
        usersCount: 0,
        userData: null,
        isLoading: false,
        error: null,
        criteria: EMPTY_CRITERIA,

        invitationsBeingSent: false,
        invitationsSendError: null,
        actvStateUpdateError: null,
    });

    const fetchUsers = async (fetchCriteria) => {

        // Regulating the input fetch crtieria.
        if (fetchCriteria) {
            fetchCriteria.search = typeof fetchCriteria.search === 'string' ? fetchCriteria.search : EMPTY_CRITERIA.search;
            fetchCriteria.role = fetchCriteria.role ? fetchCriteria.role : EMPTY_CRITERIA.role;
            fetchCriteria.isActive = typeof fetchCriteria.isActive === 'boolean' ? fetchCriteria.isActive : EMPTY_CRITERIA.isActive;
            fetchCriteria.skip = typeof fetchCriteria.skip === 'number' ? fetchCriteria.skip : EMPTY_CRITERIA.skip;
            fetchCriteria.limit = typeof fetchCriteria.limit === 'number' ? fetchCriteria.limit : EMPTY_CRITERIA.limit;
            fetchCriteria.skills = Array.isArray(fetchCriteria.skills) ? fetchCriteria.skills : EMPTY_CRITERIA.skills;
            fetchCriteria.otherLanguages = Array.isArray(fetchCriteria.otherLanguages) ? fetchCriteria.otherLanguages : EMPTY_CRITERIA.otherLanguages;
            fetchCriteria.nativeLanguage = Array.isArray(fetchCriteria.nativeLanguage) ? fetchCriteria.nativeLanguage : EMPTY_CRITERIA.nativeLanguage;
            fetchCriteria.orderColumn = typeof fetchCriteria.orderColumn === 'string' ? fetchCriteria.orderColumn : EMPTY_CRITERIA.orderColumn;
            fetchCriteria.orderAscending = typeof fetchCriteria.orderAscending === 'boolean' ? fetchCriteria.orderAscending : EMPTY_CRITERIA.orderAscending;
        }
        else {
            fetchCriteria = EMPTY_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            isLoading: true,
            criteria: fetchCriteria,
            error: null
        }));

        await usersApi.getPMUsers(fetchCriteria)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    users: res.data.users,
                    usersCount: res.data.count,
                    error: null,
                    isLoading: false
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    users: [],
                    error: formatError(e, 'Failed To Retrieve Users Data!'),
                    isLoading: false
                }));
            });
    }

    const fetchSingleUserData = async (userID) => {

        setState(ps => ({
            ...ps,
            userData: null,
            isLoading: true
        }));

        try {
            const res = await usersApi.getUserByID(userID);
            setState(ps => ({
                ...ps,
                userData: res.data,
                error: null,
                isLoading: false
            }));
        }
        catch (e) {
            setState(ps => ({
                ...ps,
                error: formatError(e, 'Failed to fetch user data!'),
                isLoading: false,
            }));
        }
    }

    const sendInvitations = async (emails) => {

        setState(ps => ({
            ...ps,
            invitationsBeingSent: true,
            invitationsSendError: null
        }));

        return new Promise((resolve, reject) => {
            usersApi.sendInvitationEmails(emails)

                .then(res => {
                    // Updating the state.
                    setState(ps => ({
                        ...ps,
                        invitationsBeingSent: false,
                        invitationsSendError: null
                    }));

                    // Checking if there are any failed invitations.
                    let failedInvitationEmails = null;
                    if (res.data &&
                        Array.isArray(res.data.failed_emails) &&
                        res.data.failed_emails.length > 0) {
                        failedInvitationEmails = res.data.failed_emails;
                    }

                    // Reporting the failed invitations if there are any.
                    resolve(failedInvitationEmails);
                }).catch(e => {
                    setState(ps => ({
                        ...ps,
                        invitationsBeingSent: false,
                        invitationsSendError: formatError(e, 'Failed To Send Invitations!')
                    }));
                    reject();
                });
        })
    }

    const setUsersActiveState = (userIDs, state) => {

        setState(ps => ({
            ...ps,
            isLoading: true,
            actvStateUpdateError: null
        }))

        const actvStatePromise = state ?
            usersApi.activateContributors(userIDs) :
            usersApi.deactivateContributors(userIDs);

        return new Promise((resolve, reject) => {
            actvStatePromise
                .then(res => {

                    // Refreshing users and this will update loading state.
                    fetchUsers(state.criteria);

                    // Checking if there are any failed deactivation of users.
                    let failedActvStateUpdates = null;
                    if (res.data &&
                        res.data.failed &&
                        Array.isArray(res.data.failed) &&
                        res.data.failed.length > 0) {
                        failedActvStateUpdates = res.data.failed
                    }

                    // Reporting the failed to activate/deactivate users.
                    resolve(failedActvStateUpdates);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isLoading: false,
                        actvStateUpdateError: formatError(e, `Failed To ${state ? 'Activate' : 'Deactivate'} Selected Users!`)
                    }))
                    reject();
                })
        })
    }


    const clearError = () => setState(ps => ({ ...ps, error: null }));
    const clearSendInvitationError = () => setState(ps => ({ ...ps, sendInvitationsResult: null }));
    const clearActvStateUpdateError = () => setState(ps => ({ ...ps, actvStateUpdateError: null }));

    const services = {
        ...state,

        fetchUsers,
        fetchSingleUserData,
        clearError,

        sendInvitations,
        setUsersActiveState,
        clearSendInvitationError,
        clearActvStateUpdateError,
    }

    return (
        <PMUsersContext.Provider value={services}>
            {children}
        </PMUsersContext.Provider>
    );
}

const usePMUsersContext = () => useContext(PMUsersContext);

export { usePMUsersContext as default, PMUsersContext, PMUsersContextProvider };
