import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Space, Divider } from 'antd';
import { RightOutlined, NotificationFilled } from '@ant-design/icons';

import ClearNotification from '../../Page/NotificationPageCard/ClearNotification';
import CardActionButton from '../../Page/NotificationPageCard/CardActionButton';

import TextHiglighter from '../../TextHiglighter';
import { NOT_AVAIALBLE, ROLES_TRANSLATOR, TYPE_DATA_PROCESSOR, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import { NOTIFICATIONS_PATH } from '../../../../routes';
import NotificationDate from '../../NotificationDate';

const { Text } = Typography;

const TaskAssigned = ({ notification }) => {
    const history = useHistory();

    const content = notification.content;
    const projectName = <TextHiglighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />
    const role = <TextHiglighter text={content.role ? ROLES_TRANSLATOR[content.role] : NOT_AVAIALBLE} />

    const goToAllNotifications = () => {
        history.push(NOTIFICATIONS_PATH)
    }

    return (
        <div>
            <Row gutter={[4, 16]}>
                <Col span={23}>
                    <Space direction="vertical">
                        <Text>You have been assigned to some completions from the project {projectName} as {role}.</Text>
                        <NotificationDate date={notification?.createdOn} />
                    </Space>
                </Col>

                <Col span={1}>
                    <NotificationFilled style={{ color: NOTIFICATIONS_CARDS_COLORS.TaskAssignment }} />
                </Col>
            </Row>
            <Divider style={{ margin: '8px 0' }} />
            <Row >
                <Col span={16}>
                    <CardActionButton key={content.role === TYPE_DATA_PROCESSOR ? 'goToDp' : 'goToRV'}
                        icon={<RightOutlined style={{ fontSize: "80%" }} />}
                        text={"More Info"}
                        onClick={goToAllNotifications}>
                    </CardActionButton>
                </Col>

                <Col span={8}>
                    <ClearNotification notificationID={notification.id} />
                </Col>
            </Row>
        </div>
    )
}

export default TaskAssigned;