import * as yup from 'yup';


export const UploadTagAttrsSchema = yup.object().shape({
  minSize: yup
    .number()
    .nullable()
    .min(0.001, 'minSize must be greater than 0.001')
    .typeError('minSize must be a numeric value'),

  maxSize: yup
    .number()
    .nullable()
    .min(0.001, 'maxSize must be greater than 0.001')
    .max(2097152, 'maxSize cannot be greater than 2,097,152 (2GB)')
    .test('maxSize > minSize', 'minSize must not be greater than maxSize', function (value, context) {
        const { minSize } = context.parent;
        if (value !== null && !isNaN(minSize)) {
          return value >= minSize;
        }
        return true;
      })
    .typeError('maxSize must be a numeric value'),

  minFiles: yup
    .number()
    .nullable()
    .integer()
    .min(1, 'minFiles must be at least 1')
    .typeError('minFiles must be a numeric value'),

  maxFiles: yup
    .number()
    .nullable()
    .integer()
    .min(1, 'maxFiles must be at least 1')
    .test('maxFiles > minFiles', 'minFiles must not be greater than maxFiles', function (value, context) {
        const { minFiles } = context.parent;
        if (value !== null && !isNaN(minFiles)) {
          return value >= minFiles;
        }
        return true;
      })
    .typeError('maxFiles must be a numeric value'),

  minSnr: yup
    .number()
    .nullable()
    .integer()
    .typeError('minSnr value must be an integer'),

  maxSnr: yup
    .number()
    .nullable()
    .integer()
    .test('maxSnr > minSnr', 'minSnr must not be greater than maxSnr', function (value, context) {
      const { minSnr } = context.parent;
      if (value !== null && minSnr !== null && !isNaN(minSnr)) {
        return value >= minSnr;
      }
      return true;
    })
    .typeError('maxSnr value must be an integer'),

  loudness: yup.number().nullable().max(0).typeError('loudness value must be a number less than or equal to 0'),
  truepeak: yup.number().nullable().lessThan(0).typeError('truepeak value must be a number less than 0'),
});
