import { useEffect, useState, useRef } from 'react';

import { CONTRIBUTOR_TYPE } from '../LookupTables';
import useCompletionTimerContext from '../../../../contexts/CompletionTimerContext';
import useCompletionContext from '../../../../contexts/CompletionContext';

const onCloseOrReload = (e) => {
	// It would have been nice here if we can check that the last input is already saved,
	// but this event listener has to respond in a finite ammount of time which is much
	// less than the time required to serialize the result from the app store. This was tried
	// and was found it is not working.
	if (process.env.REACT_APP_DOMAIN_NAME !== e.origin) return;
	e.preventDefault();
	const message =
		'Changes you made may not be saved. Please make sure first the draft is saved!';
	e.returnValue = message;
	return message;
};

const addDocumentEventListeners = (handleActivity) => {
	window.addEventListener('beforeunload', onCloseOrReload);
	document.addEventListener('mousemove', handleActivity);
	document.addEventListener('click', handleActivity);
	document.addEventListener('keydown', handleActivity);

	const addAudioEventListeners = () => {
		const audioTag = document?.querySelector('audio');
		if (audioTag) {
			audioTag.addEventListener('play', handleActivity);
			audioTag.addEventListener('pause', handleActivity);
		}
	};

	const addVideoEventListeners = () => {
		const videoTag = document?.querySelector('video');
		if (videoTag) {
			videoTag.addEventListener('play', handleActivity);
			videoTag.addEventListener('pause', handleActivity);
		}
	};

	const observer = new MutationObserver(() => {
		addAudioEventListeners();
		addVideoEventListeners();
	});

	const observableNode = document.querySelector('.ls-segment');
	const config = {
		subtree: true,
		childList: true,
		attributes: true,
	};

	if (observableNode) {
		observer.observe(observableNode, config);
	}
};

const removeDocumentEventListeners = (handleActivity) => {
	window.removeEventListener('beforeunload', onCloseOrReload);

	document.removeEventListener('mousemove', handleActivity);
	document.removeEventListener('click', handleActivity);
	document.removeEventListener('keydown', handleActivity);

	const audioTag = document?.querySelector('audio');
	if (audioTag) {
		audioTag.removeEventListener('play', handleActivity);
		audioTag.removeEventListener('pause', handleActivity);
	}

	const videoTag = document?.querySelector('video');
	if (videoTag) {
		videoTag.removeEventListener('play', handleActivity);
		videoTag.removeEventListener('pause', handleActivity);
	}
};

export const useInactiveTimer = ({ timeout, contributorType }) => {
	let inactivityTimer = null;
	const [isInactive, setIsInactive] = useState(true);
	const stopTimerOnUnmount = useRef();
	const saveDraftOnUnmount = useRef();
	const mediaPlayingRef = useRef();

	const { startTimer, resumeTimer, pauseTimer, stopTimer } =
		useCompletionTimerContext();
	const { completion, saveDraftResult, mediaPlaying } = useCompletionContext();

	stopTimerOnUnmount.current = () => {
		stopTimer();
	};
	saveDraftOnUnmount.current = () => {
		saveDraftResult(contributorType === CONTRIBUTOR_TYPE.REVIEWER);
	};
	mediaPlayingRef.current = mediaPlaying;

	const handleActivity = () => {
		setIsInactive(false);

		if (!isInactive && completion) {
			resumeTimer();
		}

		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(() => {
			if (mediaPlayingRef?.current) {
				handleActivity();
			} else {
				pauseTimer();
				setIsInactive(true);
			}
		}, timeout);
	};

	useEffect(() => {
		if (!isInactive && completion) {
			startTimer(completion?.id, contributorType);
		}

		addDocumentEventListeners(handleActivity);
		handleActivity(); // Initial setup

		return () => {
			removeDocumentEventListeners(handleActivity);
			clearTimeout(inactivityTimer);
			saveDraftOnUnmount.current();
			stopTimerOnUnmount.current();
			setIsInactive(false);
			inactivityTimer = null;
		};
		// it was intended to remove isInactive,handleActivity from dependecy array
		// to avoid unncessary renders
	}, [
		timeout,
		mediaPlayingRef,
		inactivityTimer,
		pauseTimer,
		resumeTimer,
		startTimer,
		contributorType,
		saveDraftOnUnmount,
		stopTimerOnUnmount,
		completion,
	]);

	return isInactive;
};
