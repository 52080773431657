import { Form, Select } from "antd"
import { factoryUnitsAndCostOptions } from "./helper";

const { Item } = Form;


const UnitSelect = ({ unitLabel, unitKey, projectService }) => {

    const unitSelectOptions = factoryUnitsAndCostOptions({ projectService });

    return (
        <Item
            label={unitLabel}
            name={unitKey}
            rules={[
                {
                    required: true,
                    message: "Please select unit!",
                },
            ]}>
            <Select
                placeholder="Select project's unit..."
                options={unitSelectOptions}
            />
        </Item>
    )
}

export default UnitSelect;