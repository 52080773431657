
const NOTIFICATIONS_TYPES = {
    ProjectAssignment: 1,
    TaskAssignment: 2,
    CompletionRejection: 3,
}

const NOTIFICATIONS_CARDS_COLORS = {
    ProjectAssignment: '#081f2d',
    TaskAssignment: "#36cfc9",
    CompletionRejection: '#ff4d4f',
}

const NOT_AVAIALBLE = 'Not Available';
const TYPE_DATA_PROCESSOR = 'dp';
const TYPE_REVIEWER = 'rv';
const ROLES_TRANSLATOR = {
    [TYPE_DATA_PROCESSOR]: 'Data Processor',
    [TYPE_REVIEWER]: 'Reviewer'
}

export {
    NOT_AVAIALBLE,
    TYPE_DATA_PROCESSOR,
    TYPE_REVIEWER,
    NOTIFICATIONS_TYPES,
    ROLES_TRANSLATOR,
    NOTIFICATIONS_CARDS_COLORS
};