import React, { useState, useCallback } from 'react';

const ImageViewer = ({ file, url }) => {
	const [imageURL, setImageURL] = useState('');

	const getFileURL = useCallback(
		(file) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => {
				setImageURL(reader.result);
			};
		},
		[file],
	);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<img
			src={url || imageURL}
			alt={url || imageURL}
			style={{ maxWidth: '100%' }}
		/>
	);
};

export default ImageViewer;
