import React from "react";
import { Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";

import CreateProject from './Create/CreateProject';
import PMProjects from './PMProjects';
import DisplayProject from './Display/DisplayProject';
import Users from './Users/Users';
import Content from "../AntdWrappers/Content/Content";
import { NEW_PROJECT_PATH, PM_PROJECTS_PATH, PM_SINGLE_PROJECT_PATH, PM_USERS_PATH } from "../../routes";
import { PMPrjContextProvider } from '../../contexts/PMPrjContext';
import { PMPrjSingleContextProvider } from '../../contexts/PMPrjSingleContext';
import { OptionsContextProvider } from '../../contexts/OptionsContext';

function PMComponent() {
  //TODO  PMPrjSingleContextProvider, PMPrjContextProvider need to wrap around their components only and removed from here.
  return (
    <Content>
      <OptionsContextProvider>
        <PMPrjContextProvider>
          <PMPrjSingleContextProvider>
            <Switch>
              <Route path={NEW_PROJECT_PATH} component={CreateProject} />
              <Route path={PM_SINGLE_PROJECT_PATH} component={DisplayProject} />
              <Route path={PM_PROJECTS_PATH} component={PMProjects} />
              <Route path={PM_USERS_PATH} component={Users} />
            </Switch>
          </PMPrjSingleContextProvider>
        </PMPrjContextProvider>
      </OptionsContextProvider>
    </Content>
  );
}

export default PMComponent;
