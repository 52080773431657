import { Form, Input } from "antd";
const { Item } = Form;

const ConfirmPassword = () => {
    return (
        <Item
            name="password2"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (!value || getFieldValue('password1') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                    },
                }),
            ]}
        >
            <Input.Password placeholder='Enter you password again...' autoComplete="new-password" />
        </Item>
    )
}


export default ConfirmPassword;