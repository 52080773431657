import React, { useState, useCallback, useMemo } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useGetEducationOptions from '../../api/queries/useGetEducationOptions';
import { formatError } from '../../contexts/ResponseErrorFormatter';
import { OTHER_EDUCATION_LEVEL_ID } from '../../Constants';

import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import SelectInput from './SelectInput';

const OtherEducationField = styled(Input)`
    margin-top: 1rem;
`;

const EducationInput = ({ user, editMode, onChangeEditMode }) => {
    const [educationLevelId, setEducationLevelId] = useState(undefined);
    const [otherEducation, setOtherEducation] = useState('');
    const [isEducationEditable, setIsEducationEditable] = useState(false);

    const { data: educationLevelOptions, error } = useGetEducationOptions();

    const showDisplayOtherEducationField = useMemo(
        () => educationLevelId === OTHER_EDUCATION_LEVEL_ID,
        [educationLevelId]
    );

    const handleEditableStatus = useCallback(
        (status) => {
            setIsEducationEditable(status);
            onChangeEditMode(status);
        },
        [onChangeEditMode]
    );

    const handleOnEducationLevelChange = useCallback(
        (educationLevel) => {
            setEducationLevelId(educationLevel);
        },
        []
    );

    const handleOnOtherEducationChange = useCallback(
        (e) => {
            setOtherEducation(e.target.value);
        },
        []
    );

    const hasEducation = Boolean(user?.education);
    const hasOtherEducation = Boolean(user?.otherEducation);
    const shouldDisableSaveBtn = Boolean(showDisplayOtherEducationField && !otherEducation) || !educationLevelId;

    const educationLevelOptionsError = useMemo(
        () => error && formatError(error, 'Failed To Retrieve Education Levels Options!'),
        [error]
    );

    const educationOptions = useMemo(
        () => educationLevelOptions?.map(el => ({ label: el.name, value: el.id, key: el.id })),
        [educationLevelOptions]
    );

    const getEducationValue = () => {
        if (!isEducationEditable) {
            if (hasOtherEducation) {
                return user?.otherEducation;
            }
            if (hasEducation) {
                return user?.education.name;
            }
        }
        return '--';
    };

    const educationValue = getEducationValue();

    return (
        <ProfileEntry
            hasBorder
            label="Education:"
            value={
                educationValue !== '--'
                ? educationValue
                : isEducationEditable && (
                        <>
                            <SelectInput
                                onChange={handleOnEducationLevelChange}
                                value={educationLevelId}
                                options={educationOptions}
                                optionsErr={educationLevelOptionsError}
                                placeholder="Select your education...."
                                selectMode="single"
                            />
                            {showDisplayOtherEducationField && (
                                <OtherEducationField
                                    placeholder="Please specify!"
                                    onChange={handleOnOtherEducationChange}
                                    value={otherEducation}
                                    allowClear
                                />
                            )}
                        </>
                    )
            }
            controls={
                <InputControls
                    user={user}
                    inputName={educationLevelId !== OTHER_EDUCATION_LEVEL_ID ? 'educationId' : 'otherEducation'}
                    inputValue={educationLevelId !== OTHER_EDUCATION_LEVEL_ID ? educationLevelId : otherEducation}
                    inputEditable={isEducationEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={shouldDisableSaveBtn}
                    disableEdit={editMode}
                />
            }
        />
    );
};

EducationInput.propTypes = {
    user: PropTypes.shape({
        education: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
        }),
        otherEducation: PropTypes.string,
    }),
    editMode: PropTypes.bool.isRequired,
    onChangeEditMode: PropTypes.func.isRequired,
};

export default EducationInput;