import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive'
import { Result, Button, Spin, Space, Table, Pagination } from 'antd';
import { EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import isEqual from 'lodash.isequal';

import { CONTRIBUTOR_PROJECTS_PATH, COMPLETION_DP_PATH_ID, COMPLETION_RV_PATH_ID, USER_PROFILE } from '../../routes';
import useCTProjectsContext from '../../contexts/CTProjectsContext';
import ErrorPage from '../Error/ErrorPage';
import ErrorBoundary from '../Error/ErrorBoundary';
import NewTabButton from '../Common/NewTabButton';
import MobileAssignedTasks from './MobileAssignedTasks';
import useExecludeIds from '../../hooks/useUploadTagExcludeIDs';

import { useAuthContext } from "../../contexts/AuthContext";
import { isContributorProfileComplete } from "../../lib/schemas";
import CompleteProfileModal from "../Common/CompleteProfileModal";
import RefreshTableButton from '../Common/RefreshTableButton';
import ResetTableButton from '../Common/ResetTableButton';

const EMPTY_FILTERS_INFO = {
    assignmentType: null
};

const AssignedTasks = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })
    const history = useHistory();
    const exculdeIds = useExecludeIds();
    const shouldBeDisabled = (completionId) => exculdeIds?.includes(completionId)

    const returnToProjectsList = () => history.push(CONTRIBUTOR_PROJECTS_PATH);
    const {
        isLoading,
        error,
        assignedCompletions,
        assignedCompletionsCount,
        assignedCompletionsCriteria,
        fetchAssignedCompletions,
        resetMobilePageSize,
    } = useCTProjectsContext();

    const {
        getUserProfile,
        user,
    } = useAuthContext();

    const [state, setState] = useState({
        sorterInfo: {},
        filtersInfo: EMPTY_FILTERS_INFO,
    })

    const [isCompleteProfileModalOpen, setIsCompleteProfileModalOpen] = useState(false);

    useEffect(() => {
        fetchAssignedCompletions();
        resetMobilePageSize();
        getUserProfile();
        //eslint-disable-next-line
    }, []);

    const onPaginationChange = (pageNumber, pageSize) => {
        let skip = (pageNumber - 1) * pageSize;
        let limit = pageSize;
        fetchAssignedCompletions({
            ...assignedCompletionsCriteria,
            skip,
            limit,
        });
    }

    const onFiltersAndSorterChange = (newFilters, newSorter) => {
        // Checking if the filters have changed.
        const filtersChanged = !isEqual(state.filtersInfo, newFilters);

        // Fetching data with the updated criteria.
        fetchAssignedCompletions({
            ...assignedCompletionsCriteria,
            skip: filtersChanged ? 0 : assignedCompletionsCriteria.skip, // Resetting the page if the filters were changed.
            assignmentType: Array.isArray(newFilters.assignmentType) && newFilters.assignmentType.length > 0 ? newFilters.assignmentType[0] : null,
            orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
            orderAscending: newSorter.order ? newSorter.order === 'ascend' : false,
        });

        // Updating the sorter info object.
        setState(ps => ({
            ...ps,
            filtersInfo: newFilters,
            sorterInfo: newSorter
        }));
    }

    const handleRefreshTable = useCallback(() => {
        fetchAssignedCompletions({ ...assignedCompletionsCriteria });
    }, [fetchAssignedCompletions, assignedCompletionsCriteria])

    const handleResetTable = useCallback(() => {
        fetchAssignedCompletions();
        if (isTabletOrMobile)
            resetMobilePageSize();
        setState(ps => ({
            ...ps,
            sorterInfo: {},
            filtersInfo: EMPTY_FILTERS_INFO,
        }))
    }, [fetchAssignedCompletions, isTabletOrMobile, resetMobilePageSize])

    const handleOpenCompleteProfileModal = useCallback(() => {
        setIsCompleteProfileModalOpen(true);
    }, [setIsCompleteProfileModalOpen])

    const handleCloseCompleteProfileModal = useCallback(() => {
        setIsCompleteProfileModalOpen(false);
    }, [setIsCompleteProfileModalOpen])

    const handleRedirectContributor = useCallback((path, projectId, compId) => {
        if (!isContributorProfileComplete(user)) {
            handleOpenCompleteProfileModal();
        } else {
            history.push(formatRoute(path, { projectId: projectId, compId: compId }))
        }
    }, [handleOpenCompleteProfileModal, history, user])

    const handleRedirectToProfile = useCallback(() => {
        history.push(formatRoute(USER_PROFILE))
    }, [history])

    const handleOnClickWithCtrl = useCallback((parh, projectId, compId) => {
        if (!isContributorProfileComplete(user)) {
            handleOpenCompleteProfileModal();
        } else {
            window.open(formatRoute(parh, { projectId: projectId, compId: compId }))
        }
    }, [handleOpenCompleteProfileModal, user])

    const columns = [
        {
            title: "Project ID",
            dataIndex: "projectId",
            key: "projectId",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'projectId' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Completion ID",
            dataIndex: "id",
            key: "id",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'id' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Task ID",
            dataIndex: "taskId",
            key: "taskId",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'taskId' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Project Name",
            dataIndex: "projectName",
            key: "projectName",
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: state.sorterInfo.columnKey === 'projectName' && state.sorterInfo.order,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: "Required Action",
            dataIndex: 'assignmentType',
            key: "assignmentType",

            // Sorting is not going to be supported.
            // sorter: () => undefined, // Since the sorting takes place in the backend.
            // sortOrder: state.sorterInfo.columnKey === 'assignmentType' && state.sorterInfo.order,
            // sortDirections: ['ascend', 'descend'],

            filters: [
                {
                    text: 'Review',
                    value: 'rv',
                },
                {
                    text: 'Process',
                    value: 'dp',
                }
            ],
            filterMultiple: false,
            filteredValue: state.filtersInfo.assignmentType || null,

            render: (text, record) => {
                return (
                    <Space>
                        {record.assignmentType === 'dp' &&
                            <NewTabButton
                                displayText='Process'
                                icon={<EditOutlined />}
                                type='primary'
                                size='small'
                                disabled={shouldBeDisabled(record.id)}
                                onClick={() => handleRedirectContributor(COMPLETION_DP_PATH_ID, record.projectId, record.id)}
                                onClickWithCtrl={() => handleOnClickWithCtrl(COMPLETION_DP_PATH_ID, record.projectId, record.id)}
                            />
                        }

                        {record.assignmentType === 'rv' &&
                            <NewTabButton
                                displayText='Review'
                                icon={<FileSearchOutlined />}
                                type='primary'
                                size='small'
                                className='btn-success'
                                onClick={() => handleRedirectContributor(COMPLETION_RV_PATH_ID, record.projectId, record.id)}
                                onClickWithCtrl={() => handleOnClickWithCtrl(COMPLETION_RV_PATH_ID, record.projectId, record.id)}
                            />
                        }
                    </Space>
                )
            },
        }
    ];

    return (
        <ErrorBoundary>
            {/**complete profile modal */}
            <CompleteProfileModal
                isOpen={isCompleteProfileModalOpen}
                onClickOk={handleRedirectToProfile}
                onClickCancel={handleCloseCompleteProfileModal}
            />
            {/* refresh and reset buttons */}
            <Space align='baseline' style={{
                marginTop: '20px',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <RefreshTableButton
                    tooltipText="Refresh Table Data"
                    isDisabled={isLoading}
                    onRefresh={handleRefreshTable}
                />
                <ResetTableButton
                    tooltipText="Clear Sorting, and Filters"
                    tooltipPlacement="topLeft"
                    isDisabled={isLoading}
                    onReset={handleResetTable}
                />
            </Space>


            <Spin spinning={isLoading}>

                {!error && assignedCompletions.length > 0 &&
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {isTabletOrMobile &&
                            <MobileAssignedTasks
                                onRedirectContributor={handleRedirectContributor}
                                onClickWithCtrl={handleOnClickWithCtrl}
                            />}

                        {!isTabletOrMobile &&
                            <Fragment>
                                <Table
                                    dataSource={assignedCompletions.map(item => ({ ...item, key: item.id }))}
                                    loading={isLoading}
                                    columns={columns}
                                    pagination={false}
                                    onChange={(pagination, filters, sorter) => onFiltersAndSorterChange(filters, sorter)}
                                />
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <Pagination
                                        showSizeChanger
                                        showQuickJumper
                                        showTotal={() => `Total: ${assignedCompletionsCount}`}
                                        total={assignedCompletionsCount}
                                        pageSize={assignedCompletionsCriteria.limit}
                                        current={(assignedCompletionsCriteria.skip / assignedCompletionsCriteria.limit) + 1}
                                        onChange={onPaginationChange}
                                    />
                                </div>
                            </Fragment>}
                    </Space>
                }

                {!isLoading && error &&
                    <ErrorPage error={error} redirect={{ text: 'Return To Projects', action: returnToProjectsList }} />
                }

                {!isLoading && !error && assignedCompletions.length === 0 &&
                    < Result
                        status="success"
                        title="You Have 0 Assigned Tasks"
                        extra={<Button type="primary" onClick={returnToProjectsList}>Return To Projects</Button>}
                    />
                }
            </Spin>
        </ErrorBoundary>
    )
}

export default AssignedTasks;
