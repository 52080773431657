import { Redirect } from "react-router-dom";
import { Button, Card } from "antd";
import { useMediaQuery } from "react-responsive";
import { GoogleOutlined, LoginOutlined } from "@ant-design/icons";

import Content from "../../AntdWrappers/Content/Content";
import ErrorAlert from '../../Error/ErrorAlert';
import LxtLoadingLogo from "../../Common/LxtLoadingLogo";

import { CardTitle } from "./Login"
import { useAuthContext } from "../../../contexts/AuthContext";
import { PM_PROJECTS_PATH } from "../../../routes";
import "../Auth.scss"

const LoginInternal = () => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

  const {
    user,
    isLoading,
    signInByGoogle,
    error
  } = useAuthContext();

  // Handles sign in by google.
  const handleSignInByGoogle = () => {
    signInByGoogle();
  }

  const signInCardStyle = isTabletOrMobile ? 'mobile-login-internal-card' : 'card-style internal-card-style'

  return (
    <Content alignVertically>
      {/* Redirecting the user if he is logged in */}
      {user && <Redirect to={PM_PROJECTS_PATH} />}

      {/* Loading Logo */}
      {isLoading && <LxtLoadingLogo text='Signing In...' />}

      {/* Signing In With Google */}
      {!user && !isLoading &&
        <Card
          className={signInCardStyle}
          title={<CardTitle text='LXT Users Sign In' icon={<LoginOutlined />} />}>
          <Button
            onClick={handleSignInByGoogle}>
            <GoogleOutlined /> Sign In With Google
          </Button>
        </Card>
      }

      <ErrorAlert error={error} />

    </Content>
  )
}

export default LoginInternal;
