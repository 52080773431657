import React from 'react';
import { Modal, Button, Table } from 'antd';
import TemplateAlert from './TemplateAlert';

const adhocCsvTemplateTableColumns = [
    {
        title: 'email',
        dataIndex: 'email',
    },
    {
        title: 'description',
        dataIndex: 'description',
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'other type',
        dataIndex: 'otherType',
    },
    {
        title: 'unit type',
        dataIndex: 'unitType',
    },
    {
        title: 'rate',
        dataIndex: 'rate',
    },
    {
        title: 'hours',
        dataIndex: 'hours',
    }
];

const adhocCsvTemplateTableExampleData = [
    {
        key: '1',
        email: "contributor424@gmail.com",
        description: "Task Details",
        type: "testing",
        otherType: "",
        unitType: "fixed",
        rate: 9,
        hours: "",
    },
    {
        key: '2',
        email: "contributor43@gmail.com",
        description: "Task Details",
        type: "other",
        otherType: "Evaluation",
        unitType: "fixed",
        rate: 3,
        hours: ""
    },
    {
        key: '3',
        email: "contributor65@gmail.com",
        description: "Task Details",
        type: "hiring",
        otherType: "",
        unitType: "hourly",
        rate: 2,
        hours: 4
    },
    {
        key: '4',
        email: "contributor2001@gmail.com",
        description: "Task Details",
        type: "testing",
        otherType: "",
        unitType: "fixed",
        rate: 15,
        hours: ""
    },
    {
        key: '5',
        email: "contributor32@outlook.com",
        description: "Task Details",
        type: "other",
        otherType: "Translation",
        unitType: "hourly",
        rate: 8,
        hours: 11
    },
];

const TemplateModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            title="Shown below is a template of what a CSV file for ad-hoc tasks should look like."
            visible={isOpen}
            width={1000}
            onCancel={onClose}
            footer={[
                <Button key="submit" type="primary" onClick={onClose}>
                    OK
                </Button>,
            ]}
        >
            <Table columns={adhocCsvTemplateTableColumns} dataSource={adhocCsvTemplateTableExampleData} size="small" pagination={false} />
            <TemplateAlert
                message="If the task type is other, then you have to set its type in the other type column."
            />
            <TemplateAlert
                message="If the task unit is hourly, then you have to set the number of hours in the hours column."
            />
        </Modal>
    );
};

export default TemplateModal;