import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spin, Divider, Alert } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { formatRoute } from 'react-router-named-routes';

import CompletionViewHeader from './CompletionViewHeader';
import CompletionControlPanel from './CompletionControlPanelMobile';
import CompletionViewStatus from './CompletionViewStatus';
import LabelStudioView from '../../LabelStudioView/LabelStudioView';
import ErrorBoundary from '../../Error/ErrorBoundary';
import ConsentModal from '../ConsentModal';
import { useInactiveTimer } from './Completion_Hooks/useInactiveTimer';
import ContributorActionButtons from '../../LabelStudioView/ContributorActionButtons';

import useCompletionContext from '../../../contexts/CompletionContext';
import useCompletionTimerContext from '../../../contexts/CompletionTimerContext';
import useCTProjectsContext from '../../../contexts/CTProjectsContext';

import { CONTRIBUTOR_TYPE } from './LookupTables';
import { CONTRIBUTOR_PROJECTS_PATH } from '../../../routes';

import ErrorModal from '../../Error/ErrorModal';
import { useFeatureValue } from '../../../lib/featureToggles';

const IdleAlert = () => {
	return (
		<Alert
			message="Your timer is on hold due to inactivity. Please resume your activity to continue the timer."
			type="warning"
			showIcon
		/>
	);
};

const MainEditorView = ({ contributorType }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const handleTimerConfiguration = useFeatureValue(
		'configure_contributor_handle_timer',
	);

	const history = useHistory();
	const { projectId, compId } = useParams();

	const [state, setState] = useState({
		profileData: {},
	});

	const {
		initializeContext,
		completion,
		hasNext,
		loading: isCompletionLoading,
		error,
		getLsfStore,
		fetchNextCompletion,
		fetchNextCompletionByID,
	} = useCompletionContext();

	const {
		stopTimer,
		error: timerError,
		clearError: clearTimerError,
	} = useCompletionTimerContext();

	const { getProjectConsent, projectConsent, sendUserDecisionForConsnent } =
		useCTProjectsContext();

	const store = getLsfStore();

	const idle_timeout_in_seconds =
		handleTimerConfiguration?.ideal_timeout_in_seconds ?? 15;
	const INACTIVITY_PERIOD = 1000 * idle_timeout_in_seconds;

	const userIsInactive = useInactiveTimer({
		timeout: INACTIVITY_PERIOD,
		contributorType,
	});

	const contributerIsIdle = Boolean(completion && userIsInactive);

	const stopTimerBeforeCommitResult = () => {
		return new Promise((resolve, reject) => {
			const onFailureFlags = { reportError: true, restartTimer: true };
			stopTimer(onFailureFlags)
				.then(() => {
					resolve();
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	const handleShowConsentModal = (value) =>
		setState((ps) => ({ ...ps, showConsent: value }));

	const handleOnClickAgree = () => {
		sendUserDecisionForConsnent(projectConsent.id, { agree: true }).then(() => {
			window.location.reload(true);
			handleShowConsentModal(false);
		});
	};

	const handleOnClickDisAgree = () => {
		sendUserDecisionForConsnent(projectConsent.id, { agree: false });
		history.replace(formatRoute(CONTRIBUTOR_PROJECTS_PATH));
		handleShowConsentModal(false);
	};

	const handleOnCloseStopTimerErrorModal = () => {
		clearTimerError();
		history.push(CONTRIBUTOR_PROJECTS_PATH);
	};

	// The initial fetch when the component mounts the first time.
	useEffect(() => {
		if (compId) {
			fetchNextCompletionByID(
				projectId,
				compId,
				contributorType === CONTRIBUTOR_TYPE.REVIEWER,
			).catch(() => {
				if (contributorType === CONTRIBUTOR_TYPE.DATA_PROCESSOR)
					getProjectConsent(projectId).then(() => handleShowConsentModal(true));
			});
		} else {
			fetchNextCompletion(
				projectId,
				contributorType === CONTRIBUTOR_TYPE.REVIEWER,
			).catch(() => {
				if (contributorType === CONTRIBUTOR_TYPE.DATA_PROCESSOR)
					getProjectConsent(projectId).then(() => handleShowConsentModal(true));
			});
		}

		// Initializing handlers for the completion context.
		initializeContext();

		//eslint-disable-next-line
	}, [compId]);

	return (
		<ErrorBoundary>
			{/**Consent Modal */}
			{!error && projectConsent.content && hasNext && (
				<ConsentModal
					visible={state.showConsent}
					onAgree={() => handleOnClickAgree()}
					onDisagree={() => handleOnClickDisAgree()}
					content={projectConsent.content}
					projectConsent={projectConsent}
				/>
			)}

			{timerError?.detail?.length !== 0 && (
				<ErrorModal
					error={timerError}
					onDone={handleOnCloseStopTimerErrorModal}
				/>
			)}

			<Spin spinning={isCompletionLoading}>
				<div style={{ height: '100vh' }}>
					{hasNext && !isCompletionLoading && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							{!isTabletOrMobile && (
								<CompletionViewHeader contributorType={contributorType} />
							)}

							{/* Contributor Idle Warning */}
							{contributerIsIdle && <IdleAlert />}

							{/* Contributor Action Buttons */}
							<ContributorActionButtons
								stopTimerBeforeCommitResult={stopTimerBeforeCommitResult}
								contributorType={contributorType}
							/>
						</div>
					)}

					<Divider style={{ marginBottom: '10px', marginTop: '10px' }} />

					<CompletionViewStatus contributorType={contributorType} />

					{store && completion && !error && hasNext && (
						<Fragment>
							{/* Label Studio View */}
							<ErrorBoundary>
								<LabelStudioView
									store={store}
									isTabletOrMobile={isTabletOrMobile}
								/>
							</ErrorBoundary>
						</Fragment>
					)}

					{/**Mobile Completion Control Panel */}
					{isTabletOrMobile && (
						<div>
							<Divider style={{ marginBottom: '10px' }} />
							<CompletionControlPanel contributorType={contributorType} />
						</div>
					)}
				</div>
			</Spin>
		</ErrorBoundary>
	);
};

export { MainEditorView as default };
