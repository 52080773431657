import React from 'react';
import { Modal, Button } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const WarningModal = ({ title, message, show, onProceed, onCancel }) => (
    <Modal
        title={
            <span style={{ fontSize: '1.1rem' }}>
                <WarningFilled style={{ color: '#fa8c16', marginRight: '10px', fontSize: '1.5rem' }} />{title}
            </span>
        }
        visible={show}
        onCancel={onCancel}
        footer={[
            <Button type='primary' key='proceed' onClick={onProceed}>Proceed</Button>,
            <Button type='dashed' key='cancel' onClick={onCancel}>Cancel</Button>
        ]}>
        {message}
    </Modal>
)

export default WarningModal;