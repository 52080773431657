import React, { createContext, useContext, useState } from 'react';
import projectsApi from '../api/projects';
import { formatError } from './ResponseErrorFormatter';

const CTProjectsContext = createContext(null);

const EMPTY_PROJECTS_CRITERIA = {
    search: '',
    skip: 0,
    limit: 10,
    orderColumn: "id",
    orderAscending: false,
}

const EMPTY_COMPLETIONS_CRITERIA = {
    skip: 0,
    limit: 10,
    assignmentType: null,
    orderColumn: "projectId",
    orderAscending: false,
}

const CTProjectsContextProvider = ({ children }) => {

    const [state, setState] = useState({
        isLoading: false,
        error: null,
        selectedProject: null,

        allProjects: [],
        projectsCount: 0,
        projectsCriteria: EMPTY_PROJECTS_CRITERIA,

        assignedCompletions: [],
        assignedCompletionsCount: 0,
        assignedCompletionsCriteria: EMPTY_COMPLETIONS_CRITERIA,
        projectConsent: {},

        projectsMobilePageSize: 2,
        completionsMobilePageSize: 2,

    });

    const fetchAllProjects = async (fetchCriteria) => {

        // Adding missing properties of the fetch Criteria.
        if (fetchCriteria) {
            fetchCriteria.search = typeof fetchCriteria.search === 'string' ? fetchCriteria.search : EMPTY_PROJECTS_CRITERIA.search;
            fetchCriteria.skip = typeof fetchCriteria.skip === 'number' ? fetchCriteria.skip : EMPTY_PROJECTS_CRITERIA.skip;
            fetchCriteria.limit = typeof fetchCriteria.limit === 'number' ? fetchCriteria.limit : EMPTY_PROJECTS_CRITERIA.limit;
            fetchCriteria.orderColumn = typeof fetchCriteria.orderColumn === 'string' ? fetchCriteria.orderColumn : EMPTY_PROJECTS_CRITERIA.orderColumn;
            fetchCriteria.orderAscending = typeof fetchCriteria.orderAscending === 'boolean' ? fetchCriteria.orderAscending : EMPTY_PROJECTS_CRITERIA.orderAscending;
        }
        else {
            fetchCriteria = EMPTY_PROJECTS_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            isLoading: true,
            projectsCriteria: fetchCriteria,
            error: null,
        }));

        await projectsApi.getContributorProjects(fetchCriteria)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: null,
                    allProjects: res.data.projects,
                    projectsCount: res.data.count,
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: formatError(e, 'Failed To Retrieve Projects!'),
                    projectsCount: 0,
                    allProjects: []
                }));
            });
    }

    const fetchAssignedCompletions = (fetchCriteria) => {

        // Adding missing properties of the fetch Criteria.
        if (fetchCriteria) {
            fetchCriteria.skip = typeof fetchCriteria.skip === 'number' ? fetchCriteria.skip : EMPTY_COMPLETIONS_CRITERIA.skip;
            fetchCriteria.limit = typeof fetchCriteria.limit === 'number' ? fetchCriteria.limit : EMPTY_COMPLETIONS_CRITERIA.limit;
            fetchCriteria.assignmentType = fetchCriteria.assignmentType === 'dp' || fetchCriteria.assignmentType === 'rv' ? fetchCriteria.assignmentType : EMPTY_COMPLETIONS_CRITERIA.assignmentType;
            fetchCriteria.orderColumn = typeof fetchCriteria.orderColumn === 'string' ? fetchCriteria.orderColumn : EMPTY_COMPLETIONS_CRITERIA.orderColumn;
            fetchCriteria.orderAscending = typeof fetchCriteria.orderAscending === 'boolean' ? fetchCriteria.orderAscending : EMPTY_COMPLETIONS_CRITERIA.orderAscending;
        }
        else {
            fetchCriteria = EMPTY_COMPLETIONS_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            isLoading: true,
            error: null,
            assignedCompletionsCriteria: fetchCriteria,
        }));

        projectsApi.getContributorAssignedCompletions(fetchCriteria)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: null,
                    assignedCompletions: res.data.completions,
                    assignedCompletionsCount: res.data.count
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: formatError(e, `Failed To Retrieve Assigned Completions!`),
                    assignedCompletions: [],
                    assignedCompletionsCount: 0,
                }));
            });
    }

    const fetchSingleProject = async (projectID) => {

        if (state.selectedProject && String(state.selectedProject.id) === String(projectID))
            return;

        setState(ps => ({
            ...ps,
            isLoading: true,
            error: null,
            selectedProject: null
        }));

        await projectsApi.getContributorSingleProject(projectID)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: null,
                    selectedProject: res.data
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: formatError(e, `Failed To Retrieve Project With ID: ${projectID} !`),
                    selectedProject: null
                }));
            });
    }


    const getProjectConsent = (projectID) => {

        setState(ps => ({
            ...ps,
            projectConsent: {},
            error: null,
        }));

        return new Promise((resolve, reject) => {
            projectsApi.getProjectConsent(projectID)
                .then(res => {
                    setState(ps => ({
                        ...ps,
                        projectConsent: res.data,
                        error: null,
                    }));
                    resolve(res.data);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        projectConsent: {},
                        error: formatError(e, `Failed To Retrieve Project Consent!`),
                    }));
                    reject();
                })
        })
    }

    const sendUserDecisionForConsnent = (agreementID, userDescision) => {

        setState(ps => ({ ...ps, error: null, }));

        return new Promise((resolve, reject) => {
            projectsApi.sendUserDecisionForConsnent(agreementID, userDescision)
                .then(res => {
                    //nothing will happen as it's just sending user descision and data to BE
                    resolve();
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        error: formatError(e, `Failed To Send User Descision!`),
                    }));
                    reject();
                })
        })
    }

    const resetMobilePageSize = () => {
        state.allProjects.length > 0 ?
            setState(ps => ({ ...ps, projectsMobilePageSize: 2 })) : setState(ps => ({ ...ps, completionsMobilePageSize: 2 }))
    }

    const LoadMoreMobileTasks = () => {
        const pageSize = 10;
        const skip = 0;
        let limit = (state.completionsMobilePageSize) * pageSize;


        fetchAssignedCompletions({
            ...state.assignedCompletionsCriteria,
            skip,
            limit,
        });

        setState(ps => ({ ...ps, completionsMobilePageSize: ps.completionsMobilePageSize + 1 }))
    }

    const LoadMoreMobileProjects = () => {
        const pageSize = 10;
        const skip = 0;
        let limit = (state.projectsMobilePageSize) * pageSize;


        fetchAllProjects({
            ...state.projectsCriteria,
            skip,
            limit,
        });

        setState(ps => ({ ...ps, projectsMobilePageSize: ps.projectsMobilePageSize + 1 }))
    }

    const services = {
        ...state,
        fetchAllProjects,
        fetchSingleProject,
        fetchAssignedCompletions,

        getProjectConsent,
        sendUserDecisionForConsnent,

        resetMobilePageSize,
        LoadMoreMobileTasks,
        LoadMoreMobileProjects,
    }

    return (
        <CTProjectsContext.Provider value={services}>
            {children}
        </CTProjectsContext.Provider>
    )
}

const useCTProjectsContext = () => useContext(CTProjectsContext);

export { useCTProjectsContext as default, CTProjectsContextProvider }
