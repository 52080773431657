import React, { useState, useContext } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { nanoid } from 'nanoid';

import FieldLabel from '../../../../Common/FieldLabel';
import PMPrjSingleContext from '../../../../../contexts/PMPrjSingleContext';
import { LABEL_SPAN, VALUE_SPAN } from './Constants';

const getTextValue = (project, fieldName, options) => {

    let result = 'Not Selected';

    if (project[fieldName] || project[fieldName] === 0) {
        const matchingOption = options.find(opt => opt.value === project[fieldName]);
        if (matchingOption)
            result = matchingOption.label;
    }

    return result;
}

const ProjectFieldSelect = ({ labelText, fieldName, options, editable }) => {

    const { selectedProject, updateProjectValue } = useContext(PMPrjSingleContext);
    const [editMode, setEditMode] = useState(false);
    const fieldTextValue = getTextValue(selectedProject, fieldName, options);

    const onFieldChanged = (newValue) => {

        if (newValue === null || newValue === undefined)
            message.warning('Invalid value. Project is not updated!');
        else if (newValue !== selectedProject[fieldName])
            newValue === 'null' || newValue === 'Not Selected' ?
                updateProjectValue(fieldName, null) : updateProjectValue(fieldName, newValue)

        setEditMode(false);
    }

    return (
        <Row style={{ marginBottom: '20px' }} align='middle'>

            {/* The label of the field */}
            <Col span={LABEL_SPAN}>
                <FieldLabel text={labelText} />
            </Col>

            {/* Display value of the field */}
            {!editMode &&
                <Col span={VALUE_SPAN}>

                    <span>{fieldTextValue}</span>

                    {/* The pen edit button */}
                    {editable &&
                        <Button type='text' onClick={() => setEditMode(true)}>
                            <EditOutlined />
                        </Button>
                    }
                </Col>
            }

            {/* The edit form */}
            {editMode &&
                <Col span={VALUE_SPAN}>
                    <Select
                        showSearch
                        open
                        placeholder={fieldTextValue}
                        style={{ width: '100%' }}

                        autoFocus={true}
                        onBlur={() => setEditMode(false)}
                        value={selectedProject[fieldName]}
                        onSelect={onFieldChanged}

                        options={options.map(opt => ({ label: opt.label, value: opt.value, key: nanoid() }))}
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                </Col>
            }

        </Row>
    )
}

export default ProjectFieldSelect;
