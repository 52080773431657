import React from 'react';
import { inject, observer } from 'mobx-react';
import { destroy, getRoot, types } from 'mobx-state-tree';
import ObjectTag from '../../components/Tags/Object';
import Registry from '../../core/Registry';
import Tree from '../../core/Tree';
import Types from '../../core/Types';
import { TextAreaLabel } from '../../components/TextAreaLabels/TextAreaLabels';
import RequiredMixin from '../../mixins/Required';
import PerRegionMixin from '../../mixins/PerRegion';
import ControlBase from '../control/Base';
import { guidGenerator } from '../../utils/unique';
import { cloneNode } from '../../core/Helpers';
import ProcessAttrsMixin from '../../mixins/ProcessAttrs';
import { TextAreaWithLabelsRegionModel } from '../../regions/TextAreaWithLabelsRegion';

const TagAttrs = types.model({
	name: types.identifier,
	toname: types.maybeNull(types.string),
	value: types.optional(types.string, ''),
});

const log = (...args) =>
{
	if (process.env.NODE_ENV !== 'production')
	{
		console.info('TAG>>TWL', ...args);
	}
};

const Model = types
	.model({
		type: 'textarealabels',
		pid: types.optional(types.string, guidGenerator),
		children: Types.unionArray(['textarealabel']),
		regions: types.array(TextAreaWithLabelsRegionModel),

		_textAdd: false,
		_value: types.optional(types.string, ''),
	})
	.views((self) => ({
		// get value() {
		// 	return self?._value; // ?? self?.value;
		// },

		get valueType()
		{
			log('model:views:valueType', self);
			return 'text';
		},

		get resultType()
		{
			log('model:views:resultType', self);
			return 'textarea';
		},

		get holdsState()
		{
			log('model:views:holdsState', self);
			return self.regions.length > 0;
		},

		get completion()
		{
			log('model:views:completion', self, {
				root: getRoot(self),
				selected: getRoot(self).completionStore.selected,
			});

			return getRoot(self).completionStore.selected;
		},

		get serializableValue()
		{
			log('model:views:serializableValue', self);

			if (!self.regions.length) return null;
			return { text: self.selectedValues() };
		},

		selectedValues()
		{
			log('model:views:selectedValues', self);

			return self.regions.map((r) => r._value);
		},

		get result()
		{
			log('model:views:result', self);

			if (self.perregion)
			{
				const area = self.completion.highlightedNode;
				if (!area) return null;

				return self.completion.results.find(
					(r) => r.from_name === self && r.area === area,
				);
			}
			return self.completion.results.find((r) => r.from_name === self);
		},
	}))
	.actions((self) => ({
		getSerializableValue()
		{
			log('model:actions:getSerializableValue', self);

			const texts = self.regions.map((s) => s._value);
			if (texts.length === 0) return;

			return { text: texts };
		},

		needsUpdate()
		{
			log('model:actions:needsUpdate', self);

			self.updateFromResult(self.result?.mainValue);
			//This is used to show value in text area
			//If it was set in xml configuration
			if (self._value) self.addText(self._value);
		},

		setResult(value)
		{
			log('model:actions:setResult', self, { value });

			const values = Array.isArray(value) ? value : [value];
			values.forEach((v) => self.createRegion(v));
		},

		updateFromResult(value)
		{
			log('model:actions:updateFromResult', self, { value });

			self.regions = [];
			value && self.setResult(value);
		},

		setValue(value)
		{
			log('model:actions:setValue', 'ddd', self, { value });

			const text = value
				?.text
				?.replace
				?.(/^\n\n/, '');

			self._editor = value?.json;

			self._value = text;

			self.addText(text);
		},

		remove(region)
		{
			log('model:actions:remove', self, { region });
			const index = self.regions.indexOf(region);
			if (index < 0) return;
			self.regions.splice(index, 1);
			destroy(region);
			self.onChange();
		},

		copyState(obj)
		{
			log('model:actions:copyState', self, { obj });
			self.regions = obj.regions.map((r) => cloneNode(r));
		},

		perRegionCleanup()
		{
			log('model:actions:perRegionCleanup', self);
			self.regions = [];
		},

		createRegion(text, pid)
		{
			const r = TextAreaWithLabelsRegionModel.create({
				pid: pid,
				_value: text,
			});
			log('model:action:createRegion', self, {
				text,
				pid,
				regions: r,
			});
			// Always over-ride the first value.
			self.regions[0] = r;
			return r;
		},

		onChange()
		{
			log('model:actions:onChange', self);

			if (self.result)
			{
				self.result.area.setValue(self);
			} else
			{
				self.completion.createResult(
					{ editor: self._editor ?? { na: 1 } },
					{ text: self._value },
					self,
					self.toname,
				);
			}
		},

		addText(text, pid)
		{
			log('model:actions:addText', self);

			self.createRegion(text, pid);
			self.onChange();
		},

		beforeSend()
		{
			log('model:actions:beforeSend', self);

			if (self?._value?.length && !self._textAdd)
			{
				self.addText(self._value);
				self._textAdd = true;
			}
		},

		// add unsubmitted text when user switches region
		submitChanges()
		{
			log('model:actions:submitChanges', self);
			self.beforeSend();
		},

		getValue(labelsDict)
		{
			log('model:actions:getValue', self, { labelsDict });

			return self?._value ?? self?.value;
		},

		// deleteText(text) {
		// 	destroy(text);
		// },

		// onShortcut(value) {
		// 	log('model:actions:onShortcut', self, { value });
		// 	self.setValue(self._value + value);
		// 	self.addText(self._value);
		// },

		toStateJSON()
		{
			log('model:actions:toStateJSON', self);
			if (!self.regions.length) return;

			const toname = self.toname || self.name;
			const tree = {
				id: self.pid,
				from_name: self.name,
				to_name: toname,
				type: 'textarea',
				for_editor: self._editor ?? { na: null },
				value: {
					text: self.regions.map((r) => r._value),
				},
			};

			return tree;
		},

		// fromStateJSON(obj, fromModel) {
		// 	log('model:actions:fromStateJSON', self, {
		// 		obj,
		// 		fromModel,
		// 	});
		// 	// let { text } = obj.value;
		// 	// if (!Array.isArray(text)) text = [text];
		// 	//
		// 	// text.forEach((t) => self.addText(t, obj.id));
		// },
	}));

const TextAreaLabelsModal = types.compose(
	'TextAreaLabelsModal',
	ControlBase,
	TagAttrs,
	Model,
	ProcessAttrsMixin,
	RequiredMixin,
	PerRegionMixin,
);

const HtxTextAreaLabelsView = observer(({ item }) =>
{
	return (
		<ObjectTag item={item}>
			<TextAreaLabel item={item}>{Tree.renderChildren(item)}</TextAreaLabel>
		</ObjectTag>
	);
});

const HtxTextAreaLabels = inject('store')(HtxTextAreaLabelsView);

Registry.addTag('textareawithlabels', TextAreaLabelsModal, HtxTextAreaLabels);
Registry.addObjectType(TextAreaLabelsModal);

export { TextAreaLabelsModal, HtxTextAreaLabels };