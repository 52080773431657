import React, { useCallback } from 'react';
import { Select, Tag } from 'antd';

const tagRender = ({ value, label, closable, onClose }) => {
    return (
        <Tag color='#108ee9'
            closable={closable}
            style={{ display: 'block', wordWrap: 'break-word', whiteSpace: 'normal', margin: '1px' }}
            onClose={onClose}>
            <span style={{ fontSize: '12pt' }}>{label}</span>
        </Tag>
    );
}

const MultiSelectField = ({ selectedTags, onTagsChange, options, placeholder, selectMode = "multiple", onLoseFocus, isDisabled }) => {

    const handleFilterOption = useCallback((input, option) => {
        return option
            .label
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
    }, [])


    return (
        <Select
            showSearch
            style={{ width: '100%' }}
            onBlur={onLoseFocus}
            mode={selectMode}
            allowClear
            tagRender={tagRender}
            options={options}
            placeholder={placeholder}
            value={selectedTags}
            onChange={onTagsChange}
            optionFilterProp="children"
            filterOption={handleFilterOption}
            disabled={isDisabled}
        />
    )
}

export { MultiSelectField };
