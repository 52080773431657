import React, { Fragment, useState } from 'react';
import { Space, Input, Tooltip, Button } from 'antd';
import { CloseOutlined, CheckOutlined, ClearOutlined, RedoOutlined, CopyOutlined } from '@ant-design/icons';

import PasteAssignModal from './PasteAssignModal';
import AssignmentActionsButton from './AssignmentActionsButton';
import ErrorModal from '../../../Error/ErrorModal';
import { usePMProjectSingleContext, CONTRIBUTORS_ASSIGNMENT_TYPE } from '../../../../contexts/PMPrjSingleContext';


const ContributorsTableControls = ({ selectedContributorIDs, clearTableState }) => {

    const {
        contributorsCriteria,
        contributorsAssignmentError,
        contributorsCriteriaIsApplied,

        fetchContributors,
        setContributorsAssignedState,
        clearContributorAssignmentError,
    } = usePMProjectSingleContext();

    const [state, setState] = useState({
        showPasteAssignModal: false,
        search: '',
    });


    const handleOnSearchChange = e => {
        e.persist();
        setState(ps => ({ ...ps, search: e.target.value }));
    }
    const handleOnSearchCommitted = (searchTerm) => {

        // Skipping the search if the term hasn't changed.
        if (contributorsCriteria.search === searchTerm) return;

        fetchContributors({
            ...contributorsCriteria,
            skip: 0, // Resetting skip when search is applied.
            search: searchTerm,
        });
    }

    const handleOpenPasteAssignModal = () => {
        setState(ps => ({ ...ps, showPasteAssignModal: true }));
    }
    const handleClosePasteAssignModal = () => {
        setState(ps => ({ ...ps, showPasteAssignModal: false }));
    }

    const handleAssignMenuItemClick = (e) => {
        setContributorsAssignedState(selectedContributorIDs, true, CONTRIBUTORS_ASSIGNMENT_TYPE[e.key])
            .then(res => fetchContributors(contributorsCriteria)); // TODO display failed assignments.
    }
    const handleUnassignMenuItemClick = (e) => {
        setContributorsAssignedState(selectedContributorIDs, false, CONTRIBUTORS_ASSIGNMENT_TYPE[e.key])
            .then(res => fetchContributors(contributorsCriteria)); // TODO display failed assignments.
    }

    const handleRefreshData = () => {
        fetchContributors(contributorsCriteria);
    }
    const handleClearAllFilters = () => {
        fetchContributors();
        setState(ps => ({ ...ps, search: '' }));
        clearTableState();
    }

    return (
        <Fragment>
            <PasteAssignModal show={state.showPasteAssignModal} onDone={handleClosePasteAssignModal} />

            {/* As the assignment error of the paste assign modal is handeled in the modal, then we report here the error only when the modal is not displayed. */}
            {!state.showPasteAssignModal && <ErrorModal error={contributorsAssignmentError} onDone={clearContributorAssignmentError} />}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                {/* Components to the left */}
                <Space align='center' >
                    <Input.Search
                        enterButton
                        allowClear
                        size='small'
                        style={{ width: '350px' }}
                        placeholder="Search contributors by ID, name, or email..."
                        value={state.search}
                        onChange={handleOnSearchChange}
                        onSearch={handleOnSearchCommitted}
                        onBlur={() => handleOnSearchCommitted(state.search)} />
                </Space>

                {/* Components to the right */}
                <Space align='center'>

                    <Tooltip title="You can assign contributors by pasting their emails here.">
                        <Button
                            type='dashed'
                            size='small'
                            onClick={handleOpenPasteAssignModal}>
                            <CopyOutlined /> Assign Using Emails
                        </Button>
                    </Tooltip>

                    <AssignmentActionsButton
                        //type='primary'
                        className={'btn-success'}
                        icon={<CheckOutlined />}
                        tooltipText="Assign Selected Users"
                        text='Assign As'
                        disabled={selectedContributorIDs.length === 0}
                        onClickHandler={handleAssignMenuItemClick}
                    />

                    <AssignmentActionsButton
                        type='danger'
                        icon={<CloseOutlined />}
                        tooltipText="Unassign Selected Users"
                        text='Unassign Role'
                        disabled={selectedContributorIDs.length === 0}
                        onClickHandler={handleUnassignMenuItemClick}
                    />

                    <Tooltip title="Refresh Table Data">
                        <Button
                            type='dashed'
                            size='small'
                            onClick={handleRefreshData}>
                            <RedoOutlined />
                        </Button>
                    </Tooltip>

                    <Tooltip title="Clear Selection, Filters, Sorting, and Search" placement='topLeft'>
                        <Button
                            type='dashed'
                            size='small'
                            disabled={!contributorsCriteriaIsApplied() && selectedContributorIDs.length === 0}
                            onClick={handleClearAllFilters}>
                            <ClearOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            </div>
        </Fragment>
    )
}

export default ContributorsTableControls
