import CryptoJS from 'crypto-js';

const STORAGE_ID = '_comp_meta_'; // The name her is set deliberately vague not to reveal the meaning of the value not to be altered by the user.

class CompletionMetaDataHandler {

    constructor(config = {}) {
        this._completionID = '';
        this._dataObject = null;
        this._useEncryption = config.useEncryption;
    }

    init(completionID) {
        this._completionID = completionID;
        this._dataObject = null;
    }

    // Gets the value stored in the local storage.
    getCompletionStoredEntry() {
        this._readDataObjectFromLocalStorage();
        const result = this._dataObject && this._dataObject[this._completionID] ? this._dataObject[this._completionID] : null;
        // console.log('Result:', this.storedObject);
        return result;
    }

    // Clear the time duration stored in the local storage.
    clearCompletionStoredEntry() {
        if (this._dataObject && this._dataObject[this._completionID]) {
            delete this._dataObject[this._completionID];
            this._saveDataObjectToLocalStorage();
        }
        else {
            // Do nothing, no need to update the local storage as the data is not stored.
        }
    }

    // Saves or overwrites the saved old value in the local storage.
    // CAUTION: If this is called before getCompletionStoredEnty, the data in the local storage will be overwritten.
    createOrUpdateCompletionStoredEntry(timeValue) {
        if (!this._dataObject) this._dataObject = {};
        this._dataObject[this._completionID] = timeValue;
        this._saveDataObjectToLocalStorage();
    }

    // Sets the value of storedDataObject with the object stored in local storage.
    _readDataObjectFromLocalStorage() {

        // Reading data from local storage.
        const storedData = localStorage.getItem(STORAGE_ID);

        // Validating that there are data saved from before.
        if (!storedData) {
            this._dataObject = null;
            return;
        }

        try {
            if (this._useEncryption) {
                const bytes = CryptoJS.AES.decrypt(storedData, process.env.REACT_APP_TRACKING_KEY);
                const jsonString = bytes.toString(CryptoJS.enc.Utf8);
                this._dataObject = JSON.parse(jsonString);
            }
            else {
                this._dataObject = JSON.parse(storedData);
            }
        }
        catch (e) {
            // Either the decryption or parsing has failed.
            console.log('Parsing Failed!');
            this._dataObject = null;
        }
    }

    // Saves the current value of the storedDataObject to the local storage.
    _saveDataObjectToLocalStorage() {
        try {
            const stringData = JSON.stringify(this._dataObject);
            const toStoreData = this._useEncryption ?
                CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(stringData), process.env.REACT_APP_TRACKING_KEY) :
                stringData;
            localStorage.setItem(STORAGE_ID, toStoreData);
        }
        catch (e) {
            // Do nothing, if the data object is not a valid json object or can't be encrypted, then we don't store it.
            console.log('Storing Data Failed!', e)
        }
    }

}

export default CompletionMetaDataHandler;