import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Button, message, Space } from 'antd';
import { SaveOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';

import ErrorModal from '../../../Error/ErrorModal';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';
import RichGuidelinesEditor, { RichGuidelinesDisplay, sanitizeText, isEmptyEditorValue } from '../../../Common/RichGuidelinesEditor';
import '../../../Styles/Button.scss'


const Guidelines = () => {

    const {
        selectedProject,
        errorUpdate,
        updateProjectValue,
        clearUpdateError
    } = useContext(PMPrjSingleContext);

    const [state, setState] = useState({
        editMode: false,
        editorValue: '',
        originalGuidelines: '', // Saved guidelines in the database.
    });

    useEffect(() => {
        if (selectedProject && selectedProject.guidelines) {
            sanitizeText(selectedProject.guidelines)
                .then(cleanGuidelines => {
                    setState(ps => ({
                        ...ps,
                        originalGuidelines: cleanGuidelines,
                    }))
                })
        }
        // eslint-disable-next-line
    }, [selectedProject]);



    const handleOnEditGuidelinesBtnClick = () => {
        setState(ps => ({
            ...ps,
            editMode: true,
            // Resetting the editor value to the latest saved guidelines in the database.
            editorValue: ps.originalGuidelines,
        }))
    };

    const handleOnUpdateClick = async () => {

        const isEmpty = isEmptyEditorValue(state.editorValue);

        if (isEmpty) {
            message.warning("Please enter guidelines.");
            return;
        }

        updateProjectValue('guidelines', state.editorValue)
            .then(() => {
                if (!errorUpdate) {
                    setState(ps => ({ ...ps, editMode: false }));
                    message.success('Guidelines updated successfully.');
                }
            })
            .catch(e => {
                //Do nothing the error is reported in the context.
            });
    }

    const handleOnCancelClick = () => {
        setState(ps => ({ ...ps, editMode: false }));
    }

    const handleOnEditorContentChange = value => {
        setState(ps => ({
            ...ps,
            editorValue: value,
        }))
    }



    return (
        <Fragment>

            <ErrorModal error={errorUpdate} onDone={clearUpdateError} />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>

                    {!state.editMode &&
                        <Button
                            size='small'
                            type='primary'
                            ghost={state.editMode}
                            onClick={handleOnEditGuidelinesBtnClick}>
                            <EditOutlined /> Edit Guidelines
                        </Button>
                    }

                    {state.editMode &&
                        <Fragment>
                            <Button
                                size='small'
                                type='primary'
                                className='btn-success'
                                onClick={handleOnUpdateClick}>
                                {state.editMode && <Fragment><SaveOutlined /> Save</Fragment>}
                                {!state.editMode && <Fragment><EditOutlined /> Edit Guidelines</Fragment>}
                            </Button>

                            <Button
                                size='small'
                                type='danger'
                                ghost
                                onClick={handleOnCancelClick}>
                                <CloseOutlined />Cancel
                            </Button>
                        </Fragment>
                    }

                </Space>
            </div>

            <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>

                {selectedProject && state.editMode &&
                    <RichGuidelinesEditor
                        value={state.editorValue}
                        onChange={handleOnEditorContentChange}
                    />
                }

                {selectedProject && !state.editMode &&
                    <RichGuidelinesDisplay
                        value={state.originalGuidelines}
                    />
                }

            </div>
        </Fragment>
    )
}

export default Guidelines;
