import React from "react";

const TextAreaLabelsContext = React.createContext({});
TextAreaLabelsContext.displayName = "TextAreaLabelsContext";

export const TextAreaLabelsProvider = TextAreaLabelsContext.Provider;

export function useTextAreaLabelsContext() {
  return React.useContext(TextAreaLabelsContext);
}
