import React, { Fragment, useState, useCallback, useMemo } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import useOptionsContext from '../../contexts/OptionsContext';
import { Checkbox, Tag } from 'antd';
import SelectInput from './SelectInput';
import { useMediaQuery } from 'react-responsive';


const OtherLanguagesInput = ({ user, editMode, onChangeEditMode }) => {

    const [selectedLangs, setSelectedLangs] = useState([]);

    const [isLangsEditable, setIsLangsEditable] = useState(false);

    const [isOnlyNativeLang, setIsOnlyNativeLang] = useState(false);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    const {
        languages,
        languagesError,
    } = useOptionsContext();

    const hasLangs = Boolean(user?.otherLanguages?.length);

    const filteredLanguages = useMemo(() => {
        const nativeLanguage = user?.nativeLanguage || {};

        return languages.filter(lg => lg.id !== nativeLanguage.id)
            .map(lg => ({ value: lg.id, key: lg.name, label: lg.name }))
    }, [languages, user?.nativeLanguage]);

    const handleEditableStatus = useCallback((status) => {
        setIsLangsEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const handleOnChange = useCallback((langsIds) => {
        setSelectedLangs(langsIds);
    }, []);

    const handleCheckboxStatus = useCallback((e) => {
        setIsOnlyNativeLang(e.target.checked);
        if (e.target.checked) {
            setSelectedLangs([]);
        }
    }, []);

    const renderLangs = useCallback((langs) => {
        if (hasLangs) {
            return langs.map(lg => <Tag key={lg.name} color="#081f2d">{lg.name}</Tag>)
        } else {
            return "Only Native Language";
        }
    }, [hasLangs]);

    return (
        <ProfileEntry
            hasBorder
            label="Other Languages:"
            value={!isLangsEditable ? renderLangs(user.otherLanguages) :
                <Fragment>
                    <SelectInput
                        onChange={handleOnChange}
                        value={selectedLangs}
                        options={filteredLanguages}
                        optionsErr={languagesError}
                        placeholder="Set your other languages here..."
                        selectMode="multiple"
                        disabled={isOnlyNativeLang}
                        tooltipMsg="Please add the language(s) that you can write and speak fluently"
                        tooltipPlacement={ isTabletOrMobile ? 'top' : 'leftBottom' }
                    />
                    <Checkbox
                        onChange={handleCheckboxStatus}
                        id="checkbox"
                        checked={isOnlyNativeLang}
                    >
                        Only Native Language
                    </Checkbox>
                </Fragment>
            }
            controls={
                <InputControls
                    user={user}
                    inputName="otherLanguages"
                    inputValue={selectedLangs}
                    inputEditable={isLangsEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={(!isOnlyNativeLang && !selectedLangs.length) ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default OtherLanguagesInput;