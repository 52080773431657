import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import { Input, Tooltip } from 'antd';
import { capitalizeFirstChar } from '../Common/Utils';


const LastNameTextInput = ({ onChange, value, error }) => {
    return (
        <Tooltip placement="topLeft" title={error} color="rgba(255,77,79,0.8)">
            <Input
                placeholder="Last Name"
                allowClear
                onChange={onChange}
                value={value}
                status={Boolean(error) ? 'error' : undefined}
            />
        </Tooltip>
    );
};

const LastNameInput = ({ user, editMode, onChangeEditMode }) => {
    const [lastName, setLastName] = useState("");

    const [isLastNameEditable, setIsLastNameEditable] = useState(false);

    const [lastNameValidationErr, setLastNameValidationErr] = useState("");

    const handleLastNameChange = useCallback((e) => {
        setLastName(e.target.value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsLastNameEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const handleValidationErr = useCallback((errMsg) => {
        setLastNameValidationErr(errMsg);
    }, []);

    const hasLastName = Boolean(user.lastName);
    const isEditable = isLastNameEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Last Name:"
            value={
                (hasLastName && !isEditable) ?
                    capitalizeFirstChar(user.lastName) :
                    (isEditable) ?
                        <LastNameTextInput
                            onChange={handleLastNameChange}
                            value={lastName}
                            error={lastNameValidationErr}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="lastName"
                    inputValue={lastName}
                    inputEditable={isLastNameEditable}
                    changeEditableStatus={handleEditableStatus}
                    changeValidationErrMsg={handleValidationErr}
                    disableSave={!lastName ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default LastNameInput;