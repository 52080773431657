import "./NotificationPageCard.scss"

const CardHeader = ({ color, text }) => {
    return (
        <div className="notification-card-header" style={{ backgroundColor: color }} >
            {text}
        </div>
    )
}

export default CardHeader