import { lxtBackend } from "./utils";

export default {
  getUser: async () => lxtBackend.get("users/logged"),

  getUserByID: async (id) => lxtBackend.get(`users/${id}`),

  getPMUsers: async (criteria) => {
    let url = `/users`;

    let params = [];
    if (typeof criteria.search === 'string' && criteria.search.trim()) params.push(`search=${criteria.search.trim()}`);
    if (criteria.role) params.push(`role=${criteria.role}`);
    if (typeof criteria.isActive === 'boolean') params.push(`active=${criteria.isActive}`)
    if (typeof criteria.skip === 'number') params.push(`skip=${criteria.skip}`);
    if (typeof criteria.limit === 'number') params.push(`limit=${criteria.limit}`);
    if (Array.isArray(criteria.skills)) criteria.skills.forEach(sk => params.push(`skill=${sk}`));
    if (Array.isArray(criteria.otherLanguages)) criteria.otherLanguages.forEach(ln => params.push(`otherLanguage=${ln}`));
    if (Array.isArray(criteria.nativeLanguage)) criteria.nativeLanguage.forEach(ln => params.push(`nativeLanguage=${ln}`));

    // Adding ordering parameters.
    if (typeof criteria.orderColumn === 'string') {
      params.push(`order=${criteria.orderColumn}`);
      params.push(`asc=${criteria.orderAscending}`);
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  sendInvitationEmails: async (emails) => lxtBackend.post('users/invitations', { emails }),

  registerInvitedUser: async (token, userData) => lxtBackend.post(`users/register`, userData, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }),

  activateContributors: async (memberIDs) => lxtBackend.post('users/activate', { ids: memberIDs }),

  deactivateContributors: async (memberIDs) => lxtBackend.post('users/deactivate', { ids: memberIDs }),

  getUserProfile: async () => lxtBackend.get('users/my-profile'),

  updateUserProfile: async (data) => lxtBackend.patch('users/my-profile', data),

  verifyUserEmail: async (token) => lxtBackend.post(`users/verify-email`, token),
}