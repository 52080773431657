import React, { useCallback } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { useTextAreaLabelsContext } from "./TextAreaLabelsContext";
import uniqolor from 'uniqolor';

const MarkHighlight = styled.div`
  &::before {
    width: 3px;
    z-index: 1;
    content: "|";
    margin: 1.1px;
    color: transparent;
    position: absolute;
    border-radius: 1.2px 0 0 1.2px;
    background: ${({ highlight }) => highlight};
  }
`;

/**
 * @example
 * 	<View>
 * 		<TextAreaWithLabels name="" text="7mada2">
 * 			<TextAreaLabel alias="Foo" value="[foo]" background="darkred" place="before"/>
 * 			<TextAreaLabel alias="Nois" value="[nois]" background="#3B3923" place="insert"/>
 * 		</TextAreaLabels>
 * 	</View>
 * @name  TextAreaLabelsShortCuts
 * @param {string} alias - element identifier and label button text
 * @param {string} value - label to mark the text
 * @param {string} background - color to highlight marked text
 * @param {"before"|"after"|"wrap"|"insert"} place - place to insert label mark
 */

/**
 *
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
export const TextAreaLabelShortCuts = props => {
  const readyRef = React.useRef();
  const { alias, value, background, place } = props;
  const { onLabelReady, onLabelToggle } = useTextAreaLabelsContext();

  const [valueOpen, valueClose] = value.split(":");

  const [label, setLabel] = React.useState(value);
  const [labelOpen, setLabelOpen] = React.useState(valueOpen);
  const [labelClose, setLabelClose] = React.useState(valueClose);

  const color = React.useMemo(() => {
    return background ?? uniqolor(alias)?.color;
  }, [background, alias])

  React.useEffect(() => {
    const [valOpen, valClose] = value.split(":");
    setLabel(value);
    setLabelOpen(valOpen);
    setLabelClose(valClose);
  }, [value]);

  const payload = React.useMemo(
    () => ({
      color,
      labelClose,
      labelOpen,
      label,
      alias,
      place
    }),
    [label, labelOpen, labelClose, alias, color, place]
  );

  React.useEffect(() => {
    if (!readyRef.current) {
      onLabelReady?.(payload);
    }

    return () => {
      readyRef.current = null;
    };
  }, [onLabelReady, payload]);

  const handleOnClick = useCallback(() => {
    onLabelToggle?.(alias);
  }, [onLabelToggle, alias]);

  return (
    <MarkHighlight highlight={color}>
      <Button onClick={handleOnClick} size="small">
        {alias}
      </Button>
    </MarkHighlight>
  );
};
