import React from "react";
import { Form as AntdForm } from "antd";
import "./form.scss";

const Form = ({ children, border, ...props }) => {
  const classNames = ["form"];
  if (border) {
    classNames.push("form--border");
  }

  const validateMessages = {
    required: "${label} is required!", //eslint-disable-line
    types: {
      email: "${label} is not valid!", //eslint-disable-line
    },
  };

  return (
    <AntdForm className={classNames.join(" ")} validateMessages={validateMessages} {...props}>
      {children}
    </AntdForm>
  );
};

export default Form;
