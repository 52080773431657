import React from "react";
import { Layout } from "antd";
import "./layout.scss";

const LayoutComponent = ({ children, ...props }) => {
  return (
    <Layout className="layout" {...props}>
      <div className="water-mark">{process.env.REACT_APP_ENV_NAME}</div>
      {children}
    </Layout>
  );
};

export default LayoutComponent;
