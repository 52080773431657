import React, { createContext, useState, useContext } from 'react';
import omit from 'lodash.omit';
import isEqual from 'lodash.isequal';
import projectsApi from '../api/projects';
import usersApi from '../api/users';
import { formatError } from './ResponseErrorFormatter';
import metaDataHandler from './helpers/ProjectMetaDataHandler';

const DEFAULT_CONTRIBUTORS_CRITERIA = {
    search: '',
    otherLanguages: [],
    nativeLanguage: null,
    skills: [],
    assignedDP: null,
    assignedRV: null,
    assignedAH: null,
    isActive: null,
    signedLatestServiceAgreement: null,
    showOnlyAssigned: true,

    skip: 0,
    limit: 10,
    orderColumn: null,
    orderAscending: true,
}

const CONTRIBUTORS_ASSIGNMENT_TYPE = {
    DataProcessor: 0,
    Reviewer: 1,
    AdHoc: 2,
}

const PMPrjSingleContext = createContext(null);

const PMPrjSingleContextProvider = ({ children }) => {

    const [state, setState] = useState({
        selectedProject: null,
        selectedProjectOwner: null,
        createdProjectID: null,

        isLoading: false,
        isUploadingCsv: false,
        isUpdatingMetaData: false,


        contributors: [],
        contributorsCount: 0,
        contributorsLoading: false,
        contributorsCriteria: DEFAULT_CONTRIBUTORS_CRITERIA,
        contributorsFetchError: null,
        contributorsAssignmentError: null,

        isLoadingFileUploadTaskStatuses: false,
        fileUploadTaskStatuses: [],
        errorLoadingFileUploadTaskStatuses: null,

        error: null,
        errorUpdate: null,
        errorTasksUpload: null,
        errorUpdateMetaData: null,
    });

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Project Creation

    // Creating a project with the submitted data.
    const createProject = (projectData) => {

        setState(ps => ({
            ...ps,
            createdProjectID: null,
            isLoading: true,
            error: null
        }));

        return new Promise((resolve, reject) => {
            projectsApi.createProject(projectData)
                .then(res => {
                    setState(ps => ({
                        ...ps,
                        createdProjectID: res.data,
                        error: null,
                        isLoading: false
                    }));
                    resolve(res.data);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        createdProjectID: null,
                        error: formatError(e, 'Failed To Create Project!'),
                        isLoading: false
                    }));

                    reject();
                });
        });
    }

    // Creating a new consent agreement.
    const addConsentAgreement = (consentData) => {

        setState(ps => ({
            ...ps,
            error: null
        }));

        return new Promise((resolve, reject) => {
            projectsApi.addConsentAgreement(state.selectedProject.id, consentData)
                .then((res) => {
                    setState(ps => ({
                        ...ps,
                        selectedProject: {
                            ...ps.selectedProject,
                            agreement: consentData,
                        },
                        error: null
                    }));
                    resolve();
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        errorUpdate: formatError(e, 'Failed To Update Consent!')
                    }));
                    reject();
                });
        });
    }


    // Sets the select project for display and update.
    const setSelectedProject = async (projectID) => {

        setState(ps => ({
            ...ps,
            isLoading: true,
            error: null
        }));

        await projectsApi.getPMProject(projectID)
            .then(res => {
                if (res.data && res.data.ownerId)
                    return Promise.all([res, usersApi.getUserByID(res.data.ownerId)]);
                else
                    return Promise.all([res]);
            })
            .then(projectData => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    selectedProject: projectData[0].data,
                    selectedProjectOwner: projectData.length > 1 ? projectData[1].data : null,
                    fileUploadTaskStatuses: [], // clearing upload file statuses that may be loaded from a previous project.
                    error: null,
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    selectedProject: null,
                    selectedProjectOwner: null,
                    error: formatError(e, 'Failed To Retrieve Project Data!'),
                }));
            });
    }

    const clearError = () => setState(ps => ({ ...ps, error: null }));

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Uploading Tasks File

    const uploadCsv = (projectID, fileFormData, fileName) => {

        setState(ps => ({
            ...ps,
            isUploadingCsv: true,
            errorTasksUpload: null
        }));

        return new Promise((resolve, reject) => {
            projectsApi.uploadCsvFile(projectID, fileFormData)
                // The following was commented since it is going to be delayed to a different phase.
                // .then(res => {
                //     if (res && res.data !== undefined)
                //         // Saving the upload process id to the local storage.
                //         return metaDataHandler.saveProjectUploadProcessID(projectID, res.data, fileName);
                //     else {
                //         console.log('Process ID is not found!');
                //         return Promise.resolve();
                //     }
                // })
                .then(() => {
                    setState(ps => ({
                        ...ps,
                        isUploadingCsv: false,
                        errorTasksUpload: null
                    }));
                    resolve(projectID);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isUploadingCsv: false,
                        errorTasksUpload: formatError(e, 'Failed To Upload Tasks File!')
                    }));
                    reject();
                });
        });
    }

    const clearTasksUploadError = () => setState(ps => ({ ...ps, errorTasksUpload: null }));

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Update Meta-Data

    const getUploadTasksFileProcessStatus = () => {

        setState(ps => ({
            ...ps,
            isLoadingFileUploadTaskStatuses: true,
        }));

        // Reading all the ids stored in the local storage.
        metaDataHandler.readProjectUploadProcessIDs(state.selectedProject.id)

            // Retrieving the statuses of all the processes.
            .then(uploadedFilesInfo => Promise.all(uploadedFilesInfo.map(info => getUploadProcessStatus(info))))

            // Setting the results in the state object.
            .then(uploadedFilesInfoWithStatuses => setState(ps => ({
                ...ps,
                isLoadingFileUploadTaskStatuses: false,
                fileUploadTaskStatuses: uploadedFilesInfoWithStatuses,
                errorLoadingFileUploadTaskStatuses: null,
            })))

            // Handling errors.
            .catch(e => setState(ps => ({
                ...ps,
                isLoadingFileUploadTaskStatuses: false,
                errorLoadingFileUploadTaskStatuses: formatError(e, 'Failed to retrieve upload processes status!'), // Todo adding here formatting the error.
            })));
    }

    // Creates a prmise to fetch the status of the fileInfo.
    const getUploadProcessStatus = uploadedFileInfo => new Promise((resolve, reject) => {
        projectsApi.getUploadProcessStatus(uploadedFileInfo.processID)
            .then(res => resolve({ ...uploadedFileInfo, ...res.data }))
            .catch(error => reject(error))
    })

    //////////////////// Update Meta-Data //////////////////////////

    const updateAudioLength = (projectID) => {

        setState(ps => ({
            ...ps,
            isUpdatingMetaData: true,
            errorUpdateMetaData: null
        }));

        return new Promise((resolve, reject) => {
            projectsApi.updateMetaData(projectID)
                .then(() => {
                    setState(ps => ({
                        ...ps,
                        isUpdatingMetaData: false,
                        errorUpdateMetaData: null
                    }));
                    resolve(projectID);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isUpdatingMetaData: false,
                        errorUpdateMetaData: formatError(e, 'Failed To Update completion meta-data!')
                    }));
                    reject();
                });
        });
    }

    const clearUpdateMetaDataError = () => setState(ps => ({ ...ps, errorUpdateMetaData: null }));

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Project Display And Update

    // Updates an entry of the current selected project.
    const updateProjectValue = (key, value) => {

        const updatedField = {};
        updatedField[key] = value;

        setState(ps => ({
            ...ps,
            isLoading: true,
            errorUpdate: null
        }));

        return new Promise((resolve, reject) => {
            projectsApi.updatePMProject(state.selectedProject.id, updatedField)
                .then(res => {
                    // Checking if selected project owner object needs an update or not.
                    if (key === 'ownerId')
                        return Promise.all([res, usersApi.getUserByID(value)])
                    else
                        return Promise.all([res]);
                })
                .then(res => {
                    // Updating the state upon successfull update.
                    setState(ps => ({
                        ...ps,
                        selectedProject: res[0].data,
                        selectedProjectOwner: res.length > 1 ? res[1].data : ps.selectedProjectOwner,
                        errorUpdate: null,
                        isLoading: false
                    }));
                    resolve();
                })
                .catch(e => {
                    if (e.response && e.response.status === 409) {
                        // Handling the case when allowCriticalFieldsEdit became false in the backend.
                        setState(ps => ({
                            ...ps,
                            selectedProject: { ...ps.selectedProject, allowCriticalFieldsEdit: false },
                            errorUpdate: formatError(e, 'Failed To Update Project!'),
                            isLoading: false
                        }));
                    }
                    else {
                        setState(ps => ({
                            ...ps,
                            errorUpdate: formatError(e, 'Failed To Update Project!'),
                            isLoading: false
                        }));
                    }
                    reject();
                })
        })
    }

    const clearUpdateError = () => setState(ps => ({ ...ps, errorUpdate: null }));

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Contributors

    const fetchContributors = criteria => {

        if (criteria) {
            criteria.search = typeof criteria.search === 'string' ? criteria.search : DEFAULT_CONTRIBUTORS_CRITERIA.search;
            criteria.isActive = typeof criteria.isActive === 'boolean' ? criteria.isActive : DEFAULT_CONTRIBUTORS_CRITERIA.isActive;
            criteria.signedLatestServiceAgreement = typeof criteria.signedLatestServiceAgreement === 'boolean' ? criteria.signedLatestServiceAgreement : DEFAULT_CONTRIBUTORS_CRITERIA.signedLatestServiceAgreement;
            criteria.assignedDP = typeof criteria.assignedDP === 'boolean' ? criteria.assignedDP : DEFAULT_CONTRIBUTORS_CRITERIA.assignedDP;
            criteria.assignedRV = typeof criteria.assignedRV === 'boolean' ? criteria.assignedRV : DEFAULT_CONTRIBUTORS_CRITERIA.assignedRV;
            criteria.assignedAH = typeof criteria.assignedAH === 'boolean' ? criteria.assignedAH : DEFAULT_CONTRIBUTORS_CRITERIA.assignedAH;
            // We need to retain the last value of assigned only and not reset it.
            criteria.showOnlyAssigned = typeof criteria.showOnlyAssigned === 'boolean' ? criteria.showOnlyAssigned : state.contributorsCriteria.showOnlyAssigned;
            criteria.otherLanguages = Array.isArray(criteria.otherLanguages) ? criteria.otherLanguages : DEFAULT_CONTRIBUTORS_CRITERIA.otherLanguages;
            criteria.nativeLanguage = criteria.nativeLanguage ?? DEFAULT_CONTRIBUTORS_CRITERIA.nativeLanguage;
            criteria.skills = Array.isArray(criteria.skills) ? criteria.skills : DEFAULT_CONTRIBUTORS_CRITERIA.skills;

            criteria.skip = typeof criteria.skip === 'number' ? criteria.skip : DEFAULT_CONTRIBUTORS_CRITERIA.skip;
            criteria.limit = typeof criteria.limit === 'number' ? criteria.limit : DEFAULT_CONTRIBUTORS_CRITERIA.limit;
            criteria.orderColumn = typeof criteria.orderColumn === 'string' ? criteria.orderColumn : DEFAULT_CONTRIBUTORS_CRITERIA.orderColumn;
            criteria.orderAscending = typeof criteria.orderAscending === 'boolean' ? criteria.orderAscending : DEFAULT_CONTRIBUTORS_CRITERIA.orderAscending;
        }
        else {
            criteria = {
                ...DEFAULT_CONTRIBUTORS_CRITERIA,
                // We need to retain the last value and not reset it.
                showOnlyAssigned: state.contributorsCriteria.showOnlyAssigned,
            };
        }

        setState(ps => ({
            ...ps,
            contributorsLoading: true,
            contributorsCriteria: criteria,
        }))

        projectsApi.getProjectContributorsNew(state.selectedProject.id, criteria)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    contributorsLoading: false,
                    contributors: res.data.contributors,
                    contributorsCount: res.data.count,
                    contributorsFetchError: null,
                }))
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    contributorsLoading: false,
                    contributorsFetchError: formatError(e, 'Failed To Retrieve Contributors!')
                }))
            });
    }

    const setContributorsAssignedState = (contributorsData, assigned, assignmentType, prjID = null, assignWithEmails = false) => {

        // Validating the project ID. Project ID is offered to be submitted as argument to cover the case of assignment from the users table view.
        const projectID = prjID ? prjID : state.selectedProject ? state.selectedProject.id : undefined;
        if (!projectID) {
            setState(ps => ({ ...ps, contributorsAssignmentError: formatError({}, 'Project ID Is Not Found!') }));
            return Promise.resolve();
        }

        // Validating the assignment type.
        if (!Object.values(CONTRIBUTORS_ASSIGNMENT_TYPE).includes(assignmentType) || typeof assigned !== 'boolean') {
            setState(ps => ({ ...ps, contributorsAssignmentError: formatError({}, 'Invalid Assginment Request!') }));
            return Promise.resolve();
        }

        const dataBody = {
            type: assignmentType === CONTRIBUTORS_ASSIGNMENT_TYPE.DataProcessor ? "dp" : assignmentType === CONTRIBUTORS_ASSIGNMENT_TYPE.Reviewer ? "rv" : "ah",
            [assignWithEmails ? "emails" : "ids"]: contributorsData,
        }

        let assignmentPromise = assigned ?
            projectsApi.assignProjectContributors(projectID, dataBody) :
            projectsApi.unAssignProjectContributors(projectID, dataBody);

        setState(ps => ({
            ...ps,
            contributorsLoading: true,
        }));

        return new Promise((resolve, reject) => {
            assignmentPromise
                .then(res => {

                    // Resetting loading flag.
                    setState(ps => ({
                        ...ps,
                        contributorsLoading: false
                    }));

                    // Handling assignment errors if the request was to assign users which are already assigned.
                    // TODO asking Product Owner if this is really required.
                    if (assigned) {
                        let failedAssignments = null;
                        if (res.data &&
                            Array.isArray(res.data.failedAssignments) &&
                            res.data.failedAssignments.length > 0) {
                            failedAssignments = res.data.failedAssignments
                        }
                        resolve(failedAssignments);
                    }
                    else {
                        resolve()
                    }
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        contributorsLoading: false,
                        contributorsAssignmentError: formatError(e, `Failed To ${assigned ? 'Assign' : 'Unassign'} Selected Users For Project ${projectID} !`)
                    }))
                    reject();
                })

        })

    }

    const contributorsCriteriaIsApplied = () => {
        const filtersApplied = !isEqual(omit(state.contributorsCriteria, ['showOnlyAssigned']), omit(DEFAULT_CONTRIBUTORS_CRITERIA, ['showOnlyAssigned']));
        return filtersApplied;
    }

    const clearContributorFetchError = () => setState(ps => ({ ...ps, contributorsFetchError: null }));

    const clearContributorAssignmentError = () => setState(ps => ({ ...ps, contributorsAssignmentError: null }));

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// General

    const clearAllErrors = () => setState(ps => ({
        ...ps,
        error: null,
        errorUpdate: null,
        errorTasksUpload: null,
        errorUpdateMetaData: null,
        contributorsFetchError: null,
        contributorsAssignmentError: null,
    }))

    const services = {
        ...state,
        CONTRIBUTORS_ASSIGNMENT_TYPE,

        createProject,
        setSelectedProject,
        clearError,
        uploadCsv,
        getUploadTasksFileProcessStatus,
        addConsentAgreement,
        clearTasksUploadError,

        updateProjectValue,
        clearUpdateError,

        fetchContributors,
        setContributorsAssignedState,
        contributorsCriteriaIsApplied,
        clearContributorFetchError,
        clearContributorAssignmentError,

        updateAudioLength,
        clearUpdateMetaDataError,

        clearAllErrors,
    }

    return (
        <PMPrjSingleContext.Provider value={services}>
            {children}
        </PMPrjSingleContext.Provider>
    );
}

const usePMProjectSingleContext = () => useContext(PMPrjSingleContext);

export { PMPrjSingleContext as default, PMPrjSingleContextProvider, usePMProjectSingleContext, CONTRIBUTORS_ASSIGNMENT_TYPE };
