import React, { useState, useCallback } from 'react';

const VideoViewer = ({ file, url, togglePlay }) => {
	const [videoURL, setVideoURL] = useState('');

	const getFileURL = useCallback((file) => {
		const url = new Promise((resolve) => {
			const actualFile = file instanceof Blob ? file : file.originFileObj;
			if (actualFile) {
				const reader = new FileReader();
				reader.readAsDataURL(file?.originFileObj);
				reader.onload = () => resolve(reader.result);
			} else {
				console.error('Invalid file format. Expected a Blob or File.');
			}
		});

		url.then((result) => setVideoURL(result));

	}, []);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<video
			src={url || videoURL}
			controls
			onPlay={togglePlay}
			onPause={togglePlay}
			style={{ width: '100%' }}
			controlsList="nofullscreen nodownload"
		/>
	);
};

export default VideoViewer;
