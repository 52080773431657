import React from 'react';
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useDispatch } from 'react-redux';
import { Progress, Card, Button, Tooltip } from 'antd';
import { MdOutlineFileUpload } from 'react-icons/md';
import { AiFillAudio } from 'react-icons/ai';

import TextHiglighter from '../TextHiglighter';
import { submitRecorderCompletionData } from '../../../store/audioSlice'
import { NOT_AVAIALBLE } from '../LookUpTables';
import { UPLOAD_PROCESS_STATUS_CODES } from '../../../Constants';
import { COMPLETION_DP_PATH_ID } from '../../../routes';


const GenericAudioUploadCard = ({ completionID, uploadPercent }) => {
    const compID = <TextHiglighter text={completionID ? completionID : NOT_AVAIALBLE} />
    return (
        <div>
            Your data for completion {compID} is in <TextHiglighter text={'submission process'} />
            <Progress
                percent={uploadPercent}
                status="active"
            />
        </div>
    )
}

const FailedAudioUploadCard = ({ projectID, completionID, uploadPercent, error }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const handleRetryUpload = (e) => {
        e.preventDefault()
        submitRecorderCompletionData(completionID, dispatch)
    }

    const handleReprocessCompletion = (e) => {
        e.preventDefault();
        history.push(formatRoute(COMPLETION_DP_PATH_ID, { projectId: projectID, compId: completionID }))
    }

    const compID = <TextHiglighter text={completionID ? completionID : NOT_AVAIALBLE} />

    return (
        <Tooltip title={error.title}>
            <div>
                Your data for completion {compID} <TextHiglighter text={'failed to submit'} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Progress
                        percent={uploadPercent}
                        status="exception"
                        showInfo={false}
                    />
                    <Tooltip title="Retry Upload">
                        <Button
                            type='link'
                            onClick={handleRetryUpload}
                            icon={<MdOutlineFileUpload style={{ fontSize: '140%' }} />}
                        />
                    </Tooltip>

                    <Tooltip title="Retry Record">
                        <Button
                            type='link'
                            onClick={handleReprocessCompletion}
                            icon={<AiFillAudio style={{ fontSize: '110%', color: "red" }} />}
                        />
                    </Tooltip>
                </div>
            </div>
        </Tooltip>
    )
}

const AudioFileNotification = ({ completionID, completionEntry }) => {

    const projectID = completionEntry.projectID ? completionEntry.projectID : null;
    const uploadPercent = completionEntry.progress ? completionEntry.progress : null;

    return (
        <Card
            size='small'
            bodyStyle={{ maxWidth: '250px', overflow: 'auto', whiteSpace: 'pre-line', fontSize: '0.8rem' }}>

            {completionEntry.status !== UPLOAD_PROCESS_STATUS_CODES.Failure &&
                <GenericAudioUploadCard
                    completionID={completionID}
                    uploadPercent={uploadPercent}
                />
            }

            {completionEntry.status === UPLOAD_PROCESS_STATUS_CODES.Failure &&
                <FailedAudioUploadCard
                    projectID={projectID}
                    completionID={completionID}
                    uploadPercent={uploadPercent}
                    error={completionEntry.error}
                />
            }
        </Card>
    )
}

export default AudioFileNotification;