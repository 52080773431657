import { capitalizeFirstChar } from '../components/Common/Utils'
const PASSWORD_DOES_NOT_MEET_REQUIREMENTS = "Missing password requirements";

export const formatError = (e, messageHeader) => {

    let details = null;
    let status = 0;

    // Handling the firebase messages using the firebase error code translator.
    if (e?.code && process.env.NODE_ENV === 'production') {
        let messageBody = ''

        if (e?.message?.error?.message)
            messageBody = e.message.error.message;
        else
            messageBody = e.message

        if (messageBody.includes(PASSWORD_DOES_NOT_MEET_REQUIREMENTS))
            messageBody = 'Please reset password to meet the new password policy requirements'

        messageBody = capitalizeFirstChar(messageBody.replace(/_/g, " ").toLowerCase());
        details = messageBody ? [messageBody] : ['Unknown error ocurred.', e.code];
    }

    // Handling backend API errors.
    else {
        if (e?.response &&
            e?.response?.status === 422 &&
            e?.response?.data &&
            e?.response?.data?.detail &&
            Array.isArray(e.response.data.detail)) {

            const tempDetails = e.response.data.detail.map(d => `${d.loc[1]}: ${d.msg}.`);
            details = e.message ? [e.message, ...tempDetails] : tempDetails;
            status = 422;
        }
        else if (e?.response && e?.response?.status === 401) {
            details = [];
            status = 401;
        }
        else if (e?.response &&
            e?.response.status &&
            e?.response?.data &&
            e?.response?.data?.detail &&
            typeof e?.response?.data?.detail === 'string') {

            const tempDetail = e.response.data.code ? `[CODE:${e.response.data.code}] ${e.response.data.detail}` : e.response.data.detail;
            details = [tempDetail];
            status = e.response.status;
        }

        else if (e?.code && e?.message) {
            //Handling firebase auth api messages according to the structure mentioned in the following link:
            //https://firebase.google.com/docs/reference/js/firebase.auth.Error
            details = [`[CODE: ${e.code}] ${e.message}`];
        }
        else if (e?.response &&
            e?.response?.status === 400 &&
            e?.response?.data &&
            e?.response?.data?.detail &&
            e?.response?.data?.code === 1001) {

            let errorDetails = []
            if (Array.isArray(e.response.data.detail)) {
                e.response.data.detail.forEach(errorEntry => {
                    if (typeof errorEntry === "string") {
                        errorDetails.push(<li>{errorEntry}</li>)
                    } else {
                        const tagName = errorEntry[0]
                        const validationErrors = errorEntry[1];

                        errorDetails.push(<li>{tagName}</li>)

                        for (const [key, errorValue] of Object.entries(validationErrors)) {
                            if (typeof errorValue === 'string') {
                                errorDetails.push(<ul><li>{`${key}: ${errorValue}`}</li></ul>);
                            }
                            else {
                                if (errorValue.length === 1) {
                                    errorDetails.push(<ul><li>{`${key}: ${errorValue}`}</li></ul>);
                                } else {
                                    errorDetails.push(
                                        <ul>
                                            <li>{key}:</li>
                                            {errorValue.map((val) =>
                                                <ul>
                                                    <li>
                                                        {`${val}`}
                                                    </li>
                                                </ul>
                                            )}
                                        </ul>
                                    )
                                }
                            }
                        }
                    }

                });
            }

            details = e.message ? [e.message, errorDetails] : errorDetails;

            status = 400;
        }
        else if (e?.message) {
            details = [e.message];
        }
        else {
            details = [];
        }
    }


    return { title: messageHeader, details, status };
}