import { useState } from "react";
import { Form } from "antd";
import GeoapifySelect from "../../Common/GeoapifySelect";

const { Item } = Form;

const HomeTown = () => {

    const [homeTownState, SetHomeTownState] = useState(null)

    return (
        <Item
            label="Home Town"
            name="originLocation"
            rules={[
                {
                    required: homeTownState ?? true,
                    message: "Please choose your home town!",
                },
                () => ({
                    validator(_, value) {
                        SetHomeTownState(value)
                        return Promise.resolve();
                    },
                })
            ]}
        >
            <GeoapifySelect />
        </Item>
    )
}

export default HomeTown;