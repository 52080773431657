import { Card } from 'antd';
import { formatRoute } from "react-router-named-routes";

import TextHilighter from '../../TextHiglighter';
import { TYPE_DATA_PROCESSOR, TYPE_REVIEWER, NOT_AVAIALBLE, ROLES_TRANSLATOR, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import { COMPLETION_DP_PATH_ID, COMPLETION_RV_PATH_ID } from '../../../../routes';
import '../../../Styles/Button.scss';

import ClearNotification from './ClearNotification';
import RestoreNotification from './RestoreNotification';
import CardHeader from './CardHeader';
import ManuallyAssignedTasks from './ManuallyAssignedTasks';
import NotificationDate from '../../NotificationDate';

const TaskAssignmentCard = ({ notification }) => {

    const content = notification.content;
    const projectName = <TextHilighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />;
    const projectID = <TextHilighter text={content.project_id ? content.project_id : NOT_AVAIALBLE} />;
    const role = <TextHilighter text={content.role ? ROLES_TRANSLATOR[content.role] : NOT_AVAIALBLE} />;
    const compIDs = Array.isArray(content.completion_ids) ? content.completion_ids : [];
    const cardActions = notification.cleared ?
        [<RestoreNotification notificationID={notification.id} />] :
        [<ClearNotification notificationID={notification.id} />];

    const goToCompletion = compID => {
        if (content.role === TYPE_DATA_PROCESSOR)
            window.open(formatRoute(COMPLETION_DP_PATH_ID, { projectId: content.project_id, compId: compID }))
        else if (content.role === TYPE_REVIEWER)
            window.open(formatRoute(COMPLETION_RV_PATH_ID, { projectId: content.project_id, compId: compID }))
    }

    return (
        <Card className="notification-card" actions={cardActions} >

            <CardHeader text={'New Task Assignment'} color={NOTIFICATIONS_CARDS_COLORS.TaskAssignment} />

            <p>You have been assigned as {role} to the the follwoing completion(s) from the project {projectName} with ID {projectID}:</p>
            <ManuallyAssignedTasks
                completionIDs={compIDs}
                onGoToCompletion={goToCompletion}
            />
            <NotificationDate date={notification?.createdOn} />
        </Card>
    )
}

export default TaskAssignmentCard;