import ProjectField from "../helper/ProjectField";
import ProjectFieldOwners from "../helper/ProjectFieldOwners";
import ProjectFieldSelect from "../helper/ProjectFieldSelect";

import useOptionsContext from '../../../../../contexts/OptionsContext';


const securityLevel = ['Data Processing', 'Secure Data Processing'];

const ProjectDetails = () => {
    const { writingScripts, locals, clients, projectServices } = useOptionsContext();
    return (
        <>
            <ProjectField
                labelText='Name:'
                fieldName='name'
                editable />

            <ProjectFieldOwners />

            <ProjectFieldSelect
                labelText='Client:'
                fieldName='client'
                editable
                options={clients.map(cl => ({ value: cl, label: cl }))} />

            <ProjectFieldSelect
                labelText='Service:'
                fieldName='service'
                editable
                options={projectServices.map(srv => ({ value: srv, label: srv }))} />

            <ProjectFieldSelect
                labelText='Security Level:'
                fieldName='securityLevel'
                editable
                options={securityLevel.map(sl => ({ value: sl, label: sl }))} />

            <ProjectFieldSelect
                labelText='Locale:'
                fieldName='locale'
                editable
                options={Object.keys(locals).map(loc => ({ value: loc, label: locals[loc] }))} />

            <ProjectFieldSelect
                labelText='Writing Script:'
                fieldName='writingScript'
                editable
                options={writingScripts.map(ws => ({ value: ws, label: ws }))} />

            <ProjectField
                labelText='Storage Location:'
                fieldName='storageConfiguration'
            />
        </>
    )
}

export default ProjectDetails;