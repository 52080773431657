import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, Space } from 'antd';
import { UserOutlined, ProjectOutlined, UnorderedListOutlined, SyncOutlined } from "@ant-design/icons";

import { CONTRIBUTOR_PROJECTS_PATH, CONTRIBUTOR_TASKS_PATH, LOGIN_PATH, USER_PROFILE } from "../../../routes";


const ContributorMenu = ({ isRefreshingCookies, logout, user }) =>
{
    const isAgency = useFeatureIsOn("contributor_agency");

    const location = useLocation();
    const selectedTab = location.pathname.startsWith(CONTRIBUTOR_TASKS_PATH) ? ['tasks'] :
        location.pathname.startsWith(CONTRIBUTOR_PROJECTS_PATH) ? ['projects'] :
            location.pathname.startsWith(USER_PROFILE) ? ['profile'] : [];

    const history = useHistory();

    const handleOnMenuItemClicked = e =>
    {
        if (e.key === 'logout')
        {
            logout();
        }
        else if (e.key === 'profile details')
            history.push(USER_PROFILE);
    };

    return (
        <Space>
            <Menu
                mode="horizontal"
                theme="light"
                className="nav-links-menu ct-menu"
                selectedKeys={selectedTab}
                onClick={handleOnMenuItemClicked}>

                {/* The link to the projects list for the contributors. */}
                <Menu.Item key="projects" icon={<ProjectOutlined />}>
                    <Link to={CONTRIBUTOR_PROJECTS_PATH}>Projects</Link>
                </Menu.Item>

                {/* The link to the assigned tasks link for the contributors. */}
                <Menu.Item key="tasks" icon={<UnorderedListOutlined />}>
                    <Link to={CONTRIBUTOR_TASKS_PATH}>Tasks</Link>
                </Menu.Item>

                {/* The link to the user profile and logout */}
                <Menu.SubMenu
                    key="profile"
                    icon={isRefreshingCookies ? <SyncOutlined spin /> : <UserOutlined />}
                >
                    <Menu.Item key='profile details' >
                        Profile
                    </Menu.Item>

                    <Menu.Item key="logout">
                        Logout
                    </Menu.Item>

                    {isAgency && <Menu.SubMenu key="agencies" title="Agencies" >
                        {user?.agencies?.map((agency) => (
                            <Menu.Item key={agency?.id}>
                                <a href={`/agency/${agency?.slug}`} rel="noreferrer">{agency?.name}</a>
                            </Menu.Item>
                        ))
                        }
                    </Menu.SubMenu>}

                    {isAgency && <Menu.Item key={"createAgency"}>
                        {<a href='/agency/new' rel="noreferrer">Create Agency </a>}
                    </Menu.Item>
                    }
                </Menu.SubMenu>

            </Menu>
        </Space>
    );
};

export default ContributorMenu;