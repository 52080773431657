import { lxtBackend } from "./utils";

export default {
  login: async ({ accessToken, refreshToken }) => lxtBackend.post("auth/login", {
    access_token: accessToken,
    refresh_token: refreshToken,
  }),
  logout: async () => lxtBackend.post("auth/logout"),
  refresh: async () => lxtBackend.post("auth/refresh", {}, { _retry: true }),
  refreshXsrf: async () => lxtBackend.get('security/set-csrf-token'),
  resetPassword: async email => lxtBackend.post("auth/password/new", { email: email }),
  sendInvitation: async email => lxtBackend.post("users/invitation", {email: email}),
};
