import React, { Fragment } from 'react';
import { Alert } from 'antd';
import { nanoid } from 'nanoid';

const ErrorAlert = ({ error }) => {
    return (
        <Fragment>
            {error &&
                <Alert
                    type="error"
                    message={error.title}
                    description={Array.isArray(error.details) ? error.details.map(dt => <div key={nanoid()}>{dt}</div>) : <div>Unkown Error</div>}
                    showIcon
                />
            }
        </Fragment>
    )
}

// The following component was created as there is a bug in closable property and it can't be passed in the props or set to an expression.
const ClosableErrorAlert = ({ error, onClose }) => {
    return (
        <Fragment>
            {error &&
                <Alert
                    type="error"
                    message={error.title}
                    description={Array.isArray(error.details) ? error.details.map(dt => <div key={nanoid()}>{dt}</div>) : <div>Unkown Error</div>}
                    showIcon
                    closable
                    onClose={onClose}
                />
            }
        </Fragment>
    )
}

export { ErrorAlert as default, ClosableErrorAlert }
