import React, { Fragment } from 'react';
import ProfileEntry from './ProfileEntry';
import { useAuthContext } from '../../contexts/AuthContext';
import { capitalizeFirstChar } from '../Common/Utils';


const PMInputs = () => {
    const {
        user: authUser,
    } = useAuthContext();

    return (
        <Fragment>
            <ProfileEntry
                hasBorder
                label="First Name:"
                value={capitalizeFirstChar(authUser.firstName)}
            />
            <ProfileEntry
                hasBorder
                label="Last Name:"
                value={capitalizeFirstChar(authUser.lastName)}
            />
            <ProfileEntry hasBorder label="Email:" value={authUser.email} />
            <ProfileEntry hasBorder label="Role:" value="Project Manager" />
        </Fragment>
    );
};

export default PMInputs;