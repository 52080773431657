import React, { Fragment } from 'react';
import { Result, Button, Typography, Spin } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { nanoid } from 'nanoid';

const { Paragraph, Text } = Typography;

const ErrorPage = ({ error, redirect, loading }) => {

    return (
        <Fragment>
            {error &&
                <Spin spinning={loading ? true : false}>
                    <Result
                        status="error"
                        title={error.title}
                        extra={redirect ? [<Button type="primary" key="console" onClick={redirect.action}>{redirect.text}</Button>] : []} >

                        {error && error.details && error.details.length > 0 &&
                            <div className="desc">

                                <Paragraph>
                                    <Text strong style={{ fontSize: 16 }}>Details:</Text>
                                </Paragraph>

                                {error.details.map(detail => <Paragraph key={nanoid()}><CloseCircleFilled style={{ color: 'red' }} /> {detail}</Paragraph>)}

                            </div>
                        }

                    </Result>
                </Spin>
            }
        </Fragment>
    )
}

export default ErrorPage;
