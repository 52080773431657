import { Divider } from "antd";
import ProjectFieldReasons from "../helper/ProjectFieldReasons";


const CompletionSkipAndRejectReasons = () => {
    return (
        <>
            <Divider>Completion's Skip & Rejection Reasons</Divider>
            <ProjectFieldReasons labelText='Skip Reasons:' fieldName='completionSkipReasons' />
            <ProjectFieldReasons labelText='Rejection Reasons:' fieldName='completionRejectReasons' />
        </>
    )
}

export default CompletionSkipAndRejectReasons;