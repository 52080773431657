import { Card } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive';

import CardActionButton from './CardActionButton';
import RestoreNotification from './RestoreNotification';
import ClearNotification from './ClearNotification';
import CardHeader from './CardHeader';

import TextHilighter from '../../TextHiglighter';
import { NOT_AVAIALBLE, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import { COMPLETION_DP_PATH_ID } from '../../../../routes';
import NotificationDate from '../../NotificationDate';

const CompletionRejectionCard = ({ notification }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    const content = notification.content;
    const projectName = <TextHilighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />;
    const completionID = <TextHilighter text={content.completion_id ? content.completion_id : NOT_AVAIALBLE} />;
    const cardActions = [];

    const goToCompletion = () => {
        window.open(formatRoute(COMPLETION_DP_PATH_ID, { projectId: content.project_id, compId: content.completion_id }))
    }

    cardActions.push(
        <CardActionButton
            key='goToDP'
            text={`Process ${isTabletOrMobile ? '' : 'Completion'}`}
            icon={<RightOutlined />}
            onClick={goToCompletion}
        />
    )

    if (!notification.cleared)
        cardActions.push(<ClearNotification notificationID={notification.id} />);
    else
        cardActions.push(<RestoreNotification notificationID={notification.id} />);

    return (
        <Card className="notification-card" actions={cardActions}>

            <CardHeader text={'Completion Rejected'} color={NOTIFICATIONS_CARDS_COLORS.CompletionRejection} />

            <p>The completion with the ID {completionID} from the project {projectName} was rejected by the reviewer.</p>
            <NotificationDate date={notification?.createdOn} />
        </Card>
    )
}

export default CompletionRejectionCard;