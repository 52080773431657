const ROOT_PATH = '/';
const LOGIN_PATH = '/login';
const LOGIN_INTERNAL_PATH = '/login/lxt';
const RESET_PASSWORD_PATH = '/password/new';
const SEND_INVITATION_PATH = '/invitation';
const REGISTRATION_PATH = '/registration/:token';
const LOGOUT_PATH = '/logout';

const PM_PATH = '/pm';
const PM_PROJECTS_PATH = '/pm/projects';
const NEW_PROJECT_PATH = '/pm/projects/new';
const PM_SINGLE_PROJECT_PATH = '/pm/projects/:project_id';
const PM_USERS_PATH = '/pm/users';

const CONTRIBUTOR_PATH = '/contributor';
const CONTRIBUTOR_PROJECTS_PATH = '/contributor/projects';
const CONTRIBUTOR_TASKS_PATH = '/contributor/completions';

const COMPLETION_DP_PATH = '/contributor/projects/:projectId/completions/dp';
const COMPLETION_RV_PATH = '/contributor/projects/:projectId/completions/rv';
const COMPLETION_DP_PATH_ID =
	'/contributor/projects/:projectId/completions/dp/:compId';
const COMPLETION_RV_PATH_ID =
	'/contributor/projects/:projectId/completions/rv/:compId';

const USER_PROFILE = '/profile';
const NOTIFICATIONS_PATH = '/notifications';
const USER_PROFILE_OTHERS = '/profile/:userID';

const GUIDELINES_PATH = '/contributor/projects/:projectId/guidelines';

const EMAIL_VERIFICATION_PATH = '/verify-email/:token';

export {
	ROOT_PATH,
	LOGIN_PATH,
	LOGIN_INTERNAL_PATH,
	RESET_PASSWORD_PATH,
	SEND_INVITATION_PATH,
	REGISTRATION_PATH,
	LOGOUT_PATH,
	PM_PATH,
	NEW_PROJECT_PATH,
	PM_PROJECTS_PATH,
	PM_SINGLE_PROJECT_PATH,
	PM_USERS_PATH,
	CONTRIBUTOR_PROJECTS_PATH,
	CONTRIBUTOR_PATH,
	CONTRIBUTOR_TASKS_PATH,
	COMPLETION_DP_PATH,
	COMPLETION_RV_PATH,
	COMPLETION_DP_PATH_ID,
	COMPLETION_RV_PATH_ID,
	NOTIFICATIONS_PATH,
	USER_PROFILE,
	USER_PROFILE_OTHERS,
	GUIDELINES_PATH,
	EMAIL_VERIFICATION_PATH,
};
