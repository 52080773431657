import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const prodConfig = {
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	authDomain: process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
};

const devConfig = {
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	authDomain: process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// if (process.env.NODE_ENV !== 'production') {
//     console.log(process.env.NODE_ENV);
//     console.log(config);
// }

export const app = initializeApp(config);
export const firebaseAuth = getAuth(app);

