import { get, map, values } from 'lodash-es';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import completionsApi from '../api/completions';
import { formatError } from './ResponseErrorFormatter';
import { COMPLETION_DP_ACTION, COMPLETION_RV_ACTION, UPLOAD_STATES } from '../Constants';
import LabelStudioHandler from './helpers/LabelStudioHandler';
import { loadCompletion, setCompletionResult, submitRecorderCompletionData } from '../store/audioSlice';
import {
	loadUploadTagCompletion, performUpload,
	setUploadTagCompletionResult,
	submitUploadedFilesCompletion,
} from '../store/uploadSlice';
import { clearClientState, loadTextAreaLabelsCompletion } from '../store/textAreaLabelsSlice';

//////////////////////////////////////////////////////////////////////////////////////////////// Local Storage Comment Data Handler.

const COMMENTS_KEY = '__comments__';

const checkCompletionEditableComments = (completion) =>
{
	const editableComments = localStorage.getItem(COMMENTS_KEY);
	if (
		editableComments &&
		Array.isArray(completion.comments) &&
		completion.comments.length > 0
	)
	{
		try
		{
			const jsonEditableComments = JSON.parse(editableComments);
			for (let comment of completion.comments)
			{
				if (jsonEditableComments[comment.id]) comment.isEditable = true;
			}
		} catch (e)
		{
			// Do nothing.
		}
	}
};

const removeCompletionEditableComments = (completion) =>
{
	let editableComments = localStorage.getItem(COMMENTS_KEY);

	if (
		editableComments &&
		Array.isArray(completion.comments) &&
		completion.comments.length > 0
	)
	{
		try
		{
			const jsonEditableComments = JSON.parse(editableComments);
			const completionCommentIDs = completion.comments.map((c) => String(c.id));

			const updatedObject = Object.entries(jsonEditableComments)
				.filter(([k, v]) => !completionCommentIDs.includes(String(k)))
				.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

			editableComments = JSON.stringify(updatedObject);
			editableComments = localStorage.setItem(COMMENTS_KEY, editableComments);
		} catch (e)
		{
			// Do nothing.
		}
	}
};

const setEditableComment = (comment) =>
{
	let editableComments = localStorage.getItem(COMMENTS_KEY);
	try
	{
		let jsonEditableComments = editableComments
			? JSON.parse(editableComments)
			: {};

		jsonEditableComments[comment.id] = true;
		editableComments = JSON.stringify(jsonEditableComments);

		editableComments = localStorage.setItem(COMMENTS_KEY, editableComments);
	} catch (e)
	{
		console.log('Error:', e);
	}
};

const removeEditableComment = (commentID) =>
{
	let editableComments = localStorage.getItem(COMMENTS_KEY);

	if (editableComments && commentID)
	{
		try
		{
			const jsonEditableComments = JSON.parse(editableComments);
			const strCommentID = String(commentID);

			const updatedEditableComments = Object.entries(jsonEditableComments)
				.filter(([k, v]) => String(k) !== strCommentID)
				.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

			editableComments = JSON.stringify(updatedEditableComments);
			editableComments = localStorage.setItem(COMMENTS_KEY, editableComments);
		} catch (e)
		{
			console.log('Error:', e);
		}
	}
};

//////////////////////////////////////////////////////////////////////////////////////////////// Context

const CompletionContext = createContext();

const hasResultChanged = (currResult, prevResult) =>
{
	let strCurrent = JSON.stringify(currResult);
	let strPrev = JSON.stringify(prevResult);
	return strCurrent !== strPrev;
};

const _lsfHandler = new LabelStudioHandler();
window.lsfh = _lsfHandler;

const UPLOAD_FILE_ACCEPTABLE_STATUS_VALUES = [
	undefined,
	'uploading',
	'success',
	'done',
];

const CompletionContextProvider = ({ children }) =>
{
	const [state, setState] = useState({
		completion: null,
		hasNext: true,
		loading: false,
		error: null,
		errorSaveResults: null,
		isSavingDraftResult: false,
		prevDraftResult: null,
		isCommentAdded: false,

		mediaPlaying: false,
		originalCompletionSelected: true,
	});

	const { toUploadCompletionDataTasks } = useSelector((state) => state.audio);
	const { toUploadCompletionFiles } = useSelector((state) => state.upload);
	const { toSubmitCompletionDataTasks } = useSelector(
		(state) => state.textAreaLabels,
	);

	const dispatch = useDispatch();

	const sanitizeTextAreaWithLabelResults = useCallback(
		(results) =>
		{
			console.log('[CompletionContext]', 'sanitizeTextAreaWithLabelResults', {
				state,
				results,
			});

			if (!results) { return; }

			const resultsTextArea = Object.values(results
				?.filter((t) => t.type === 'textarea')
				?.reduce((a, t) =>
				{
					a[t.from_name] = t;
					return a;
				}, {}))
				?.map((t) =>
				{
					const tawlState = get(window, `_ls_.tawl.${t?.from_name}`);

					if (!tawlState) { return t; }

					return {
						...t,
						value: {
							text: [tawlState.getSerializedText().replace(/^\n\n/, '')]
						}
					};
				});

			const resultsRest = results?.filter((t) => t.type !== 'textarea') ?? [];

			const cleanResults = [
				...resultsRest,
				...resultsTextArea
			];

			console.log('[CompletionContext]', 'sanitizeTextAreaWithLabelResults', {
				results,
				cleanResults
			}, {
				resultsTextArea,
				resultsRest,
			});

			return cleanResults;
		},
		[state]
	);

	const initializeContext = () =>
	{
		_lsfHandler.addOriginalCompletionSelectedListener((value) =>
		{
			setState((ps) => ({
				...ps,
				originalCompletionSelected: value,
			}));
		});

		_lsfHandler.addMediaPlayingListener((value) =>
		{
			setState((ps) => ({
				...ps,
				mediaPlaying: value,
			}));
		});
	};

	const getLsfStore = () => _lsfHandler.getStore();

	const completionHasValidResult = () =>
	{
		return _lsfHandler.completionHasValidResult();
	};

	const completionHasResult = () =>
	{
		const completionID = state.completion.id;

		const isAudioRecorded =
			completionID in toUploadCompletionDataTasks &&
			toUploadCompletionDataTasks[completionID].recorded;

		const areFilesUploaded =
			completionID in toUploadCompletionFiles &&
			toUploadCompletionFiles[completionID].hasResult;

		return (
			_lsfHandler.completionHasResult() || isAudioRecorded || areFilesUploaded
		);
	};

	const prepareTextAreaLabelsNameClientState = (completionID) => {
		return Object.fromEntries(
			map(values(get(window, '_ls_.tawl', {})), (tawlItem) => {
				if (tawlItem.completionId === completionID) {
					tawlItem._processed = [true, completionID];
					return [tawlItem.name, tawlItem?.editor?.getJSON?.()];
				}
			}).filter(Boolean),
		);
	};

	const prepareTextAreaLabelsClientState = (completionID) =>
	{
		const jsonID = toSubmitCompletionDataTasks[completionID].getJsonID;
		const getJSON = window[jsonID];
		return getJSON();
	};

	const completionResultIsAllowed = () =>
	{
		// NOTE: It is safe to act on the selected since this function is called upon submit, and submit is disabled when the original
		// completion is not selected.

		if (
			state.completion.allowDuplicateResults ||
			!state.completion.prevCompletions
		)
		{
			return true;
		} else
		{
			// Preparing a list of all previous completions results.
			const prevCompletions = [];
			state.completion.prevCompletions.forEach((pc) =>
			{
				prevCompletions.push(JSON.stringify(pc.result.map((res) => res.value)));
			});

			// Preparing the current completion result.
			const currCompletionResult = JSON.stringify(
				_lsfHandler.getSelectedCompletionResult().map((e) => e.value),
			);

			// Checking if the result matches on of the previous results or not.
			const isAllowed = !prevCompletions.includes(currCompletionResult);
			return isAllowed;
		}
	};

	const completionResultModified = () =>
	{
		// NOTE: It is safe to act on the selected since this function is called upon submit, and submit is disabled when the original
		// completion is not selected.

		const lsfCompletionResult = _lsfHandler.getSelectedCompletionResult();
		const originalResult = state.completion.result
			? state.completion.result
			: [];
		const resultChanged = hasResultChanged(lsfCompletionResult, originalResult);
		return resultChanged;
	};

	const fetchNextCompletion = (projectID, isReviewer) => {
		setState((ps) => ({
			...ps,
			completion: null,
			hasNext: true,
			loading: true,
			error: null,
		}));

		// Getting the id's of all the completions in upload progress.
		// const skipCompletionIDs = Object.entries(toUploadCompletionDataTasks)
		//     .filter(([_, value]) => value.status !== UPLOAD_PROCESS_STATUS_CODES.NotStarted)
		//     .map(([key, _]) => key);

		const excludeIds = [];
		const hasUploadTag = _lsfHandler.checkSubstringInXMLConfig(
			state.completion,
			'<Upload',
		);
		const hasAudioDataCollectionTag = _lsfHandler.checkSubstringInXMLConfig(
			state.completion,
			'<Record',
		);
		const hasTextAreaWithLabelsTag = _lsfHandler.checkSubstringInXMLConfig(
			state.completion,
			'<TextAreaWithLabels',
		);

		if (hasAudioDataCollectionTag) {
			Object.keys(toUploadCompletionDataTasks).forEach((val) =>
				excludeIds.push(parseInt(val)),
			);
		}

		if (hasUploadTag || Object.keys(toUploadCompletionFiles).length > 0) {
			const uploadCompletions = { ...toUploadCompletionFiles };
			const excludedStates = [
				undefined,
				UPLOAD_STATES.Uploading,
				UPLOAD_STATES.Pending,
				UPLOAD_STATES.Validating,
				UPLOAD_STATES.Preparing,
			];

			Object.entries(uploadCompletions).forEach(([key, value]) => {
				if (value.files) {
					Object.values(value.files).forEach((file) => {
						if (excludedStates.includes(file.status))
							excludeIds.push(parseInt(key));
					});
				}
			});

		}

		if (hasTextAreaWithLabelsTag) {
			excludeIds.push(state.completion.id);
		}

		return new Promise((resolve, reject) => {
			completionsApi
				.getCompletionId(projectID, isReviewer, excludeIds)
				.then((idRes) => completionsApi.getCompletion(idRes.data, isReviewer))
				.then((completionRes) =>
					_lsfHandler.loadLdpCompletion(completionRes.data, isReviewer),
				)
				.then((completionData) => {
					checkCompletionEditableComments(completionData);
					setState((ps) => ({
						...ps,
						completion: completionData,
						hasNext: true,
						loading: false,
						error: null,
						isCommentAdded: Array.isArray(completionData.comments)
							? completionData.comments.some((c) => c.isEditable)
							: false,
					}));

					const hasAudioDataCollectionTag =
						_lsfHandler.checkSubstringInXMLConfig(completionData, '<Record');
					const hasTextAreaWithLabelsTag =
						_lsfHandler.checkSubstringInXMLConfig(
							completionData,
							'<TextAreaWithLabels',
						);
					const hasUploadTag = _lsfHandler.checkSubstringInXMLConfig(
						completionData,
						'<Upload',
					);

					// Loading the completion data in the store for review.
					if (hasAudioDataCollectionTag) {
						dispatch(
							loadCompletion({
								completion: completionData,
								projectID,
								review: !!isReviewer,
							}),
						);
					}

					if (hasTextAreaWithLabelsTag) {
						dispatch(
							loadTextAreaLabelsCompletion({
								completion: completionData,
								projectID,
								isReviewer: !!isReviewer,
							}),
						);
					}

					if (hasUploadTag) {
						dispatch(
							loadUploadTagCompletion({
								completion: completionData,
								projectID,
								isReviewer: !!isReviewer,
							}),
						);
					}

					resolve();
				})
				.catch((e) => {
					if (e.isAxiosError && e.response && e.response.status === 404) {
						setState((ps) => ({
							...ps,
							completion: null,
							hasNext: false,
							loading: false,
							error: null, // No error here. The server is just reporting there are no more completions.
						}));

						// Clearing the currently loaded completion if it is not uploading any files.
						//dispatch(loadUploadTagCompletion(null));
						dispatch(loadCompletion(null));
						dispatch(loadTextAreaLabelsCompletion(null));
					} else {
						if (e.response?.status === 403 && e.response.data.code === 1001) {
							reject(e.response);
						} else {
							setState((ps) => ({
								...ps,
								completion: null,
								hasNext: true,
								loading: false,
								isCommentAdded: false,
								error: formatError(
									e,
									`Failed To Fetch A Valid Completion For Project ${projectID} !`,
								),
							}));
						}
					}
				});
		});
	};

	const fetchNextCompletionByID = (projectID, completionID, isReviewer) =>
	{
		setState((ps) => ({
			...ps,
			completion: null,
			hasNext: true,
			loading: true,
			error: null,
		}));

		return new Promise((resolve, reject) =>
		{
			completionsApi
				.getCompletion(completionID, isReviewer)
				.then((completionRes) =>
					_lsfHandler.loadLdpCompletion(completionRes.data, isReviewer),
				)
				.then((completionData) =>
				{
					checkCompletionEditableComments(completionData);
					setState((ps) => ({
						...ps,
						completion: completionData,
						hasNext: true,
						loading: false,
						error: null,
						isCommentAdded: Array.isArray(completionData.comments)
							? completionData.comments.some((c) => c.isEditable)
							: false,
					}));

					const hasAudioDataCollectionTag = _lsfHandler.checkSubstringInXMLConfig(completionData,'<Record');
					const hasTextAreaWithLabelsTag = _lsfHandler.checkSubstringInXMLConfig(completionData,'<TextAreaWithLabels');
					const hasUploadTag = _lsfHandler.checkSubstringInXMLConfig(completionData,'<Upload');

					// Loading the completion data in the store for review.
					if (hasAudioDataCollectionTag){
						dispatch(
							loadCompletion({
								completion: completionData,
								projectID,
								review: !!isReviewer,
							}),
						);
					}

					if (hasTextAreaWithLabelsTag){
						dispatch(
							loadTextAreaLabelsCompletion({
								completion: completionData,
								projectID,
								isReviewer: !!isReviewer,
							}),
						);
					}

					if (hasUploadTag){
						dispatch(
							loadUploadTagCompletion({
								completion: completionData,
								projectID,
								isReviewer: !!isReviewer,
							}),
						);
					}
				})
				.catch((e) =>
				{
					if (e.response?.status === 403 && e.response.data.code === 1001)
					{
						reject(e.response);
					} else
					{
						setState((ps) => ({
							...ps,
							completion: null,
							hasNext: true,
							loading: false,
							isCommentAdded: false,
							error: formatError(
								e,
								`Failed To Fetch Completion ${completionID} !`,
							),
						}));

						// Clearing the currently loaded completion if it is not uploading any files.
						dispatch(loadCompletion(null));
						dispatch(loadUploadTagCompletion(null));
						dispatch(loadTextAreaLabelsCompletion(null));
					}
				});
		});
	};

	const skipCompletionDP = async (skipReasons) =>
	{
		setState((ps) => ({
			...ps,
			loading: true,
			errorSaveResults: null,
		}));

		try
		{
			await completionsApi.dpSendCompletionResult(state.completion.id, {
				action: COMPLETION_DP_ACTION.Skip,
				skipReasons,
			});

			removeCompletionEditableComments(state.completion);
			dispatch(clearClientState());
			setState((ps) => ({
				...ps,
				completion: null, // Clearing successfully saved completion
				loading: false,
				errorSaveResults: null,
				prevDraftResult: null, // Clearing the draft result upon successfull submission.
				isCommentAdded: false,
			}));
		} catch (e)
		{
			if (e.response?.status === 403 && e.response.data.code === 1001)
			{
				return e.response;
			} else
			{
				setState((ps) => ({
					...ps,
					loading: false,
					errorSaveResults: formatError(
						e,
						`Failed To Save Result For Completion ${state.completion.id} !`,
					),
				}));
			}
		}
	};
	const prepareUploadFilesPromises = (completionID) => {
		const completionEntry = toUploadCompletionFiles[completionID];
		const filesToBeUploaded = Object.values(completionEntry.files);
		const promises = [];

		filesToBeUploaded.forEach((file) => {
			if (UPLOAD_FILE_ACCEPTABLE_STATUS_VALUES.includes(file.status)) {
				let fileFormData = new FormData();
				fileFormData.append('file', file.originFileObj);
				fileFormData.append('tagId', file.tagId);
				promises.push(
					dispatch(
						performUpload({
							fileFormData:fileFormData,
							completionID: completionID,
							fileId: file.uid,
						}),
					),
				);
			}
		});

		return promises;
	};

	const isAudioRecorded = (completionID) =>
	{
		return (
			completionID in toUploadCompletionDataTasks &&
			toUploadCompletionDataTasks[completionID].recorded
		);
	};

	const hasUploadFiles = (completionID) =>
	{
		return (
			completionID in toUploadCompletionFiles &&
			toUploadCompletionFiles[completionID].files &&
			Object.values(toUploadCompletionFiles[completionID].files).length > 0
		);
	}

	const hasTextAreaLabelsTagByCompletionId = (completionID) =>
	{
		return (
			completionID in toSubmitCompletionDataTasks &&
			toSubmitCompletionDataTasks[completionID].hasResult
		);
	};

	const submitCompletionDP = async (isSyncFunction = true) =>
	{
		try
		{
			if (isSyncFunction)
			{
				setState((ps) => ({
					...ps,
					loading: true,
					errorSaveResults: null,
				}));
			}

			const result = await _lsfHandler.serializeSelectedCompletionResult();
			const completionID = state.completion.id;

			if (hasTextAreaLabelsTagByCompletionId(completionID))
			{
				const clientState = {
					textAreaLabels: {
						editorState: prepareTextAreaLabelsClientState(completionID),
					},
				};

				completionsApi.dpSendCompletionResult(completionID, {
					action: COMPLETION_DP_ACTION.Submit,
					result: sanitizeTextAreaWithLabelResults(result),
					client_state: clientState,
				});
			} else if (isAudioRecorded(completionID))
			{
				dispatch(setCompletionResult(result));
				await submitRecorderCompletionData(completionID, dispatch);
			} else if (hasUploadFiles(completionID))
			{
				dispatch(setUploadTagCompletionResult(result));

				let files = await Promise.all(prepareUploadFilesPromises(completionID));

				const areFilesUploadedSuccessfully = !files.some((file) =>
					file.hasOwnProperty('error'),
				);

				if (areFilesUploadedSuccessfully)
				{
					await dispatch(submitUploadedFilesCompletion(completionID));
				}
			} else
			{
				await completionsApi.dpSendCompletionResult(completionID, {
					action: COMPLETION_DP_ACTION.Submit,
					result,
				});
			}

			removeCompletionEditableComments(state.completion);
			dispatch(clearClientState());

			if (isSyncFunction)
			{
				setState((ps) => ({
					...ps,
					completion: null,
					loading: false,
					errorSaveResults: null,
					prevDraftResult: null,
					isCommentAdded: false,
				}));
			}

			return Promise.resolve();
		} catch (e)
		{
			if (e.response?.status === 403 && e.response.data.code === 1001)
			{
				return Promise.reject(e.response);
			} else
			{
				setState((ps) => ({
					...ps,
					loading: false,
					errorSaveResults: formatError(
						e,
						`Failed To Save Result For Completion ${state.completion.id} !`,
					),
				}));
				return Promise.reject();
			}
		}
	};

	const submitCompletionRV = async (action, rejectReasons) =>
	{
		setState((ps) => ({
			...ps,
			loading: true,
			errorSaveResults: null,
		}));

		return new Promise((resolve, reject) =>
		{
			(action === COMPLETION_RV_ACTION.AcceptWithModifications
				? _lsfHandler.serializeSelectedCompletionResult()
				: Promise.resolve()
			)
				.then((result) =>
				{
					const completionID = state.completion.id;
					const isValidReviewerAction = action === COMPLETION_RV_ACTION.AcceptWithModifications
						|| action === COMPLETION_RV_ACTION.Accept
						|| action === COMPLETION_RV_ACTION.Reject;

					if (isValidReviewerAction) {
						const completionPayload = {
							result,
							action,
							rejectReasons,
						}

						if (hasTextAreaLabelsTagByCompletionId(completionID)) {
							completionPayload.result = sanitizeTextAreaWithLabelResults(completionPayload.result)
							completionPayload.client_state = {
								textAreaLabels: {
									...prepareTextAreaLabelsNameClientState(completionID),
									editorState: prepareTextAreaLabelsClientState(completionID),
								},
							}
						}

						return completionsApi.rvSendCompletionResult(state.completion.id, completionPayload);
					} else
					{
						return Promise.reject(
							new Error('Invalid reviewer completion action!'),
						);
					}
				})
				.then(() =>
				{
					removeCompletionEditableComments(state.completion);
					dispatch(clearClientState());
					setState((ps) => ({
						...ps,
						completion: null, // Clearing successfully saved completion
						loading: false,
						errorSaveResults: null,
						prevDraftResult: null, // Clearing the draft result upon successfull submission.
						isCommentAdded: false, // Resetting the comment added flag after submission.
					}));
					resolve();
				})
				.catch((e) =>
				{
					setState((ps) => ({
						...ps,
						loading: false,
						errorSaveResults: formatError(
							e,
							`Failed To Save Result For Completion ${state.completion.id} !`,
						),
					}));
					reject(e);
				});
		});
	};

	const saveDraftResult = (rv = false) =>
	{
		// Testing there is a completion available in the state.
		if (!state.completion) return Promise.resolve(false);

		// Serializing the results of the original completion and not the selected one.
		// this ensures that autosave will save the result of the original completion even
		// if it is not selected.
		const originalCompletionResult = _lsfHandler.getOriginalCompletionResult();

		if (!originalCompletionResult) return Promise.resolve(false);

		// Making sure results has changed by comparing it the previous saved result.
		const resultChanged = hasResultChanged(
			originalCompletionResult,
			state.prevDraftResult,
		);

		if (!resultChanged) return Promise.resolve(false);

		// Returning the promise which saves the updated result into the database.
		setState((ps) => ({
			...ps,
			isSavingDraftResult: true,
		}));

		return new Promise((resolve, reject) =>
		{
			const completionID = state.completion.id

			const draftData = {
				action: rv
					? COMPLETION_RV_ACTION.SaveDraft
					: COMPLETION_DP_ACTION.SaveDraft,
				result: originalCompletionResult,
			};

			if (hasTextAreaLabelsTagByCompletionId(completionID)) {
				draftData.result = sanitizeTextAreaWithLabelResults(draftData.result)
				draftData.client_state = {
					textAreaLabels: {
						...prepareTextAreaLabelsNameClientState(completionID),
						editorState: prepareTextAreaLabelsClientState(completionID),
					},
				}
			}

			const saveResultPromise = rv
				? completionsApi.rvSendCompletionResult(state.completion.id, draftData)
				: completionsApi.dpSendCompletionResult(state.completion.id, draftData);

			saveResultPromise
				.then(() =>
				{
					setState((ps) => ({
						...ps,
						isSavingDraftResult: false,
						prevDraftResult: originalCompletionResult, // Updating the prev result with the current result for the next check.
					}));
					resolve(true);
				})
				.catch((e) =>
				{
					if (e.response?.status === 403 && e.response.data.code === 1001)
					{
						reject(e.response);
					} else
					{
						setState((ps) => ({
							...ps,
							isSavingDraftResult: false,
						}));
						reject(
							formatError(
								e,
								`Saving Draft Failed For Completion ${state.completion.id} !`,
							),
						);
					}
				});
		});
	};

	const clearSaveResultsError = () =>
		setState((ps) => ({ ...ps, errorSaveResults: null }));

	const submitComment = (addedComment, contributorType) =>
	{
		return new Promise((resolve, reject) =>
		{
			if (addedComment.text)
			{
				completionsApi
					.sendComment(state.completion.id, contributorType, addedComment.text)
					.then((res) =>
					{
						// Adding the id of the new comment from the backend and isEditable field
						addedComment = {
							...addedComment,
							createdOn: new Date().toUTCString(),
							isEditable: true,
							id: res.data,
						};

						// Adding the comment to the completion comments.
						let completion = state.completion;
						if (completion.comments)
							completion.comments = [...completion.comments, addedComment];
						else completion = { ...completion, comments: [addedComment] };

						setEditableComment(addedComment);

						setState((ps) => ({
							...ps,
							completion,
							isCommentAdded: true,
						}));

						resolve();
					})
					.catch((e) =>
					{
						reject(
							formatError(
								e,
								`Failed to save comment for the completion ${state.completion.id}.`,
							),
						);
					});
			} else
			{
				resolve();
			}
		});
	};

	const updateComment = (commentID, updatedCommentText) =>
	{
		return new Promise((resolve, reject) =>
		{
			if (updatedCommentText)
			{
				completionsApi
					.updateComment(commentID, updatedCommentText)
					.then(() =>
					{
						const updateComment = state.completion.comments.find(
							(c) => c.id === commentID,
						);
						updateComment.text = updatedCommentText;
						resolve();
					})
					.catch((e) =>
					{
						reject(
							formatError(
								e,
								`Failed to update comment for the completion ${state.completion.id}.`,
							),
						);
					});
			} else
			{
				resolve();
			}
		});
	};

	const deleteComment = (commentID) =>
	{
		return new Promise((resolve, reject) =>
		{
			if (commentID)
			{
				completionsApi
					.deleteComment(commentID)
					.then(() =>
					{
						let completion = state.completion;
						completion.comments = completion.comments.filter(
							(c) => c.id !== commentID,
						);

						removeEditableComment(commentID);

						setState((ps) => ({
							...ps,
							completion,
							isCommentAdded:
								ps.completion.comments.filter((c) => c.isEditable).length > 0, // Checking how many recently added comments by the user.
						}));

						resolve();
					})
					.catch((e) =>
					{
						reject(
							formatError(
								e,
								`Failed to delete comment for the completion ${state.completion.id}.`,
							),
						);
					});
			} else
			{
				resolve();
			}
		});
	};

	const services = {
		...state,

		initializeContext,
		getLsfStore,

		completionHasResult,
		completionHasValidResult,
		completionResultIsAllowed,
		completionResultModified,

		fetchNextCompletionByID,
		fetchNextCompletion,

		submitCompletionDP,
		skipCompletionDP,
		submitCompletionRV,

		saveDraftResult,
		clearSaveResultsError,

		submitComment,
		updateComment,
		deleteComment,
	};

	return (
		<CompletionContext.Provider value={services}>
			{children}
		</CompletionContext.Provider>
	);
};

const useCompletionContext = () => useContext(CompletionContext);

export { useCompletionContext as default, CompletionContextProvider };
