import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

const NewTabButton = ({ displayText, type, size, className, onClick, onClickWithCtrl, icon, disabled = false }) => {

    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleKeyDown = e => {
        if (e.ctrlKey || e.metaKey) {
            setCtrlKeyPressed(true);
        }
    };

    const handleKeyUp = e => {
        if (!e.ctrlKey || !e.metaKey) {
            setCtrlKeyPressed(false);
        }
    };

    return (
        <Button
            type={type}
            size={size}
            className={className ? className : undefined}
            onClick={e => ctrlKeyPressed ? onClickWithCtrl(e) : onClick(e)}
            icon={icon ? icon : undefined}
            disabled={disabled}
        >
            {displayText}
        </Button>
    )
}

export default NewTabButton