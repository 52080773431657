import { Fragment } from "react";
import { Tag } from "antd";

const TagsTableCell = ({ values }) => {

    const tags =
        (values && !Array.isArray(values)) ?
            Object.values(values).filter(val => typeof val === 'string') : values

    const hasValues = (tags && tags.length > 0);

    return (
        <Fragment>
            {hasValues ? tags.map(tag => {
                const tagkey = tag.id ?? tag.name ?? tag
                return (
                    <Tag color='blue' key={tagkey}>
                        {tag.name ? tag.name : tag}
                    </Tag>
                );
            }) : 'Not Available'
            }
        </Fragment>
    )
}

export function renderTagsTableCell(values) {
    return (<TagsTableCell values={values} />)
}