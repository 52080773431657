import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeCompletionID: null,
    toSubmitCompletionDataTasks: {},
}

const EMPTY_COMPLETION_ENTRY = {
    // The project ID of the completion belongs to.
    projectID: -1,
    // An indicator for whether the completion is loaded for isReviewer or data processing.
    isReviewer: false,
    result: []
}

const textAreaLabelsSlice = createSlice({
    name: 'textAreaLabels',
    initialState,

    reducers: {
        loadTextAreaLabelsCompletion: (state, action) => {

            const prevCompletionEntries = Object.entries(state.toSubmitCompletionDataTasks)
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

            if (action.payload) {

                // The payload is the completion ID in this case.
                const { completion: payloadCompletion, projectID, isReviewer } = action.payload;
                const { id: completionID } = payloadCompletion;

                // Creating or updating the completion entry.
                const completionEntry = (completionID in state.toSubmitCompletionDataTasks) ? {
                    ...state.toSubmitCompletionDataTasks[completionID],
                } : { ...EMPTY_COMPLETION_ENTRY }

                if (payloadCompletion.clientState) {
                    completionEntry.clientState = payloadCompletion.clientState;
                }

                // Updating entry fields from the arguments.
                completionEntry.projectID = projectID;
                completionEntry.isReviewer = isReviewer;
                completionEntry.hasResult = false



                // Updating the activeCompletionID with the payload, even if it is equal to null.
                state.activeCompletionID = completionID;

                // Creating or updating the entry for the current completion.
                state.toSubmitCompletionDataTasks = { ...prevCompletionEntries, [completionID]: completionEntry };
            }
            else {
                state.activeCompletionID = null;
                state.toSubmitCompletionDataTasks = { ...prevCompletionEntries };
            }
        },

        setTextAreaLabelsValue: (state, action) => {
            if (state.activeCompletionID === null || !(state.activeCompletionID in state.toSubmitCompletionDataTasks))
                return;

            let completionEntry = {}

            completionEntry = {
                ...state.toSubmitCompletionDataTasks[state.activeCompletionID],
                getJsonID: action.payload.getJsonID,
                getTextID: action.payload.getTextID,
                hasResult: true
            };

            // Updating the completion entry in the state.
            state.toSubmitCompletionDataTasks = {
                ...state.toSubmitCompletionDataTasks,
                [state.activeCompletionID]: completionEntry,
            };
        },
        clearClientState: (state, action) => {
            if (state.activeCompletionID === null || !(state.activeCompletionID in state.toSubmitCompletionDataTasks))
                return;

            let completionEntry = {}

            completionEntry = {
                ...state.toSubmitCompletionDataTasks[state.activeCompletionID],
                clientState: null
            };

            // Updating the completion entry in the state.
            state.toSubmitCompletionDataTasks = {
                ...state.toSubmitCompletionDataTasks,
                [state.activeCompletionID]: completionEntry,
            };
        },
    },
});

export const {
    loadTextAreaLabelsCompletion,
    setTextAreaLabelsValue,
    clearClientState,
} = textAreaLabelsSlice.actions;

export default textAreaLabelsSlice.reducer;