import React, { useState, useCallback } from 'react';
import { Button, Alert, Typography } from 'antd';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'
import InfoModal from '../Common/InfoModal';
import WarningModal from '../Common/WarningModal';
import ErrorBoundary from '../Error/ErrorBoundary';
import ErrorModal from '../Error/ErrorModal';
import ProfileEntry from './ProfileEntry';
import { useAuthContext } from '../../contexts/AuthContext';
import { OptionsContextProvider } from './../../contexts/OptionsContext';
import ContributorInputs from './ContributorInputs';
import PMInputs from './PMInputs';
import styled from 'styled-components';
const { Text } = Typography;

const StyledAlert = styled(Alert)`
    margin-bottom: 1rem;
    width: fit-content;
`

const AlertMessage = ({ user }) => {
    return (
        <Text>
            A verification email has been sent to <Text strong>{user?.pendingEmail}</Text>,
            please verify this email within 24 hours to use it!
        </Text>
    )
}

const UserProfile = () => {
    const {
        user: authUser,
        secondFactorPhoneNumber,
        recentLoginRequired,
        error,

        resetSecondFactor,
        clearError,
        clearRecentLoginRequiredFlag,

        isLxtUser,
    } = useAuthContext();

    const [state, setState] = useState({
        // Showing the the pre-warning to the user to notify him that he will be logged out.
        showResetPhoneNumberModal: false,
    });

    const [isUserEditing, setIsUserEditing] = useState(false);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    // The following code is going to be needed to enable the PM view the full profile of other users.
    // const { userData, isLoading, error, fetchSingleUserData } = usePMUsersContext();
    // useEffect(() => {
    //     if (authUser) {
    //         //Making sure that the current signed in user is a pm in order to prevent cotnributors 
    //         //from accessing other users profiles.
    //         if (authUser.role === 'pm' && userID)
    //             getUserData(userID);
    //         else
    //             getUserData(authUser.id);
    //     }
    //     //eslint-disable-next-line
    // }, [userID, authUser]);

    const handlePreWarningShow = useCallback(() => {
        setState(ps => ({ ...ps, showResetPhoneNumberModal: true }));
    }, [])

    const handlePreWarningCancel = useCallback(() => {
        setState(ps => ({ ...ps, showResetPhoneNumberModal: false }));
    }, [])

    const handlePreWarningProceed = useCallback(() => {
        setState(ps => ({ ...ps, showResetPhoneNumberModal: false }));
        resetSecondFactor();
    }, [resetSecondFactor])

    const handlePostWarningOk = useCallback(() => {
        clearRecentLoginRequiredFlag();
    }, [clearRecentLoginRequiredFlag]);


    return (
        <ErrorBoundary>

            <ErrorModal
                error={error}
                onDone={() => clearError()}
            />

            {/* The modal to notify the user that the process will require a logout. */}
            <WarningModal
                title="You Will Be Signed Out!"
                message='Resetting the second factor will log you out to reset the second factor, would you like to proceed?'
                show={state.showResetPhoneNumberModal}
                onCancel={handlePreWarningCancel}
                onProceed={handlePreWarningProceed}
            />

            {/* The modal to notify the user about that recent login is required. */}
            <InfoModal
                title="Recent Sign In Required!"
                message='Resetting the passowrd requires a more recent login operation. Please try again soon after you re-login.'
                show={recentLoginRequired}
                onDone={handlePostWarningOk}
            />

            <div
                style={{
                    paddingBottom: '150px',
                    paddingTop: '50px',
                    paddingLeft: !isTabletOrMobile && '50px',
                    paddingRight: !isTabletOrMobile && '50px',
                    zIndex: '1',
                }}
                className="user-profile-container"
            >

                <h1 style={{ paddingBottom: '20px' }}><UserOutlined />User Profile</h1>

                <div>
                    {authUser.pendingEmail &&
                        <StyledAlert
                            message={<AlertMessage user={authUser} />}
                            type="success"
                        />}

                    <ProfileEntry hasBorder label={'ID:'} value={authUser.id} />

                    {authUser.role === 'contributor' ? (
                        <OptionsContextProvider>
                            <ContributorInputs ctProfileEditMode={isUserEditing} onChangeCtProfileEditMode={setIsUserEditing} />
                        </OptionsContextProvider>
                    ) : (
                        <PMInputs />
                    )
                    }

                    {/* Reset phone number should be available only for the external users. */}
                    {!isLxtUser(authUser.email) &&
                        <ProfileEntry
                            hasBorder
                            label={'Mobile Number:'}
                            value={secondFactorPhoneNumber ? secondFactorPhoneNumber : '+*********'}
                            controls={
                                <Button
                                    type='link'
                                    onClick={handlePreWarningShow}
                                    disabled={authUser.role === 'contributor' && isUserEditing}
                                    icon={<EditOutlined />}
                                />
                            }
                        />
                    }
                </div>
            </div>

        </ErrorBoundary>
    )
}

export default UserProfile;