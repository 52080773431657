import React, { createContext, useContext, useState } from 'react';
import completionApi from '../api/completions';
import { formatError } from './ResponseErrorFormatter';
import CompletionMetaDataHandler from './helpers/CompletionMetaDataHandler';

const MAIN_PROCESS_INTERVAL_IN_MS = 1000; //1 second.
const MIN_FACTOR = 2; // Used to determine the division factor which is used to determined the minum amount of time that can be considered.
// const TRANSMISSION_THRESHOLD_IN_SEC = 60;

let _calculatedDurationInSeconds = 0;
let _timerProcess = null;
const _completionMetaDataHandler = new CompletionMetaDataHandler({
	useEncryption: true,
});

// The following two fields were stored in module variable instead of state because they are shared among many setInterval & setTimeout and periodic
// procedures always don't get the latest value of the state, but they only see a snap shot of the state when they were created.
let _completionID = null;
let _role = '';

const CompletionTimerContext = createContext(null);

// - The context which should wrap around the completion to calculate the handle time of a single completion.
// - If the connection is lost or failed with the backend, the context starts to accumulate the value in the local storage in fasion of an entry per completion.
// - The context assumes that there is no completion can be submitted without saving the handle time for first. So if the user left the completion while the connection
// is down, and navigated to to the project list for example then the connection is trestored, the progress the contributor made will be kept in the local storage
// and is saved while he/she is working on the completion again.
// - The context is also tackling the issue of browser sleep by using set interval which wil be put to sleep when the browser is put to sleep, so that no time will be calculate.
// - There is no need to store the role in the local storage as even when the contributor is assigned the same completion as dp and rv, he will not be able to review it till the
// completion is processed, so there is no need to save the role as there is no conflict will occurr.
const CompletionTimerContextProvider = ({ children }) => {
	// We may not need a state at all since the periodic processes are unable to access the latest versions of states.
	const [state, setState] = useState({
		error: null,
	});

	const startTimer = (completionID, role) => {
		// Stopping the old interval process if it was already running.
		if (_timerProcess !== null) clearInterval(_timerProcess);

		_completionID = completionID ? completionID : _completionID;
		_role = role ? role : _role;

		_calculatedDurationInSeconds = 0;
		_completionMetaDataHandler.init(completionID);
		_timerProcess = setInterval(mainTimerProcess, MAIN_PROCESS_INTERVAL_IN_MS);
	};

	const pauseTimer = () => {
		clearInterval(_timerProcess);
		_timerProcess = null;
	};

	const resumeTimer = () => {
		if (_timerProcess === null)
			_timerProcess = setInterval(
				mainTimerProcess,
				MAIN_PROCESS_INTERVAL_IN_MS,
			);
	};

	const stopTimer = (
		onFailureFlags = { reportError: false, restartTimer: false },
	) => {
		// Stopping the timer.
		clearInterval(_timerProcess);
		_timerProcess = null;

		// Sending the current value of the timer.
		return new Promise((resolve, reject) => {
			// We need to add the error margin since stopTimer can be called anywhere inside the (MAIN_PROCESS_INTERVAL_IN_MS / 1000) seconds period.
			// Error margin is not needed any more after decreasing the sampling time to 1 second.
			saveMeasuredHandleTime(false)
				.then(() => resolve())
				.catch((e) => {
					// Reporting the error to the state.
					if (onFailureFlags.reportError) {
						setState((ps) => ({
							...ps,
							error: formatError(e, 'Unable to save the task meta data.'),
						}));
					}

					// Restarting the timer again as the stop has failed.
					if (onFailureFlags.restartTimer) {
						startTimer();
					}

					// Reporting the promise failure.
					reject(e);
				});
		});
	};

	const clearError = () => setState((ps) => ({ ...ps, error: null }));

	// The main process for tracking the completion handle time.
	const mainTimerProcess = () => {
		// - In case the browser was put to sleep, the current function will not be called and the timer will not coninue incrementing.
		// - Because the function is not relying on storing start and end times, which are not accurate, it was designed to store small
		// increments of data so that the error in calculating time becomes as small as 5 seconds. To get what is meant by the error margin here,
		// think if the main process is called every minute instead every MAIN_PROCESS_INTERVAL_IN_MS, what should happen when the user opens a
		// completion and processes it for around 40 seconds and then navigates back to the project list? should we consider it a minute, 30 seconds, or none?
		// - On the other side, we can't increment the time at the backend second by second as this will drown the backend with calls.
		// - So the design was made to create an interval function called every 10 (MAIN_PROCESS_INTERVAL_IN_MS / 1000) seconds and when the increments reaches
		// a certain limit, 60 (TRANSMISSION_DURATION_IN_SEC) seconds, the backend is updated with the time increment.
		// - In the current implementation, when the user remains less than 10 (MAIN_PROCESS_INTERVAL_IN_MS / 1000) seconds on the completion,
		// the time is considered 5 (MAIN_PROCESS_INTERVAL_IN_MS / (1000 * MIN_FACTOR)) seconds which is a small error compared to 30 seconds.
		// - Start and end times based calculation are not accurate because they include the sleep period of the browser and up to this moment
		// there are no way to tell when the browser slept or woke up.

		// Incrementing the function by MAIN_PROCESS_INTERVAL milli-seconds since it is the interval the current function is called with.
		_calculatedDurationInSeconds += MAIN_PROCESS_INTERVAL_IN_MS / 1000;
		// The following part was commented based on Product Owner request and to decrease the load on the backend, the handling time will
		// be sent to the backend when the completion is submitted which is handled by stop timer.

		// // Checking if the calculated period reached the transmission to the backend limit or not.
		// if (_calculatedDurationInSeconds >= TRANSMISSION_THRESHOLD_IN_SEC)
		//     // No need to add the error margin since the _calculatedDuration has been just incremented and we are certain that the function is called at the
		//     // end of the 10 (MAIN_PROCESS_INTERVAL_IN_MS / 1000) seconds period.
		//     sendTimeDurationValue(false)
		//         .catch(e => {
		//             // Do nothing, the error shouldn't be reported as the the value is stored in the local storage waiting for it to be saved later.
		//         })
		//         .finally(() => {
		//             // Clearing the calculated duration variable whether the transmission is succcessfull or not to continue calculation in the next interval.
		//             // The value the variable gather is now recorded either in the backend tables or in the local storage.
		//             _calculatedDurationInSeconds = 0;
		//         });
	};

	// Sends the time to the backend or saves it to the local storage if sending has failed.
	const saveMeasuredHandleTime = (addErrorMargin) => {
		return new Promise((resolve, reject) => {
			// const start = Date.now(); // Used for performance testing.

			let calculatedDuration = _calculatedDurationInSeconds;
			// Example: If INTERVAL is 10,000 ms and the MIN_FACTOR is 2, the error margin will be 5 seconds.
			if (addErrorMargin)
				calculatedDuration += MAIN_PROCESS_INTERVAL_IN_MS / (1000 * MIN_FACTOR);

			// Checking the value saved in the local storage and including it in the time increment to be sent.
			const storedDuration =
				_completionMetaDataHandler.getCompletionStoredEntry();
			const toSendDuration = storedDuration
				? calculatedDuration + storedDuration
				: calculatedDuration;

			// Sending the increment of time to the backend.
			if (toSendDuration > 0) {
				completionApi
					.sendTimeIncrement(_completionID, _role, toSendDuration)
					.then(() => {
						// Clearing the value that was stored in the local storage as it is now successfully saved to the backend if there was any.
						if (storedDuration)
							_completionMetaDataHandler.clearCompletionStoredEntry();
						resolve();
					})
					.catch((e) => {
						if (e.response?.status === 403 && e.response.data.code === 1001) {
							reject(e.response);
						} else {
							// Updating the local storage with new value including _calculatedDurationInSeconds and the old value storedDuration.
							_completionMetaDataHandler.createOrUpdateCompletionStoredEntry(
								toSendDuration,
							);
							reject(e);
						}
					})
					.finally(() => {
						// Clearing the counter after either the duration is sent to the backend or saved in the
						// local storage.
						_calculatedDurationInSeconds = 0;
					});
			}
		});
	};

	const services = {
		...state,
		clearError,
		startTimer,
		pauseTimer,
		resumeTimer,
		stopTimer,
	};

	return (
		<CompletionTimerContext.Provider value={services}>
			{children}
		</CompletionTimerContext.Provider>
	);
};

const useCompletionTimerContext = () => useContext(CompletionTimerContext);

export { useCompletionTimerContext as default, CompletionTimerContextProvider };
