import React, { Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import {
	UserOutlined,
	TeamOutlined,
	ProjectOutlined,
	SyncOutlined,
} from '@ant-design/icons';

import {
	LOGIN_PATH,
	PM_PROJECTS_PATH,
	PM_USERS_PATH,
	USER_PROFILE,
} from '../../../routes';

import DownloadCenterModal from '../../PM/DownloadCenter/DownloadCenterModal';
import { SocketContextProvider } from '../../../contexts/SocketContext';
import { setShowDownloadCenterRequest } from '../../../store/downloadCenterSlice';

const PmMenu = ({ user, isRefreshingCookies, logout }) => {
	const location = useLocation();

	const selectedTab = location.pathname.startsWith(PM_USERS_PATH)
		? ['users']
		: location.pathname.startsWith(PM_PROJECTS_PATH)
		? ['projects']
		: location.pathname.startsWith(USER_PROFILE)
		? ['profile']
		: [];

	const dispatch = useDispatch();
	const history = useHistory();

	const menuItemsHandlers = {
		download_center: () => dispatch(setShowDownloadCenterRequest(true)),
		projects_list: () => history.push(PM_PROJECTS_PATH),
		users: () => history.push(PM_USERS_PATH),
		profile_details: () => history.push(USER_PROFILE),
		msa_manage: () => {
			window.location = '/app/service-agreement/create';
		},
		logout: () => {
			logout();
		},
	};

	const hanldeOnClickSubMenu = (e) => {
		menuItemsHandlers[e.key]();
	};

	const hasGrant = (grant) => user?.permissions?.includes?.(`${grant}`);
	const showMasterServiceAgreement =
		hasGrant('service-agreements:create') &&
		hasGrant('service-agreements:list');

	return (
		<Fragment>
			<Menu
				mode="horizontal"
				theme="light"
				className="nav-links-menu pm-menu"
				selectedKeys={selectedTab}
				onClick={hanldeOnClickSubMenu}
			>
				<Menu.SubMenu
					key="projects"
					icon={<ProjectOutlined />}
					title="Projects"
				>
					<Menu.ItemGroup>
						<Menu.Item key="projects_list">Projects List</Menu.Item>
						<Menu.Item key="download_center">Download Center</Menu.Item>
					</Menu.ItemGroup>
				</Menu.SubMenu>

				{showMasterServiceAgreement && (
					<Menu.Item key="msa_manage">Service Agreement</Menu.Item>
				)}

				{/* The link to the users management page for the PM */}
				<Menu.Item key="users" icon={<TeamOutlined />}>
					Users
				</Menu.Item>

				{/* The link to the user profile and logout */}
				<Menu.SubMenu
					key="profile"
					icon={isRefreshingCookies ? <SyncOutlined spin /> : <UserOutlined />}
				>
					<Menu.ItemGroup>
						<Menu.Item key="profile_details">Profile</Menu.Item>
						<Menu.Item key="logout">Logout</Menu.Item>
					</Menu.ItemGroup>
				</Menu.SubMenu>
			</Menu>
			<SocketContextProvider>
				<DownloadCenterModal />
			</SocketContextProvider>
		</Fragment>
	);
};

export default PmMenu;
