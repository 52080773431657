import React, { useState } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import CardActionButton from './CardActionButton';

import useNotificationsContext from '../../../../contexts/NotificationsContext';

const ClearNotification = ({ notificationID }) => {

    const { clearNotifications } = useNotificationsContext();
    const [state, setState] = useState({
        clearing: false,
    });

    const onClearNotification = () => {
        setState(ps => ({ ...ps, clearing: true }));
        // If the notifications clear is successful, the card is cleared, so 
        // there is no need to set clearing back to false unless the operation
        // fails.
        clearNotifications([notificationID])
            .catch(e => {
                setState(ps => ({ ...ps, clearing: false }));
                // For the user, the error will be that notification is not removed.
                console.log(e);
            });
    }

    return (
        <CardActionButton
            key='clear'
            text='Clear'
            loading={state.clearing}
            icon={<ClearOutlined style={{ fontSize: "80%" }} />}
            onClick={onClearNotification}
        />
    )

}

export default ClearNotification;