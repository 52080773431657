import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatError } from '../contexts/ResponseErrorFormatter';
import { DOWNLOAD_AUDIO_FILES_PRJECT_STATUS, SOCKET_TO_STATUS_TRANSLATOR } from '../Constants'
import projectsApi from '../api/projects';

// Starting to archive the audio file.
export const initDowloadProjectFilesSession = createAsyncThunk(
    'downloadCenter/prepareFilesOfProject',
    async (payload, { rejectWithValue }) => {
        const { projectID } = payload;
        try {
            const res = await projectsApi.initDownloadSession(projectID);
            const { uuid } = res.data;
            return { uuid }
        }
        catch (e) {
            const formattedError = formatError(e, `Failed to retrieve the download links for project ${projectID}!`);
            return rejectWithValue(formattedError);
        }
    }
)

// Get updates about initiated project.
export const getInitiatedProjectsUpdates = createAsyncThunk(
    'downloadCenter/getProjectUpdates',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await projectsApi.getInitiatedProjectsUpdates();
            const initiatedProjects = res.data;
            return initiatedProjects;
        }
        catch (e) {
            const formattedError = formatError(e, `Failed to retrieve projects updates!`);
            return rejectWithValue(formattedError);
        }
    }
)

const EMPTY_PROJECY_ENTRY = {
    status: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.NotStarted,
    archiveLinks: [],
    error: null,
    taskUuid: -1,
    timestamp: Date.now(),
}

const initialState = {
    // A key value pair dictionary for all the files being downloaded indexed by the project ID.
    projectList: {},
    // A flag which marks a request to show the download center component.
    requestShowDownloadCenter: false,
    projectsUpdates: {
        error: null
    }
}

const downloadCenterSlice = createSlice({
    name: 'downloadCenter',
    initialState,
    reducers: {
        // Update the status corresponding to a project ID.
        updateStatus: (state, action) => {
            const { projectID, status, timestamp } = action.payload;

            // Validating args.
            if (!Object.values(DOWNLOAD_AUDIO_FILES_PRJECT_STATUS).includes(status))
                throw new Error('Invalid status value');

            // Updating the project entry.
            const projectEntry = projectID in state.projectList ?
                { ...state.projectList[projectID], status, timestamp } :
                // Adding a new entry with the receved status with the received project ID.
                { ...EMPTY_PROJECY_ENTRY, status, timestamp };

            // Updating the project list.
            state.projectList = {
                ...state.projectList,
                [projectID]: projectEntry
            }
        },
        // Adding a link corresponding to one of the projectID's
        addReceivedLink: (state, action) => {
            const { projectID, link } = action.payload;

            // Constructing the project entry.
            let projectEntry = null;
            if (projectID in state.projectList) {
                const prevEntry = state.projectList[projectID];
                const prevLinks = prevEntry.archiveLinks || [];
                projectEntry = {
                    ...prevEntry,
                    archiveLinks: [...prevLinks, link]
                }
            }
            else {
                // Adding a new entry with the link for the received ID of the project.                
                projectEntry = {
                    ...EMPTY_PROJECY_ENTRY,
                    archiveLinks: [link]
                }
            }

            // Updating the state
            state.projectList = {
                ...state.projectList,
                [projectID]: projectEntry
            }
        },
        // Removes the project entry from state.
        removeProject: (state, action) => {
            const { projectID } = action.payload;
            const filteredProjectList = Object.entries(state.projectList)
                .filter(([key, _]) => key !== projectID);
            // Updating the prject list.
            state.projectList = { ...filteredProjectList };
        },
        // Sets the show modal request.
        setShowDownloadCenterRequest: (state, action) => {
            const flagValue = action.payload;
            state.requestShowDownloadCenter = flagValue;
        },
        // Removes any of the expired links from the project links.
        removeExpiredLinks: (state) => {
            state.projectList = Object.entries(state.projectList)
                .filter(([_, value]) => {
                    let result = true;
                    if (value.timestamp)
                        result = (Math.abs((new Date(value.timestamp)) - Date.now()) / 36e5) <= 24;
                    return result;
                })
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        }
    },
    extraReducers: {
        [initDowloadProjectFilesSession.pending]: (state, action) => {
            // Create or update the entry of the projet that the files are prepared for.
            const { projectID } = action.meta.arg;

            // Resetting the status and the error for an already created project entry
            if (projectID && projectID in state.projectList) {
                // Here is the project entry.
                const projectEntry = {
                    ...state.projectList[projectID],
                    status: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.NotStarted,
                    error: null,
                };
                // Updating the state.
                state.projectList = {
                    ...state.projectList,
                    [projectID]: projectEntry,
                }
            }
            // Adding a new entry in the projects list.
            else if (projectID) {
                state.projectList = {
                    ...state.projectList,
                    // Adding or resetting the project entry in the list.
                    [projectID]: { ...EMPTY_PROJECY_ENTRY },
                }
            }
        },

        [initDowloadProjectFilesSession.fulfilled]: (state, action) => {
            const { projectID } = action.meta.arg;
            const { uuid } = action.payload;

            // Updating the status of the project entry.
            const projectEntry = {
                ...state.projectList[projectID],
                status: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.PreparingArchives,
                taskUuid: uuid,
            };

            // Updating the state.
            state.projectList = {
                ...state.projectList,
                [projectID]: projectEntry,
            }
        },

        [initDowloadProjectFilesSession.rejected]: (state, action) => {
            const { projectID } = action.meta.arg;
            const error = action.payload;

            // Updating the error of the project entry.
            const projectEntry = {
                ...state.projectList[projectID],
                status: DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed,
                error
            };

            // Updating the state.
            state.projectList = {
                ...state.projectList,
                [projectID]: projectEntry,
            }
        },

        [getInitiatedProjectsUpdates.fulfilled]: (state, action) => {
            const initiatedProjects = action.payload;
            if (initiatedProjects && initiatedProjects.length > 0) {

                const updatedProjectsList = {};

                // Looping through the incoming result.
                initiatedProjects
                    .forEach(project => {
                        // Reading data of the project.
                        const { projectId, data, status, completedOn } = project;

                        // Constructing the project entry.
                        let projectEntry = null;

                        // Adding a new entry with the link for the received ID of the project.                
                        projectEntry = {
                            ...EMPTY_PROJECY_ENTRY,
                            archiveLinks: data.urls,
                            status: SOCKET_TO_STATUS_TRANSLATOR[status],
                            timestamp: completedOn,
                        }

                        // Setting the query result to the state.
                        updatedProjectsList[projectId] = projectEntry;
                    });

                // Updating the state.
                state.projectList = updatedProjectsList;
            }
        },

        [getInitiatedProjectsUpdates.rejected]: (state, action) => {
            // Updating the state.
            state.projectsUpdates = {
                ...state.projectsUpdates,
                error: action.payload
            }
        },
    }
})

export const {
    updateStatus,
    addReceivedLink,
    setProjectData,
    removeProject,
    setShowDownloadCenterRequest,
    removeExpiredLinks,
} = downloadCenterSlice.actions;


export default downloadCenterSlice.reducer;