import { lxtBackend } from "./utils";
import { IMPORT_ADHOC_STATUS } from "../Constants";
import BackgroundJobWorkingError from "../components/Error/BackgroundJobWorkingError";

export const adhocApi = {
    getAdhocs: async (projectId, skip, limit) => {

        let url = `pm/projects/${projectId}/ad-hoc-tasks`;
        let params = [];

        if (typeof skip === 'number') params.push(`skip=${skip}`);
        if (typeof limit === 'number') params.push(`limit=${limit}`);

        url = params.length > 0 ? `${url}?${params.join('&')}` : url;

        const { data } = await lxtBackend.get(url);
        return data
    },

    createAdhocEntery: async ({ projectId, adhocData }) => {
        const data = await lxtBackend.post(`pm/projects/${projectId}/ad-hoc-tasks`, adhocData);
        return data;
    },

    exportToFile: async ({ projectId, tasksIds }) => {
        const tasksParam = new URLSearchParams();
        const hasSelectedTasks = tasksIds?.length;
        if (hasSelectedTasks) {
            tasksIds.forEach( id => tasksParam.append("task_id", id) );
        }
        const data = await lxtBackend.get(`pm/projects/${projectId}/ad-hoc-tasks/export`, { responseType: 'blob', params: tasksParam });
        return data;
    },

    importFromFile: async ({ projectId, fileFormData }) => {
        const data = await lxtBackend.post(`pm/projects/${projectId}/ad-hoc-tasks/import`, fileFormData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return data;
    },

    updateAhocEntery: async ({ projectId, adhocTaskId, isApproved }) => {
        const data = await lxtBackend.patch(`pm/projects/${projectId}/ad-hoc-tasks/${adhocTaskId}`, isApproved);
        return data;
    },

    deleteAdhocEntery: async ({ projectId, adhocTaskId }) => {
        const data = await lxtBackend.delete(`pm/projects/${projectId}/ad-hoc-tasks/${adhocTaskId}`);
        return data;
    },

    getImportStatus: async ({ queueTaskId }) => {
        const { data } = await lxtBackend.get(`queue-tasks/${queueTaskId}/result`);

        const isImportSuccess = data?.statusCode === IMPORT_ADHOC_STATUS.success;
        const isImportFailure = data?.statusCode === IMPORT_ADHOC_STATUS.failure;

        if (!isImportSuccess && !isImportFailure) {
            throw new BackgroundJobWorkingError({ message: "Fetching data...", reason: data.status })
        } else {
            return data
        }

    },
}