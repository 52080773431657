import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive';
import { Button, message, Modal, Dropdown, Menu, Space, Checkbox } from "antd";
import { CheckOutlined, ExclamationCircleOutlined, ExclamationCircleFilled, IssuesCloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { isEmpty } from 'lodash-es';

import useCompletionContext from '../../../contexts/CompletionContext';
import { COMPLETION_DP_PATH } from '../../../routes';
import { COMPLETION_DP_ACTION } from '../../../Constants';
import '../../Styles/Button.scss';



const DPControlPanel = ({ beforeCommittingResult, afterCommittingResult }) =>
{
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    const { toUploadCompletionFiles, isUploadMinValid } = useSelector((state) => state.upload);
    const { toUploadCompletionDataTasks } = useSelector((state) => state.audio);
    const { toSubmitCompletionDataTasks } = useSelector((state) => state.textAreaLabels ?? {});
    const activeCompletionID = useSelector((state) => state.upload?.activeCompletionID ?? state.audio?.activeCompletionID ?? state.textAreaLabels?.activeCompletionID);


    const {
        completion,
        isSavingDraftResult,
        isCommentAdded,
        mediaPlaying,
        originalCompletionSelected,

        fetchNextCompletion,
        submitCompletionDP,
        skipCompletionDP,
        saveDraftResult,

        completionHasResult,
        completionHasValidResult,
        completionResultIsAllowed,
    } = useCompletionContext();

    const hasUploadTag = completion?.config?.includes('<Upload')

    let canSubmitUploadTag = hasUploadTag ? isUploadMinValid : true;

    const history = useHistory();

    const { projectId, compId } = useParams();

    const [state, setState] = useState({
        selectedSkipReasons: [],
        reasonsMenuVisible: false,
    });

    useEffect(() =>
    {
        // clearing the selected reasons with every completion update.
        setState(ps => ({ ...ps, selectedSkipReasons: [] }));
        // eslint-disable-next-line
    }, [completion]);


    const sendUserAction = async (userAction) =>
    {
        try
        {
            await beforeCommittingResult();

            if (userAction === COMPLETION_DP_ACTION.Skip)
            {
                const selectedSkipReasons = state.selectedSkipReasons.map(sr => sr.key);
                await skipCompletionDP(selectedSkipReasons);
                goToNextCompletion(userAction);
                return;
            }

            const uploadTagCompletion = toUploadCompletionFiles[activeCompletionID];
            const shouldSaveDraft = uploadTagCompletion && uploadTagCompletion?.isReviewer;

            const isADC = completion && completion.id && (completion.id in toUploadCompletionDataTasks);
            const isUploadTag = (uploadTagCompletion?.files && Object.keys(uploadTagCompletion.files).length > 0);

            await saveDraftResult(shouldSaveDraft);

            if (isADC || isUploadTag)
            {
                const isAsyncFunction = false;
                goToNextCompletion(userAction,);
                await submitCompletionDP(isAsyncFunction);
                return;

            } else
            {
                await submitCompletionDP();
                goToNextCompletion(userAction);
                return;
            }

        } catch (error)
        {
            if (error?.status === 403 && error?.data?.code === 1001)
            {
                Modal.info({
                    title: 'Project has updated consent, completion will reload to view newer consent version.',
                    icon: <ExclamationCircleOutlined />,
                    onOk()
                    {
                        window.location.reload(true);
                    }
                });
            }
        }
    };

    const goToNextCompletion = (userAction) =>
    {
        message.success(`Completion was ${userAction === COMPLETION_DP_ACTION.Submit ? 'submitted' : 'skipped'} successfully!`);

        if (afterCommittingResult)
            afterCommittingResult();

        // Retrieving the new completion.
        if (compId)
        {
            // Updating the path to contain only the project ID after completion result is saved of a
            // completion selected from assigned tasks, the rest of the current project's completions
            // are iterated in regular fashion.
            history.push(formatRoute(COMPLETION_DP_PATH, { projectId: projectId }));
        }
        else
        {
            // Fetching the next completion after submit.
            fetchNextCompletion(projectId, false);
        }
    };

    const invalidateTextAreaLabelResult = useCallback(() =>
    {
        const textAreaLabelsTextId = toSubmitCompletionDataTasks?.[activeCompletionID]?.getTextID;
        const getText = textAreaLabelsTextId && window[textAreaLabelsTextId];
        const isValidTextAreaLabelsResult = !isEmpty(getText?.()?.trim());

        return (textAreaLabelsTextId && !isValidTextAreaLabelsResult);

    }, [toSubmitCompletionDataTasks, activeCompletionID]);

    const handleSubmit = async () =>
    {

        if (invalidateTextAreaLabelResult())
        {
            message.error("Completion must have a result to be submitted!");
            return;
        }

        const hasResult = completionHasResult();
        const hasValidResult = completionHasValidResult();
        const hasSkipReasons = completion && completion.skipReasons && Object.keys(completion.skipReasons).length > 0;

        // Validating the completion has a result.
        if (hasSkipReasons && !hasResult)
        {
            message.error("Completion must have a result to be submitted!");
            return;
        }

        // Supporting old types of projects which don't have skip reasons. In this case
        // user is asked to input either a result or comment.
        if (!hasSkipReasons && !hasResult && !isCommentAdded)
        {
            message.error("Completion must have a result or a comment to be submitted!");
            return;
        }

        // Validating the result is complete and has a valid result..
        if (!hasValidResult)
        {
            message.error("Completion input is incomplete!");
            return;
        }

        // Validating the result is not a duplicate of previous results if this was required.
        const isResultAllowed = completionResultIsAllowed();
        if (!isResultAllowed)
        {
            message.error("Duplicate results are not allowed for this project!");
            return;
        }

        // Asking the user to confirm the submission action.
        Modal.confirm({
            title: 'Are you sure you want to submit?',
            icon: <ExclamationCircleOutlined />,
            onOk()
            {
                sendUserAction(COMPLETION_DP_ACTION.Submit);
            }
        });
    };

    const handleCommitSkipButtonOnClick = () =>
    {

        // Validating that a comment is added in case of other is selected.
        const otherIsSelected = state.selectedSkipReasons.find(sr => sr.requiresComment) !== undefined;

        if (otherIsSelected && !isCommentAdded)
        {
            message.error('Selecting Other as a skip reason requires leaving a comment!');
            return;
        }

        // Closing reasons menu.
        setState(ps => ({ ...ps, reasonsMenuVisible: false }));

        // Showing the action confirmation modal.
        Modal.confirm({
            title: 'Are you sure you want to skip the completion for the following reasons?',
            icon: <ExclamationCircleOutlined />,
            content: <ul>{state.selectedSkipReasons.map(sr => <li key={nanoid()}>{sr.name}</li>)}</ul>,
            onOk()
            {
                sendUserAction(COMPLETION_DP_ACTION.Skip);
            },
        });
    };

    const handleSkipReasonSelected = (skipReason, checked) =>
    {
        // Adding or removing the skip reason based on the checked state.
        setState(ps => ({
            ...ps,
            selectedSkipReasons: checked ?
                [...ps.selectedSkipReasons, skipReason] :
                ps.selectedSkipReasons.filter(sr => sr.key !== skipReason.key)
        }));
    };

    const handleShowRejectedForReasons = (reasons) =>
    {

        const contents = (
            <Fragment>
                <span>Reason(s) of rejection:</span>
                <ul>{reasons.map(r => <li key={nanoid()}>{r}</li>)}</ul>
            </Fragment>
        );

        Modal.error({
            title: 'Completion Was Rejected',
            content: contents,
            width: isTabletOrMobile ? '100%' : '40%',
        });
    };

    const handleReasonsMenuOnVisibleChange = e => setState(ps => ({ ...ps, reasonsMenuVisible: e }));



    const skipReasons = completion && completion.skipReasons ? completion.skipReasons : {};
    const rejectedForReasons = completion && completion.rejectedForReasons && Array.isArray(completion.rejectedForReasons) ? completion.rejectedForReasons : [];
    const menuOverlay = (
        <Menu>

            {Object.keys(skipReasons).length > 0 &&
                Object.keys(skipReasons).map(skrKey =>
                    <Menu.Item key={skrKey}>
                        <Checkbox
                            onChange={e =>
                            {
                                const selectedObject = {
                                    key: skrKey,
                                    name: skipReasons[skrKey].name ? skipReasons[skrKey].name : 'Skip Reason',
                                    requiresComment: skipReasons[skrKey].requires_comment ? true : false,
                                };
                                handleSkipReasonSelected(selectedObject, e.target.checked);
                            }}>
                            {skipReasons[skrKey].name}
                        </Checkbox>
                    </Menu.Item>
                )
            }

            <Menu.Item key={'confirm'}>
                <Button
                    type='danger'
                    size='small'
                    style={{ width: '100%' }}
                    onClick={handleCommitSkipButtonOnClick}
                    disabled={state.selectedSkipReasons.length === 0}
                >
                    Skip
                </Button>
            </Menu.Item>

        </Menu>
    );

    return (
        <Fragment>

            <Space>

                {/* The list of the skip reasons which is available for the data processor to select from. If the list is not provided by the PM, the button will not be shown. */}
                {Object.keys(skipReasons).length > 0 &&
                    <Dropdown
                        overlay={menuOverlay}
                        disabled={isSavingDraftResult || !originalCompletionSelected || mediaPlaying}
                        visible={state.reasonsMenuVisible}
                        trigger={['click']}
                        onVisibleChange={handleReasonsMenuOnVisibleChange}
                    >
                        <Button
                            shape={isTabletOrMobile ? 'circle' : 'default'}
                            size={isTabletOrMobile ? 'middle' : 'small'}
                            type='danger'
                            icon={<IssuesCloseOutlined />}>
                            {isTabletOrMobile ? '' : 'Skip'}
                        </Button>
                    </Dropdown>
                }

                {/* Completion submission button. */}
                <Button
                    type="primary"
                    shape={isTabletOrMobile ? 'circle' : 'default'}
                    size={isTabletOrMobile ? 'middle' : 'small'}
                    icon={<CheckOutlined />}
                    onClick={handleSubmit}
                    disabled={isSavingDraftResult || !originalCompletionSelected || mediaPlaying || !canSubmitUploadTag}
                >
                    {isTabletOrMobile ? '' : 'Submit'}
                </Button>

                {/* The button to show the reasons of completion rejection selected by the reviewer. */}
                {rejectedForReasons.length > 0 &&
                    <Button
                        danger
                        type='text'
                        size='small'
                        icon={<ExclamationCircleFilled />}
                        onClick={() => handleShowRejectedForReasons(rejectedForReasons)}
                        disabled={!originalCompletionSelected}>
                        Completion Was Rejected
                    </Button>
                }

            </Space>

        </Fragment>
    );
};

export default DPControlPanel;
