import { useMemo } from "react";
import { Form } from "antd";
import { MultiSelectField } from "../../PM/SelectExt";
import ErrorAlert from "../../Error/ErrorAlert";
import useOptionsContext from '../../../contexts/OptionsContext';

const { Item } = Form;

const NativeLanguage = ({ onLanguageChange, nativeLanguage, otherLanguages }) => {

    const {
        languages,
        languagesError,
    } = useOptionsContext();

    const filteredLanguages = useMemo(() => languages
        .map(lg => ({ value: lg.id, key: lg.id, label: lg.name }))
        .filter(lang => !otherLanguages.includes(lang.value))
        , [languages, otherLanguages])

    return (
        <Item
            label="Native Language"
            name="nativeLanguage"
            rules={[
                {
                    required: nativeLanguage === undefined,
                    message: "Please set your native language!",
                }
            ]}>
            <MultiSelectField
                selectedTags={nativeLanguage}
                onTagsChange={onLanguageChange}
                placeholder="Set your languages here..."
                selectMode="single"
                options={filteredLanguages}
            />
            <ErrorAlert error={languagesError} />
        </Item>
    )
}

export default NativeLanguage;