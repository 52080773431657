import { Button } from 'antd';

const CardActionButton = ({ text, icon, onClick, loading }) => {
    return (
        <Button
            size='small'
            type='link'
            loading={loading}
            icon={icon}
            onClick={onClick}>
            {text}
        </Button>
    )
}

export default CardActionButton;