import React, { Fragment, useState } from 'react';
import {Form, Button, Upload, Space, Select} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {nanoid} from "nanoid";

const { Item } = Form;

const TasksUpload = ({ onFileSelected }) => {

    const [filePath, setFilePath] = useState('');

    return (
        <Fragment>

            <Item
              label="Storage Location"
              name="storageConfiguration">
                <Select
                  defaultValue='us' // TODO: default value should be sent from the frontend, currently it's just displayed
                  options={[{value: 'us', label: 'us', key: nanoid()}, { value: 'eu', label: 'eu', key: nanoid()}]}
                />
            </Item>

            <Item
                name='tasksFile'
                label="Task Files"
                rules={[
                    {
                        required: true,
                        message: "Please select the tasks file!",
                    },
                ]}>

                {/* Upload Tasks */}
                <Upload
                    beforeUpload={() => false}//Required to stop antd default post request
                    onChange={info => {
                        let selectedFileIndex = info.fileList.length - 1;
                        onFileSelected(info.fileList[selectedFileIndex].originFileObj);
                        setFilePath(info.fileList[selectedFileIndex].name);
                    }}
                    showUploadList={false}>
                    <Space>
                        <Button
                            type='primary'>
                            <UploadOutlined /> Upload Tasks File </Button>
                        <span>{filePath}</span>
                    </Space>
                </Upload>

            </Item>

        </Fragment>
    )
}

export default TasksUpload
