import React, { useContext, createContext, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';

import config from '../configuration/config';
import { updateStatus, addReceivedLink } from '../store/downloadCenterSlice';
import { SOCKET_TO_STATUS_TRANSLATOR } from '../Constants';

const SocketContext = createContext(null);

let _socket = null;
const URL = process.env.NODE_ENV === 'production' ?
    `${config.lxt_backend}users` :
    'http://localhost:49255';

const SocketContextProvider = ({ children }) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        error: null,
    });

    /**
     * Socket will only be initiated if no socket is opened/exists
     */
    const initAppSocket = useCallback(() => {
        if (_socket === null) {
            try {
                const csrfToken = getCookieValue("XSRF-TOKEN");

                _socket = io(URL, {
                    withCredentials: true,
                    rejectUnauthorized: process.env.NODE_ENV === 'production',
                    transports: ['websocket'],
                    path: process.env.NODE_ENV === 'production' ? "/api/socketio" : '',
                    reconnectionDelay: 1000,
                    auth: {
                        'X-XSRF-TOKEN': csrfToken,
                    }
                });

                _socket.on("connect", () => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Client socket is connected!!!');
                        console.log('Socket ID:', _socket.id);
                    }
                    // Nothing more needed to be done here.
                })

                _socket.on('connect_error', args => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Error in clinet socket connection.');
                        console.log(args);
                    }
                    // socket.io in this case will try to reconnect automatically with the socket.
                })

                _socket.on('disconnect', () => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Socket is disconnected!!!');
                    }
                    // Nothing more needed here whent the socket is disconnected.
                })

                _socket.on('status_update', args => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Received status_update', args);
                    }
                    let { projectID, status, timestamp } = args;
                    if (status in SOCKET_TO_STATUS_TRANSLATOR) {
                        status = SOCKET_TO_STATUS_TRANSLATOR[status]
                        dispatch(updateStatus({ projectID, status, timestamp }));
                    }
                    else {
                        // Do Nothing, the received status is not recognized.
                    }
                });

                _socket.on('link_ready', args => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('Received link_ready', args);
                    }
                    const { projectID, link } = args;
                    if (link)
                        dispatch(addReceivedLink({ projectID, link }));
                });
            }
            catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log('Error in socket creation:');
                    console.log(e);
                }
                setState(ps => ({ ...ps, error: { message: "Failed to initialize application socket" } }));
            }
        }
        else {
            // DO nothing, the socket is already initialized.
        }
        // eslint-disable-next-line
    }, []);

    const closeAppSocket = useCallback(() => {
        if (_socket !== null) {
            try {
                _socket.close();
                _socket = null;
            }
            catch (e) {
                setState(ps => ({ ...ps, error: { message: "Failed to initialize application socket" } }));
            }
        }
        else {
            // Do nothing, the socket is already closed.
        }
        // eslint-disable-next-line
    }, []);

    return (
        <SocketContext.Provider value={{ initAppSocket, closeAppSocket }} >
            {children}
        </SocketContext.Provider>
    )
}

const getCookieValue = (name) => {
    const result = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    return result;
}

const useSocketContext = () => useContext(SocketContext);

export { useSocketContext as default, SocketContextProvider }