import React from "react";
import { CheckCircleFilled, CloseCircleFilled, MinusCircleFilled } from "@ant-design/icons";

const primaryColor = '#081f2d'
const trueColor = '#2CB280'
const falseColor = '#f5222d'
const disabledColor = '#d9d9d9'


const TrueIcon = ({ iconSize }) => <CheckCircleFilled style={{ color: trueColor, fontSize: iconSize }} />;
const FalseIcon = ({ iconSize }) => <CloseCircleFilled style={{ color: falseColor, fontSize: iconSize }} />;
const AssignedIcon = () => <CheckCircleFilled size='large' style={{ color: primaryColor, fontSize: '1.5rem' }} />;
const NotAssignedIcon = () => <MinusCircleFilled style={{ color: disabledColor, fontSize: '1.5rem' }} />;

export { TrueIcon, FalseIcon, AssignedIcon, NotAssignedIcon } 