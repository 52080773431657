import React, { Fragment } from 'react';
import { Row, Col, Divider, Typography } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'

import FieldLabel from '../Common/FieldLabel';

const { Text } = Typography;

const LabelColumn = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const OptionalText = styled(Text)`
    font-size: 12px;
`;

const ProfileEntry = ({ label, value, controls, hasBorder, optional }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    const controlsColSpan = isTabletOrMobile && 4
    const labelColSpan = isTabletOrMobile ? 9 : 6
    const valueColSpan = isTabletOrMobile ? 11 : 6
    const rowGutter = isTabletOrMobile ? 32 : 16
    const rowPadding = isTabletOrMobile ? '0px' : '40px'

    return (
        <Fragment>
            <Row gutter={rowGutter} style={{ paddingLeft: rowPadding, alignItems: 'center', wordBreak: 'break-word' }}>
                <LabelColumn span={labelColSpan}>
                    <FieldLabel text={label} />
                    {optional && <OptionalText type="secondary">(optional)</OptionalText>}
                </LabelColumn>
                <Col span={valueColSpan} style={isTabletOrMobile && { padding: '0px' }}>
                    {value}
                </Col>

                <Col span={controlsColSpan} style={{ padding: '0px' }}>
                    {controls}
                </Col>
            </Row>
            {hasBorder && <Divider />}
        </Fragment>
    )
}

export default ProfileEntry;
