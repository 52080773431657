import React, { useState, useCallback, useEffect } from 'react';
import DOMPurify from 'dompurify';

const HtmlViewer = ({ file,url }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
		if (url) {
			fetch(url)
				.then((response) => response.text())
				.then((textHTML) =>
				{
						const sanitizedContent = DOMPurify.sanitize(textHTML);
						setContent(sanitizedContent);
				})
				.catch((error) => console.error('Error fetching HTML file:', error));
		}
	}, [url]);

  const handlefile = useCallback((file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file.originFileObj);
      reader.onload = () => {
        const stringifiedHTML = reader.result;
        const sanitizedContent = DOMPurify.sanitize(stringifiedHTML);
        setContent(sanitizedContent);
      };
    }
  }, []);

  if (file && !url) {
    handlefile(file);
	}

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HtmlViewer;
