import { useEffect } from "react";

const GA_SCRIPT = `window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-4QN8PH3VTH');`;

const GoogleTagManager = () => {

    useEffect(() => {
        const noGtmScriptTag = !document.getElementById('gtm')
        const isEnvProd = process.env.NODE_ENV === 'production';
        if(noGtmScriptTag && isEnvProd) {
            const gtagElement = document.createElement('script');
            gtagElement.setAttribute('async', true);
            gtagElement.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4QN8PH3VTH');

            const gtmElement = document.createElement('script');
            gtmElement.setAttribute('id', 'gtm');
            gtmElement.text = GA_SCRIPT;
            document.head.appendChild(gtagElement);
            document.head.appendChild(gtmElement);
        }
    }, []);

    return null;
}

export default GoogleTagManager;