/**
 * Libraries
 */
import React, { Component } from "react";
import { Result, Spin, Button } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { getEnv } from "mobx-state-tree";
import { observer, Provider } from "mobx-react";

/**
 * Core
 */
import Tree from "../../LSF/core/Tree";

/**
 * Components
 */
import Controls from "../../LSF/components/Controls/Controls";
import Debug from "../../LSF/components/Debug";
import Panel from "../../LSF/components/Panel/Panel";
import Segment from "../../LSF/components/Segment/Segment";
import Settings from "../../LSF/components/Settings/Settings";
import { RelationsOverlay } from "../../LSF/components/RelationsOverlay/RelationsOverlay";
import CompletionResultsPanel from "./CompletionResultsPanel";
import CompletionResults from "./CompletionResults";


/**
 * Tags
 */
import * as ObjectTags from "../../LSF/tags/object"; // eslint-disable-line no-unused-vars
import * as ControlTags from "../../LSF/tags/control"; // eslint-disable-line no-unused-vars
import * as VisualTags from "../../LSF/tags/visual"; // eslint-disable-line no-unused-vars

/**
 * Styles
 */
import styles from "./LabelStudioView.scss";
import { TreeValidation } from "../../LSF/components/TreeValidation/TreeValidation";
import { guidGenerator } from "../../LSF/utils/unique";

/**
 * The LSF Main View
 */
class LabelStudioView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showResults: false,
            isSpinning: true
        };
    }

    renderSuccess() {
        return <Result status="success" title={getEnv(this.props.store).messages.DONE} />;
    }

    renderNoCompletion() {
        return <Result status="success" title={getEnv(this.props.store).messages.NO_COMP_LEFT} />;
    }

    renderNothingToLabel() {
        return <Result status="success" title={getEnv(this.props.store).messages.NO_NEXT_TASK} />;
    }

    renderNoAccess() {
        return <Result status="warning" title={getEnv(this.props.store).messages.NO_ACCESS} />;
    }

    renderConfigValidationException() {
        return (
            <Segment>
                <TreeValidation errors={this.props.store.completionStore.validation} />
            </Segment>
        );
    }

    renderLoader() {
        return <Result icon={<Spin size="large" />} />;
    }

    _renderAll(obj) {
        if (obj.length === 1) return <Segment completion={obj[0]}>{[Tree.renderItem(obj[0].root)]}</Segment>;

        return (
            <div className="ls-renderall">
                {obj.map(c => (
                    <div className="ls-fade">
                        <Segment completion={c}>{[Tree.renderItem(c.root)]}</Segment>
                    </div>
                ))}
            </div>
        );
    }

    _renderUI(root, store, cs, settings, isCustomTags, isTabletOrMobile) {
        return (
            <>
                {!cs.viewingAllCompletions && !cs.viewingAllPredictions && (
                    <Segment
                        isCustomTags={isCustomTags}
                        completion={cs.selected}
                        isTabletOrMobile={isTabletOrMobile}
                        className={settings.bottomSidePanel ? "" : styles.segment + " ls-segment"}
                    >
                        <div style={{ position: "relative" }}>
                            {Tree.renderItem(root)}
                            {this.renderRelations(cs.selected)}
                        </div>
                        {store.hasInterface("controls") && <Controls item={cs.selected} />}
                    </Segment>
                )}
                {cs.viewingAllCompletions && this.renderAllCompletions()}
                {cs.viewingAllPredictions && this.renderAllPredictions()}
            </>
        );
    }

    renderAllCompletions() {
        return this._renderAll(this.props.store.completionStore.completions);
    }

    renderAllPredictions() {
        return this._renderAll(this.props.store.completionStore.predictions);
    }

    renderRelations(selectedStore) {
        const store = selectedStore.relationStore;
        return <RelationsOverlay key={guidGenerator()} store={store} />;
    }

    render() {
        const self = this;
        const { store, isTabletOrMobile } = self.props;
        const cs = store.completionStore;
        const root = cs.selected && cs.selected.root;
        const { settings } = store;

        //const isADC = cs?.selected?.objects[0]?.type === "record"
        const isCustomTags = cs?.selected?.objects[0]?.type === "record" || cs?.selected?.objects[0]?.type === "upload";


        if (store.isLoading) return self.renderLoader();

        if (store.noTask) return self.renderNothingToLabel();

        if (store.noAccess) return self.renderNoAccess();

        if (store.labeledSuccess) return self.renderSuccess();

        if (!root) return self.renderNoCompletion();

        const stEditor = settings.fullscreen ? styles.editorfs : styles.editor;
        const stCommon = settings.bottomSidePanel ? styles.commonbsp : styles.common;

        return (
            <div className={stEditor + " ls-editor"}>
                <Settings store={store} />
                <Provider store={store}>

                    {!this.state.showResults && <div>
                        {store.hasInterface("panel") && <Panel store={store} />}
                        {/**Commenting this part to avoid usage of dangerouslySetInnerHTML*/}
                        {/*store.showingDescription && (
                            <Segment>
                                <div dangerouslySetInnerHTML={{ __html: store.description }} />
                            </Segment>
                        )*/}

                        {/* <div className={styles.pins}> */}
                        {/*   <div style={{ width: "100%", marginRight: "20px" }}><PushpinOutlined /></div> */}
                        {/*   <div className={styles.pinsright}><PushpinOutlined /></div> */}
                        {/* </div> */}
                        <div className={this.props.columView ? stCommon : stCommon + " ls-common"}>
                            <div className={'ls-main'}>
                                {cs.validation === null
                                    ? this._renderUI(root, store, cs, settings, isCustomTags, isTabletOrMobile)
                                    : this.renderConfigValidationException()}
                            </div>
                            {/**Desktop completion results */}
                            {!isTabletOrMobile && !isCustomTags && <CompletionResultsPanel store={store} />}
                        </div>
                    </div>}

                    {/**Mobile completion results */}
                    {this.state.showResults &&
                        <Spin spinning={this.state.isSpinning}>
                            <CompletionResults store={store} />
                        </Spin>}

                    {/**Show Result button for Mobile*/}
                    {isTabletOrMobile && !isCustomTags &&
                        <Button
                            style={{ position: 'sticky', left: '90%' }}
                            icon={<DoubleLeftOutlined rotate={this.state.showResults ? '180' : '0'} />}
                            shape="circle"
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    showResults: !this.state.showResults
                                })

                                setTimeout(() => {
                                    this.setState({
                                        ...this.state,
                                        isSpinning: !this.state.isSpinning
                                    })
                                }, 1000);
                            }
                            }
                        />
                    }

                </Provider>
                {store.hasInterface("debug") && <Debug store={store} />}
            </div>
        );
    }
}

export default observer(LabelStudioView);
