import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Input, Button, Modal, Row, Col, Tooltip, Spin, message } from 'antd';
import { CommentOutlined, SendOutlined } from '@ant-design/icons';

import { ClosableErrorAlert } from '../../../Error/ErrorAlert';
import CommentList from './CommentList';
import useCompletionContext from '../../../../contexts/CompletionContext';
import useCommentsContext, { CommentsContextProvider } from './CommentsContext';

const CommentsSection = ({ contributorType }) => {

    const {
        completion,
        submitComment,
    } = useCompletionContext();

    const {
        loading,
        error,
        setLoading,
        setError,
    } = useCommentsContext();

    const [state, setState] = useState({
        commentInputText: '',
    });



    const handleOnAddButtonClick = () => {
        const trimmedComment = state.commentInputText.trim();
        if (trimmedComment) {

            // Creating the comment object which will be sent to the backend.
            const commentToAdd = {
                text: trimmedComment,
                role: contributorType,
            }

            // Setting the loading flag.
            setLoading(true);

            // Saving the new comment to the backend.
            submitComment(commentToAdd, contributorType)
                .then(() => {
                    message.success("Comment was added successfully");
                    setState(ps => ({ ...ps, commentInputText: '' }))
                })
                .catch(e => {
                    setError(e);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            message.warning("Please add a comment!");
        }
    }

    const handleOnCommentChange = (e) => {
        const { value } = e.target;
        setState(ps => ({ ...ps, commentInputText: value }));
    }

    const handleOnErrorAlertCloseClick = () => {
        setError(null);
    }



    return (
        <Spin spinning={loading}>
            <div>

                {/* Handling Of Errors */}
                <ClosableErrorAlert error={error} onClose={handleOnErrorAlertCloseClick} />

                {/* Displaying comments */}
                {completion && <CommentList comments={completion.comments} />}

                {/* Comment Input Section */}
                <Row align='middle' justify='center' gutter={[0, 0]}>
                    <Col span={20}>
                        {/* Text-Area for adding comment */}
                        <Input.TextArea
                            dir='auto'
                            autoSize={true}
                            placeholder="Enter any comments for the completion here if required..."
                            value={state.commentInputText}
                            onChange={handleOnCommentChange}
                            style={{
                                padding: '10px',
                                borderRadius: "20px",
                                borderColor: "#f5f5f5",
                                backgroundColor: "#f5f5f5"
                            }}
                        />
                    </Col>

                    <Col span={2}>
                        {/* Add comment button */}
                        <Tooltip title='Save Comment'>
                            <Button
                                type="text"
                                size="large"
                                disabled={!state.commentInputText}
                                icon={<SendOutlined style={{ fontSize: '1.5rem' }} />}
                                onClick={handleOnAddButtonClick}
                            />
                        </Tooltip>
                    </Col>

                </Row>
            </div>
        </Spin>
    )
}

const CommentsModal = ({ visible, onDone, contributorType }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    return (
        <CommentsContextProvider>
            <Modal
                title={<span><CommentOutlined /> Comments</span>}
                width={isTabletOrMobile ? '100%' : '60%'}
                visible={visible}
                onCancel={onDone}
                footer={null}>
                <CommentsSection contributorType={contributorType} />
            </Modal>
        </CommentsContextProvider>
    )
}

export { CommentsSection as default, CommentsModal }