import React, { Fragment, useState } from "react";
import { Switch, Form, Input } from "antd";
import RichGuidelinesEditor, { isEmptyEditorValue } from '../../Common/RichGuidelinesEditor';

const { Item } = Form;

const ConsentAgreement = ({ projectServiceValue }) => {
    const [state, setState] = useState({
        activateAgreement: false
    })


    const onChange = (checked) => {
        setState(ps => ({ ...ps, activateAgreement: checked }));
    };

    const servicesRequireConsent = [
        'Audio Data Collection',
        'Image Data Collection',
        'Video Data Collection',
        'Text Data Collection',
        'Generic Data Collection'
    ]

    return (
        <Fragment>
            {!servicesRequireConsent.includes(projectServiceValue) && <div style={{ marginBottom: '20px' }}>
                <text style={{ marginRight: '10px' }}>Activate Agreement:</text> <Switch onChange={onChange} />
            </div>}

            {(servicesRequireConsent.includes(projectServiceValue) || state.activateAgreement) &&
                <div>
                    <Item
                        labelCol
                        label='Agree'
                        name="agree"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please input agree button name!",
                            },
                        ]}>
                        <Input
                            placeholder="Type agree button display name" style={{ marginLeft: '17px' }} />
                    </Item>

                    <Item
                        labelCol
                        label='Disagree'
                        name="disagree"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please input disagree button name!",
                            },
                        ]}>
                        <Input placeholder="Type disagree button dispaly name" />
                    </Item>

                    <Item
                        labelCol
                        wrapperCol
                        name="agreement"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    return isEmptyEditorValue(value) ?
                                        Promise.reject(new Error("Please set the project agreement details!")) :
                                        Promise.resolve();
                                },
                            })
                        ]}
                    >
                        <RichGuidelinesEditor placeholder='Add here the participant agreement of the project...' />
                    </Item>
                </div>
            }
        </Fragment>
    )
}

export default ConsentAgreement;