import React from 'react';
import sanitizeHtml from 'sanitize-html';
import JoditEditor from "jodit-react";


const SANITIZATION_CONFIGURATION = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe', 'embed']),
    allowedSchemesByTag: {
        img: ['data']
    },
    allowedAttributes: {
        'p': ['style'],
        'span': ['style'],
        'li': ['style'],
        'a': ['href', 'style'],
        'img': ['src', 'style'],
        's': ['style'],
        'strong': ['style'],
        'iframe': ['src', 'style', 'width', 'height',],
        'h1': ['style'],
        'h2': ['style'],
        'h3': ['style'],
        'h4': ['style'],
        'table': ['style'],
        'td': ['style'],
        'tr': ['style'],
        'blockquote': ['style'],
        'embed': ['src', 'width', 'height']
    },
    disallowedTagsMode: 'escape',
    selfClosing: ['img', 'embed', 'iframe'],
}

// The following is not needed as the feature of rich text editor has been delayed.
export const isEmptyEditorValue = (value) => {
    //checking for spaces and line breaks, then replacing them with empty string
    value = value.replace(/&nbsp;|<br>/g, '').replace(/ /g, '')
    const isEmpty = !value;//TODO will include a package to get the text out of the html or implement it ourselves.
    return isEmpty;
}

export const sanitizeText = (input) => {
    return new Promise((resolve) => {
        const cleanGuidelines = sanitizeHtml(input, SANITIZATION_CONFIGURATION);
        resolve(cleanGuidelines);
    })
}

// The display component.
export const RichGuidelinesDisplay = ({ value }) => {
    return (
        <div dir='auto' style={{ width: '100%' }}>
            <JoditEditor
                value={value}
                config={{
                    useSearch: false,
                    readonly: true,
                    direction: '',
                    language: 'auto',
                    activeButtonsInReadOnly: [],
                    toolbar: false,
                    showCharsCounter: false,
                    showWordsCounter: false,
                    showXPathInStatusbar: false,
                }}
            />
        </div>
    )
}

// The editor.
const RichGuidelinesEditor = ({ value, onChange, placeholder = '' }) => {
    return (
        <div dir='auto' style={{ width: '83vw' }}>
            <JoditEditor
                value={value}
                config={{
                    useSearch: false,
                    placeholder,
                    readonly: false,
                    direction: '',
                    language: 'auto',
                    spellcheck: true,
                    removeButtons: ['fullsize', 'print', 'about', 'find', 'image', 'file', 'classSpan'],

                    showCharsCounter: false,
                    showWordsCounter: false,
                    showXPathInStatusbar: false,

                    askBeforePasteHTML: false,
                    askBeforePasteFromWord: false,
                    defaultActionOnPaste: "insert_clear_html",
                }}
                onBlur={newContent => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={() => { }} // Intended to be left empty.
            />
        </div>
    );
}

export default RichGuidelinesEditor;
