import { useSelector } from "react-redux";
import { UPLOAD_STATES } from "../Constants";

const getExcludeIDs = (state) => {
    const { upload } = state;
    const uploadExcludeIds = { ...upload.toUploadCompletionFiles };
		const excludedStates = [undefined ,UPLOAD_STATES.Uploading, UPLOAD_STATES.Pending, UPLOAD_STATES.Validating, UPLOAD_STATES.Preparing];

    let excludeIds = new Set;
    Object.entries(uploadExcludeIds).forEach(([key, value]) => {
        if (value.files) {
            Object.values(value.files).forEach(file => {
							console.log('Excluded Status', excludedStates.includes(file.status) , file.status);
							if (excludedStates.includes(file.status))
                    excludeIds.add(parseInt(key));
            });
        }
    })
	console.log(Array.from(excludeIds));
	return Array.from(excludeIds);
}

const useExecludeIds = () => {
    return useSelector(getExcludeIDs);
}

export default useExecludeIds;
