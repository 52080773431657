import { useContext, useState, useCallback } from 'react';
import { Table, Space, Pagination } from 'antd';

import ErrorPage from '../../../Error/ErrorPage';
import ErrorModal from '../../../Error/ErrorModal';
import { formatError } from '../../../../contexts/ResponseErrorFormatter';

import { useProjectCostData } from '../../../../api/queries/useUnitAndCostQuery';
import { useCostExport } from '../../../../api/mutations/useUnitAndCostMutations';
import { IfFeature } from '../../../../lib/featureToggles';

import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';
import CostTableControlButtons from './CostTableControlButtons';
import { isEqual } from 'lodash-es';


const factoryCostTableColumns = (sortedInfo) => {
    const columns = [
        {
            title: 'ID',
            key: 'id',
            sorter: () => undefined, // Since the sorting takes place in the backend.
            sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => record?.contributor?.id,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                const firstName = record?.contributor?.firstName
                const lastName = record?.contributor?.lastName
                const fullName = `${firstName} ${lastName}`

                return fullName;
            }
        },
        {
            title: 'Email',
            key: 'email',
            render: (_, record) => record?.contributor?.email,
        },
        {
            title: 'Data Processor',
            dataIndex: 'dataProcessingCost',
            key: 'dataProcessingCost',
            align: 'center',
        },
        {
            title: 'Reviewer',
            dataIndex: 'reviewCost',
            key: 'reviewCost',
            align: 'center',
        },
        {
            title: 'Ad hoc',
            dataIndex: 'adHocCost',
            key: 'adHocCost',
            align: 'center',
        },
        {
            title: 'Total',
            key: 'total',
            align: 'center',
            render: (_, record) => record?.totalCost
        },
    ];

    return columns
}

const CostTable = () => {

    const {
        selectedProject,
    } = useContext(PMPrjSingleContext);

    const [pagination, setPagination] = useState({
        skip: 0,
        limit: 10
    })

    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedContributorsIds, setSelectedContributorsIds] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const {
        data: projectCostQueryData,
        isLoading: isProjectCostLoading,
        error: projectCostError,
        isError: isProjectCostError,

    } = useProjectCostData({
        projectId: selectedProject?.id,
        skip: pagination.skip,
        limit: pagination.limit,
        searchTerm,
        sortedInfo,
    });

    const {
        mutate: mutateExport,
        error: exportError,
        isError: isExportError,
        isLoading: isExporting } = useCostExport({ selectedProject, onError: showModal })

    const handleOnSelectTableRow = useCallback((selectedRow, isSelected) => {
        if (isSelected && !selectedContributorsIds.includes(selectedRow?.contributor?.id))
            setSelectedContributorsIds([...selectedContributorsIds, selectedRow.contributor.id])
        else
            setSelectedContributorsIds(selectedContributorsIds.filter(rowId => rowId !== selectedRow.contributor.id))
    }, [selectedContributorsIds])

    const handleOnSelectAllRows = useCallback((isSelected, _, changedRows) => {
        const currentSelectedContributorsIds = changedRows.map(row => row?.contributor.id);

        if (isSelected) {
            setSelectedContributorsIds([...new Set([...selectedContributorsIds, ...currentSelectedContributorsIds])]);
        } else {
            setSelectedContributorsIds(selectedContributorsIds.filter(rowId => !currentSelectedContributorsIds.includes(rowId)));
        }

    }, [selectedContributorsIds])

    const handleOnPaginationChange = useCallback((pageNumber, pageSize) => {
        const skip = (pageNumber - 1) * pageSize;
        const limit = pageSize;
        setPagination({ skip, limit });
    }, [])

    const getTableRowKey = useCallback((tableRow) => tableRow?.contributor?.id, [])

    const handleOnSearch = (searchInputValue) => {
        setSearchTerm(searchInputValue.toLowerCase().trim())
        setPagination(ps => ({ ...ps, skip: 0 }))
    }

    const handleExportAllContributors = useCallback(() => {
        mutateExport({ projectId: selectedProject.id });
    }, [mutateExport, selectedProject.id]);

    const handleExportSelectedConributors = useCallback(() => {
        mutateExport({ projectId: selectedProject?.id, contributorsIds: selectedContributorsIds });
    }, [mutateExport, selectedContributorsIds, selectedProject.id]);

    const handleOnTableSortingChange = useCallback((pagination, filters, sorter) => {
        if (sorter.order === undefined)
            setSortedInfo({});
        else
            setSortedInfo(sorter);
    }, []);

    const costTableFiltersAreApplied = useCallback(() => {

        const hasSearch = !isEqual(searchTerm, "");
        const isSorted = !isEqual(sortedInfo, {})
        const filtersApplied = hasSearch || isSorted;

        return filtersApplied;

    }, [searchTerm, sortedInfo])

    const handleTableReset = useCallback(
        () => {
            setSearchTerm("");
            setSelectedContributorsIds([]);
            setSortedInfo({});
        }, [])

    const projectCostData = projectCostQueryData?.data?.items
    const rowSelection = {
        selectedRowKeys: selectedContributorsIds,
        onSelect: handleOnSelectTableRow,
        onSelectAll: handleOnSelectAllRows,
    };
    const hasSelectedContributors = Boolean(selectedContributorsIds.length)
    const resetButtonIsDisabled = isExporting || isProjectCostLoading || (!hasSelectedContributors && !costTableFiltersAreApplied());

    return (
        <>
            {isExportError &&
                <ErrorModal
                    error={formatError(exportError, 'Failed To Export Result File!')}
                    onDone={hideModal}
                    visible={isModalOpen}
                />}

            {/* The error display page. */}
            {isProjectCostError &&
                <ErrorPage
                    loading={isProjectCostLoading}
                    error={formatError(projectCostError)}
                />
            }

            {
                !isProjectCostError &&
                <>
                    <IfFeature isEnabled feature="project-unit-and-cost">
                        <CostTableControlButtons
                            querData={projectCostQueryData}
                            isExporting={isExporting}
                            hasSelectedContributors={hasSelectedContributors}
                            onSearch={handleOnSearch}
                            onExportAll={handleExportAllContributors}
                            onExportSelected={handleExportSelectedConributors}
                            onReset={handleTableReset}
                            resetButtonIsDisabled={resetButtonIsDisabled}
                        />
                    </IfFeature>

                    <IfFeature isEnabled feature="project-unit-and-cost">
                        <Space direction='vertical' style={{ width: '100%' }}>

                            <Table
                                size='small'
                                columns={factoryCostTableColumns(sortedInfo)}
                                dataSource={projectCostData}
                                loading={isProjectCostLoading}
                                pagination={false}
                                rowKey={getTableRowKey}
                                rowSelection={rowSelection}
                                onChange={handleOnTableSortingChange}
                            />

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Pagination
                                    showSizeChanger
                                    showQuickJumper
                                    showTotal={() => `Total: ${projectCostQueryData?.data?.count}`}
                                    total={projectCostQueryData?.data?.count}
                                    pageSize={pagination.limit}
                                    current={(pagination.skip / pagination.limit) + 1}
                                    onChange={handleOnPaginationChange}
                                />
                            </div>

                        </Space>
                    </IfFeature>
                </>
            }

        </>
    )
}

export default CostTable;