import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Card, Space, Spin, Result } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';

import Content from '../AntdWrappers/Content/Content';
import ErrorAlert from '../Error/ErrorAlert';
import useOptionsContext, { OptionsContextProvider } from '../../contexts/OptionsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { IfFeature, useFeatureIsOn } from '../../lib/featureToggles';
import { ROOT_PATH } from '../../routes';
import { OTHER_EDUCATION_LEVEL_ID } from '../../Constants';

import "./Auth.scss"

import FirstName from './RegisterationFormItems/FirstName';
import LastName from './RegisterationFormItems/LastName';
import Password from './RegisterationFormItems/Password';
import ConfirmPassword from './RegisterationFormItems/ConfirmPassword';
import Gender from './RegisterationFormItems/Gender';
import DateOfBirth from './RegisterationFormItems/DateOfBirth';
import Skills from './RegisterationFormItems/Skills';
import NativeLanguage from './RegisterationFormItems/NativeLanguage';
import OtherLanguages from './RegisterationFormItems/OtherLanguages';
import CurrentCity from './RegisterationFormItems/CurrentCity';
import HomeTown from './RegisterationFormItems/HomeTown';
import Footer from './RegisterationFormItems/Footer';
import CopyRigths from './RegisterationFormItems/CopyRights';
import EducationLevel from './RegisterationFormItems/EducationLevel';



// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Registration = (props) => {
    return (
        <OptionsContextProvider>
            <RegistrationInternal {...props} />
        </OptionsContextProvider>
    )
}

const RegistrationInternal = ({ location }) => {
    const {
        user,
        error,
        isLoading: authLoading,
        registerInvitedUser,
        refreshXsrfToken,
        isLxtUser,
    } = useAuthContext();

    const {
        isLoading: optionsLoading,
        fetchLanguageOptions,
        fetchSkillOptions
    } = useOptionsContext();

    const isProfileEnhancementEnabled = useFeatureIsOn("contributor-profile-enhancement");
    const isEducationEnabled = useFeatureIsOn("education-level")

    const [skills, setSkills] = useState([])
    const [otherLanguages, setOtherLanguages] = useState([])
    const [nativeLanguage, setNativeLanguage] = useState(undefined)
    const [hasNativeLangOnly, setHasNativeLangOnly] = useState(false)
    const [educationLevelId, setEducationLevelId] = useState(undefined);
    const [otherEducation, setOtherEducation] = useState('');

    const { token } = useParams();
    const history = useHistory();
    const query = useQuery();

    useEffect(() => {
        refreshXsrfToken(true)
            .then(() => {
                fetchLanguageOptions(token);
                fetchSkillOptions(token);
            })
        //eslint-disable-next-line
    }, [])

    const handleOnSkillsChange = useCallback((skill) => {
        setSkills(skill)
    }, [setSkills])

    const handleOnLanguageChange = useCallback((language) => {
        setOtherLanguages(language)
    }, [setOtherLanguages])

    const handleOnNativeLanguageChange = useCallback((language) => {
        setNativeLanguage(language)
    }, [setNativeLanguage])

    const handleOnToggleCheckBox = useCallback((e) => {
        setHasNativeLangOnly(e.target.checked)
        if (e.target.checked === true)
            setOtherLanguages([])

    }, [setHasNativeLangOnly]);

    const handleOnEducationLevelChange = useCallback((educationLevel) => {
        setEducationLevelId(educationLevel)
    }, [setEducationLevelId])

    const handleOnOtherEducationChange = useCallback((e) => {
        setOtherEducation(e.target.value)
    }, [setOtherEducation])

    const handleOnFinish = useCallback((values) => {

        if (token && query.get('email')) {

            const currentCityValue = values.currentLocation.properties;

            const selectedCurrentCity = {
                city: currentCityValue?.city || currentCityValue?.state,
                state: currentCityValue.state,
                country: currentCityValue.country,
                lat: currentCityValue.lat,
                lon: currentCityValue.lon,
                formatted: currentCityValue.formatted,
            };

            const userData = {
                ...values,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                currentLocation: selectedCurrentCity,
                skills,
                otherLanguages,
                nativeLanguage,
                email: query.get('email'),
            };

            if (!isProfileEnhancementEnabled) {
                const dateToFormat = moment.utc(values.dateOfBirth);
                const date = dateToFormat.format("YYYY-MM-DD");

                const homeTownValue = values.originLocation.properties;
                const selectedHomeTown = {
                    city: homeTownValue?.city || homeTownValue?.state,
                    state: homeTownValue.state,
                    country: homeTownValue.country,
                    lat: homeTownValue.lat,
                    lon: homeTownValue.lon,
                    formatted: homeTownValue.formatted,
                };

                userData.originLocation = selectedHomeTown;
                userData.dateOfBirth = date;
                userData.gender = values.gender;
            }

            if (isEducationEnabled) {
                const hasOtherEducation = Boolean(educationLevelId === OTHER_EDUCATION_LEVEL_ID);
                if (hasOtherEducation)
                {
                    userData.otherEducation = otherEducation;
                } else
                {
                    userData.educationId = educationLevelId;
                }
            }

            registerInvitedUser(token, userData)
                .then(() => {
                    //Redirecting the user to the root after he creates his/her account successfully
                    history.push(ROOT_PATH);
                })
        }
    }, [token, query, skills, otherLanguages, nativeLanguage, educationLevelId, isProfileEnhancementEnabled, isEducationEnabled, registerInvitedUser, otherEducation, history])

    return (
        <Fragment>

            {/* Displaying an error message if the user is already logged */}
            {user &&
                <Result
                    status="403"
                    title="Access Not Allowed"
                />
            }

            {/* Create Account Form */}
            {!user &&
                <Content alignVertically >
                    <Spin spinning={authLoading || optionsLoading}>
                        <Space align="center" direction="vertical">

                            <Card title={<span><UserOutlined /> Create your account</span>}>
                                <Form
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 30 }}
                                    style={{ width: '500px' }}
                                    onFinish={handleOnFinish}
                                >
                                    <FirstName />

                                    <LastName />

                                    {!isLxtUser(query.get('email')) &&
                                        <Fragment>
                                            <Password />
                                            <ConfirmPassword />
                                        </Fragment>
                                    }

                                    <IfFeature notEnabled feature="contributor-profile-enhancement">
                                        <Gender />
                                    </IfFeature>

                                    <IfFeature notEnabled feature="contributor-profile-enhancement">
                                        <DateOfBirth />
                                    </IfFeature>

                                    <Skills onSkillChange={handleOnSkillsChange} selectedSkills={skills} />

                                    <NativeLanguage
                                        onLanguageChange={handleOnNativeLanguageChange}
                                        nativeLanguage={nativeLanguage}
                                        otherLanguages={otherLanguages}
                                    />

                                    <OtherLanguages
                                        onLanguageChange={handleOnLanguageChange}
                                        otherLanguages={otherLanguages}
                                        nativeLanguage={nativeLanguage}
                                        OnToggleCheckBox={handleOnToggleCheckBox}
                                        isDisabled={hasNativeLangOnly}
                                    />

                                    <CurrentCity />

                                    <IfFeature notEnabled feature="contributor-profile-enhancement">
                                        <HomeTown />
                                    </IfFeature>

                                    <IfFeature isEnabled feature="education-level">
                                        <EducationLevel
                                            onEducationLevelChange={handleOnEducationLevelChange}
                                            educationLevel={educationLevelId}
                                            otherEducation={otherEducation}
                                            onOtherEducationChange={handleOnOtherEducationChange}
                                        />
                                    </IfFeature>

                                    {/* Sign in and Submit buttons */}
                                    <Footer />

                                </Form>
                            </Card>

                            <ErrorAlert error={error} />

                        </Space>
                    </Spin>
                </Content>
            }
            <CopyRigths />
        </Fragment>
    )
}

export default Registration