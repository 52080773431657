import { useQuery } from '@tanstack/react-query'
import { unitAndCostAPi } from '../cost';

export const useProjectCostData = ({ projectId, skip, limit, searchTerm, sortedInfo }) => {
    const queryData = useQuery({
        queryKey: ['cost', projectId, skip, limit, searchTerm, sortedInfo.order],
        queryFn: () => unitAndCostAPi.fetchProjectCost({
            projectId,
            skip,
            limit,
            params: {
                searchTerm,
                sortedColumn: sortedInfo?.columnKey,
                sortDirection: sortedInfo?.order,
            }
        }),
        keepPreviousData: true
    });

    return queryData
}
