const DATA_KEY = "_prj_meta_";


const readLocalStorageEntry = () => {

    // Reading raw data from the local storage. 
    const dataString = localStorage.getItem(DATA_KEY);

    // Validating the data exists.
    if (!dataString) return {};

    // Parsing data.
    try {
        const dataObject = JSON.parse(dataString);
        return dataObject;
    }
    catch (error) {
        return {};
    }
}


export default {

    // Saves the upload process ids to the local storage.
    saveProjectUploadProcessID: (projectID, processID, fileName) => new Promise(resolve => {

        const dataObject = readLocalStorageEntry();
        const timeStamp = (new Date()).toLocaleString();
        const newEntry = { processID, fileName, timeStamp };

        if (dataObject[projectID] && Array.isArray(dataObject[projectID]))
            dataObject[projectID].push(newEntry);
        else
            dataObject[projectID] = [newEntry];

        localStorage.setItem(DATA_KEY, JSON.stringify(dataObject));
        resolve();
    }),

    // Reads the upload process ids from the local storage for any given project.
    readProjectUploadProcessIDs: (projectID) => new Promise(resolve => {
        const dataObject = readLocalStorageEntry();
        const projectEntry = dataObject[projectID];
        if (projectEntry && Array.isArray(projectEntry))
            resolve(projectEntry);
        else
            resolve([]);
    }),
}
