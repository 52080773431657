import React, { useState, useEffect } from "react"
import { Row, Col, Space, Button, Popover, Collapse, Radio } from "antd"
import { EditOutlined, FileSearchOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { IoMdFunnel } from 'react-icons/io'

import NewTabButton from "../Common/NewTabButton"
import useCTProjectsContext from '../../contexts/CTProjectsContext';
import { COMPLETION_DP_PATH_ID, COMPLETION_RV_PATH_ID } from "../../routes"
import './contributor-style.scss'

const { Panel } = Collapse

const MobileAssignedTasks = ({ onRedirectContributor, onClickWithCtrl }) => {

    const {
        assignedCompletions,
        assignedCompletionsCriteria,
        fetchAssignedCompletions,
        LoadMoreMobileTasks
    } = useCTProjectsContext();

    useEffect(() => {
        fetchAssignedCompletions();
        //eslint-disable-next-line
    }, []);

    return (
        <Space direction="vertical" size={20}>

            {/**Sorting and Filters Section */}
            <FiltersSection
                fetchAssignedCompletions={fetchAssignedCompletions}
                assignedCompletionsCriteria={assignedCompletionsCriteria}
            />

            {/**Completion Cards */}
            {assignedCompletions.map(completion => {

                const popoverContent = (
                    <div>
                        <p> <b>Project Name:</b> {completion.projectName}</p>
                        <p> <b>Task ID:</b> {completion.taskId}</p>
                    </div>
                )

                return (
                    <div className="project-card-style" key={completion.id}>

                        <p><b>Project ID:</b> {completion.projectId}</p>
                        <p><b>Completion ID:</b> {completion.id}</p>

                        <Row gutter={[8, 8]}>
                            <Col>
                                <Popover content={popoverContent} trigger="click" placement="bottom">
                                    <Button
                                        type="dashed"
                                        size="small"
                                        icon={<InfoCircleOutlined />}>
                                        Task Info
                                    </Button>
                                </Popover>
                            </Col>

                            {completion.assignmentType === 'dp' &&
                                <Col>
                                    <NewTabButton
                                        displayText='Process'
                                        icon={<EditOutlined />}
                                        type='primary'
                                        size='small'
                                        onClick={() => onRedirectContributor(COMPLETION_DP_PATH_ID, completion.projectId, completion.id)}
                                        onClickWithCtrl={() => onClickWithCtrl(COMPLETION_DP_PATH_ID, completion.projectId, completion.id)}
                                    />
                                </Col>
                            }

                            {completion.assignmentType === 'rv' &&
                                <Col>
                                    <NewTabButton
                                        displayText='Review'
                                        icon={<FileSearchOutlined />}
                                        type='primary'
                                        size='small'
                                        className='btn-success'
                                        onClick={() => onRedirectContributor(COMPLETION_RV_PATH_ID, completion.projectId, completion.id)}
                                        onClickWithCtrl={() => onClickWithCtrl(COMPLETION_RV_PATH_ID, completion.projectId, completion.id)}
                                    />
                                </Col>
                            }
                        </Row>
                    </div>
                )
            })}

            {/**Load More Button */}
            <Button
                style={{ height: '40px', width: '100%' }}
                type='primary'
                onClick={LoadMoreMobileTasks}>
                Load More
            </Button>
        </Space>
    )
}

const FiltersSection = ({ fetchAssignedCompletions, assignedCompletionsCriteria }) => {

    const [state, setState] = useState({
        mobileSorterOption: null,
        mobileSorterValue: null,
        mobileFilterValue: null
    });

    useEffect(() => {
        return () => {
            setState(ps => ({
                ...ps,
                mobileSorterOption: null,
                mobileSorterValue: null,
                mobileFilterValue: null
            }))
        }
    }, [])

    const onSorterChange = e => {
        setState(ps => ({
            ...ps,
            mobileSorterOption: e.target.value
        }));
    };

    const onSorterValueChange = e => {
        setState(ps => ({
            ...ps,
            mobileSorterValue: e.target.value
        }));
    };

    const onFilterValueChange = e => {
        setState(ps => ({
            ...ps,
            mobileFilterValue: e.target.value
        }))
    }

    const handleApplyFilters = () => {
        const columnKey = state.mobileSorterOption
        const sortOrder = state.mobileSorterValue === 1 ? true : false
        const userFilter = state.mobileFilterValue
        // Fetching data with the updated criteria.
        fetchAssignedCompletions({
            ...assignedCompletionsCriteria,
            skip: 0,
            assignmentType: userFilter ? userFilter : null,
            orderColumn: columnKey ? columnKey : null,
            orderAscending: sortOrder ? sortOrder : false,
        });
    }
    return (
        <Collapse expandIcon={() => <IoMdFunnel />} ghost className="sorter-style">
            <Panel>
                <Row>
                    {/**Sort Options */}
                    <Col span={11}>
                        <b>Sort by</b>
                        <Radio.Group onChange={onSorterChange}>
                            <Radio value={'projectId'}>Project ID</Radio>
                            <Radio value={'id'}>Completion ID</Radio>
                            <Radio value={'taskId'}>Task ID</Radio>
                            <Radio value={'projectName'}>Project Name</Radio>
                        </Radio.Group>
                    </Col>

                    {/**Filter Options */}
                    <Col span={8}>
                        <b>Filter by</b>
                        <Radio.Group onChange={onFilterValueChange}>
                            <Radio value={'dp'}>Process</Radio>
                            <Radio value={'rv'}>Review</Radio>
                        </Radio.Group>
                    </Col>

                    {/**Sort Order */}
                    <Col span={5}>
                        <b>Order by</b>
                        <Radio.Group onChange={onSorterValueChange}>
                            <Radio value={1}>Asc</Radio>
                            <Radio value={2}>Dsc</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {/**Apply Button */}
                <Row justify='end'>
                    <Button
                        type="primary"
                        size="small"
                        onClick={handleApplyFilters}>
                        Apply
                    </Button>
                </Row>
            </Panel>
        </Collapse>
    )
}

export default MobileAssignedTasks