import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import
	{
		useEditor,
		BubbleMenu,
		FloatingMenu,
		EditorContent,
	} from '@tiptap/react';
import { get, set } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { TextAreaLabelHighlight } from './TextAreaLabelHighlight';
import { TextAreaLabelsProvider } from './TextAreaLabelsContext';
import
{
	ControlsCompact,
	ControlsGroup,
	EditorPanel,
	TextAreaEditorGlobalStyle,
} from './TextAreaEditorStyle';
import
{
	serializersTextNode,
	serializersParagraphNode,
} from './SerializersTextNode';
import { Button } from 'antd';

import { setTextAreaLabelsValue } from '../../../store/textAreaLabelsSlice';

const BUBBLE_MENU_ENABLED = true;
const FLOATING_MENU_ENABLED = false;

const TextAreaLabelsEditor = ({
	name,
	value,
	labels,
	controls,
	onLabelReady,
	onEditorChange,
}) =>
{
	const handleOnEditorUpdate = React.useCallback(
		(value) =>
		{
			onEditorChange?.(value);
		},
		[onEditorChange],
	);

	const extractJSONandText = (editorValue) =>
	{
		const json = editorValue.getJSON();

		const text = editorValue?.getText({
			textSerializers: {
				text: serializersTextNode,
				paragraph: serializersParagraphNode,
			},
		});

		return { text, json };
	};

	const dispatch = useDispatch();
	const { clientState } = useSelector(state => state?.textAreaLabels?.toSubmitCompletionDataTasks?.[state?.textAreaLabels?.activeCompletionID] ?? {});

	const editor = useEditor({
		content: clientState?.textAreaLabels?.editorState ?? value,
		extensions: [Text, Document, Paragraph, TextAreaLabelHighlight],

		onUpdate: ({ editor }) =>
		{
			const { text, json } = extractJSONandText(editor);

			console.log('[useEditor]', 'onUpdate', text, json);

			handleOnEditorUpdate({ text, json });
		},
	});

	useEffect(() =>
	{
		if (clientState?.textAreaLabels?.editorState)
		{
			editor?.commands?.setContent(clientState?.textAreaLabels?.editorState);
		}
	}, [editor, clientState?.textAreaLabels?.editorState]);

	const getJSON = React.useCallback(() =>
	{
		return editor?.getJSON();
	}, [editor]);

	const getText = React.useCallback(() =>
	{
		return editor?.getText();
	}, [editor]);

	useEffect(() =>
	{
		const getJsonID = `TextAreaLabel${nanoid()}`;
		const getTextID = `TextAreaLabelText${nanoid()}`;

		window[getJsonID] = getJSON;
		window[getTextID] = getText;

		set(window, `_ls_.tawl.${name}`, {
			editor,
			// getJSON: editor.getJSON,
			getSerializedText: () => editor?.getText({
				textSerializers: {
					text: serializersTextNode,
					paragraph: serializersParagraphNode,
				},
			}),
		});

		setTimeout(() =>
		{
			dispatch(setTextAreaLabelsValue({ getJsonID, getTextID }));
		}, 500);

	}, [editor]);

	const handleOnLabelClear = React.useCallback(() =>
	{
		editor
			.chain()
			.focus()
			.unsetTextAreaLabelHighlight()
			.run();
	}, [editor]);

	const handleOnLabelToggle = React.useCallback(
		(labelAlias) =>
		{
			const triggeredLabel = get(labels, labelAlias);

			if (!triggeredLabel)
			{
				return;
			}

			if (triggeredLabel.place === 'insert')
			{
				editor
					.chain()
					.focus()
					.putTextAreaLabelHighlight(triggeredLabel)
					.run();
				return;
			}

			editor
				.chain()
				.focus()
				.toggleTextAreaLabelHighlight(triggeredLabel)
				.run();
		},
		[labels, editor],
	);

	const ctx = React.useMemo(() =>
	{
		return {
			labels,
			onLabelReady,
			onLabelToggle: handleOnLabelToggle,
		};
	}, [handleOnLabelToggle, onLabelReady, labels]);

	return (
		<TextAreaLabelsProvider value={ctx}>
			<ControlsGroup>
				{controls}
				<Button
					onClick={handleOnLabelClear}
					size="small"
					disabled={!editor?.isActive?.('TextAreaLabelHighlight')}
				>
					Clear
				</Button>
			</ControlsGroup>

			{editor && BUBBLE_MENU_ENABLED ? (
				<BubbleMenu
					tippyOptions={{ duration: 100, placement: 'bottom-end' }}
					editor={editor}
				>
					<ControlsCompact>
						<Button
							onClick={handleOnLabelClear}
							disabled={!editor?.isActive?.('TextAreaLabelHighlight')}
						>
							Clear
						</Button>
						{controls}
					</ControlsCompact>
				</BubbleMenu>
			) : null}

			{editor && FLOATING_MENU_ENABLED ? (
				<FloatingMenu tippyOptions={{ duration: 100 }} editor={editor}>
					<ControlsCompact>{controls}</ControlsCompact>
				</FloatingMenu>
			) : null}

			<EditorPanel>
				<TextAreaEditorGlobalStyle labels={Object.values(labels)} />
				{editor ? <EditorContent editor={editor} /> : null}
			</EditorPanel>
		</TextAreaLabelsProvider>
	);
};

export default TextAreaLabelsEditor;
