import React, { Fragment, useState, useCallback } from 'react';
import TagsBox from '../../Common/TagsBox';
import { Form } from 'antd';

const { Item } = Form;

const ReasonsSelector = ({ onChange, placeholder }) => {

    const [state, setState] = useState({
        selectedReasons: []
    })

    const handleOnSelectedReasonsChange = useCallback((values) => {

        const uniqueLowercasedValues = new Set();
        const uniqueOriginalValues = new Set();

        values.forEach(v => {
            if (!v.toLowerCase().startsWith('other') && v.trim()) {
                const lowercasedValue = v.toLowerCase();
                if (!uniqueLowercasedValues.has(lowercasedValue)) {
                    uniqueLowercasedValues.add(lowercasedValue);
                    uniqueOriginalValues.add(v);
                }
            }
        });

        // Putting the values in the format added to the create project request after excluding the value of other.
        const finalValues = Array.from(uniqueOriginalValues).map(v => ({ name: v, requires_comment: false }));

        // Updating the state.
        setState(ps => ({
            ...ps,
            selectedReasons: finalValues,
        }));

        // Notifying the parent component with the new values.
        onChange(finalValues);

    }, [onChange])

    return (
        <TagsBox
            value={state.selectedReasons.map(r => r.name)}
            onChange={handleOnSelectedReasonsChange}
            size='medium'
            placeholder={placeholder}
            tokenSeparators={[',']}
            allowClear={true}
        />
    )
}

const SkipAndRejectReasons = () => {

    return (
        <Fragment>
            <Item
                label='Skip Reasons'
                name='completionSkipReasons'>
                <ReasonsSelector
                    placeholder="Add skip reasons here, comma separated, if required..."
                />
            </Item>
            <Item
                label='Rejection Reasons'
                name='completionRejectReasons'>
                <ReasonsSelector
                    placeholder="Add rejection reasons here, comma separated, if required..."
                />
            </Item>
            <span>Note: 'Other' option will be added by default to both Skip and Rejection reasons so no need to add them.</span>
        </Fragment>
    )
}

export default SkipAndRejectReasons;
