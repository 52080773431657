import * as y from 'yup';

y.addMethod(y.number, 'maxDecimalPlaces', function (maxDecimal) {
    return this.test({
        name: 'maxDecimalPlaces',
        params: { maxDecimal },
        exclusive: true,
        message: `Value must have at most ${maxDecimal} decimal places`,
        test: (value) => {
            const decimalCount = value.toString().split('.')[1]?.length || 0;
            return decimalCount <= maxDecimal;
        },
    });
});

y.addMethod(y.Schema, 'validateSafe', function (value, options={}) {
    try {
        const transformValue = this.validateSync(value, options);
        return {
            isValid: true,
            value: transformValue,
            originalValue: value,
        };
    } catch (error) {
        return Object.assign({}, error, { isValid: false })
    }
});

export default y;
