import React, { useEffect } from "react";
import ProfileEntry from "./ProfileEntry";
import { useParams, Prompt } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import useOptionsContext from "./../../contexts/OptionsContext";
import GenderInput from "./GenderInput";
import DateOfBirthInput from "./DateOfBirthInput";
import HometownInput from "./HometownInput";
import CurrentLocationInput from "./CurrentLocationInput";
import FirstNameInput from "./FirstNameInput";
import LastNameInput from "./LastNameInput";
import SkillsInput from "./SkillsInput";
import { Spin } from "antd";
import NativeLanguageInput from "./NativeLanguageInput";
import OtherLanguagesInput from "./OtherLanguages";
import EmailInput from "./EmailInput";
import { isContributorProfileComplete } from "../../lib/schemas";
import { IfFeature } from "../../lib/featureToggles";
import CopyRigths from "../Auth/RegisterationFormItems/CopyRights";
import EducationInput from "./EducationInput";
import useGetEducationOptions from "../../api/queries/useGetEducationOptions";

const ContributorInputs = ({
  ctProfileEditMode,
  onChangeCtProfileEditMode
}) => {
  const { user: authUser, getUserProfile, refreshXsrfToken } = useAuthContext();

  const { isLoading: isEducationLevelLoading } = useGetEducationOptions();

  const {
    isLoading: optionsLoading,
    fetchLanguageOptions,
    fetchSkillOptions
  } = useOptionsContext();

  const { token } = useParams();

  useEffect(() => {
    refreshXsrfToken(true).then(() => {
      fetchLanguageOptions(token);
      fetchSkillOptions(token);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const contributorInputsStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    rowGap: "1rem"
  };

  return (
    <>
      <Prompt
        when={!isContributorProfileComplete(authUser)}
        message="You won't be able to process any completions until you fill in all
                the missing information for your profile. Would you like to continue?"
      />
      <IfFeature notEnabled feature="contributors_profile:first_last_name">
        <ProfileEntry hasBorder label={"Name:"} value={authUser.fullName} />
      </IfFeature>

      <IfFeature isEnabled feature="contributors_profile:first_last_name">
        <FirstNameInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
        <LastNameInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
      </IfFeature>
      <EmailInput
        user={authUser}
        editMode={ctProfileEditMode}
        onChangeEditMode={onChangeCtProfileEditMode}
      />
      <GenderInput
        user={authUser}
        editMode={ctProfileEditMode}
        onChangeEditMode={onChangeCtProfileEditMode}
      />

      <DateOfBirthInput
        user={authUser}
        editMode={ctProfileEditMode}
        onChangeEditMode={onChangeCtProfileEditMode}
      />

      <div style={contributorInputsStyle}>
        <ProfileEntry label="Places Lived:" />
        <HometownInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
        <CurrentLocationInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
      </div>

      <Spin spinning={optionsLoading}>
        <SkillsInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
        <NativeLanguageInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
        <OtherLanguagesInput
          user={authUser}
          editMode={ctProfileEditMode}
          onChangeEditMode={onChangeCtProfileEditMode}
        />
      </Spin>

      <IfFeature isEnabled feature="education-level">
        <Spin spinning={isEducationLevelLoading}>
          <EducationInput
            user={authUser}
            editMode={ctProfileEditMode}
            onChangeEditMode={onChangeCtProfileEditMode}
          />
        </Spin>
      </IfFeature>
      <CopyRigths />
    </>
  );
};

export default ContributorInputs;
