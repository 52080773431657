import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive'
import { Table, Button, Space, Input, Pagination, Empty } from "antd";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";

import useCTProjectsContext from '../../contexts/CTProjectsContext';
import ErrorPage from "../Error/ErrorPage";
import ErrorBoundary from '../Error/ErrorBoundary';
import NewTabButton from '../Common/NewTabButton';
import MobileProjectsCards from "./MobileProjectsCards";
import { useAuthContext } from "../../contexts/AuthContext";
import { isContributorProfileComplete } from "../../lib/schemas";
import CompleteProfileModal from "../Common/CompleteProfileModal";
import RefreshTableButton from '../Common/RefreshTableButton';
import ResetTableButton from '../Common/ResetTableButton';

import {
  GUIDELINES_PATH,
  COMPLETION_RV_PATH,
  COMPLETION_DP_PATH,
  USER_PROFILE
} from "../../routes";
import './contributor-style.scss'

const EmptyTableState = () => {
  return (
    <Empty description='You have no assigned projects' />
  )
}

const ProjectsTable = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' })

  const {
    isLoading,
    error,
    allProjects,
    projectsCount,
    projectsCriteria,
    fetchAllProjects,
    resetMobilePageSize,
  } = useCTProjectsContext();

  const {
    getUserProfile,
    user,
  } = useAuthContext();

  const history = useHistory();

  const [state, setState] = useState({
    sorterInfo: {},
  })

  const [projectsSearchValue, setProjectsSearchValue] = useState("");

  const [isCompleteProfileModalOpen, setIsCompleteProfileModalOpen] = useState(false);

  useEffect(() => {
    fetchAllProjects();
    resetMobilePageSize();
    getUserProfile();
    //eslint-disable-next-line
  }, []);

  const hasAssignedProjects = Boolean(projectsCount);

  const handleResetTable = useCallback(() => {
    fetchAllProjects();
    if (isTabletOrMobile)
      resetMobilePageSize()
    setState(ps => ({ ...ps, sorterInfo: {} }));
  }, [fetchAllProjects, isTabletOrMobile, resetMobilePageSize])

  const handleRefreshData = useCallback(() => {
    fetchAllProjects({ ...projectsCriteria });
  }, [fetchAllProjects, projectsCriteria])

  const handleTableSorterChange = (newSorter) => {
    // Fetching data with the updated criteria.
    fetchAllProjects({
      ...projectsCriteria,
      orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
      orderAscending: newSorter.order ? newSorter.order === 'ascend' : false,
    });

    // Updating the sorter info object.
    setState(ps => ({ ...ps, sorterInfo: newSorter }));
  }

  const handleSearchChange = useCallback((e) => {
    setProjectsSearchValue(e.target.value);
  }, []);

  const handleOnSearchCommitted = useCallback((searchTerm) => {

    // Skipping the search if the term hasn't changed.
    if (projectsCriteria.search === searchTerm) return;

    fetchAllProjects({
      ...projectsCriteria,
      skip: 0, // Resetting the page number when a new search is applied.
      search: searchTerm,
    });
  }, [projectsCriteria, fetchAllProjects])

  const onPaginationChange = (pageNumber, pageSize) => {
    let skip = (pageNumber - 1) * pageSize;
    let limit = pageSize;
    fetchAllProjects({
      ...projectsCriteria,
      skip,
      limit,
    });
  }

  const handleOpenCompleteProfileModal = useCallback(() => {
    setIsCompleteProfileModalOpen(true);
  }, [setIsCompleteProfileModalOpen])

  const handleCloseCompleteProfileModal = useCallback(() => {
    setIsCompleteProfileModalOpen(false);
  }, [setIsCompleteProfileModalOpen])

  const handleRedirectContributor = useCallback((path, projectId) => { // handleRedirectContributor
    if (!isContributorProfileComplete(user)) {
      handleOpenCompleteProfileModal();
    } else {
      history.push(formatRoute(path, { projectId: projectId }))
    }
  }, [handleOpenCompleteProfileModal, user, history])

  const handleRedirectToProfile = useCallback(() => {
    history.push(formatRoute(USER_PROFILE))
  }, [history])

  const handleOnClickWithCtrl = useCallback((parh, projectId) => {
    if (!isContributorProfileComplete(user)) {
      handleOpenCompleteProfileModal();
    } else {
      window.open(formatRoute(parh, { projectId: projectId }))
    }
  }, [handleOpenCompleteProfileModal, user])

  const columns = [
    {
      title: "Project ID",
      dataIndex: "id",
      key: "id",
      // sorter: (recA, recB) => sortCompare(recA.id, recB.id),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'id' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: "Project Name",
      dataIndex: "internalName",
      key: "internalName",
      // sorter: (recA, recB) => sortCompare(recA.internalName, recB.internalName),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'internalName' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: "Guidelines",
      key: "guidelines",
      render: (value, record) => (
        <Button
          type='link'
          onClick={() => window.open(formatRoute(GUIDELINES_PATH, { projectId: record.id }))}>
          View
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {record.roles.includes('dp') &&
              <NewTabButton
                displayText='Process'
                icon={<EditOutlined />}
                type='primary'
                size='small'
                onClick={() => handleRedirectContributor(COMPLETION_DP_PATH, record.id)}
                onClickWithCtrl={() => handleOnClickWithCtrl(COMPLETION_DP_PATH, record.id)}
              />
            }

            {record.roles.includes('rv') &&
              <NewTabButton
                displayText='Review'
                icon={<FileSearchOutlined />}
                type='primary'
                size='small'
                className='btn-success'
                onClick={() => handleRedirectContributor(COMPLETION_RV_PATH, record.id)}
                onClickWithCtrl={() => handleOnClickWithCtrl(COMPLETION_RV_PATH, record.id)}
              />
            }
          </Space>
        )
      },
    }
  ];


  return (
    <ErrorBoundary>
      {/**complete profile modal */}
      <CompleteProfileModal
        isOpen={isCompleteProfileModalOpen}
        onClickOk={handleRedirectToProfile}
        onClickCancel={handleCloseCompleteProfileModal}
      />
      <Space
        align='baseline'
        className="contributor-controls"
        style={{
          marginBottom: isTabletOrMobile ? '20px' : '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >

        {/* The search form */}
        <Input.Search
          enterButton
          style={{ width: '100%' }}
          placeholder="Search by Name, or ID..."
          value={projectsSearchValue}
          size="small"
          allowClear
          onChange={handleSearchChange}
          onSearch={handleOnSearchCommitted}
          onBlur={() => handleOnSearchCommitted(projectsSearchValue)}
        />
        {/* refresh and reset buttons */}
        <Space>
          <RefreshTableButton
            tooltipText="Refresh Table Data"
            isDisabled={isLoading}
            onRefresh={handleRefreshData}
          />
          <ResetTableButton
            tooltipText="Clear Sorting, and Search"
            tooltipPlacement="topLeft"
            isDisabled={isLoading}
            onReset={handleResetTable}
          />
        </Space>
      </Space>

      <ErrorPage error={error} />

      {/** Mobile table cards */}
      {!error && isTabletOrMobile && hasAssignedProjects &&
        <MobileProjectsCards
          onRedirectContributor={handleRedirectContributor}
          onClickWithCtrl={handleOnClickWithCtrl}
        />}

      {!error && isTabletOrMobile && !hasAssignedProjects &&
        <EmptyTableState />
      }

      {/** Desktop table */}
      {!error && !isTabletOrMobile &&
        <Space direction='vertical' style={{ width: '100%' }}>
          <Table
            dataSource={allProjects.map(item => ({ ...item, key: item.id }))}
            loading={isLoading}
            columns={columns}
            pagination={false}
            onChange={(pagination, filters, sorter) => handleTableSorterChange(sorter)}
            locale={{
              emptyText: <EmptyTableState />
            }}
          />
          {hasAssignedProjects &&
            <div className="pagination-style">
            <Pagination
              showSizeChanger
              showQuickJumper
              showTotal={() => `Total: ${projectsCount}`}
              total={projectsCount}
              pageSize={projectsCriteria.limit}
              current={(projectsCriteria.skip / projectsCriteria.limit) + 1}
              onChange={onPaginationChange}
            />
            </div>
          }
        </Space>
      }
    </ErrorBoundary>
  )
}

export default ProjectsTable;
