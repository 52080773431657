import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Result } from 'antd';
import { LOGIN_PATH, PM_PROJECTS_PATH, CONTRIBUTOR_PROJECTS_PATH, USER_PROFILE, NOTIFICATIONS_PATH } from "../../routes";
import { useAuthContext } from '../../contexts/AuthContext';

const PrivateRoute = ({ component: Component, location, role, ...rest }) => {

  const { user } = useAuthContext();

  // When the user role is not available, then we redirect the user to the login page.
  if (!user || !user.role) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { referrer: location.pathname },
        }}
      />
    )
  }


  //We redirect the user only when the the path name includes his role to the required page.
  //Also covering the case where the user tries to view his profile, which doesn't depend on his role.
  if ((user.role === role && location.pathname.includes(role)) || location.pathname === NOTIFICATIONS_PATH || location.pathname === USER_PROFILE) {
    return (
      <Route {...rest} render={props => <Component {...props} />} />
    )
  }



  //If the user is trying to access a page which is not available for his role, then we redirect the user to the default page matching his/her role.
  if (user.role === 'pm') {
    return (
      <Redirect to={PM_PROJECTS_PATH} />
    )
  }
  if (user.role === 'contributor') {
    return (
      <Redirect to={CONTRIBUTOR_PROJECTS_PATH} />
    )
  }

  return (
    <Result
      status="Error"
      title="Insufficient role or invalid url!"
    />
  )
}

export default PrivateRoute;
