import CostInput from "./CostInput";
import UnitSelect from "./UnitSelect";

const UnitAndCost = ({ projectService }) => {
    return (
        <>
            {/* DataProcessing Unit & Cost */}
            <UnitSelect
                unitLabel={"Data Processing Unit"}
                unitKey={"dataProcessingCostUnit"}
                projectService={projectService}
            />
            <CostInput
                costLabel={"Data Processing Cost"}
                costKey={"dataProcessingCost"}
            />

            {/* Review Unit & Cost */}
            <UnitSelect
                unitLabel={"Review Unit"}
                unitKey={"reviewCostUnit"}
                projectService={projectService}
            />
            <CostInput
                costLabel={"Review Cost"}
                costKey={"reviewCost"}
            />
        </>
    )
}

export default UnitAndCost;