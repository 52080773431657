import React from 'react';
import { Form } from 'antd';
import RichGuidelinesEditor, { isEmptyEditorValue } from '../../Common/RichGuidelinesEditor';

const { Item } = Form;

const Guidelines = () => {
    return (
        <Item
            labelCol
            wrapperCol
            name="guidelines"
            rules={[
                () => ({
                    validator(_, value) {
                        return isEmptyEditorValue(value) ?
                            Promise.reject(new Error("Please set the project guidelines!")) :
                            Promise.resolve();
                    },
                })
            ]}
        >
            <RichGuidelinesEditor placeholder='Add here the guidelines of the project...' />
        </Item>
    )
}

export default Guidelines;
