import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
	GrowthBook,
	useGrowthBook,
	GrowthBookProvider,
	FeaturesReady as FeaturesReadyGB,
	useFeatureIsOn as useFeatureIsOnGB,
	useFeatureValue as useFeatureValueGB,
} from '@growthbook/growthbook-react';

const ENABLE_DEV_MODE = process.env.NODE_ENV === 'development';

// Create a GrowthBook instance
const growthBookInstance = new GrowthBook({
	enableDevMode: ENABLE_DEV_MODE,
	backgroundSync: true,
	subscribeToChanges: true,
	apiHost: process.env.REACT_APP_GROWTHBOOK_HOST,
	clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
	attributes: {
		loggedIn: false,
	},
	onFeatureUsage: (featureKey, result) => {
		if (ENABLE_DEV_MODE) {
			console.info('🏁 feature', featureKey, result.value);
		}
	},
});

/**
 * @typedef {JSON|string|number|boolean} Primitives
 *
 * @interface WidenPrimitives
 * @type {Primitives}
 */

// Load Feature
growthBookInstance
	.loadFeatures({
		skipCache: true,
		autoRefresh: true,
		timeout: 1000 * 60 * 3, // 3 min
	})
	.then(() => {
		if (ENABLE_DEV_MODE) {
			console.info('🏁 feature', 'ready');
		}
	})
	.catch((error) => {
		if (ENABLE_DEV_MODE) {
			console.error('🏁 feature', error);
		}
	});

/**
 * Get Feature value React Hook
 *
 * @param {string} id - feature toggle key
 * @param {*} fallback - fallback value
 * @returns {WidenPrimitives<*>}
 */
export function useFeatureValue(id, fallback) {
	const ft = useFeatureValueGB(id, fallback);
	datadogRum.addFeatureFlagEvaluation(id, ft);
	return ft;
}

/**
 * Get if feature is Enabled (on)
 * @param {string} id - feature toggle key
 * @returns {boolean}
 */
export function useFeatureIsOn(id) {
	const ft = useFeatureIsOnGB(id);
	datadogRum.addFeatureFlagEvaluation(id, ft);
	return ft;
}



/**
 * Get User Attribute API React Hook
 * @returns {{getAttributes: (function(): {[p: string]: any}), setAttributes: (function(*): void)}}
 */
export function useFeatureUser() {
	const growthbook = useGrowthBook();

	const getAttributes = useCallback(() => {
		return growthbook.getAttributes();
	}, [growthbook]);

	const setAttributes = useCallback(
		(attributes) => {
			return growthbook.setAttributes(attributes);
		},
		[growthbook],
	);

	return {
		getAttributes,
		setAttributes,
	};
}

/**
 * @interface IfFeatProps
 * @property {string} feature
 * @property {React.ReactNode} children
 *
 * @typedef {Object} IfFeatOnProps
 * @property {boolean} isEnabled
 *
 * @typedef {Object} IfFeatOffProps
 * @property {boolean} notEnabled
 */

/**
 * Conditional for Feature Toggle, React Component
 * @param {IfFeatProps<IfFeatOnProps|IfFeatOffProps>} props
 * @returns {React.ReactNode}
 * @constructor
 */
export function IfFeature(props) {
	const { feature, isEnabled, notEnabled, children } = props;
	const isFeatureOn = useFeatureIsOn(feature);

	const isShowIfFeatureEnable = isFeatureOn && isEnabled === true;
	const isShowIfFeatureNotEnable = !isFeatureOn && notEnabled === true;

	if (isShowIfFeatureEnable || isShowIfFeatureNotEnable) {
		return children;
	}

	return null;
}

/**
 * @param {number} timeout
 * @param {React.ReactNode} fallback
 * @param {React.ReactNode} children
 * @returns {JSX.Element}
 * @constructor
 */
export function FeaturesReady({ timeout, fallback, children }) {
	return (
		<FeaturesReadyGB timeout={timeout} fallback={fallback}>
			{children}
		</FeaturesReadyGB>
	);
}

export function FeatureToggleProvider(props) {
	return (
		<GrowthBookProvider growthbook={growthBookInstance}>
			{props?.children}
		</GrowthBookProvider>
	);
}

export const featureToggle = {
	/**
	 * @alias {gb.getAttributes}
	 */
	getUserAttributes() {
		return growthBookInstance.getAttributes;
	},

	/**
	 * @alias {gb.setAttributes}
	 */
	setUserAttributes(attrs) {
		growthBookInstance.setAttributes(attrs);
		growthBookInstance.refreshFeatures({
			skipCache: true,
		});
	},

	/**
	 *
	 * @param {string} key
	 * @returns {boolean}
	 */
	isOn(key) {
		return growthBookInstance.isOn(key);
	},

	/**
	 *
	 * @param {string} key
	 * @returns {boolean}
	 */
	isOff(key) {
		return !growthBookInstance.isOn(key);
	},

	/**
	 *
	 * @param {string} key
	 * @param {*} fallback
	 * @returns {WidenPrimitives<*>}
	 */
	getValue(key, fallback) {
		return growthBookInstance.getFeatureValue(key, fallback);
	},
};

export const FeatureToggle = {
	Provider: FeatureToggleProvider,
	Ready: FeaturesReady,
	If: IfFeature,
};

if (process.env?.NODE_ENV === 'development') {
	window.__gb__ = {
		growthBookInstance,
		featureToggle,
		FeatureToggle,
	};
}
