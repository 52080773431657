import { useContext } from "react";
import { Space, Switch } from "antd";
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';

const ActivateConsent = ({ activateAgreement, setState }) => {

    const {
        updateProjectValue
    } = useContext(PMPrjSingleContext);

    const onChange = (checked) => {
        setState(ps => ({ ...ps, activateAgreement: checked }));
        updateProjectValue('consentRequired', checked)
    };

    return (
        <Space>
            <label>Activate Agreement: </label>
            <Switch onChange={onChange} checked={activateAgreement} />
        </Space>
    )
}

export default ActivateConsent;