import { useState } from "react";
import { Form } from "antd";
import GeoapifySelect from "../../Common/GeoapifySelect";

const { Item } = Form;

const CurrentCity = () => {
    const [currentCityState, SetCurrentCityState] = useState(null)

    return (
        <Item
            label="Current City"
            name="currentLocation"
            rules={[
                {
                    required: currentCityState ?? true,
                    message: "Please choose your current city!",
                },
                () => ({
                    validator(_, value) {
                        SetCurrentCityState(value)
                        return Promise.resolve();
                    },
                })
            ]}
        >
            <GeoapifySelect />
        </Item>
    )
}

export default CurrentCity;