import React, { Fragment, useState, useEffect } from 'react'
import { Button, Space, Collapse, Radio, Row, Col, Spin } from "antd";
import { EditOutlined, FileSearchOutlined, FileTextOutlined } from "@ant-design/icons";
import { IoMdFunnel } from 'react-icons/io'
import NewTabButton from "../Common/NewTabButton";
import { GuidelinesModal } from './Guidelines'
import useCTProjectsContext from '../../contexts/CTProjectsContext';
import './contributor-style.scss'


import {
    COMPLETION_RV_PATH,
    COMPLETION_DP_PATH
} from "../../routes";

const { Panel } = Collapse;
const MobileProjectsCards = ({ onRedirectContributor, onClickWithCtrl }) => {

    const [state, setState] = useState({
        showGuidelines: false,
        projectId: null,
        mobileSorterOption: 1,
        mobileSorterValue: 1,
    });

    const {
        isLoading,
        allProjects,
        projectsCriteria,
        fetchAllProjects,
        LoadMoreMobileProjects
    } = useCTProjectsContext();

    useEffect(() => {
        fetchAllProjects();
        //eslint-disable-next-line
    }, []);

    const toggleShowGuidelines = () => setState(ps => ({
        ...ps,
        showGuidelines: !ps.showGuidelines
    }))

    const displayProjectGuidelines = (id) => {
        toggleShowGuidelines();
        setState(ps => ({
            ...ps,
            projectId: id
        }))
    }

    return (
        <Spin spinning={isLoading}>
            <Space
                direction='vertical'
                size={20}>

                {/**Sorting section */}
                <SorterSection
                    fetchAllProjects={fetchAllProjects}
                    projectsCriteria={projectsCriteria}
                />

                {/**Projects cards */}
                {allProjects.map(project => {
                    return (
                        <Fragment key={project.id}>
                            {!state.showGuidelines && <div className='project-card-style'
                                key={project.id}>
                                <p> <b>Project ID:</b> {project.id}</p>
                                <Row gutter={[8, 8]}>
                                    <Col>
                                        <Button
                                            type='dashed'
                                            size='small'
                                            icon={<FileTextOutlined />}
                                            onClick={() => displayProjectGuidelines(project.id)}>
                                            Guidelines
                                        </Button>
                                    </Col>


                                    {project.roles.includes('dp') &&
                                        <Col>
                                            <NewTabButton
                                                displayText='Process'
                                                icon={<EditOutlined />}
                                                type='primary'
                                                size='small'
                                                onClick={() => onRedirectContributor(COMPLETION_DP_PATH, project.id)}
                                                onClickWithCtrl={() => onClickWithCtrl(COMPLETION_DP_PATH, project.id)}
                                            />
                                        </Col>
                                    }

                                    {project.roles.includes('rv') &&
                                        <Col>
                                            <NewTabButton
                                                displayText='Review'
                                                icon={<FileSearchOutlined />}
                                                type='primary'
                                                size='small'
                                                className='btn-success'
                                                onClick={() => onRedirectContributor(COMPLETION_RV_PATH, project.id)}
                                                onClickWithCtrl={() => onClickWithCtrl(COMPLETION_RV_PATH, project.id)}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </div>}
                        </Fragment>
                    )
                })}

                {/**Load More Button */}
                <Button
                    style={{ height: '40px', width: '100%' }}
                    type='primary'
                    onClick={LoadMoreMobileProjects}>
                    Load More
                </Button>

                {/**Guideliens modal */}
                {state.showGuidelines &&
                    <GuidelinesModal
                        visible={state.showGuidelines}
                        onDone={toggleShowGuidelines}
                        projectId={state.projectId}
                    />
                }
            </Space>
        </Spin>
    )
}

const SorterSection = ({ fetchAllProjects, projectsCriteria }) => {

    const [state, setState] = useState({
        mobileSorterOption: null,
        mobileSorterValue: null,
    });

    useEffect(() => {
        return () => {
            setState(ps => ({
                ...ps,
                mobileSorterOption: null,
                mobileSorterValue: null
            }))
        }
    }, [])

    const onSorterChange = e => {
        setState(ps => ({
            ...ps,
            mobileSorterOption: e.target.value
        }));
    };

    const onSorterValueChange = e => {
        setState(ps => ({
            ...ps,
            mobileSorterValue: e.target.value
        }));
    };

    const handleApplySorting = () => {
        const columnKey = state.mobileSorterOption ? state.mobileSorterOption : null
        const sortOrder = state.mobileSorterValue === 1 ? true : false
        // Fetching data with the updated criteria.
        fetchAllProjects({
            ...projectsCriteria,
            orderColumn: columnKey ? columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
            orderAscending: sortOrder ? sortOrder : false,
        });
    }

    return (
        <Collapse expandIcon={() => <IoMdFunnel />} ghost className="sorter-style">
            <Panel>
                <Row>
                    <Col span={12}>
                        <Radio.Group onChange={onSorterChange}>
                            <Radio value={'id'}>Project ID</Radio>
                            <Radio value={'internalName'}>Project Name</Radio>
                        </Radio.Group>
                    </Col>

                    <Col span={12}>
                        <Radio.Group onChange={onSorterValueChange}>
                            <Radio value={1}>Asc</Radio>
                            <Radio value={2}>Dsc</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row justify='end'>
                    <Button type="primary" size="small" onClick={handleApplySorting}>Apply</Button>
                </Row>
            </Panel>
        </Collapse>
    )
}

export default MobileProjectsCards;