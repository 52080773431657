import { Collapse, Tooltip } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";

import CompletionResults from "./CompletionResults";

const CompletionResultsPanel = ({ store }) => {
    return (
        <Collapse
            className={`collapse-container-completions`}
            expandIcon={({ isActive }) =>
                <Tooltip title={isActive ? "Collapse Menu" : "Expand Menu"}>
                    <CaretRightOutlined
                        rotate={isActive ? 0 : 180}
                        style={{ fontSize: '1.5rem', margin: '-10px', }}
                    />
                </Tooltip>
            }
        >
            <Collapse.Panel className="collapse-panel-completions">
                <CompletionResults store={store} />
            </Collapse.Panel>
        </Collapse>
    )
}

export default observer(CompletionResultsPanel);