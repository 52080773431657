import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import { Input, Tooltip } from 'antd';

const EmailTextInput = ({ onChange, value, error }) => {
    return (
        <Tooltip placement="topLeft" title={error} color="rgba(255,77,79,0.8)">
            <Input
                placeholder="Email"
                allowClear
                onChange={onChange}
                value={value}
                status={Boolean(error) ? 'error' : undefined}
            />
        </Tooltip>
    );
};

const EmailInput = ({ user, editMode, onChangeEditMode }) => {
    const [email, setEmail] = useState("");

    const [isEmailEditable, setIsEmailEditable] = useState(false);

    const [emailValidationErr, setEmailValidationErr] = useState("");

    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsEmailEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const handleValidationErr = useCallback((errMsg) => {
        setEmailValidationErr(errMsg);
    }, []);

    const hasEmail = Boolean(user.email);
    const isEditable = isEmailEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Email:"
            value={
                (hasEmail && !isEditable) ?
                    user.email :
                    (isEditable) ?
                        <EmailTextInput
                            onChange={handleEmailChange}
                            value={email}
                            error={emailValidationErr}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="email"
                    inputValue={email}
                    inputEditable={isEmailEditable}
                    changeEditableStatus={handleEditableStatus}
                    changeValidationErrMsg={handleValidationErr}
                    disableSave={!email ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default EmailInput;