import { lxtBackend } from "./utils";

export const unitAndCostAPi = {

    approveCost: async ({ projectId, role, requestBody }) => {
        let url = `pm/projects/${projectId}/completions/approve-cost`;

        const searchParams = new URLSearchParams({ role });

        const data = await lxtBackend.post(url, requestBody, {
            params: searchParams,
        });
        return data;
    },

    fetchProjectCost: async ({ projectId, skip, limit, params }) => {


        let url = `pm/projects/${projectId}/cost/contributors`;

        const searchParams = new URLSearchParams({ skip, limit })

        if (params) {
            const { searchTerm, sortedColumn, sortDirection } = params;

            if (searchTerm) searchParams.append("search", searchTerm);
            if (sortedColumn) searchParams.append("order", sortedColumn);
            if (sortDirection) searchParams.append("asc", sortDirection === "ascend" ? true : false);
        }

        const data = await lxtBackend.get(url, {
            params: searchParams
        });
        return data;
    },

    exportProjectCostTasks: async ({ projectId, contributorsIds }) => {

        let url = `pm/projects/${projectId}/cost/contributors/export`;

        const params = new URLSearchParams();
        const hasSelectedContributors = contributorsIds?.length;

        if (hasSelectedContributors) {
            contributorsIds.forEach(id => params.append("contributor_ids", id));
        }

        const data = await lxtBackend.get(url, {
            responseType: 'blob',
            params
        });
        return data;
    },
}

