import React, { useState, useContext } from 'react';
import { Row, Col, Button, DatePicker, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import moment from 'moment';

import FieldLabel from '../../../../Common/FieldLabel';
import PMPrjSingleContext from '../../../../../contexts/PMPrjSingleContext';
import { formatDate } from '../../../../Common/Utils';
import { formatDateWithTimeStamp } from '../../../../../LSF/utils/date';
import { LABEL_SPAN, VALUE_SPAN } from './Constants';


const ProjectFieldDate = ({ labelText, fieldName, editable, thresholds }) => {

    const [editMode, setEditMode] = useState(false);
    const { selectedProject, updateProjectValue } = useContext(PMPrjSingleContext);
    const dateText = formatDate(selectedProject[fieldName]);

    const datePickerDefaultValue = selectedProject[fieldName] && moment(selectedProject[fieldName]).isValid() ?
        moment(selectedProject[fieldName]) :
        moment()

    const minDate = thresholds && thresholds.minFieldName && selectedProject[thresholds.minFieldName] ?
        formatDateWithTimeStamp(selectedProject[thresholds.minFieldName]) : null

    const maxDate = thresholds && thresholds.maxFieldName && selectedProject[thresholds.maxFieldName] ?
        formatDateWithTimeStamp(selectedProject[thresholds.maxFieldName]) : null

    const onFieldChanged = (newDate) => {
        if (newDate) {
            newDate = formatDateWithTimeStamp(newDate);
            if (minDate && newDate < minDate) {
                message.error(`${labelText} date can't be earlier than ${thresholds.minFieldLabel} date!`)
            }
            else if (maxDate && newDate > maxDate) {
                message.error(`${labelText} date can't be later than ${thresholds.maxFieldLabel} date!`)
            }
            else {
                updateProjectValue(fieldName, newDate);
                setEditMode(false);
            }
        }
        else {
            message.warning('Invalid value. Project is not updated!');
        }
    }

    return (
        <Row style={{ marginBottom: '20px' }} align='middle'>

            {/* The label of the field */}
            <Col span={LABEL_SPAN}>
                <FieldLabel text={labelText + ':'} />
            </Col>

            {/* Display value of the field */}
            {!editMode &&
                <Col span={VALUE_SPAN}>

                    <span>{dateText}</span>

                    {/* The pen edit button */}
                    {editable &&
                        <Button type='text' onClick={() => setEditMode(true)}>
                            <EditOutlined />
                        </Button>
                    }

                </Col>
            }

            {/* The edit form */}
            {editMode &&
                <Col span={VALUE_SPAN}>
                    <DatePicker
                        open
                        format='DD/MM/YYYY'
                        defaultValue={datePickerDefaultValue}
                        onChange={e => onFieldChanged(e)}
                        autoFocus={true}
                        onBlur={() => setEditMode(false)} />
                </Col>
            }

        </Row >
    )
}

export default ProjectFieldDate;
