import React, { Fragment, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import { Button, Space, Modal } from "antd";
import { SyncOutlined, SaveOutlined, LikeOutlined } from '@ant-design/icons';

import useCompletionContext from '../../../contexts/CompletionContext';
import useInterval from '../../Common/useInterval';
import { CONTRIBUTOR_TYPE } from "./LookupTables";
import ErrorModal from '../../Error/ErrorModal';

const AUTO_SAVE_PERIOD = 1000 * 30;


const SaveDraftButton = ({ contributorType }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })
    const [isAutoSavingDraftResult, setIsAutoSavingDraftResult] = useState(false);
    const [autoSavingError, setAutoSavingError] = useState(null);

    const {
        isSavingDraftResult,
        originalCompletionSelected,
        saveDraftResult,
    } = useCompletionContext();

    // Setting the interval hook which saves the results every AUTO_SAVE_PERIOD.
    useInterval(() => saveDraft(true), AUTO_SAVE_PERIOD);

    // Creating the auto save function.
    const saveDraft = (autoSaving) => {

        if (autoSaving)
            setIsAutoSavingDraftResult(true)

        //Saving the draft result.
        saveDraftResult(contributorType === CONTRIBUTOR_TYPE.REVIEWER)
            .then(res => {
                if (autoSaving) {
                    setIsAutoSavingDraftResult(false);
                }
                else if (!res) {
                    // If the action was originated by the user (not auto saving) and there were nothing to save,
                    // then we display a modal to the user.
                    Modal.info({
                        content: 'All your updates are already saved.',
                        icon: <LikeOutlined />
                    });
                }
            })
            .catch(e => {
                if (autoSaving) {
                    setIsAutoSavingDraftResult(false)
                }
                setAutoSavingError(e);
            });
    }

    const clearErrors = () => [
        setAutoSavingError(null)
    ]

    return (
        <Fragment>


            <ErrorModal
                error={autoSavingError}
                onDone={clearErrors}
            />


            {isAutoSavingDraftResult && !isTabletOrMobile && <Space>Auto Saving!</Space>}

            {!isAutoSavingDraftResult &&
                <Button
                    type="dashed"
                    onClick={() => saveDraft(false)}
                    size={isTabletOrMobile ? "middle" : 'small'}
                    shape={isTabletOrMobile ? 'circle' : 'default'}
                    disabled={isSavingDraftResult || !originalCompletionSelected}
                    icon={isSavingDraftResult ? <SyncOutlined spin /> : <SaveOutlined />}
                >
                    {isTabletOrMobile ? '' : isSavingDraftResult ?
                        <Space>Saving</Space> :
                        <Space>Save Draft</Space>}
                </Button>}
        </Fragment>
    )
}

export default SaveDraftButton;