import React, { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { Input, Space, Button, Table, Pagination } from "antd";
import { FileAddOutlined, CheckCircleFilled, CloseCircleFilled, SyncOutlined } from "@ant-design/icons";
import isEqual from 'lodash.isequal';

import { formatDate, calcElapsedTime } from '../Common/Utils';
import { NEW_PROJECT_PATH } from "../../routes";
import { PROJ_STATUS_ACTIVE, PROJ_STATUS_COMPLETED, PROJ_STATUS_CANCELLED } from '../../Constants';
import PMPrjContext from '../../contexts/PMPrjContext';
import ErrorPage from '../Error/ErrorPage';
import ErrorBoundary from '../Error/ErrorBoundary';
import RefreshTableButton from "../Common/RefreshTableButton";
import ResetTableButton from "../Common/ResetTableButton";

const activeIcon = <SyncOutlined />;
const completedIcon = <CheckCircleFilled />;
const cancelledIcon = <CloseCircleFilled />;

// The following is used to match the initial state of filters info to the initial state of the table filters info.
const EMPTY_FILTERS_INFO = {
  status: null,
}

function PMProjects() {

  const {
    projects,
    projectsCount,
    isLoading,
    error,
    criteria,
    getProjects
  } = useContext(PMPrjContext);

  let { path } = useRouteMatch();
  const history = useHistory();
  const [state, setState] = useState({
    filtersInfo: EMPTY_FILTERS_INFO,
    sorterInfo: {},
  })

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // Reloading the projects when the page navigates.
    getProjects();

    //eslint-disable-next-line
  }, []);


  const handleCreateProject = () => history.push(NEW_PROJECT_PATH);



  // const getOwnerFilterValues = () => {
  //   let ownerSet = [];
  //   let ownerValues = [];
  //   projects.forEach(p => {
  //     if ((p.owner !== "" && p.owner !== null) && ownerSet.indexOf(p.owner) === -1) {
  //       ownerSet.push(p.owner);
  //       ownerValues.push({ text: p.owner, value: p.owner })
  //     }
  //   })
  //   return ownerValues;
  // }

  const handleOnSearchChange = useCallback((e) => {
    setSearchValue(e.target.value)
  }, []);

  const handleOnSearchCommitted = useCallback((searchTerm) => {

    // Skipping the search if the term hasn't changed.
    if (criteria.search === searchTerm) return;

    getProjects({
      ...criteria,
      skip: 0,
      search: searchTerm,
    });
  }, [criteria, getProjects])

  const onTablePaginationChange = (pageNumber, pageSize) => {
    let newSkip = (pageNumber - 1) * pageSize;
    let newLimit = pageSize;
    getProjects({
      ...criteria,
      skip: newSkip,
      limit: newLimit,
    });
  }

  const onTabelFiltersAndSorterChange = (newFilters, newSorter) => {

    const filtersChanged = !isEqual(newFilters, state.filtersInfo);

    // Fetching data with the updated criteria.
    getProjects({
      ...criteria,
      skip: filtersChanged ? 0 : criteria.skip, //Resetting the skip if the filters were changed.
      statusFilter: newFilters.status,
      orderColumn: newSorter.order ? newSorter.columnKey : null, // newSorter.order is used to know whether to the sort is applied or not as the columnKey is not updated when the order is removed.
      orderAscending: newSorter.order ? newSorter.order === 'ascend' : false,
    });

    // Updating the filters indo object.
    setState(ps => ({
      ...ps,
      filtersInfo: newFilters,
      sorterInfo: newSorter,
    }));
  }

  const handleResetTable = () => {
    setState(ps => ({
      ...ps,
      filtersInfo: EMPTY_FILTERS_INFO,
      sorterInfo: {},
    }));
    getProjects();
  }

  const handleRefreshData = useCallback(() => {
    getProjects(criteria);
  }, [getProjects, criteria])

  //eslint-disable-next-line
  const columns = useMemo(() => [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // sorter: (recA, recB) => sortCompare(recA.id, recB.id),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'id' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Project Internal Name",
      dataIndex: "internalName",
      key: "internalName",
      render: (text, record) => <Link to={`${path}/${record.id}`} style={{ color: '#1890ff' }}>{text}</Link>,
      // sorter: (recA, recB) => sortCompare(recA.internalName, recB.internalName),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'internalName' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      // sorter: (recA, recB) => sortCompare(recA.owner, recB.owner),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'owner' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend'],
      // filters: getOwnerFilterValues(),
      // filterMultiple: true,
      // filteredValue: filtersInfo.owner || null,
      // onFilter: (value, record) => record.owner === value,
    },
    {
      title: "ETA",
      dataIndex: "eta",
      key: "eta",
      render: (text, record) => formatDate(record.eta),
      // sorter: (recA, recB) => Date.parse(recA.eta) - Date.parse(recB.eta),
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'eta' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Completions Processed %",
      dataIndex: "processedCompletions",
      key: "processedCompletions",
      render: (text, record) => `${calculateComp(record)}%`
    },
    {
      title: "Completions Reviewed %",
      dataIndex: "reviewedCompletions",
      key: "reviewedCompletions",
      render: (text, record) => `${calculateRev(record)}%`
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.status === PROJ_STATUS_ACTIVE) return (<span style={{ color: '#1890ff' }}>{activeIcon} Active </span>)
        if (record.status === PROJ_STATUS_COMPLETED) return (<span style={{ color: '#2CB280' }}>{completedIcon} Completed </span>)
        if (record.status === PROJ_STATUS_CANCELLED) return (<span style={{ color: '#f5222d' }}>{cancelledIcon} Cancelled </span>)
      },
      // sorter: (recA, recB) => recA.status - recB.status,
      sorter: () => undefined, // Since the sorting takes place in the backend.
      sortOrder: state.sorterInfo.columnKey === 'status' && state.sorterInfo.order,
      sortDirections: ['ascend', 'descend'],
      filters: [
        {
          text: 'Active',
          value: PROJ_STATUS_ACTIVE,
        },
        {
          text: 'Completed',
          value: PROJ_STATUS_COMPLETED,
        },
        {
          text: 'Cancelled',
          value: PROJ_STATUS_CANCELLED
        }
      ],
      filteredValue: state.filtersInfo.status || null,
      filterMultiple: true,
    },
    {
      title: "Time Elapsed",
      key: "timeElapsed",
      render: (text, record) => calcElapsedTime(record),
    }
  ]);

  const isProjectsCriteriaApplied = useMemo(() => {
    const filtersApplied = !isEqual(state.filtersInfo, EMPTY_FILTERS_INFO) || !isEqual(state.sorterInfo, {});
    return filtersApplied;
  }, [state.filtersInfo, state.sorterInfo])

  return (
    <ErrorBoundary>
      <Space
        align='baseline'
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >

        {/* The search form */}
        <Input.Search
          style={{ width: '300px' }}
          enterButton
          placeholder='Search By Name, or Owner...'
          value={searchValue}
          size='small'
          allowClear
          onChange={handleOnSearchChange}
          onSearch={handleOnSearchCommitted}
          onBlur={() => handleOnSearchCommitted(searchValue)}
        />

        <Space>
          {/* refresh and reset buttons */}
          <RefreshTableButton
            tooltipText="Refresh Table Data"
            isDisabled={isLoading}
            onRefresh={handleRefreshData}
          />
          <ResetTableButton
            tooltipText="Clear Filters, Sorting, and Search"
            isDisabled={isLoading || !isProjectsCriteriaApplied}
            onReset={handleResetTable}
          />

          {/* Add new project button */}
          <Button type="primary" size='small' onClick={handleCreateProject}>
            <FileAddOutlined /> New project
          </Button>
        </Space>

      </Space>

      {/* Displaying the error message and hiding it if a new response is loaded.*/}
      <ErrorPage error={error} />

      {/* The projects table */}
      {!error &&
        <Space direction='vertical' style={{ width: '100%' }}>
          < Table
            dataSource={projects.map(item => ({ ...item, key: item.id }))}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 'max-content' }}
            onChange={(pagination, filters, sorter) => onTabelFiltersAndSorterChange(filters, sorter)}
            pagination={false}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Pagination
              showSizeChanger
              showQuickJumper
              showTotal={() => `Total: ${projectsCount}`}
              total={projectsCount}
              pageSize={criteria.limit}
              current={(criteria.skip / criteria.limit) + 1}
              onChange={onTablePaginationChange}
            />
          </div>
        </Space>
      }

    </ErrorBoundary>
  );
}


const calculateComp = (record) => {
  return record.totalCompletions > 0 ? Math.floor((record.processedCompletions / record.totalCompletions) * 100) : 0;
}

const calculateRev = (record) => {
  return record.totalCompletions > 0 ? Math.floor((record.reviewedCompletions / record.totalCompletions) * 100) : 0;
}
export default PMProjects;
