import { useCallback, useContext, useState } from 'react';

import { Checkbox, Popconfirm, Table, Button, Pagination, Space, Typography } from 'antd';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import ExportButton from './ExportButton';
import ImportButton from './ImportButton';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';
import { IfFeature } from "../../../../lib/featureToggles";

import ErrorPage from '../../../Error/ErrorPage';
import ErrorModal from '../../../Error/ErrorModal';

import { useAdhocData, } from '../../../../api/queries/useAdhocQueries';
import { useAdhocDelete, useAdhocImport, useAdhocExport, useAdhocCheckImport, useAdhocUpdate } from '../../../../api/mutations/useAdhocMutations';

import { formatError } from '../../../../contexts/ResponseErrorFormatter';
import TemplateModal from './TemplateModal';

const { Text } = Typography;

const renderColumns = (adhocs, handleDelete, getHandleOnApprove) => {
    const columns = [
        {
            title: 'Contributor ID',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => record?.contributor?.id,
        },
        {
            title: 'Contributor Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                const firstName = record?.contributor?.firstName
                const lastName = record?.contributor?.lastName
                const fullName = `${firstName} ${lastName}`

                return fullName;
            }
        },
        {
            title: 'Contributor Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => record?.contributor?.email,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Type',
            dataIndex: 'taskType',
            key: 'taskType',
        },
        {
            title: 'Other Type',
            dataIndex: 'otherType',
            key: 'otherType',
        },
        {
            title: 'Unit Type',
            dataIndex: 'unitType',
            key: 'unitType',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Approved',
            dataIndex: 'approved',
            key: 'approved',
            render: (_, record) => {
                return (
                    <Checkbox defaultChecked={record?.approved ?? true} onChange={getHandleOnApprove(record.id)} />
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) =>
                adhocs?.length >= 1 ? (
                    <Popconfirm placement='topLeft' title="Are you sure you want to delete the record?" onConfirm={() => handleDelete(record.id)}
                    >
                        <Button
                            icon={<DeleteFilled />}
                            type="link"
                            danger
                        />
                    </Popconfirm>
                ) : null,
        },
    ];

    return columns
}

const AdhocTable = () => {

    const [pagination, setPagination] = useState({
        skip: 0,
        limit: 10
    })
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isCsvTemplateModalOpen, setIsCsvTemplateModalOpen] = useState(false);

    const [selectedTableRowsIds, setSelectedTableRowsIds] = useState([]);

    const hasSelectedTasks = Boolean(selectedTableRowsIds.length);
    const selectedTasksCount = selectedTableRowsIds.length;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOnOpenCsvTemplateModal = useCallback(() => {
        setIsCsvTemplateModalOpen(true);
    }, []);

    const handleOnCloseCsvTemplateModal = useCallback(() => {
        setIsCsvTemplateModalOpen(false);
    }, []);

    const {
        selectedProject,
    } = useContext(PMPrjSingleContext);

    const { data: adhocs, error, isError, isLoading } = useAdhocData({ projectId: selectedProject?.id, skip: pagination.skip, limit: pagination.limit });
    const { mutate: mutateDelete, error: deleteError, isError: isDeleteError } = useAdhocDelete({ onError: showModal });
    const { mutate: mutateUpdate, error: updateError, isError: isUpdateError } = useAdhocUpdate({ onError: showModal });
    const { mutate: mutateExport, error: exportError, isError: isExportError, isLoading: isExporting } = useAdhocExport({ selectedProject, onError: showModal });
    const { mutateAsync: mutateImport, error: importError, isError: isImportError, isLoading: isImporting } = useAdhocImport({ onError: showModal });
    const { mutateAsync: mutateCheckImport, error: importCheckError, isError: isImportCheck } = useAdhocCheckImport({ onError: showModal });

    const handleDelete = useCallback((adhocId) => {
        mutateDelete({ projectId: selectedProject?.id, adhocTaskId: adhocId });
    }, [selectedProject?.id, mutateDelete]);

    const getHandleOnApprove = useCallback((adhocId) => (evt) => {
        mutateUpdate({
            projectId: selectedProject?.id,
            adhocTaskId: adhocId,
            isApproved: {
                approved: evt.target.checked
            }
        })
    }, [selectedProject?.id, mutateUpdate]);

    const handleExportAllTasks = useCallback(() => {
        mutateExport({ projectId: selectedProject?.id });
    }, [selectedProject?.id, mutateExport]);

    const handleExportSelectedTasks = useCallback(() => {
        mutateExport({ projectId: selectedProject?.id, tasksIds: selectedTableRowsIds });
    }, [selectedProject?.id, mutateExport, selectedTableRowsIds]);

    const handleOnPaginationChange = useCallback((pageNumber, pageSize) => {
        let skip = (pageNumber - 1) * pageSize;
        let limit = pageSize;

        setPagination({ skip, limit });
    }, [])

    const handleImportAdhocs = useCallback((info) => {
        const latestFileIndex = info.fileList.length - 1
        const tasksFile = info.fileList[latestFileIndex].originFileObj;
        const fileFormData = new FormData();
        fileFormData.append('adhoc_tasks_file', tasksFile);

        mutateImport({ projectId: selectedProject.id, fileFormData })
            .then((res) => {
                const queueTaskId = res.data;
                mutateCheckImport({ queueTaskId })
            })

    }, [mutateCheckImport, mutateImport, selectedProject.id])

    const handleTableRowSelection = useCallback((row, isSelected) => {
        if (isSelected && !selectedTableRowsIds.includes(row.id)) {
            setSelectedTableRowsIds([...selectedTableRowsIds, row.id])
        } else {
            setSelectedTableRowsIds(selectedTableRowsIds.filter(rowId => rowId !== row.id))
        }
    }, [selectedTableRowsIds])

    const handleTableSelectAll = useCallback((isSelected, selectedRows, changeRows) => {
        let bulkSelectedRowsIds;
        if (isSelected) {
            bulkSelectedRowsIds = changeRows.map(row => row.id);
            setSelectedTableRowsIds([...new Set([...selectedTableRowsIds, ...bulkSelectedRowsIds])]);
        } else {
            bulkSelectedRowsIds = changeRows.map(row => row.id);
            setSelectedTableRowsIds(selectedTableRowsIds.filter(rowId => !bulkSelectedRowsIds.includes(rowId)))
        }
    }, [selectedTableRowsIds]);


    return (
        <>
            {isError && <ErrorPage error={formatError(error, 'Failed To Retrieve Adhocs!')} />}

            {isImportError &&
                <ErrorModal
                    error={formatError(importError, 'Failed To Import File!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}

            {isExportError &&
                <ErrorModal
                    error={formatError(exportError, 'Failed To Export Result File!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}

            {isImportCheck &&
                <ErrorModal
                    error={formatError(importCheckError, 'Failed To Check Import Status!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}

            {isDeleteError &&
                <ErrorModal
                    error={formatError(deleteError, 'Failed To Delete User!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}

            {isUpdateError &&
                <ErrorModal
                    error={formatError(updateError, 'Failed To Update User!')}
                    onDone={handleCancel}
                    visible={isModalOpen}
                />}

            <TemplateModal isOpen={isCsvTemplateModalOpen} onClose={handleOnCloseCsvTemplateModal} />

            <Space
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '15px'
                }}
            >
                <Text>{hasSelectedTasks && `Selected ${selectedTasksCount} Tasks`}</Text>
                <Space>
                    <ExclamationCircleOutlined onClick={handleOnOpenCsvTemplateModal} />
                    <IfFeature isEnabled feature="adhoc-contributor-task">
                        <ImportButton
                            disabled={isImporting}
                            loading={isImporting}
                            onClick={handleImportAdhocs}
                        />
                    </IfFeature>

                    <IfFeature isEnabled feature="adhoc-contributor-task">
                        <ExportButton
                            disabled={adhocs?.count === 0 || isImporting}
                            loading={isExporting}
                            onExportAll={handleExportAllTasks}
                            onExportSelected={handleExportSelectedTasks}
                            hasSelectedTasks={hasSelectedTasks}
                        />
                    </IfFeature>
                </Space>
            </Space>

            {!isError &&
                <IfFeature isEnabled feature="adhoc-contributor-task">
                    <Table
                        columns={renderColumns(adhocs?.adHocTasks, handleDelete, getHandleOnApprove)}
                        rowSelection={{
                            selectedRowKeys: selectedTableRowsIds,
                            onSelect: (row, isSelected) => handleTableRowSelection(row, isSelected),
                            onSelectAll: (isSelected, selectedRows, changeRows) => handleTableSelectAll(isSelected, selectedRows, changeRows),
                        }}
                        dataSource={adhocs?.adHocTasks}
                        loading={isLoading}
                        pagination={false}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Pagination
                            showSizeChanger
                            showQuickJumper
                            showTotal={() => `Total: ${adhocs?.count}`}
                            total={adhocs?.count}
                            pageSize={pagination.limit}
                            current={(pagination.skip / pagination.limit) + 1}
                            onChange={handleOnPaginationChange}
                        />
                    </div>
                </IfFeature>
            }
        </>
    )
}


export default AdhocTable;