import { useQuery } from '@tanstack/react-query'
import { adhocApi } from '../adhoc';

export const useAdhocData = ({ projectId, skip, limit }) => {
    const queryData = useQuery({
        queryKey: ['adhocs', projectId, skip, limit],
        queryFn: () => adhocApi.getAdhocs(projectId, skip, limit),
        keepPreviousData: true,
    });

    const response = queryData?.data;

    return {
        ...queryData,
        data: {
            ...response,
            adHocTasks: response?.adHocTasks?.map((obj) => ({ ...obj, key: obj.id }))
        }
    }
}
