import React from "react";
import { Layout } from "antd";
import "./content.scss";

const { Content: AntdContent } = Layout;

const Content = ({ children, alignVertically, ...props }) => {
  const classNames = ["content"];
  if (alignVertically) {
    classNames.push("content--align-vertically")
  }

  return (
    <AntdContent
      className={classNames.join(" ")}
      {...props}
    >
      {children}
    </AntdContent>
  );
}

export default Content;
