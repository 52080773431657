import React from 'react';
import Spin from 'antd/es/spin';

const LxtLoadingLogo = ({ text }) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: '50px',
                right: '0',
                left: '0',
                bottom: '0',
                backgroundColor: 'transparent'
            }}
        >
            <img
                src={process.env.PUBLIC_URL + '/images/ls_logo.svg'}
                alt='logo'
                style={{ width: '200px', height: '200px' }}
            />

            <Spin
                size='large'
                spinning
                tip={<span style={{ fontSize: '2rem' }}>{text}</span>}
            />

        </div>
    )
}

export default LxtLoadingLogo;
