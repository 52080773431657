import React from 'react';
import { Card } from 'antd';

import ProjectAssigned from './NotificationDropDownMenuCardType/ProjectAssigned';
import CompletionRejected from './NotificationDropDownMenuCardType/CompletionRejected';
import TaskAssigned from './NotificationDropDownMenuCardType/TaskAssigned';
import { NOTIFICATIONS_TYPES } from '../LookUpTables';


const NotificationCard = ({ notification }) => {
    const content = notification.content;

    return (
        <Card
            size='small'
            bodyStyle={{
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'pre-line',
                fontSize: '0.8rem',
            }}
        >
            {content && notification.type === NOTIFICATIONS_TYPES.ProjectAssignment && <ProjectAssigned notification={notification} />}
            {content && notification.type === NOTIFICATIONS_TYPES.CompletionRejection && <CompletionRejected notification={notification} />}
            {content && notification.type === NOTIFICATIONS_TYPES.TaskAssignment && <TaskAssigned notification={notification} />}
        </Card>
    )
}

export default NotificationCard;