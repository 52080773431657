import React from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const ResetTableButton = ({ tooltipText, tooltipPlacement, isDisabled, onReset }) => {
    return (
        <Tooltip title={tooltipText} placement={tooltipPlacement}>
            <Button
                type='dashed'
                size='small'
                disabled={isDisabled}
                onClick={onReset}>
                <ClearOutlined />
            </Button>
        </Tooltip>
    );
};

export default ResetTableButton;