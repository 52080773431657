import { useMemo } from "react";
import { Form, Tooltip } from "antd";
import { MultiSelectField } from "../../PM/SelectExt";
import ErrorAlert from "../../Error/ErrorAlert";
import useOptionsContext from '../../../contexts/OptionsContext';
import OnlyNativeLanguageToggler from "./OnlyNativeLanguageToggler";
import { useMediaQuery } from "react-responsive";

const { Item } = Form;

const OtherLanguages = ({ onLanguageChange, otherLanguages, nativeLanguage, OnToggleCheckBox, isDisabled }) => {

    const {
        languages,
        languagesError,
    } = useOptionsContext();

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    const tooltipPlacement = isTabletOrMobile ? 'topLeft' : 'top';

    const filteredLanguages = useMemo(() => languages
        .map(lg => ({ value: lg.id, key: lg.id, label: lg.name }))
        .filter(lang => nativeLanguage !== lang.value)
        , [languages, nativeLanguage]);

    return (
        <Tooltip
            title="Please add the language(s) that you can write and speak fluently"
            placement={tooltipPlacement}
        >
            <Item
                label="Other Languages"
                name="languages"
                rules={[
                    {
                        required: (otherLanguages.length === 0 && !isDisabled),
                        message: "Please set your languages!",
                    }
                ]}>
                <MultiSelectField
                    selectedTags={otherLanguages}
                    onTagsChange={onLanguageChange}
                    placeholder="Set your languages here..."
                    options={filteredLanguages}
                    isDisabled={isDisabled}
                />

                <OnlyNativeLanguageToggler
                    OnToggleCheckBox={OnToggleCheckBox}
                />

                <ErrorAlert error={languagesError} />
            </Item>
        </Tooltip>
    )
}

export default OtherLanguages;