import React, { useState } from 'react';
import { Button, Tag, Spin, message, Modal } from "antd";
import { SendOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';

import TagsBox from '../../Common/TagsBox';
import ErrorAlert from '../../Error/ErrorAlert';
import usePMUsersContext from '../../../contexts/PMUsersContext';
import { validateEmail } from '../../Common/Utils';

const UsersSendInvitations = () => {

    const {
        invitationsBeingSent,
        invitationsSendError,
        sendInvitations,
        clearSendInvitationError
    } = usePMUsersContext();

    const [state, setState] = useState({
        emails: [],
        invalidEmails: []
    })

    const onSendInvitations = async () => {

        // Filtering to get the valid emails to send them in the request.
        const validEmails = state.emails
            .filter((em) => validateEmail(em))
            .map((em) => em.toLowerCase());

        if (validEmails.length > 0) {
            sendInvitations(validEmails)
                .then(failedToInviteEmails => {
                    if (failedToInviteEmails) {
                        // Keeping the failed to invite emails with the invalid emails in the tags box and removing valid emails.
                        setState(ps => ({
                            ...ps,
                            emails: [...ps.invalidEmails, ...failedToInviteEmails]
                        }))

                        // Displaying the warning message.
                        Modal.warning({
                            title: "The following emails don't exist or belong to already existing users!",
                            content: <ul>{failedToInviteEmails.map(fi => <li key={nanoid()}>{fi}</li>)}</ul>
                        })
                    }
                    else {
                        // Keeping the invalid emails in the tags box and removing valid emails.
                        setState(ps => ({
                            ...ps,
                            emails: [...ps.invalidEmails]
                        }))
                        message.success('Email invitations are sent successfully.', 4);
                    }
                })
                .catch(e => {
                    //Do nothing the error is reported by invitationsSendError.
                });
        }
        else {
            message.warning('No valid emails are found to send invitations to!', 4);
        }
    };

    const handleEmailChanged = (emails) => {
        clearSendInvitationError();
        setState(ps => ({
            ...ps,
            emails,
            invalidEmails: emails.filter(em => !validateEmail(em))
        }))
    }

    return (
        <Spin spinning={invitationsBeingSent}>

            <TagsBox
                style={{ width: '100%', marginTop: '20px' }}
                size='large'
                value={state.emails}
                onChange={handleEmailChanged}
                tagRender={tagRender}
                placeholder="Paste emails here..."
                allowClear={true}
            />

            <ErrorAlert error={invitationsSendError} />

            <Button
                style={{ marginTop: '20px', display: 'block' }}
                type='primary'
                size='large'
                onClick={onSendInvitations}>
                <SendOutlined /> Send Invitations
            </Button>

        </Spin>
    )
}

const tagRender = ({ value, label, closable, onClose }) => {
    const valid = validateEmail(label);
    return (
        <Tag color={valid ? '#081f2d' : '#f50'}
            closable={closable}
            onClose={onClose}>
            <span style={{ fontSize: '12pt' }}>{label}</span>
        </Tag>
    );
}

export default UsersSendInvitations;
