import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Alert, Space, Input, Spin, Typography } from "antd";

import Content from "../AntdWrappers/Content/Content";
import FormItem from "../AntdWrappers/FormItem/FormItem";
import Form from "../AntdWrappers/Form/Form";

import { useAuthContext } from '../../contexts/AuthContext';
import ErrorAlert from '../Error/ErrorAlert';
import { ROOT_PATH } from '../../routes';
import "./Auth.scss"


const { Text, Link, Title } = Typography;

const SendInvitation = () => {

  const {
    user,
    sendInvitation,
    error,
    isLoading,
    success,
    clearSuccessMessage,
    clearError,
  } = useAuthContext();

  useEffect(() => {
    //Required to clear the success message which could be set by another component
    //and clear the current user because no signed user will enter this page.
    clearSuccessMessage();
    //eslint-disable-next-line
  }, []);

  const history = useHistory();

  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 30,
    },
  };

  const handleSendInvitation = (values) => {
    sendInvitation(values.email);
  }

  const handleBackToSignIn = () => {
    clearSuccessMessage();
    clearError();
    history.push(ROOT_PATH);
  }

  return (
    <Content alignVertically>

      {/* Redirecting the user back to root if the user already signed in and this will take him to the proper landing page to his. */}
      {user && <Redirect to={ROOT_PATH} />}

      {!user &&
        <Spin spinning={isLoading}>
          <Space align="center" direction="vertical">
            <Title style={{ fontWeight: '400' }}>LXT Data Platform</Title>
            <Text>
              Thank you for your interest in LXT! To be considered for future projects, please start by entering your email and you will be directed through the sign-up process.
            </Text>
            <Form
              name="basic"
              labelCol={{ span: 5 }}
              initialValues={{ remember: true }}
              style={{ width: '300px', marginTop: '24px' }}
              onFinish={handleSendInvitation}
              border>

              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
                validateTrigger={"onBlur"}>
                <Input placeholder='Enter your email to get started' />
              </FormItem>

              <div className="auth-footer">
                <Button
                  type='link'
                  onClick={handleBackToSignIn}>
                  Back to sign in
                </Button>

                <FormItem align="center" {...tailLayout}>
                  <Button type="primary" htmlType="submit" style={{ float: 'right' }}>Sign up</Button>
                </FormItem>
              </div>
              <Space>
                <Text>
                  By clicking the Sign up button you agree to our
                  <Link
                    href="https://www.lxt.ai/privacy-policy/"
                    target="_blank"
                    style={{ color: '#1890ff', padding: '0 5px' }}
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </Space>
            </Form>

            {success && success.isSuccess && <Alert message={success.message} type="success" />}

            <ErrorAlert error={error} />

          </Space>
        </Spin>
      }

    </Content>)
}

export default SendInvitation;
