import React, { createContext, useState } from 'react';
import projectsApi from '../api/projects';
import { formatError } from './ResponseErrorFormatter';

const PMPrjContext = createContext();

const EMPTY_CRITERIA = {
    search: '',
    statusFilter: [],
    skip: 0,
    limit: 10,

    orderColumn: "id",
    orderAscending: false,
}

const PMPrjContextProvider = ({ children }) => {

    const [state, setState] = useState({
        projects: [],
        projectsCount: 0,
        isLoading: false,
        error: null,
        criteria: EMPTY_CRITERIA,
    });

    const getProjects = async (fetchCriteria) => {

        // Regulating the input fetch criteria to make the context set the missing property names in the fetch criteria.
        if (fetchCriteria) {
            // In the following we can't save the trimmed value since the components are binding 
            // to it directly, so the user will not be able to add spaces if the saved value is
            // trimmed.
            fetchCriteria.search = typeof fetchCriteria.search === 'string' ? fetchCriteria.search : EMPTY_CRITERIA.search;
            fetchCriteria.statusFilter = Array.isArray(fetchCriteria.statusFilter) ? fetchCriteria.statusFilter : EMPTY_CRITERIA.statusFilter;
            fetchCriteria.skip = typeof fetchCriteria.skip === 'number' ? fetchCriteria.skip : EMPTY_CRITERIA.skip;
            fetchCriteria.limit = typeof fetchCriteria.limit === 'number' ? fetchCriteria.limit : EMPTY_CRITERIA.limit;
            fetchCriteria.orderColumn = typeof fetchCriteria.orderColumn === 'string' ? fetchCriteria.orderColumn : EMPTY_CRITERIA.orderColumn;
            fetchCriteria.orderAscending = typeof fetchCriteria.orderAscending === 'boolean' ? fetchCriteria.orderAscending : EMPTY_CRITERIA.orderAscending;
        }
        else {
            fetchCriteria = EMPTY_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            isLoading: true,
            error: null,
            criteria: fetchCriteria,
        }));

        await projectsApi.getPMProjects(fetchCriteria)
            .then(res => {
                setState(ps => ({
                    ...ps,
                    projects: res.data.projects,
                    projectsCount: res.data.count,
                    isLoading: false,
                    error: null,
                }));
            })
            .catch(e => {
                setState(ps => ({
                    ...ps,
                    projects: [],
                    projectsCount: 0,
                    isLoading: false,
                    error: formatError(e, 'Failed To Retrieve Projects!')
                }));
            });
    };

    const clearError = () => setState(ps => ({ ...ps, error: null }));

    const services = {
        ...state,
        getProjects,
        clearError
    }

    return (
        <PMPrjContext.Provider value={services}>
            {children}
        </PMPrjContext.Provider>
    );
}

const PMPrjContextConsumer = ({ children }) => {
    return (
        <PMPrjContext.Consumer>
            {children}
        </PMPrjContext.Consumer>
    );
}

export { PMPrjContext as default, PMPrjContextProvider, PMPrjContextConsumer };
