import React, { useContext, createContext, useState } from 'react';
import notificationApi from '../api/notifications';
import { formatError } from './ResponseErrorFormatter';

const NotificationsContext = createContext();

const DEFAULT_NOTIFICATIONS_CRITERIA = {
    read: null,
    cleared: false,
    skip: 0,
    limit: 5,
}

const EMPTY_STATE = {
    notificationsLoading: false,
    notifications: [],
    notificationsCount: 0,
    notificationsCriteria: DEFAULT_NOTIFICATIONS_CRITERIA,
    notificationsError: null,
}

const NotificationsContextProvider = ({ children }) => {

    const [state, setState] = useState(EMPTY_STATE);

    const fetchNotifications = (criteria) => {

        if (criteria) {
            criteria.read = typeof criteria.read === 'boolean' ? criteria.read : DEFAULT_NOTIFICATIONS_CRITERIA.read;
            criteria.cleared = typeof criteria.cleared === 'boolean' ? criteria.cleared : DEFAULT_NOTIFICATIONS_CRITERIA.cleared;
            criteria.skip = typeof criteria.skip === 'number' ? criteria.skip : DEFAULT_NOTIFICATIONS_CRITERIA.skip;
            criteria.limit = typeof criteria.limit === 'number' ? criteria.limit : DEFAULT_NOTIFICATIONS_CRITERIA.limit;
        }
        else {
            criteria = DEFAULT_NOTIFICATIONS_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            notificationsCriteria: criteria,
            notificationsLoading: true,
        }));

        return new Promise((resolve, reject) => {
            notificationApi.getNotifications(criteria)
                .then(res => {
                    setState(ps => ({
                        ...ps,
                        notificationsLoading: false,
                        notificationsError: null,
                        notifications: res.data.notifications,
                        notificationsCount: res.data.count,
                    }));
                    resolve();
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        notificationsLoading: false,
                        notificationsError: formatError(e, 'Failed To Retrieve Notifications!'),
                    }));
                    reject();
                })
        })

    }

    const fetchMore = () => {
        const criteria = {
            ...state.notificationsCriteria,
            // In order not to keep increasing the notifications limit indefinitely, we need first to make sure that the latest fetch got a full data.
            limit: state.notifications.length < state.notificationsCriteria.limit ? state.notificationsCriteria.limit : state.notificationsCriteria.limit + DEFAULT_NOTIFICATIONS_CRITERIA.limit,
        }
        return fetchNotifications(criteria);
    }

    const clearNotifications = (ids) => {

        if (!Array.isArray(ids)) {
            return Promise.reject(new Error('Invalid Argument: id'))
        }

        return new Promise((resolve, reject) => {
            notificationApi.clearNotifications(ids)
                .then(() => {
                    setState(ps => ({
                        ...ps,
                        notifications: ps.notifications.filter(n => !ids.includes(n.id)),
                        notificationsCount: ps.notificationsCount - ids.length,
                    }));
                    resolve();
                })
                .catch(e => {
                    reject(formatError(e, 'Failed To Clear Notification(s)!'));
                })
        });
    }

    const clearAllNotifications = () => {
        return new Promise((resolve, reject) => {
            notificationApi.clearAllNotifications()
                .then(() => {
                    setState(ps => ({
                        ...ps,
                        notifications: [],
                        notificationsCount: 0,
                    }));
                    resolve();
                })
                .catch(e => {
                    reject(formatError(e, 'Failed To Clear All Notifications!'));
                })
        });
    }

    const restoreNotification = (id) => {
        return new Promise((resolve, reject) => {
            notificationApi.restoreNotification(id)
                .then(() => {
                    setState(ps => ({
                        ...ps,
                        notifications: ps.notifications.filter(n => n.id !== id),
                        notificationsCount: ps.notificationsCount - 1,
                    }));
                    resolve();
                })
                .catch(e => {
                    reject(formatError(e, 'Failed To Resotre Notification!'));
                })
        });
    }

    const clearError = () => setState(ps => ({ ...ps, notificationsError: EMPTY_STATE.notificationsError }))

    const cleanContext = () => {
        setState(() => EMPTY_STATE);
    }

    const services = {
        ...state,
        fetchNotifications,
        fetchMore,
        clearError,
        clearNotifications,
        clearAllNotifications,
        restoreNotification,
        cleanContext,
    };

    return (
        <NotificationsContext.Provider value={services}>
            {children}
        </NotificationsContext.Provider>
    )
}

const useNotificationsContext = () => useContext(NotificationsContext);

export { useNotificationsContext as default, NotificationsContextProvider };