import React, { Fragment } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import { nanoid } from 'nanoid';

import ProjectOwnersSelector from './ProjectOwnersSelector';
import useOptionsContext from '../../../contexts/OptionsContext';
import { formatDateWithTimeStamp } from '../../../LSF/utils/date';

const { Item } = Form;

const BasicInfo = () => {

    const { writingScripts, locals, clients, projectServices, securityLevel } = useOptionsContext();

    return (
        <Fragment>

            <Item
                label='Project Name'
                name="name"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: "Please input project name!",
                    },
                ]}>
                <Input placeholder="Type project's name" />
            </Item>

            <Item
                label='Client'
                name="client"
                rules={[
                    {
                        required: true,
                        message: "Please enter the client's name!",
                    },
                ]}>
                <Select
                    showSearch
                    placeholder="Select a client..."
                    options={clients.map(cl => ({ label: cl, value: cl, key: nanoid() }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            </Item>

            <Item
                label="Service"
                name="service"
                rules={[
                    {
                        required: true,
                        message: "Please select service!",
                    },
                ]}>
                <Select
                    showSearch
                    placeholder="Select project service..."
                    options={projectServices.map(ps => ({ label: ps, value: ps, key: nanoid() }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            </Item>

            <Item
                label="Project Security Level"
                name="securityLevel"
                rules={[
                    {
                        required: true,
                        message: "Please select project's security level!",
                    },
                ]}>
                <Select
                    placeholder="Select project's security level..."
                    options={securityLevel.map(sl => ({ label: sl, value: sl, key: nanoid() }))}
                />
            </Item>

            <ProjectOwnersSelector />

            <Item
                label="Start Date"
                name="startedOn"
                dependencies={['kickoffDate']}
                rules={[
                    {
                        required: true,
                        message: "Please select start date!",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            const kickOff = getFieldValue('kickoffDate') ?
                                formatDateWithTimeStamp(getFieldValue('kickoffDate')) : null

                            const startedOn = value ? formatDateWithTimeStamp(value) : null

                            if (startedOn && kickOff && startedOn > kickOff)
                                return Promise.reject('Start Date must be ealier than or equal Kick Off Date!');
                            else
                                return Promise.resolve();
                        },
                    })
                ]}>
                <DatePicker format='DD/MM/YYYY' />
            </Item>

            <Item
                label="Kick Off Date"
                dependencies={['startedOn', 'eta']}
                name="kickoffDate"
                rules={[
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            const eta = getFieldValue('eta') ?
                                formatDateWithTimeStamp(getFieldValue('eta')) : null

                            const startedOn = getFieldValue('startedOn') ?
                                formatDateWithTimeStamp(getFieldValue('startedOn')) : null

                            const kickOff = value ? formatDateWithTimeStamp(value) : null

                            if (kickOff && eta && kickOff > eta)
                                return Promise.reject('Kick Off Date must be ealier than or equal ETA Date!');
                            else if (kickOff && startedOn && kickOff < startedOn)
                                return Promise.reject('Kick Off Date must be later than or equal Start Date!');
                            else
                                return Promise.resolve();
                        },
                    })
                ]}>
                <DatePicker format='DD/MM/YYYY' />
            </Item>

            <Item
                label="ETA Date"
                name="eta"
                dependencies={['startedOn', 'kickoffDate']}
                rules={[
                    {
                        required: true,
                        message: "Please select ETA date!",
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            const kickOff = getFieldValue('kickoffDate') ?
                                formatDateWithTimeStamp(getFieldValue('kickoffDate')) : null

                            const startedOn = getFieldValue('startedOn') ?
                                formatDateWithTimeStamp(getFieldValue('startedOn')) : null

                            const eta = value ? formatDateWithTimeStamp(value) : null

                            if (eta && kickOff && eta < kickOff)
                                return Promise.reject('ETA must be later than or equal Kick off date!');
                            else if (eta && startedOn && eta < startedOn)
                                return Promise.reject('ETA must be later than or equal Start date!');
                            else
                                return Promise.resolve();
                        },
                    })
                ]}>
                <DatePicker format='DD/MM/YYYY' />
            </Item>

            <Item
                label="Locale"
                name="locale">
                <Select
                    showSearch
                    placeholder="Select locale..."
                    options={Object.keys(locals).map(localeKey => ({ label: locals[localeKey], value: localeKey, key: nanoid() }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            </Item>

            <Item
                label="Writing Script"
                name="writingScript">
                <Select
                    showSearch
                    placeholder="Select a writing script..."
                    options={writingScripts.map(ws => ({ label: ws, value: ws, key: nanoid() }))}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
            </Item>

        </Fragment >
    )
}

export default BasicInfo;