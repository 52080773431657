import { Input } from "antd";
import { useState, useCallback } from 'react';

const CostInputSearch = ({ onSearch }) => {

    const [searchValue, setSearchValue] = useState("");

    const handleOnSearchChange = useCallback((e) => {
        setSearchValue(e.target.value)
    }, []);

    return (
        <Input.Search
            enterButton
            allowClear
            size='small'
            style={{ width: '350px' }}
            placeholder="Search contributors by ID, name, or email..."
            onChange={handleOnSearchChange}
            onSearch={onSearch}
            onBlur={() => onSearch(searchValue)}
        />
    )
}

export default CostInputSearch;