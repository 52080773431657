import * as React from 'react';
import { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-balham.css';

import { COMPLETION_STATUS_VALUES } from '../../../../Constants';
import CommaSeparatedFilter from './CommaSeparatedFilter';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const autoSizeStrategy = {
	type: 'fitGridWidth',
};

export const GridView = ({
	rowData,
	onSelection,
	isLoading,
	totalRowCount,
	onLoadMore,
	maxCount,
}) => {
	const gridApi = useRef(null);

	const onGridReady = ({ api }) => {
		gridApi.current = api;
	};

	const formatDateTime = (
		date,
		format = 'HH:mm:ss A DD-MM-YYYY',
		fallback = '-',
	) => {
		if (!date) return fallback;

		const d = new Date(date);
		if (isNaN(d.getTime())) return fallback;

		const day = d.getDate().toString().padStart(2, '0');
		const month = (d.getMonth() + 1).toString().padStart(2, '0');
		const year = d.getFullYear();

		let hours = d.getHours();
		const minutes = d.getMinutes().toString().padStart(2, '0');
		const seconds = d.getSeconds().toString().padStart(2, '0');

		const isPM = hours >= 12;
		const period = isPM ? 'PM' : 'AM';
		hours = hours % 12 || 12; // Convert to 12-hour format, replacing 0 with 12const paddedHours = hours.toString().padStart(2, '0');
		const paddedHours = hours.toString().padStart(2, '0');

		return format
			.replace('DD', day)
			.replace('MM', month)
			.replace('YYYY', year)
			.replace('HH', paddedHours)
			.replace('mm', minutes)
			.replace('ss', seconds)
			.replace('A', period)
			.replace('a', period.toLowerCase());
	};
	const formatDPName = ({ data: { dataProcessor } }) => {
		if (
			!dataProcessor ||
			!dataProcessor?.firstName ||
			!dataProcessor?.lastName
		) {
			return '-';
		}
		return `${dataProcessor.firstName} ${dataProcessor.lastName}`;
	};

	const formatRVName = ({ data: { reviewer } }) => {
		if (!reviewer || !reviewer?.firstName || !reviewer?.lastName) {
			return '-';
		}
		return `${reviewer.firstName} ${reviewer.lastName}`;
	};

	const loadMore = () => {
		onLoadMore();
	};
	const columnDefs = [
		{
			width: 40,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			resizable: false,
		},
		{
			headerName: 'Completion ID',
			field: 'id',
			filter: CommaSeparatedFilter,
		},
		{
			headerName: 'Task ID',
			field: 'taskId',
			filter: CommaSeparatedFilter,
		},
		{
			headerName: 'Status',
			field: 'status',
			valueGetter: (params) => params.data.status,
			valueFormatter: (params) => COMPLETION_STATUS_VALUES[params.value],
			filterValueGetter: (params) =>
				COMPLETION_STATUS_VALUES[params.data.status],
			headerTooltip: `Filter by status: ${Object.values(
				COMPLETION_STATUS_VALUES,
			).join(' , ')}`,
			filter: 'true',
		},
		{
			headerName: 'Data Processor ID',
			field: 'dataProcessor.id',
			valueGetter: (params) => params.data.dataProcessor?.id,
			filterValueGetter: (params) => params.data.dataProcessor?.id,
			filter: CommaSeparatedFilter,
		},
		{
			headerName: 'Data Processor Name',
			field: 'dataProcessor.name',
			valueGetter: formatDPName,
			valueFormatter: formatDPName,
			filterValueGetter: formatDPName,
			filter: true,
		},
		{
			headerName: 'Reviewer ID',
			field: 'reviewer.id',
			valueGetter: (params) => params.data.reviewer?.id,
			filterValueGetter: (params) => params.data.reviewer?.id,
			filter: CommaSeparatedFilter,
		},
		{
			headerName: 'Reviewer Name',
			field: 'reviewer.name',
			valueGetter: formatRVName,
			valueFormatter: formatRVName,
			filterValueGetter: formatRVName,
		},
		{
			headerName: 'In Progress On',
			field: 'inProgressOn',
			valueGetter: (params) => formatDateTime(params.data.inProgressOn),
			filterValueGetter: (params) => formatDateTime(params.data.inProgressOn),
			filter: 'agDateColumnFilter',
		},
		{
			headerName: 'Ready On',
			field: 'readyOn',
			valueGetter: (params) => formatDateTime(params.data.readyOn),
			filterValueGetter: (params) => formatDateTime(params.data.readyOn),
			filter: 'agDateColumnFilter',
		},
		{
			headerName: 'In Review On',
			field: 'inReviewOn',
			valueGetter: (params) => formatDateTime(params.data.inReviewOn),
			filterValueGetter: (params) => formatDateTime(params.data.inReviewOn),
			filter: 'agDateColumnFilter',
		},
		{
			headerName: 'Completed On',
			field: 'completedOn',
			valueGetter: (params) => formatDateTime(params.data.completedOn),
			filterValueGetter: (params) => formatDateTime(params.data.completedOn),
			filter: 'agDateColumnFilter',
		},
	];

	const handleSelectionChanged = ({ api }) => {
		const selectedNodes = api.getSelectedNodes();
		const selectedData = selectedNodes.map((node) => node.data);
		onSelection(selectedData);
	};

	return (
		<div className="ag-theme-balham" style={{ height: 500, marginTop: 40 }}>
			<AgGridReact
				columnDefs={columnDefs}
				rowData={rowData}
				pagination={true}
				paginationPageSize={100}
				paginationPageSizeSelector={[10, 25, 50, 100, 1000, maxCount]}
				rowSelection="multiple"
				checkboxSelection={true}
				suppressRowClickSelection={true}
				onSelectionChanged={handleSelectionChanged}
				loading={isLoading}
				autoSizeStrategy={autoSizeStrategy}
				onGridReady={onGridReady}
				enableCellTextSelection={true}
			/>
			{totalRowCount > maxCount && (
				<div style={{ textAlign: 'center', marginTop: 10 }}>
					{totalRowCount > rowData.length && (
						<span>
							Showing only first {rowData?.length} out of {totalRowCount}{' '}
							records.
						</span>
					)}
					{totalRowCount === rowData.length && (
						<span style={{ marginLeft: 10 }}>All records are loaded.</span>
					)}

					{/*	Load more button */}

					<Button
						type="link"
						size="small"
						onClick={loadMore}
						icon={<DownloadOutlined />}
						disabled={isLoading || rowData.length >= totalRowCount}
					>
						Load more
					</Button>
				</div>
			)}
		</div>
	);
};
