import React, { Fragment } from 'react';

import { NOTIFICATIONS_TYPES } from '../../LookUpTables';
import '../../../Styles/Button.scss';

import ProjectAssignmentCard from './ProjectAssignmentCard';
import CompletionRejectionCard from './CompletionRejectionCard';
import TaskAssignmentCard from './TaskAssignmentCard';

import "./NotificationPageCard.scss"

const NotificationPageCard = ({ notification }) => {

    const content = notification.content;
    return (
        <Fragment>
            {notification.type === NOTIFICATIONS_TYPES.ProjectAssignment && content && <ProjectAssignmentCard notification={notification} />}
            {notification.type === NOTIFICATIONS_TYPES.TaskAssignment && content && <TaskAssignmentCard notification={notification} />}
            {notification.type === NOTIFICATIONS_TYPES.CompletionRejection && content && <CompletionRejectionCard notification={notification} />}
        </Fragment>
    )
}

export default NotificationPageCard 
