import React, { useEffect, useState } from 'react';
import { Drawer, List, Progress, Space } from 'antd';
import { CloseOutlined, InboxOutlined, MailOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
	UploadFileNotificationItem,
	useUploadList,
} from '../Notifications/List/UploadFileNotification';

const UploadProgressDrawer = () => {
	const [visible, setVisible] = useState(false);
	const [collapsed, setCollapsed] = useState(true);
	const [uploadFiles, setUploadFiles] = useState([]);

	const uploadList = useUploadList();

	const totalProgress =
		uploadList?.allUploadTaskList?.length > 0
			? uploadList?.allUploadTaskList?.reduce(
					(acc, item) => acc + item.progress,
					0,
			  ) / uploadList?.allUploadTaskList?.length
			: 0;

	useEffect(() => {
		if (uploadList?.allUploadTaskList?.length > 0) {
			setVisible(true);

			// Get all files to upload from the uploadList
			const fileList = uploadList?.allUploadTaskList.reduce((acc, item) => {
				 const files = Object.entries(item?.files || {}).map(([fileID, fileData]) => fileData);
					if (files && files.length > 0) {
						return [...files, ...acc];
					}
					return acc;
				}, []);

			// Show the drawer if there are files to upload
			if (fileList.length > 0) {
				setUploadFiles(fileList);
				setCollapsed(false);
			}

		} else {
			setVisible(false);
		}
	}, [uploadList]);

	const handleClose = () => setVisible(false);
	const handleToggleCollapse = () => setCollapsed(!collapsed);

	return (
		visible && (
			<Drawer
				placement="bottom"
				height={collapsed ? 43 : 300}
				visible={visible}
				mask={false}
				closable={false}
				bodyStyle={{ padding: 0, height: collapsed ? 'auto' : 0 }}
				headerStyle={{ padding: 0, border: 'none' }}
				footerStyle={{ backgroundColor: 'rgba(225,227,230,0.57)' }}
				style={{ bottom: 0, width: 450, left: '65vw' }}
				footer={
					<Space
						style={{
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						direction="horizontal"
					>
						<Space
							style={{
								width: '100%',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
							direction="horizontal"
						>
							<span>Upload Progress</span>
							{/*<div*/}
							{/*	style={{ width: 200, height: '100%', transition: 'width 0.5s' }}*/}
							{/*>*/}
							{/*	<Progress percent={totalProgress} status="active" />*/}
							{/*</div>*/}
						</Space>

						<Space>
							{collapsed ? (
								<PlusOutlined onClick={handleToggleCollapse} />
							) : (
								<MinusOutlined onClick={handleToggleCollapse} />
							)}
							<CloseOutlined onClick={handleClose} />
						</Space>
					</Space>
				}
			>
				{!collapsed ? (
					<List
						itemLayout="horizontal"
						style={{ padding: '0 15px' }}
						dataSource={uploadList?.allUploadTaskList}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta
									description={
										<UploadFileNotificationItem uploadCompletion={item} />
									}
								/>
							</List.Item>
						)}
					/>
				) : null}
				{!collapsed && uploadFiles.length === 0 ? (
					<Space
						style={{
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						direction="vertical"
					>
						<InboxOutlined style={{ fontSize: 30, color: 'rgba(0,0,0,0.45)' }} />
						<span style={{color: 'rgba(0,0,0,0.45)'}} >No Uploads in Progress</span>
					</Space>
				) : null}
			</Drawer>
		)
	);
};

export default UploadProgressDrawer;
