import React from "react";
import { Link } from "react-router-dom";
import { Input, Button, Divider } from "antd";

import { RESET_PASSWORD_PATH, SEND_INVITATION_PATH } from '../../../routes';
import Form from "../../AntdWrappers/Form/Form";
import FormItem from "../../AntdWrappers/FormItem/FormItem";
import "../Auth.scss"

function LoginWithEmailPasswordMobile({ signInByEmailAndPassword }) {

    const onSubmit = async ({ email, password }) => {
        signInByEmailAndPassword(email, password);
    };



    return (
        <div>
            <p className="mobile-card-header">Sign In</p>

            <div className="mobile-card-style">
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onSubmit}
                >
                    <FormItem
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                            },
                        ]}
                        validateTrigger={"onBlur"}
                    >
                        <Input placeholder="Email" autoComplete="new-password" />
                    </FormItem>

                    <FormItem
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" autoComplete="new-password" />
                    </FormItem>

                    <FormItem >
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{
                                width: '90vw',
                                marginTop: '20px'
                            }}>
                            Log in
                        </Button>
                    </FormItem>

                    <Divider plain> OR</Divider>
                    <Link to={RESET_PASSWORD_PATH} style={{ display: 'flex', justifyContent: 'center' }}>Forgot password?</Link>


                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                        <span style={{ paddingRight: '5px' }}>Don't have an account?</span><Link to={SEND_INVITATION_PATH}>Sign up</Link>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default LoginWithEmailPasswordMobile;
