import { Form, InputNumber } from "antd"
import y from "../../../../lib/yupExtended";

const { Item } = Form;

const CostInput = ({ costLabel, costKey }) => {
    return (
        <Item
            label={costLabel}
            name={costKey}
            rules={[
                {
                    required: true,
                    message: "Please input cost!",
                },
                {
                    validator: (_, value) => {
                        if (value && !y.number().maxDecimalPlaces(3).isValidSync(value)) {
                            console.log("!y.number().maxDecimalPlaces(3).isValidSync(value)", y.number().maxDecimalPlaces(3).isValidSync(value));
                            return Promise.reject('Value must not have more than 3 decimal places!');
                        }
                        return Promise.resolve();
                    }
                }
            ]}>
            <InputNumber min={0} />
        </Item>
    )
}

export default CostInput;