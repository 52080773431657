import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import GeoapifySelect from '../Common/GeoapifySelect';
import { useFeatureIsOn } from '../../lib/featureToggles';

const HometownSelect = ({ onChange, value }) => {

    const onSelectHometown = (value) => {
        if (value) {
            const hometownDetails = {
                city: value.properties.city || value.properties.state,
                state: value.properties.state,
                country: value.properties.country,
                formatted: value.properties.formatted,
                lat: value.properties.lat,
                lon: value.properties.lon
            };

            onChange(hometownDetails);
        } else {
            onChange(null);
        }
    }

    return (
        <GeoapifySelect
            value={value?.formatted ? value?.formatted : ''}
            onChange={onSelectHometown}
        />
    )
};

const HometownInput = ({ user, editMode, onChangeEditMode }) => {

    const [originLocation, setOriginLocation] = useState(null);

    const [isOriginLocationEditable, setIsOriginLocationEditable] = useState(false);

    const isProfileEnhancementEnabled = useFeatureIsOn("contributor-profile-enhancement");

    const handleOnChange = useCallback((value) => {
        setOriginLocation(value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsOriginLocationEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const hasOriginLocation = Boolean(user.originLocation);
    const isEditable = isOriginLocationEditable;

    return (
        <ProfileEntry
            label="Hometown"
            optional={isProfileEnhancementEnabled}
            value={
                (hasOriginLocation && !isEditable) ?
                    user.originLocation.formatted :
                    (isEditable) ?
                        <HometownSelect
                            onChange={handleOnChange}
                            value={originLocation}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="originLocation"
                    inputValue={originLocation}
                    inputEditable={isOriginLocationEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!originLocation ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    )
};

export default HometownInput;