import AdhocForm from "./AdhocForm";
import AdhocTable from "./AdhocTable";
import { IfFeature } from "../../../../lib/featureToggles";

const Adhoc = () => {
    return (
        <>
            <IfFeature isEnabled feature="adhoc-contributor-task">
                <AdhocForm />
            </IfFeature>
            <IfFeature isEnabled feature="adhoc-contributor-task">
                <AdhocTable />
            </IfFeature>
        </>
    )
}

export default Adhoc;