import React, { Fragment } from 'react';
import { Form, InputNumber, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';


const { Item } = Form;


const CompletionsInfo = () => {

    return (
        <Fragment>

            <Item
                label="Completions Per Task"
                name="completionsPerTask"
                initialValue={1}
                rules={[
                    {
                        required: true,
                        message: "Please set the number of completions required for each task!",
                    },
                ]}>
                <InputNumber min={1} />
            </Item>

            <Item
                label="Allow duplicate completions"
                name="allowDuplicateCompletions"
                initialValue={false}
                valuePropName="checked">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} />
            </Item>

            <Item
                label="Force different completions by different users"
                name="diffUsersToTaskCompletions"
                initialValue={false}
                valuePropName="checked">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} />
            </Item>

            <Item
                label="Show previous completions for the same task"
                name="showPreviousCompletions"
                initialValue={false}
                valuePropName="checked">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} />
            </Item>

        </Fragment>
    )
}

export default CompletionsInfo;