import React, { useState } from 'react';
import { Button, Comment, Space, Tag, Tooltip } from 'antd';

import { EditTwoTone, DeleteTwoTone, UserOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import CommentEditor from './CommentEditor';
import useCompletionContext from '../../../../contexts/CompletionContext';
import useCommentsContext from './CommentsContext';
import moment from 'moment';

const Dot = ({ length, bgColor, sideMargin, margBottom }) => {
    return (
        <span style={{
            display: "inline-block",
            height: length,
            width: length,
            margin: `0px ${sideMargin} ${margBottom} ${sideMargin}`,
            borderRadius: "50%",
            backgroundColor: bgColor
        }} />)
}

const SingleComment = ({ comment }) => {

    const {
        deleteComment,
    } = useCompletionContext();

    const {
        editedComment, // The comment that is currently being edited.
        setLoading,
        setError,
        activateEditMode,
        deactivateEditMode,
    } = useCommentsContext();

    const [state, setState] = useState({
        showConfirmDeleteButtons: false,
        commentInputText: comment.text,
    })

    const handleOnEditClick = () => {
        activateEditMode(comment)
    }

    const handleOnDeleteClick = () => {
        setState(ps => ({ ...ps, showConfirmDeleteButtons: true }));
    }

    const handleOnDeleteConfirmClick = () => {
        setState(ps => ({ ...ps, showConfirmDeleteButtons: false }));
        setLoading(true);
        deleteComment(comment.id)
            .then(() => {
                // Deactivating the edit mode which could be activated before the delete action.
                // This is to avoid errors of trying to edit an already deleted comment.
                if (editedComment && editedComment.id === comment.id)
                    deactivateEditMode();
            })
            .catch(e => {
                setError(e);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleOnDeleteCancelClick = () => {
        setState(ps => ({ ...ps, showConfirmDeleteButtons: false }));
    }

    const formatCommentDate = (commentDate) => {
        const dateToFormat = moment.utc(commentDate);
        const date = dateToFormat.format("MMM DD, YYYY");
        const time = dateToFormat.format("hh:mm A");

        return (
            <Space size={3}>
                {date}
                <Dot
                    length="5px"
                    bgColor="#C4C4C4"
                    sideMargin="0.5px"
                    margBottom="1.5px"
                />
                {time} UTC

            </Space>
        )
    }

    const commentBeingEdited = editedComment && editedComment.id === comment.id;

    const content = (
        <Space direction='vertical' size='small' style={{ width: '100%' }}>


            {!commentBeingEdited && <p>{comment.text}</p>}

            {commentBeingEdited && <CommentEditor />}

            {comment.isEditable && !state.showConfirmDeleteButtons && !commentBeingEdited &&
                <Space size='small' align='center'>
                    {/**Edit Button */}
                    <Tooltip title='Edit Comment'>
                        <Button
                            onClick={handleOnEditClick}
                            type="text"
                            icon={<EditTwoTone />}
                        />
                    </Tooltip>
                    {/**Delete Button */}
                    <Tooltip title='Delete Comment'>
                        <Button
                            onClick={handleOnDeleteClick}
                            type="text"
                            icon={<DeleteTwoTone twoToneColor="#FF0000" />}
                        />
                    </Tooltip>
                </Space>
            }

            {comment.isEditable && state.showConfirmDeleteButtons &&
                <Space size={0} align='center'>
                    {/* <span>Are you sure you want to delete this comment?</span> */}
                    <Tag color="#ffa940" style={{ whiteSpace: 'normal' }}>Are you sure you want to delete this comment?</Tag>
                    <Button
                        type="text"
                        size="small"
                        text="Yes"
                        style={{ color: "#2CB280" }}
                        icon={<CheckOutlined />}
                        onClick={handleOnDeleteConfirmClick}
                    />

                    <Button
                        type="text"
                        size="small"
                        style={{ color: "red" }}
                        icon={<CloseOutlined />}
                        onClick={handleOnDeleteCancelClick}
                    />
                </Space>
            }
        </Space>
    )

    return (
        <Comment
            avatar={<UserOutlined />}
            author={
                <h3 style={{ fontWeight: "bold", color: "black" }}>
                    {comment.role === 'dp' ? 'Data Processor' : 'Reviewer'}
                    <Dot
                        length="7px"
                        bgColor="black"
                        sideMargin="3.5px"
                        margBottom="1px"
                    />
                    {comment.userId ? comment.userId : "Current User"}
                </h3>
            }
            content={content}
            datetime={formatCommentDate(comment.createdOn)}
        />
    )

}

export default SingleComment;