import React from 'react';
import { Modal } from "antd";

const CompleteProfileModal = ({ isOpen, onClickOk, onClickCancel }) => {

    return (
        <Modal visible={isOpen} onOk={onClickOk} onCancel={onClickCancel} okText="Complete Profile">
            <p>You have to complete your profile before processing any completions</p>
        </Modal>
    )
};

export default CompleteProfileModal;