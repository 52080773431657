/**
 * @typedef {Object} TextNode
 */

/**
 * @typedef {Object} ParseBlock
 * @property {TextNode} node
 * @property {object} parent
 * @property {number} pos
 * @property {number} index
 * @property {object} range
 */

/**
 *
 * @param {ParseBlock} arg
 * @returns {string}
 */
export function serializersTextNode({ node }) {
  const hasMarks = node?.marks?.length;

  if (!hasMarks) {
    return node?.text;
  }

  const [mark] = node?.marks;
  const isTextAreaLabelMark = mark?.type?.name === "TextAreaLabelHighlight";

  if (!isTextAreaLabelMark) {
    return node?.text;
  }

  const { place, label, labelOpen, labelClose } = mark?.attrs;

  if (place === "wrap") {
    return `${labelOpen}${node?.text}${labelClose}`;
  }

  if (place === "before") {
    return `${label}${node?.text}`;
  }

  if (place === "after") {
    return `${node?.text}${label}`;
  }

  if (place === "insert") {
    return `${label}`;
  }

  return node?.text;
}

/**
 * @param {ParseBlock} arg
 *
 * @returns {string}
 */
export function serializersParagraphNode(arg) {
  return "\n\n";
}
