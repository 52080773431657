import React, { useState, useCallback } from 'react';

const AudioViewer = ({ file, url, togglePlay }) => {
	const [audioURL, setAudioURL] = useState('');

	const getFileURL = useCallback((file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.originFileObj);
		reader.onload = () => {
			setAudioURL(reader.result);
		};
	}, []);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<audio
			src={url || audioURL}
			style={{ width: '100%' }}
			controls
			onPlay={togglePlay}
			onPause={togglePlay}
			controlsList="nodownload"
		/>
	);
};

export default AudioViewer;
