import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from "antd";
import { DownloadOutlined, FileExclamationOutlined } from '@ant-design/icons';

import ItemToDownload from './ItemToDownload/ItemToDownload';
import EmptyPlaceHolder from '../../Common/EmptyPlaceHolder';
import ErrorAlert from '../../Error/ErrorAlert';

import { getInitiatedProjectsUpdates, setShowDownloadCenterRequest, removeExpiredLinks } from '../../../store/downloadCenterSlice'
import useSocketContext from '../../../contexts/SocketContext';
import { DOWNLOAD_AUDIO_FILES_PRJECT_STATUS } from '../../../Constants';

const DownloadCenterModal = () => {
    const [state, setState] = useState({ showDownloadCenterModal: false });
    const { initAppSocket, closeAppSocket } = useSocketContext();

    const dispatch = useDispatch();

    const { projectList, projectsUpdates, requestShowDownloadCenter } = useSelector(state => state.downloadCenter)
    const projectEntries = Object.entries(projectList) || [];


    useEffect(() => {
        dispatch(getInitiatedProjectsUpdates())
        // eslint-disable-next-line
    }, [dispatch])

    // Listening to open modal requests.
    useEffect(() => {

        if (requestShowDownloadCenter) {
            // Fulfilling the request.
            setState(ps => ({ ...ps, showDownloadCenterModal: true }));
            // Clearing the request flag.
            dispatch(setShowDownloadCenterRequest(false));
            // Removing expired links when show is requested.
            dispatch(removeExpiredLinks());
        }

        // eslint-disable-next-line
    }, [requestShowDownloadCenter]);

    // Closing the socket with the backend if there are no more updates to be received for any of the projects.
    useEffect(() => {
        const doneGettingAllArchivedLinks = projectEntries.length === 0 || projectEntries.every(([_, projectEntry]) =>
            projectEntry.status === DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Done ||
            projectEntry.status === DOWNLOAD_AUDIO_FILES_PRJECT_STATUS.Failed);

        // Closing the socket.
        if (doneGettingAllArchivedLinks)
            closeAppSocket();
        else
            initAppSocket();

        // eslint-disable-next-line
    }, [projectList]);

    const handleCloseDownloadCenter = () => setState(ps => ({ ...ps, showDownloadCenterModal: false }))

    return (
        <Modal
            title={<span><DownloadOutlined /> Download Center</span>}
            width="50%"
            visible={state.showDownloadCenterModal}
            onCancel={handleCloseDownloadCenter}
            footer={[
                <Button type='primary' key="ok" onClick={handleCloseDownloadCenter}> Ok </Button>
            ]}>

            {/** Displaying archives */}
            {!projectsUpdates.error && projectEntries.length > 0 && projectEntries.map(([projectID, projectEntry]) => {
                return (
                    <ItemToDownload
                        key={projectID}
                        projectID={projectID}
                        projectEntry={projectEntry}
                    />
                )
            })}

            {/** Failed to retrieve Updates */}
            {projectsUpdates.error &&
                <ErrorAlert error={projectsUpdates.error} />
            }

            {/**Empty archives */}
            {projectEntries.length === 0 &&
                <EmptyPlaceHolder
                    text='No archives are available at the moment.'
                    icon={<FileExclamationOutlined />}
                />
            }

        </Modal>
    );
}

export default DownloadCenterModal;