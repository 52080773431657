import { Form, Radio } from "antd";

const { Item } = Form;

const Gender = () => {
    return (
        <Item
            label="Gender"
            name="gender"
            rules={[
                {
                    required: true,
                    message: "Please choose your gender!",
                },
            ]}>
            <Radio.Group>
                <Radio value='male'>Male</Radio>
                <Radio value='female'>Female</Radio>
                <Radio value='other'>Other</Radio>
            </Radio.Group>
        </Item>
    )
}

export default Gender;