import React, { Fragment } from 'react';
import { Row, Col } from 'antd';

import ProjectField from './ProjectInfo/helper/ProjectField';
import ProjectFieldSelect from './ProjectInfo/helper/ProjectFieldSelect';
import { PROJ_STATUS_ACTIVE, PROJ_STATUS_COMPLETED, PROJ_STATUS_CANCELLED } from '../../../Constants';
import { calcElapsedTime } from '../../Common/Utils';


const ProjectHeader = ({ project }) => {

    const processedCompletionsPercentage = project.totalCompletions > 0 ? (project.processedCompletions / project.totalCompletions) * 100 : 0;
    const reviewedCompletionsPercentage = project.totalCompletions > 0 ? (project.reviewedCompletions / project.totalCompletions) * 100 : 0;
    const timeElapsed = calcElapsedTime(project);
    return (
        <Fragment>
            {project &&
                <Fragment>
                    <div style={{ marginTop: '20px' }}>
                        <Row >
                            <Col span={12}>
                                <ProjectField labelText='ID:' fieldName='id' />
                                <ProjectField labelText='Internal Name:' fieldName='internalName' />
                                <ProjectFieldSelect
                                    labelText='Status:'
                                    fieldName='status'
                                    editable={true}
                                    options={[
                                        { value: PROJ_STATUS_ACTIVE, label: 'Active' },
                                        { value: PROJ_STATUS_COMPLETED, label: 'Completed' },
                                        { value: PROJ_STATUS_CANCELLED, label: 'Cancelled' }
                                    ]}
                                />
                                <ProjectField labelText='Processed Completions:' fieldName='processedCompletions' />
                                <ProjectField labelText='Reviewed Completions:' fieldName='reviewedCompletions' />
                                <ProjectField labelText='Total Completions:' fieldName='totalCompletions' />
                            </Col>
                            <Col span={12}>
                                <ProjectField labelText='Processed Completions%:' calculatedValue={`${processedCompletionsPercentage.toFixed(1)}%`} />
                                <ProjectField labelText='Reviewed Completions%:' calculatedValue={`${reviewedCompletionsPercentage.toFixed(1)}%`} />
                                <ProjectField labelText='Assigned Completions:' fieldName='assignedCompletionsCount' />
                                <ProjectField labelText='Completions Per Task:' fieldName='completionsPerTask' />
                                <ProjectField labelText='Time Elapsed:' calculatedValue={timeElapsed} />
                                <ProjectField labelText='Average Processing Time:' fieldName='averageProcessingTime' />
                                <ProjectField labelText='Average Review Time:' fieldName='averageReviewTime' />
                            </Col>
                        </Row>
                    </div>

                    {/* Displaying the error message that there are no sufficient number of data processors to process all completions. */}
                    {/* {state.showAlert &&
                        <Alert
                            type="warning"
                            message={<Text strong>Insufficient Assigned Data Processors!</Text>}
                            description={
                                <div>
                                    <p>When <Text strong>Different User Per Completion</Text> is selected, the number of assigned data processors should be greater than or equal to the number of <Text strong>Completions Per Task</Text>.</p>
                                    <p><Text strong>Note:</Text> The alert will remain visible while data processors' filters or search are applied. To make sure this check is re-evaluated, please clear filters and search after you are done with assignment if any were used.</p>
                                </div>
                            }
                            showIcon
                        />
                    } */}

                </Fragment>
            }
        </Fragment>
    )
}

export default ProjectHeader;
