import React from 'react';
import { Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const TemplateAlert = ({ message }) => {
    return (
        <Alert
            showIcon
            message={message}
            style={{ marginTop: '16px', backgroundColor: "#e6f7ff", borderColor: "#91d5ff" }}
            icon={<ExclamationCircleOutlined />}
        />
    );
};

export default TemplateAlert;