import React, { useState } from 'react';
import { RetweetOutlined } from '@ant-design/icons';
import CardActionButton from './CardActionButton';

import useNotificationsContext from '../../../../contexts/NotificationsContext';

const RestoreNotification = ({ notificationID }) => {

    const { restoreNotification } = useNotificationsContext();
    const [state, setState] = useState({
        restoring: false,
    });

    const onRestoreNotification = () => {
        setState(ps => ({ ...ps, restoring: true }));
        // If the notifications resore is successful, the card is removed, so 
        // there is no need to set restoring back to false unless the operation
        // fails.
        restoreNotification(notificationID)
            .catch(e => {
                setState(ps => ({ ...ps, restoring: false }));
                // For the user, the error will be that notification card is not removed.
                console.log(e);
            });
    }

    return (
        <CardActionButton
            key='restore'
            text='Restore'
            loading={state.restoring}
            icon={<RetweetOutlined />}
            onClick={onRestoreNotification}
        />
    )
}

export default RestoreNotification;