import React, { useState, useCallback } from 'react';

const OthersViewer = ({ file, url }) => {
	const [othersURL, setOthersURL] = useState('');

	const getFileURL = useCallback((file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.originFileObj);
		reader.onload = () => {
			setOthersURL(reader.result);
		};
	}, []);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<object data={url || othersURL} height="100%" width="100%">
			<embed src={url || othersURL} />
		</object>
	);
};

export default OthersViewer;
