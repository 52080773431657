import React from 'react';
import { MultiSelectField } from '../PM/SelectExt';
import ErrorAlert from '../Error/ErrorAlert';
import { Tooltip } from 'antd';


const SelectInput = ({ onChange, value, options, optionsErr, selectMode, placeholder, disabled, tooltipMsg, tooltipPlacement }) => {

    return (
        <>
            <Tooltip title={!disabled && tooltipMsg} placement={tooltipPlacement} >
                <MultiSelectField
                    selectedTags={value}
                    onTagsChange={onChange}
                    placeholder={placeholder}
                    options={options}
                    selectMode={selectMode}
                    isDisabled={disabled}
                />
                <ErrorAlert error={optionsErr} />
            </Tooltip>
        </>
    );
};

export default SelectInput;