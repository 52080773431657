import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import completionApi from '../api/completions';
import { formatError } from '../contexts/ResponseErrorFormatter';
import {
	COMPLETION_DP_ACTION,
	UPLOAD_PROCESS_STATUS_CODES,
} from '../Constants';
import config from '../configuration/config';
import { calculateChecksum } from '../components/Common/Utils';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Async Thunks

// Gets the upload session information which includes the signed link to upload the file to the bucket.
const getUploadSessionUrl = createAsyncThunk(
	'audio/getUploadSessionInfo',
	async (completionID, { getState, rejectWithValue }) => {
		const state = getState().audio;
		const completionEntry = state.toUploadCompletionDataTasks[completionID];

		// Testing if the file contains any data to be uploaded.
		if (!completionEntry.recorded) return null;

		// Checking if the upload url is already available (from a previous upload trial)
		// then we return null.
		if (completionEntry.urlUpload) return null;

		try {
			const checksum = await calculateChecksum(completionEntry.blob);

			const completionEntryWithCheckSum = {
				...completionEntry,
				contentType: completionEntry.blob.type,
				checksum,
			};

			const res = await completionApi.initiateUploadSession(
				completionID,
				completionEntryWithCheckSum,
			);
			return res.data;
		} catch (e) {
			console.error(e);
			const formattedError = formatError(
				e,
				`Failed To Get Upload URL For Completion ${completionID}!`,
			);
			return rejectWithValue(formattedError);
		}
	},
);

// Uploads the file to the bucket.
const uploadFileToBucket = createAsyncThunk(
	'audio/uploadFileToBucket',
	async (completionID, { getState, dispatch, rejectWithValue }) => {
		try {
			const state = getState().audio;
			const completionEntry = state.toUploadCompletionDataTasks[completionID];

			// There is no need to try uploading the file if there is no blob.
			if (!completionEntry.recorded) return;

			const { urlUpload, blob, error } = completionEntry;

			// Throwing the found error instead of continuing with the call.
			if (error) return rejectWithValue(error);

			// Calling the upload endpoint with the progress value setter call back.
			await completionApi.uploadRecordedAudioToBucket(
				urlUpload,
				blob,
				(progressEvent) => {
					const progressValue = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total,
					);
					dispatch(
						setUploadCompletionProgress({ completionID, progressValue }),
					);
				},
			);
		} catch (e) {
			const formattedError = formatError(
				e,
				`Failed To Upload The File To Bucket For Completion ${completionID}!`,
			);
			return rejectWithValue(formattedError);
		}
	},
);

// Submits the rest of the results of the LSF components.
const submitCompletionResult = createAsyncThunk(
	'audio/submitCompletionResult',
	async (completionID, { getState, rejectWithValue }) => {
		try {
			const state = getState().audio;
			const { result, error } = state.toUploadCompletionDataTasks[completionID];

			// Throwing the found error instead of continuing with the call.
			if (error) return rejectWithValue(error);

			// Sending the completion result.
			await completionApi.dpSendCompletionResult(completionID, {
				action: COMPLETION_DP_ACTION.Submit,
				result,
			});
		} catch (e) {
			const formattedError = formatError(
				e,
				`Failed To Submit Result Of Completion: ${completionID}`,
			);
			return rejectWithValue(formattedError);
		}
	},
);

// Starts or retries the uploading of the completion files and results.
export const submitRecorderCompletionData = (completionID, dispatch) => {
	dispatch(getUploadSessionUrl(completionID))
		.then(() => dispatch(uploadFileToBucket(completionID)))
		.then(() => dispatch(submitCompletionResult(completionID)));
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Helper functions.

// Creates the redict end point call to get the signed url which is used to get the audio for the reviewer securely from the bucket.
const signUrl = (completionID, key, review) => {
	const result = `${
		config.lxt_backend
	}contributor/completions/${completionID}/files?field=${key}&source=2&role=${
		review ? 'rv' : 'dp'
	}`;
	return result;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// State Slice

const initialState = {
	activeCompletionID: null,
	toUploadCompletionDataTasks: {},
};

const EMPTY_COMPLETION_ENTRY = {
	// The project ID of the completion belongs to.
	projectID: -1,
	// An indicator for whether the completion is loaded for review or data processing.
	review: false,

	/////// The following section is set by the audio recorder component.

	// The url containing either the new recording or a recording uploaded to the bucket previously.
	url: '',
	// The extension of the recorded audio.
	ext: '',
	// The blob created by the recorder component.
	blob: null,
	// The size of the blob which is created by the recorder component.
	size: 0,
	// The identifier of the Record xml tag.
	tagId: '',

	/////// The following section is the information about the bucket to upload the file to.

	// The url to upload the recorded blob to.
	urlUpload: '',
	// The file name that will be given to the recorded blob after it is uploaded.
	filename: '',
	// The upload process created by the backend
	processId: '',

	/////// The statuses of the completion.

	// The status of the step the completion entry is currently in.
	status: UPLOAD_PROCESS_STATUS_CODES.NotStarted,
	// Indicates whether there is a new recording that needs to be uploaded or not.
	recorded: false,
	// The progress of uploading the audio recording process.
	progress: 0,
	// The error which is reported through any of the completion upload steps.
	error: null,
};

const audioSlice = createSlice({
	name: 'audio',
	initialState,

	reducers: {
		loadCompletion: (state, action) => {
			// Removing obsolete completion entries.
			const prevCompletionEntries = Object.entries(
				state.toUploadCompletionDataTasks,
			)
				// Keeping only the completions with recorded flag set and has a status beyond NotStarted are kept.
				.filter(
					([_, value]) =>
						value.recorded &&
						value.status !== UPLOAD_PROCESS_STATUS_CODES.NotStarted,
				)
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

			if (action.payload) {
				// The payload is the completion ID in this case.
				const {
					completion: payloadCompletion,
					projectID,
					review,
				} = action.payload;
				const { resultFiles, id: completionID } = payloadCompletion;

				// Creating or updating the completion entry.
				const completionEntry =
					completionID in state.toUploadCompletionDataTasks
						? {
								...state.toUploadCompletionDataTasks[completionID],
								// We reset the status because the user may select to skip when upload fails in the first
								// and this should remove the completion entry in this case from the list. Note: Skip is
								// handled by the completion context and not the redux store.
								// Also this will hide the notification card from the dropdown list.
								status: UPLOAD_PROCESS_STATUS_CODES.NotStarted,
						  }
						: { ...EMPTY_COMPLETION_ENTRY };

				// Updating entry fields from the arguments.
				completionEntry.projectID = projectID;
				completionEntry.review = review;
				// Updating the url of the recorded file if it exists in completion and there is no new recording.
				// This is done to keep the latest recording visible to the user when he/she opens the completion
				// from the notification card.
				if (resultFiles && !completionEntry.recorded) {
					const fileEntries = Object.entries(resultFiles);
					if (fileEntries.length > 0) {
						// The following gets the url of the first entry in results file as currently
						// only a single recorder control is supported.
						const [tagID, url] = fileEntries[0];
						if (url) {
							const signedUrl = signUrl(completionID, tagID, review);
							completionEntry.url = signedUrl;
							completionEntry.tagId = tagID;
						}
					}
				}

				// Updating the activeCompletionID with the payload, even if it is equal to null.
				state.activeCompletionID = completionID;

				// Creating or updating the entry for the current completion.
				state.toUploadCompletionDataTasks = {
					...prevCompletionEntries,
					[completionID]: completionEntry,
				};
			} else {
				state.activeCompletionID = null;
				state.toUploadCompletionDataTasks = { ...prevCompletionEntries };
			}
		},

		setCompletionAudioBlob: (state, action) => {
			// Making sure there is an active completion ID selected. activeCompletionID is null in the case when the project manager
			// recrods an audio from the configuration input view.
			if (
				state.activeCompletionID === null ||
				!(state.activeCompletionID in state.toUploadCompletionDataTasks)
			)
				return;

			// Renaming the payload
			const audioInfo = action.payload;
			let completionEntry = {};

			if (audioInfo.size > 200) {
				// Updating the current active completion entry with the audio file information which
				// is recorded by the audio recorder component.
				completionEntry = {
					...state.toUploadCompletionDataTasks[state.activeCompletionID],
					...audioInfo,
					recorded: true,
				};
			} else {
				// Set the current active completion entry to empty to prevent
				// submission of empty audio files
				completionEntry = {
					...state.toUploadCompletionDataTasks[state.activeCompletionID],
					...EMPTY_COMPLETION_ENTRY,
				};
			}

			// Updating the completion entry in the state.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[state.activeCompletionID]: completionEntry,
			};
		},

		setCompletionResult: (state, action) => {
			if (state.activeCompletionID === null)
				throw new Error('activeCompletionID is not set!!!');

			// Renaming the payload
			const completionSerializedResult = action.payload;

			// Updating the result for the completion entry with the serialized completion result.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[state.activeCompletionID],
				result: completionSerializedResult,
			};

			// Updating the completion entry in the state.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[state.activeCompletionID]: completionEntry,
			};
		},

		setUploadCompletionProgress: (state, action) => {
			const { completionID, progressValue } = action.payload;

			// Setting the status of current audio completion being uploaded.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				progress: progressValue, // The progress percentage.
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},
	},

	extraReducers: {
		[getUploadSessionUrl.pending]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;

			// Setting the status of current audio completion being uploaded.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.FetchingUrl,
				progress: 0, // Resetting the progress value.
				error: null, // Resetting the error that could be reported from a previous upload trial.
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},
		[getUploadSessionUrl.fulfilled]: (state, action) => {
			if (action.payload) {
				// Retrieving the argument sent to the thunk.
				const completionID = action.meta.arg;

				const { processId, filename, url } = action.payload;

				// Concatenating the returned results properties into the current object in the list.
				const completionEntry = {
					...state.toUploadCompletionDataTasks[completionID],
					urlUpload: url,
					filename,
					processId,
				};

				// Updating the completion entry.
				state.toUploadCompletionDataTasks = {
					...state.toUploadCompletionDataTasks,
					[completionID]: completionEntry,
				};
			} else {
				// Do nothing as the getUploadSessionUrl function returned null because it found the urlUpload
				// is already set from a previous upload trial.
			}
		},
		[getUploadSessionUrl.rejected]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;
			const error = action.payload;

			// Updating the status of the completion entry.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.Failure,
				error,
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},

		[uploadFileToBucket.pending]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;

			// Updating the status of the completion entry.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.UploadingFile,
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},
		[uploadFileToBucket.rejected]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;
			const error = action.payload;

			// Updating the status of the completion entry.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.Failure,
				error,
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},

		[submitCompletionResult.pending]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;

			// Updating the status of the completion entry.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.SubmittingResult,
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},
		[submitCompletionResult.fulfilled]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = String(action.meta.arg);

			// Removing the entry from the completions dictionary after successfull submission.
			state.toUploadCompletionDataTasks = Object.entries(
				state.toUploadCompletionDataTasks,
			)
				.filter(([key, _]) => String(key) !== completionID)
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
		},
		[submitCompletionResult.rejected]: (state, action) => {
			// Retrieving the argument sent to the thunk.
			const completionID = action.meta.arg;
			const error = action.payload;

			// Updating the status of the completion entry.
			const completionEntry = {
				...state.toUploadCompletionDataTasks[completionID],
				status: UPLOAD_PROCESS_STATUS_CODES.Failure,
				error,
			};

			// Updating the completion entry.
			state.toUploadCompletionDataTasks = {
				...state.toUploadCompletionDataTasks,
				[completionID]: completionEntry,
			};
		},
	},
});

export const {
	loadCompletion,
	setCompletionAudioBlob,
	setCompletionResult,
	setUploadCompletionProgress,
} = audioSlice.actions;

export default audioSlice.reducer;
