import React from 'react';
import { Checkbox, Space, Typography } from "antd";

const { Title } = Typography;

const RecorderInstruction = (props) => {
  const { instruction, onCheckInstruction } = props;

  return (
    <Space style={{ padding: '0 2rem' }} direction='vertical'>
      <Title level={3}>Pre-recording Instructions</Title>
      <Checkbox.Group
        options={instruction}
        onChange={onCheckInstruction}
        style={{ display: 'flex', flexDirection: 'column' }}
      />
    </Space>
  );
};

export default RecorderInstruction;