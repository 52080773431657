import { Button, Menu, Dropdown } from "antd";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";

const ExportButton = ({ disabled, loading, onExportAll, onExportSelected, hasSelectedTasks }) => {

    const exportButtons = <Menu>
        <Menu.Item key={"allTasks"}>
            <Button
                type='link'
                size='small'
                onClick={onExportAll}
                icon={<DownloadOutlined />}
            >
                All Tasks
            </Button>
        </Menu.Item>
        {hasSelectedTasks && <Menu.Item key={"selectedTasks"}>
            <Button
                type='link'
                size='small'
                onClick={onExportSelected}
                icon={<DownloadOutlined />}
            >
                Selected Tasks
            </Button>
        </Menu.Item>}
    </Menu>

    return (
        <Dropdown overlay={exportButtons} disabled={disabled}>
            <Button
                type='primary'
                ghost size='small'
                icon={<DownOutlined />}
                loading={loading}
            >Export
            </Button>
        </Dropdown>
    )
}

export default ExportButton;