import styled, { css, createGlobalStyle } from "styled-components";

const mkLabelsInsertStyle = labelEntry => {
  const { place, alias, label, labelOpen, labelClose } = labelEntry;

  const className = `.label-highlight.label-highlight-${alias}-${place}`;

  const style = `
    content: "${label}";
    vertical-align: super;
    font-size: 0.8rem;  
  `;

  let result;

  switch (place) {
    default:
    case "wrap":
      result = `
        ${className}::before { ${style} content: "${labelOpen}"; }
        ${className}::after { ${style} content: "${labelClose}";}
      `;
      break;
    case "before":
      result = `${className}::before { ${style} }`;
      break;
    case "after":
      result = `${className}::after { ${style} }`;
      break;
    case "insert":
      result = css`
        ${className} {
          border-radius: 12px;
          padding: 0 5px;
          ;
        }
        
        ${className}::before {
          content: "${label}";
          font-size: 0.7rem;
          vertical-align: text-bottom;
        }
      `;
  }

  return result;
};

export const TextAreaEditorGlobalStyle = createGlobalStyle`
  .label-highlight {
    border-radius: 4px;
  }
  
  ${({ labels }) => labels?.map?.(mkLabelsInsertStyle)}
`;

export const EditorPanel = styled.div`
  & .ProseMirror {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    margin: 8px 0;
    padding: 4px;
  }
`;

export const ControlsGroup = styled.div`
  display: flex;
  gap: 0.4rem;
`;

export const ControlsCompact = styled.div`
  display: flex;
  gap: 0;

  & div:before {
    border-radius: 0;
  }

  & button {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 0;
  }

  & button span:nth-child(2) {
    display: none;
  }
`;
