import { lxtBackend } from "./utils";

const getCookieValue = (name) => {
    const result = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    return result;
}

const routes = {
    getLanguageOptions: async (authToken) => {
        const config = {};
        if (authToken) {
            const csrfToken = getCookieValue("XSRF-TOKEN");
            config.headers = {
                'Authorization': `Bearer ${authToken}`,
                'X-XSRF-TOKEN': csrfToken,
            }
        }
        return lxtBackend.get("languages", config);
    },
    getSkillOptions: async (authToken) => {
        const config = {};
        if (authToken) {
            const csrfToken = getCookieValue("XSRF-TOKEN");
            config.headers = {
                'Authorization': `Bearer ${authToken}`,
                'X-XSRF-TOKEN': csrfToken,
            }
        }
        return lxtBackend.get("contributor/skills", config);
    },
    getProjectOwners: async (searchTxt) => {
        return searchTxt ?
            lxtBackend.get(`users?role=pm&skip=0&limit=10&search=${searchTxt}`) :
            lxtBackend.get("users?role=pm&skip=0&limit=10");
    },
    getEducationOptions: async (authToken) => {
        const config = {};
        if (authToken) {
            const csrfToken = getCookieValue("XSRF-TOKEN");
            config.headers = {
                'Authorization': `Bearer ${authToken}`,
                'X-XSRF-TOKEN': csrfToken,
            }
        }
        return lxtBackend.get("educations", config);
    },
}

export default routes;