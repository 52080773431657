import React, { useState, useCallback } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import { Input, Tooltip } from 'antd';
import { capitalizeFirstChar } from '../Common/Utils';


const FirstNameTextInput = ({ onChange, value, error }) => {
    return (
        <Tooltip placement="topLeft" title={error} color="rgba(255,77,79,0.8)">
            <Input
                placeholder="First Name"
                allowClear
                onChange={onChange}
                value={value}
                status={Boolean(error) ? 'error' : undefined}
            />
        </Tooltip>
    );
};

const FirstNameInput = ({ user, editMode, onChangeEditMode }) => {
    const [firstName, setFirstName] = useState("");

    const [isFirstNameEditable, setIsFirstNameEditable] = useState(false);

    const [firstNameValidationErr, setFirstNameValidationErr] = useState("");

    const handleFirstNameChange = useCallback((e) => {
        setFirstName(e.target.value);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsFirstNameEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const handleValidationErr = useCallback((errMsg) => {
        setFirstNameValidationErr(errMsg);
    }, []);

    const hasFirstName = Boolean(user.firstName);
    const isEditable = isFirstNameEditable;

    return (
        <ProfileEntry
            hasBorder
            label="First Name:"
            value={
                (hasFirstName && !isEditable) ?
                    capitalizeFirstChar(user.firstName) :
                    (isEditable) ?
                        <FirstNameTextInput
                            onChange={handleFirstNameChange}
                            value={firstName}
                            error={firstNameValidationErr}
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="firstName"
                    inputValue={firstName}
                    inputEditable={isFirstNameEditable}
                    changeEditableStatus={handleEditableStatus}
                    changeValidationErrMsg={handleValidationErr}
                    disableSave={!firstName ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default FirstNameInput;