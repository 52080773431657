import React from 'react';
import { Spin } from 'antd';

const LoadingNotificationsPlaceHolder = ({ width, loading }) => {
    return (
        <div style={{
            width: { width },
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        }}>
            <Spin size='large' tip='One moment please...' spinning={loading} />
        </div>
    )
}

export default LoadingNotificationsPlaceHolder;