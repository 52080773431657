import React, { Fragment, useContext, useState } from 'react';
import { Button, Divider, message, Space } from 'antd';
import { SaveOutlined, UndoOutlined } from '@ant-design/icons';

import ErrorModal from '../../../Error/ErrorModal';
import LabelStudioConfigurationView from '../../../Common/LabelStudioConfigurationView';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';

const TasksXmlConfig = () => {

    const {
        selectedProject,
        errorUpdate,
        updateProjectValue,
        clearUpdateError
    } = useContext(PMPrjSingleContext);

    const [prjXmlConfig, setPrjXmlConfig] = useState(selectedProject.xmlConfig);

    const handleUpdate = () => {
        if (selectedProject.xmlConfig === prjXmlConfig) {
            message.info('No modifications are done to save!');
        }
        else if (!prjXmlConfig) {
            message.error("Configuration can't be left empty!")
        }
        else {
            updateProjectValue('xmlConfig', prjXmlConfig)
                .then(() => {
                    if (!errorUpdate) {
                        message.success("Project's XML configuration is updated successfully.");
                    }
                })
                .catch(e => {
                    //Do nothing
                });
        }
    }

    const handleReset = () => {
        setPrjXmlConfig(selectedProject.xmlConfig);
    }

    return (
        <Fragment>
            <ErrorModal error={errorUpdate} onDone={clearUpdateError} />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>
                    {!selectedProject.allowCriticalFieldsEdit && <h4>Project XML Configuration Is Not Editable</h4>}
                    <Button type='dashed' onClick={handleReset}><UndoOutlined /> Reset</Button>
                    {selectedProject.allowCriticalFieldsEdit &&
                        <Button type="primary" htmlType='submit' onClick={handleUpdate}><SaveOutlined /> Update Configuration</Button>
                    }
                </Space>
            </div>


            <Divider />

            <LabelStudioConfigurationView
                value={prjXmlConfig}
                onChange={setPrjXmlConfig}
            />

        </Fragment>
    )
}

export default TasksXmlConfig;
