import { useEffect, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom';
import { Modal, Button, Popconfirm } from "antd";
import { RichGuidelinesDisplay, sanitizeText } from "../Common/RichGuidelinesEditor";
import { CONTRIBUTOR_PROJECTS_PATH } from '../../routes';
import useCTProjectsContext from '../../contexts/CTProjectsContext'
import ErrorAlert from '../Error/ErrorAlert';


const ConsentModal = ({ visible, onAgree, content, onDisagree, projectConsent }) => {
    const { error } = useCTProjectsContext();
    const history = useHistory();
    const { agree, disagree } = projectConsent;
    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

    const btnStyles = {
        width: isTabletOrMobile ? '100%' : '25%',
        height: 'fit-content',
        wordBreak: 'break-all',
        whiteSpace: 'normal',
        margin: isTabletOrMobile ? '5px 0px' : '0px 5px'
    };

    useEffect(() => {
        if (content)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            content = sanitizeText(content)
    }, [])

    const returnToProjectsList = () => history.push(CONTRIBUTOR_PROJECTS_PATH);

    return (
        <Fragment>
            {error && <Modal
                title={'Consent Agreement'}
                closable={false}
                width='60%'
                visible={visible}
                onCancel={returnToProjectsList}
                footer={[<Button type='primary' key="OK" onClick={returnToProjectsList}>Ok</Button>]}>
                <ErrorAlert error={error} />
            </Modal>
            }

            {!error && content && <Modal
                title={'Consent Agreement'}
                closable={false}
                width='100vw'
                visible={visible}
                onCancel={onDisagree}
                footer={[
                    <Fragment key="consentButtons">
                        <Popconfirm
                            placement="topRight"
                            title="You will be redirected to the projects page and all project tasks will be unassigned, Do you want to continue?"
                            onConfirm={onDisagree}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='ghost' key="disagree" style={btnStyles}> {disagree} </Button>
                        </Popconfirm>
                        <Button type='primary' key="agree" onClick={onAgree} style={btnStyles}> {agree} </Button>
                    </Fragment>
                ]}>
                <RichGuidelinesDisplay value={content} />
            </Modal>
            }
        </Fragment>
    )
}

export default ConsentModal;