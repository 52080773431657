import { Form, Input } from "antd";
const { Item } = Form;

const Password = () => {
    return (
        <Item
            label="Password"
            name="password1"
            rules={[
                {
                    required: true,
                    message: "Please type your password!",
                },
                {
                    min: 8,
                    message: "Password length should be 8 characters or more!"
                },
                {
                    max: 100,
                    message: "Password length should not be more than 100 characters!"
                },
                {
                    pattern: /(?=.*?[A-Z])/,
                    message: 'Password must contain upper case letter',
                },
                {
                    pattern: /(?=.*[a-z])/,
                    message: 'Password must contain lower case letter',
                },
                {
                    pattern: /(?=.*[!"#$%&'()*,./:;<>?@\\[\]^_`{|}~])/,
                    message: 'Password must contain a special character!',
                },
                {
                    pattern: /(?=.*\d)/,
                    message: 'Password must contain a number!',
                },
            ]}>
            <Input.Password placeholder='Choose Password...' autoComplete="new-password" />
        </Item>
    )
}

export default Password;