import React, { useContext, createContext, useState } from 'react';

const CommentsContext = createContext(null);

const CommentsContextProvider = ({ children }) => {

    const [state, setState] = useState({
        editedComment: null, // The comment that is currently being edited.
        loading: false,
        error: null,
    })

    const activateEditMode = comment => {
        setState(ps => ({ ...ps, editedComment: comment }));
    }

    const deactivateEditMode = () => {
        setState(ps => ({ ...ps, editedComment: null }));
    }

    const setLoading = value => {
        setState(ps => ({ ...ps, loading: value }));
    }

    const setError = (error) => {
        setState(ps => ({ ...ps, error }))
    }

    const services = {
        ...state,
        setLoading,

        activateEditMode,
        deactivateEditMode,

        setError,
    }

    return (
        <CommentsContext.Provider value={services}>
            {children}
        </CommentsContext.Provider>
    )
}

const useCommentsContext = () => useContext(CommentsContext);

export { useCommentsContext as default, CommentsContextProvider }
