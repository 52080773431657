import React from "react";
import
{
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { Provider } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import
{
  CONTRIBUTOR_PATH,
  LOGIN_PATH,
  LOGIN_INTERNAL_PATH,
  PM_PATH,
  RESET_PASSWORD_PATH,
  SEND_INVITATION_PATH,
  REGISTRATION_PATH,
  USER_PROFILE,
  USER_PROFILE_OTHERS,
  NOTIFICATIONS_PATH,
  EMAIL_VERIFICATION_PATH,
  LOGOUT_PATH
} from "./routes";

import ReactQueryProvider from "./lib/reactQuery";
import { AuthContextProvider } from './contexts/AuthContext';
import store from './store/store';
import GoogleTagManager from "./lib/GoogleTagManager";

import roles from "./model/roles";
import PM from "./components/PM/PM";
import Contributor from "./components/Contributor/Contributor";
import NotificationsPage from "./components/Notifications/Page/NotificationsPage";
import SendInvitation from "./components/Auth/SendInvitation";
import Registration from "./components/Auth/Registration";
import Login from "./components/Auth/Login/Login";
import LoginInternal from "./components/Auth/Login/LoginInternal";
import ResetPassword from "./components/Auth/ResetPassword";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Layout from "./components/AntdWrappers/Layout/Layout";
import Navbar from "./components/Navbar/Navbar";
import UserProfile from "./components/UserProfile";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import RegistrationMobile from "./components/Auth/RegistrationMobile";
import EmailVerification from "./components/UserProfile/EmailVerification";
import { Logout } from './components/logout/Logout';
import { FeatureToggleProvider } from "./lib/featureToggles";

import './components/Styles/App.css';
import './components/Styles/Button.scss';
import { DDRumViewInstrumentation } from "./initDatadog";
import LxtLogo from "./lxt";


const App = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  return (
    <FeatureToggleProvider>
      <ReactQueryProvider>
        <Provider store={store}>
          {process.env.NODE_ENV === 'production' && <GoogleTagManager />}
          <ErrorBoundary>
            <Layout>
              <AuthContextProvider>
                <Router>
                  {/* The navigation bar which displays links according to user auth state */}
                  <DDRumViewInstrumentation />

                  <Navbar/>

                  {/* The router different pages*/}
                  <Switch>

                    <Route path="/--test--/lxt" component={LxtLogo} />
                    <Route path={LOGIN_INTERNAL_PATH} component={LoginInternal} />
                    <Route path={LOGIN_PATH} component={Login} />
                    <Route path={RESET_PASSWORD_PATH} component={ResetPassword} />
                    <Route path={SEND_INVITATION_PATH} component={SendInvitation} />
                    <Route path={REGISTRATION_PATH} component={isTabletOrMobile ? RegistrationMobile : Registration} />
                    <Route path={EMAIL_VERIFICATION_PATH} component={EmailVerification} />
                    <Route path={LOGOUT_PATH} component={Logout} />

                    <PrivateRoute path={PM_PATH} component={PM} role={roles.PM} />
                    <PrivateRoute path={CONTRIBUTOR_PATH} component={Contributor} role={roles.CONTRIBUTOR} />

                    <PrivateRoute path={USER_PROFILE_OTHERS} component={UserProfile} />
                    <PrivateRoute path={USER_PROFILE} component={UserProfile} />
                    <PrivateRoute path={NOTIFICATIONS_PATH} component={NotificationsPage} />

                    <Redirect to={LOGIN_PATH} />
                  </Switch>
                </Router>
              </AuthContextProvider>
            </Layout>
          </ErrorBoundary>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryProvider>
    </FeatureToggleProvider>
  );
};

export default App;
