
const ArchiveLinks = ({ projectArchiveLinks, projectID }) => {
    return (
        <ul>
            {projectArchiveLinks.map((url, index) =>
                <li key={url}>
                    <a href={url} download target="_blank" rel="noreferrer">
                        {projectArchiveLinks.length === 1 ? `Project ${projectID} Archive` : `Project ${projectID} Archive part ${index + 1}`}
                    </a>
                </li>
            )}
        </ul>
    )
}

export default ArchiveLinks;