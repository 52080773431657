import React from "react";
import { Button, Row } from "antd";
import { observer } from "mobx-react";
import { PauseOutlined, CaretRightOutlined, StepBackwardFilled, StepForwardFilled } from "@ant-design/icons";

import Hint from "../../../components/Hint/Hint";

const AudioControls = ({ item, store }) => {
  return (

    <div style={{ display: "inline-block", marginTop: "10px", marginBottom: "10px" }}>

      <Row justify="center">
        {store.settings.enableTooltips && store.settings.enableHotkeys && item.hotkey &&
          <Hint>[{item.hotkey}]</Hint>
        }
      </Row>

      <Row align='middle'>

        <Button
          icon={<StepBackwardFilled />}
          type="text"
          size="large"
          onClick={e => {
            item._ws.seekTo(0)
          }}
        />


        <Button
          type="primary"
          shape="circle"
          size='large'
          style={{ padding: '5px' }}
          icon={item.playing ? <PauseOutlined style={{ fontSize: '1.2rem' }} /> : <CaretRightOutlined style={{ fontSize: '1.2rem' }} />}
          onClick={ev => {
            item._ws.playPause();
          }}
        />


        <Button
          icon={<StepForwardFilled />}
          type="text"
          size="large"
          onClick={e => {
            item._ws.seekTo(1)
          }}
        />

      </Row>
    </div>
  );
};

export default observer(AudioControls);
