import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Tabs } from 'antd';
import { NotificationOutlined, CheckOutlined } from '@ant-design/icons';

import ErrorBoundary from '../../Error/ErrorBoundary';
import NotificationsPageTab from './NotificationsPageTab';
import { NotificationsContextProvider } from '../../../contexts/NotificationsContext';
import { Fragment } from 'react';

const { TabPane } = Tabs

const NotificationsPage = (props) => {
    return (
        <ErrorBoundary>
            <NotificationsContextProvider>
                <NotificationsPageInternal {...props} />
            </NotificationsContextProvider>
        </ErrorBoundary>
    )
}

const NotificationsTabContainer = ({ children }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            marginBottom: '150px',
        }}>

            <div style={{
                width: isTabletOrMobile ? '100%' : '50%',
                border: isTabletOrMobile ? '' : '1px solid #f0f0f0',
                padding: isTabletOrMobile ? '0px' : '20px 30px 20px 30px',
                borderRadius: '5px'
            }}>
                {children}
            </div>

        </div>
    )
}

const NotificationsPageInternal = () => {

    const [state, setState] = useState({
        selectedTabKey: '1',
    });

    const handleOnTabChange = tabKey => setState(ps => ({ ...ps, selectedTabKey: tabKey }))

    // Showing the count badge when the page is selected and hiding it otherwise.
    const newTabHeader = <Fragment>New <NotificationOutlined /></Fragment>;
    const clearedTabHeader = <Fragment>Cleared <CheckOutlined /></Fragment>;

    return (
        <NotificationsTabContainer>

            <Tabs defaultActiveKey='1' centered onChange={handleOnTabChange}>

                <TabPane key='1' tab={newTabHeader}>
                    <NotificationsPageTab forNewNotifications={true} isTabSelected={state.selectedTabKey === '1'} />
                </TabPane>

                <TabPane key='2' tab={clearedTabHeader}>
                    <NotificationsPageTab forNewNotifications={false} isTabSelected={state.selectedTabKey === '2'} />
                </TabPane>

            </Tabs>

        </NotificationsTabContainer>
    )
}

export default NotificationsPage;
