import { useEffect, useContext } from 'react';

import AuthContext from '../../contexts/AuthContext';
import { LOGIN_PATH } from '../../routes';

export const Logout = () => {
	const { logout } = useContext(AuthContext);
	useEffect(() => {
		logout();
	}, []);

	return null;
};
