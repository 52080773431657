import React, { useState } from 'react';
import { Alert, Button, Form, Input, Space } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const TwoFactorAuthTOTPVerification = ({
	verifyCode,
	goBackToSignIn,
	goToOther2FAOption,
	hasOther2FAOption,
}) => {
	const [state, setState] = useState({
		showDeviceLostTip: false,
	});

	const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

	const onSubmitVerificationCode = (values) => {
		verifyCode(values.verificationCode);
	};

	const handleToggleSupportTip = () => {
		setState((ps) => ({
			...ps,
			showDeviceLostTip: !state.showDeviceLostTip,
		}));
	};

	const VerificationsButtons = () => {
		if (isTabletOrMobile) {
			return (
				<Form.Item style={{ textAlign: 'center' }}>
					<Space>
						<Button
							type="link"
							style={{ padding: 0 }}
							hidden={!hasOther2FAOption}
							onClick={() => goToOther2FAOption()}
						>
							Other verification options
						</Button>
					</Space>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-around',
						}}
					>
						<Button
							style={{ width: '40%' }}
							type="primary"
							className="btn-success"
							htmlType="submit"
						>
							Verify
						</Button>
					</div>
					<Button type="link" onClick={() => goBackToSignIn()}>
						Go Back To Sign In
					</Button>
				</Form.Item>
			);
		} else {
			return (
				<Form.Item>
					<Space>
						<Button
							type="link"
							style={{ padding: 0 }}
							hidden={!hasOther2FAOption}
							onClick={() => goToOther2FAOption()}
						>
							Other verification options
						</Button>
					</Space>
					<Space
						align="end"
						direction="horizontal"
						style={{
							justifyContent: 'space-between',
							width: '100%',
							marginTop: 5,
						}}
					>
						<Space>
							<Button type="primary" className="btn-success" htmlType="submit">
								Verify
							</Button>
							<Button type="dashed" onClick={() => goBackToSignIn()}>
								Go Back To Sign In
							</Button>
						</Space>
					</Space>
				</Form.Item>
			);
		}
	};

	return (
		<Form onFinish={onSubmitVerificationCode}>
			<Alert
				style={{ marginBottom: 20 }}
				description="Please enter the verification code from you authenticator app"
				type="info"
			/>

			<Form.Item
				name="verificationCode"
				style={{ marginBottom: 5 }}
				rules={[
					{
						required: true,
						message: 'Verification code is required!',
					},
					{
						min: 6,
						message: "Verification code can't be less than 6 digits!",
					},
					{
						max: 6,
						message: "Verification code can't exceed 6 digits!",
					},
				]}
			>
				<Input type="number" />
			</Form.Item>

			<VerificationsButtons />

			{/* The info text in case the mobile phone is lost. */}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<QuestionCircleFilled onClick={() => handleToggleSupportTip()} />
			</div>
			{state.showDeviceLostTip && (
				<p>
					If you lost your device, please contact support using the email
					<a href="mailto:ldpsupport@lxt.ai">ldpsupport@lxt.ai</a>
				</p>
			)}
		</Form>
	);
};

export default TwoFactorAuthTOTPVerification;
