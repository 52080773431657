import React, { createContext, useContext, useState } from 'react';
import projectsApi from '../api/projects';
import { formatError } from './ResponseErrorFormatter';

const PMCompletionsContext = createContext(null);

const EMPTY_CRITERIA = {
    completionID: '',
    taskID: '',
    statuses: null,
    dataProcessors: null,
    reviewers: null,
    skip: 0,
    limit: 10,

    orderColumn: "id",
    orderAscending: false,
}

const PMCompletionsContextProvider = ({ children }) => {

    const [state, setState] = useState({
        isLoading: false,
        error: null,

        isDownloadingResults: false,
        errorDownloadResults: null,

        isDownloadingStats: false,
        errorDownloadStats: null,

        isDownloadingHistoricalStats: false,
        errorDownloadHistorcalStats: null,

        isAssigning: false,
        assignmentError: null,

        isChangingStatus: false,
        statusChangeError: null,

        projectID: null,
        completions: [],
        completionsCount: 0,
        completionsCriteria: EMPTY_CRITERIA,
    });

    const fetchProjectCompletions = async (prjID, fetchCriteria, append= false) => {

        // Regulating the values of the fetch criteria to add all the missing fields with the default values.
        if (fetchCriteria) {
            fetchCriteria.completionID = typeof fetchCriteria.completionID === 'string' ? fetchCriteria.completionID : EMPTY_CRITERIA.completionID;
            fetchCriteria.taskID = typeof fetchCriteria.taskID === 'string' ? fetchCriteria.taskID : EMPTY_CRITERIA.taskID;
            fetchCriteria.statuses = Array.isArray(fetchCriteria.statuses) ? fetchCriteria.statuses : EMPTY_CRITERIA.statuses;
            fetchCriteria.dataProcessors = Array.isArray(fetchCriteria.dataProcessors) ? fetchCriteria.dataProcessors : EMPTY_CRITERIA.dataProcessors;
            fetchCriteria.reviewers = Array.isArray(fetchCriteria.reviewers) ? fetchCriteria.reviewers : EMPTY_CRITERIA.reviewers;
            fetchCriteria.skip = typeof fetchCriteria.skip === 'number' ? fetchCriteria.skip : EMPTY_CRITERIA.skip;
            fetchCriteria.limit = typeof fetchCriteria.limit === 'number' ? fetchCriteria.limit : EMPTY_CRITERIA.limit;
            fetchCriteria.orderColumn = typeof fetchCriteria.orderColumn === 'string' ? fetchCriteria.orderColumn : EMPTY_CRITERIA.orderColumn;
            fetchCriteria.orderAscending = typeof fetchCriteria.orderAscending === 'boolean' ? fetchCriteria.orderAscending : EMPTY_CRITERIA.orderAscending;
        }
        else {
            fetchCriteria = EMPTY_CRITERIA;
        }

        setState(ps => ({
            ...ps,
            isLoading: true,
            error: null,
            projectID: prjID,
            completionsCriteria: fetchCriteria,
        }));

        await projectsApi.getProjectCompletions(prjID, fetchCriteria)
            .then(res =>
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: null,
                    completions: append ? [...ps.completions, ...res.data.completions] : res.data.completions,
                    completionsCount: res.data.count,
                }))
            )
            .catch(e =>
                setState(ps => ({
                    ...ps,
                    isLoading: false,
                    error: formatError(e, `Failed To Retrieve Completions Of Project ${prjID} !`),
                }))
            )
    }

    const downloadResults = async () => {

        setState(ps => ({
            ...ps,
            isDownloadingResults: true,
            errorDownloadResults: null,
        }));

        return new Promise((resolve, reject) =>
            projectsApi.downloadResults(state.projectID)
                .then(res => {
                    let fileData = new Blob([res.data]);
                    setState(ps => ({
                        ...ps,
                        isDownloadingResults: false,
                        errorDownloadResults: null
                    }));
                    resolve(fileData);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isDownloadingResults: false,
                        errorDownloadResults: formatError(e, `Failed To Retrieve Results File For Project ${state.projectID} !`)
                    }));
                    reject();
                })
        );
    }

    const downloadStats = async () => {

        setState(ps => ({
            ...ps,
            isDownloadingStats: true,
            errorDownloadStats: null,
        }));

        return new Promise((resolve, reject) =>
            projectsApi.downloadStats(state.projectID)
                .then(res => {
                    let fileData = new Blob([res.data]);
                    setState(ps => ({
                        ...ps,
                        isDownloadingStats: false,
                        errorDownloadStats: null
                    }));
                    resolve(fileData);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isDownloadingStats: false,
                        errorDownloadStats: formatError(e, `Failed To Retrieve Stats File For Project ${state.projectID} !`)
                    }));
                    reject();
                })
        );
    }

    const downloadHistoricalStats = async () => {

        setState(ps => ({
            ...ps,
            isDownloadingHistoricalStats: true,
            errorDownloadHistorcalStats: null,
        }));

        return new Promise((resolve, reject) =>
            projectsApi.downloadHistoricalStats(state.projectID)
                .then(res => {
                    let fileData = new Blob([res.data]);
                    setState(ps => ({
                        ...ps,
                        isDownloadingHistoricalStats: false,
                        errorDownloadHistorcalStats: null
                    }));
                    resolve(fileData);
                })
                .catch(e => {
                    setState(ps => ({
                        ...ps,
                        isDownloadingHistoricalStats: false,
                        errorDownloadHistorcalStats: formatError(e, `Failed To Retrieve Historical Stats File For Project ${state.projectID} !`)
                    }));
                    reject();
                })
        );
    }

    const assignCompletionsToUser = (userID, completions, asReviewer) => {
        if (state.projectID) {

            setState(ps => ({
                ...ps,
                isAssigning: true,
                assignmentError: null
            }))

            return new Promise((resolve, reject) => {
                projectsApi.assignCompletionsOnContributer(state.projectID, userID, completions, asReviewer)
                    .then(res => {

                        let failedCompletions = null;
                        if (res.data &&
                            res.data.failed &&
                            Array.isArray(res.data.failed) &&
                            res.data.failed.length > 0) {

                            failedCompletions = res.data.failed;
                        }

                        setState(ps => ({
                            ...ps,
                            isAssigning: false
                        }))

                        resolve(failedCompletions)
                    })
                    .catch(e => {

                        setState(ps => ({
                            ...ps,
                            isAssigning: false,
                            assignmentError: formatError(e, 'Failed To Assign Contributor To User!')
                        }))

                        reject()
                    })
            })
        }
        else {
            setState(ps => ({
                ...ps,
                assignmentError: formatError(new Error('Project ID is not set.'), 'Unable To Assign Completions To User!')
            }))
            return Promise.reject();
        }
    }

    const updateCompletionsStatus = (completions, status) => {
        if (state.projectID) {

            setState(ps => ({
                ...ps,
                isChangingStatus: true,
                statusChangeError: null
            }))

            return new Promise((resolve, reject) => {
                projectsApi.updateCompletionsStatus(state.projectID, completions, status)
                    .then(res => {

                        let failedCompletions = null;
                        if (res.data &&
                            res.data.failed &&
                            Array.isArray(res.data.failed) &&
                            res.data.failed.length > 0) {

                            failedCompletions = res.data.failed;
                        }

                        setState(ps => ({
                            ...ps,
                            isChangingStatus: false
                        }))

                        resolve(failedCompletions)
                    })
                    .catch(e => {
                        setState(ps => ({
                            ...ps,
                            isChangingStatus: false,
                            statusChangeError: formatError(e, 'Failed To Change Completions Status!')
                        }))
                        reject()
                    })
            })
        }
        else {
            setState(ps => ({
                ...ps,
                statusChangeError: formatError(new Error('Project ID is not set.'), 'Unable To Change Completions Status!')
            }))
            return Promise.reject();
        }
    }

    const clearDownloadResultsError = () => setState(ps => ({ ...ps, errorDownloadResults: null }));
    const clearDownloadStatsError = () => setState(ps => ({ ...ps, errorDownloadStats: null }));
    const clearDownloadHistoricalStatsError = () => setState(ps => ({ ...ps, errorDownloadHistorcalStats: null }));
    const clearAssignmentError = () => setState(ps => ({ ...ps, assignmentError: null }));
    const clearStatusChangeError = () => setState(ps => ({ ...ps, statusChangeError: null }));

    const services = {
        ...state,

        fetchProjectCompletions,

        downloadResults,
        clearDownloadResultsError,

        downloadStats,
        clearDownloadStatsError,

        downloadHistoricalStats,
        clearDownloadHistoricalStatsError,

        assignCompletionsToUser,
        clearAssignmentError,

        updateCompletionsStatus,
        clearStatusChangeError,
    };

    return (
        <PMCompletionsContext.Provider value={services}>
            {children}
        </PMCompletionsContext.Provider>
    )
}

const usePMCompletionsContext = () => useContext(PMCompletionsContext);

export { usePMCompletionsContext as default, PMCompletionsContextProvider };
