import React, { useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Alert } from "antd";

import { useReactMediaRecorder } from "react-media-recorder";
import { AudioOutlined, PauseOutlined } from "@ant-design/icons";
import { setCompletionAudioBlob } from "../../../store/audioSlice";

export const { Title, Text } = Typography;

function pad(num, length = 2) {
    return ('' + num).padStart(length, '0');
}

function msToTime(ms) {
    let remainder = ms;
    const hours = Math.floor(remainder / 3.6e6);
    remainder -= hours * 3.6e6;
    const minutes = Math.floor(remainder / 60e3);
    remainder -= minutes * 60e3;
    const seconds = Math.floor(remainder / 1e3);
    remainder = Math.floor(remainder - seconds * 1e3);

    return [
        !!hours && `${pad(hours)}`,
        !!minutes && `${pad(minutes)}`,
        `${pad(seconds)}.${pad(remainder, 3)}`,
    ].filter(Boolean).join(':')
}

const RecordingControl = (props) => {
  const { isRecording, error, onStart, onStop, enableRecording, isCounterOn } = props;

  const recordIconShouldRender = Boolean(!isRecording && !error && !isCounterOn);
  const pauseIconShouldRender = Boolean(isRecording && !isCounterOn);

  return (
    <div>
      {recordIconShouldRender && (
        <AudioOutlined
          onClick={enableRecording && onStart}
          style={{
            color: '#ffffff',
            fontSize: '48px',
            padding: '16px',
            borderRadius: '50%',
            background: 'red',
            display: enableRecording ? 'block' : 'none',
            cursor: 'pointer',
          }}
        />
      )}
      {pauseIconShouldRender && (
        <PauseOutlined
          onClick={onStop}
          style={{
            color: '#fff',
            fontSize: '48px',
            padding: '16px',
            borderRadius: '16px',
            background: 'red',
            display: 'block',
          }}
        />
      )}
    </div>
  );
};

export const ReactMediaRecorder = (props) => {
  const {
    tagID,
    currentRecUrl,
    leadingDelay,
    trailingDelay,
    hasInstruction,
    instructionChecked,
    hideRecordingInstruction,
    showRecordingInstruction,
		setMediaPlaying,
  } = props;
  const dispatch = useDispatch();
  const timerUpdateRef = useRef();
  const [timer, setTimer] = useState({ elapsed: 0 });
  const [rec, setRec] = useState({})
  const [countdown, setCountdown] = useState(0);
  const [countdownMessage, setCountdownMessage] = useState('');

  const handleRecordingStopped = React.useCallback((blobUrl, blob) => {
      const newRec = {
          blob,
          size: blob.size,
          tagId: tagID,
          ext: blob.type.split('/')[1],
          url: URL.createObjectURL(blob),
      }

      setRec(newRec);
      dispatch(setCompletionAudioBlob(newRec))
  }, [setRec, dispatch, tagID]);

  const {
    error,
    status,
    isAudioMuted,
    stopRecording,
    startRecording,
  } = useReactMediaRecorder({
    audio: {
      channelCount: 2,
      autoGainControl: false,
      echoCancellation: false,
      noiseSuppression: false,
    },
    video: false,
    screen: false,
    askPermissionOnMount: true,
    onStop: handleRecordingStopped,
    blobPropertyBag: { type: 'audio/wav' },
    mediaRecorderOptions: { mimeType: 'audio/wav' },
  });

  const handleTimerUpdate = useCallback(() => {
      setTimer((s) => {
          return {
              ...s,
              elapsed: new Date().getTime() - s.start,
          }
      })
  }, [setTimer])

  const startTrailingCountdown = useCallback((seconds) => {
    let count = seconds;
    setCountdown(count);
    setCountdownMessage(`Recording will stop in`);

    const interval = setInterval(() => {
      count = count - 1;
      setCountdown(count);

      if (count < 1) {
        clearInterval(interval);
        setCountdownMessage('');
      }
    }, 1000);
  }, []);

  const startLeadingCountdown = useCallback((seconds) => {
    let count = seconds;
    setCountdown(count);

    if(count > 2) {
      setCountdownMessage('Collecting room ambient sound. Please remain quiet!');
    }

    const interval = setInterval(() => {
      count = count - 1;
      setCountdown(count);

      if (count < 1) {
        clearInterval(interval);
        setCountdownMessage('');
      } else if(count <= 2) {
        setCountdownMessage('Start speakin in');
      }
    }, 1000);
  }, []);

  const handleOnStartRec = useCallback(() => {
		if (error || status === 'recording') {
			return;
		}

		hideRecordingInstruction();
		const countdownSeconds = Math.round(leadingDelay / 1000);
		if (countdownSeconds > 0) {
			startLeadingCountdown(countdownSeconds);
		}

		const ts = new Date().getTime();
		setMediaPlaying();
		startRecording();
		setTimer({ start: ts, elapsed: 0 });
		timerUpdateRef.current = setInterval(handleTimerUpdate, 231);
	}, [
		error,
		status,
		hideRecordingInstruction,
		leadingDelay,
		startRecording,
		handleTimerUpdate,
		startLeadingCountdown,
		setMediaPlaying,
	]);

  const handleOnStopRec = useCallback(() => {
		const countdownSeconds = Math.round(trailingDelay / 1000);
		if (countdownSeconds > 0) {
			startTrailingCountdown(countdownSeconds);
		}

		setTimeout(() => {
			clearInterval(timerUpdateRef.current);
			setMediaPlaying();
			stopRecording();
			showRecordingInstruction();
		}, trailingDelay);
	}, [
		showRecordingInstruction,
		startTrailingCountdown,
		stopRecording,
		trailingDelay,
		setMediaPlaying,
  ]);

  const isRecording = status === 'recording';
  const hasRecordings = Boolean(rec?.url);

  const startRecordingPrompt = (hasInstruction && !instructionChecked) ?
    "Please check all instructions to start recording." : "Click to start recording";

  const recordingStatus = (hasInstruction && !isRecording) ?
    "Please check all instructions to restart and override recording." : status?.replaceAll('_', ' ');

  return (
    <div
      style={{
          gap: '16px',
          height: '205px',
          display: 'flex',
          flexDirection: 'column',
          placeItems: 'center',
          textAlign: 'center',
      }}
    >
      {
        isAudioMuted && (
          <Alert
            showIcon
            type="warning"
            description="Audio is muted"
            style={{ textTransform: 'capitalize' }}
          />
        )

      }

      {
        (!error && !hasRecordings && !isRecording)
          ? (
            <Title level={5} style={{ textTransform: 'none' }}>
              { startRecordingPrompt }
            </Title>
          ) : null
      }
      {
        (error || (!hasRecordings && !isRecording))
          ? null : (
            <Title level={5} style={{ textTransform: 'none' }}>
              {countdownMessage ? countdownMessage : recordingStatus}
            </Title>
          )
      }

      <Title level={1} style={{ textAlign: 'center', margin: '0' }}>
        { countdown ? countdown : msToTime(timer.elapsed) }
      </Title>

      <RecordingControl
        isRecording={isRecording}
        error={error}
        onStart={handleOnStartRec}
        onStop={handleOnStopRec}
        enableRecording={instructionChecked}
        isCounterOn={Boolean(countdownMessage)}
      />

      {error && (
        <Alert
          showIcon
          type="error"
          message={error.replaceAll('_', ' ')}
          description="Access to microphone is required to recorde audio for this completion"
          style={{ textTransform: 'capitalize' }}
        />
      )}
    </div>
  );
}