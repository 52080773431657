import { Link } from "react-router-dom";
import { Form, Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { LOGIN_PATH } from "../../../routes";
import "../Auth.scss"


const { Item } = Form;

const Footer = () => {
    return (
        <div className="auth-footer">
            <div>
                <span>Already have an account? </span><Link to={LOGIN_PATH}>Sign In</Link>
            </div>
            <Item>
                <Button type="primary" htmlType="submit">
                    <UserAddOutlined /> Create Account
                </Button>
            </Item>
        </div>
    )
}

export default Footer;