import { Form } from "antd";
import { MultiSelectField } from "../../PM/SelectExt";
import ErrorAlert from "../../Error/ErrorAlert";
import useOptionsContext from '../../../contexts/OptionsContext';

const { Item } = Form;

const Skills = ({ onSkillChange, selectedSkills }) => {

    const {
        skills,
        skillsError
    } = useOptionsContext();

    return (
        <Item
            label="Skills"
            name="skills"
            rules={[
                {
                    required: selectedSkills.length === 0,
                    message: "Please set your skills!",
                }
            ]}>
            <MultiSelectField
                selectedTags={selectedSkills}
                onTagsChange={onSkillChange}
                placeholder="Set your skills here..."
                options={skills.map(sk => ({ value: sk.id, key: sk.id, label: sk.name }))}
            />
            <ErrorAlert error={skillsError} />
        </Item>
    )
}

export default Skills;