import React from "react";
import { Switch, Route } from "react-router-dom";

import Content from "../AntdWrappers/Content/Content";
import MainEditorView from './CompletionEditPage/MainEditorView';
import Guidelines from './Guidelines';
import AssignedTasks from './AssignedTasks';
import ProjectsTable from "./ProjectsTable";
import { CTProjectsContextProvider } from '../../contexts/CTProjectsContext';
import { CompletionContextProvider } from '../../contexts/CompletionContext';
import { CompletionTimerContextProvider } from '../../contexts/CompletionTimerContext';

import { CONTRIBUTOR_TYPE } from "./CompletionEditPage/LookupTables";
import {
  CONTRIBUTOR_PROJECTS_PATH,
  CONTRIBUTOR_TASKS_PATH,
  COMPLETION_DP_PATH,
  COMPLETION_RV_PATH,
  COMPLETION_DP_PATH_ID,
  COMPLETION_RV_PATH_ID,
  GUIDELINES_PATH,
} from "../../routes";
import UploadProgressDrawer from './uploadProgressDrawer';

const DataProcessorEditor = () => <MainEditorView contributorType={CONTRIBUTOR_TYPE.DATA_PROCESSOR} />;
const ReviewerEditor = () => <MainEditorView contributorType={CONTRIBUTOR_TYPE.REVIEWER} />;

function Contributor() {
  return (
    <Content>
      <CompletionContextProvider>
        <CTProjectsContextProvider>
          <CompletionTimerContextProvider>
            <Switch>
              <Route path={COMPLETION_DP_PATH_ID} component={DataProcessorEditor} />
              <Route path={COMPLETION_DP_PATH} component={DataProcessorEditor} />

              <Route path={COMPLETION_RV_PATH_ID} component={ReviewerEditor} />
              <Route path={COMPLETION_RV_PATH} component={ReviewerEditor} />

							<Route path={GUIDELINES_PATH} component={Guidelines} />
							<Route path={CONTRIBUTOR_TASKS_PATH} component={AssignedTasks} />
							<Route
								path={CONTRIBUTOR_PROJECTS_PATH}
								component={ProjectsTable}
							/>
						</Switch>
						<UploadProgressDrawer />
					</CompletionTimerContextProvider>
				</CTProjectsContextProvider>
			</CompletionContextProvider>
		</Content>
	);
}

export default Contributor;
