import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Input, Row } from 'antd';

import ConsentControlButtons from './ConsetnControlButtons';
import RichTextArea from './RichTextArea';
import ActivateConsent from './ActivateConsent';
import ErrorModal from '../../../Error/ErrorModal';
import PMPrjSingleContext from '../../../../contexts/PMPrjSingleContext';
import { sanitizeText } from '../../../Common/RichGuidelinesEditor';


const ConsentAgreement = () => {

    const {
        selectedProject,
        errorUpdate,
        clearUpdateError
    } = useContext(PMPrjSingleContext);

    const [state, setState] = useState({
        editMode: false,
        editedConsent: {
            content: '',
            agree: '',
            disagree: ''
        },
        originalConsent: {
            content: '',
            agree: '',
            disagree: ''
        },
        activateAgreement: selectedProject.service === 'Audio Data Collection' || selectedProject.consentRequired,
    });

    useEffect(() => {
        if (selectedProject && selectedProject.agreement && selectedProject.agreement.content) {
            sanitizeText(selectedProject.agreement.content)
                .then(cleanConsent => {
                    setState(ps => ({
                        ...ps,
                        originalConsent: {
                            content: cleanConsent,
                            agree: selectedProject.agreement.agree,
                            disagree: selectedProject.agreement.disagree
                        },
                    }))
                })
        }
        // eslint-disable-next-line
    }, [selectedProject]);

    useEffect(() => {
        if (selectedProject.service === 'Audio Data Collection')
            setState(ps => ({ ...ps, activateAgreement: true }))
        else
            if (!selectedProject.consentRequired)
                setState(ps => ({ ...ps, activateAgreement: false }))
        // eslint-disable-next-line
    }, [selectedProject.service]);

    const hanldeChangeAgreeButtonName = (e) => {
        e.persist()
        setState(ps => ({
            ...ps,
            editedConsent: {
                ...ps.editedConsent,
                agree: e.target.value
            }
        }))
    }

    const hanldeChangeDisagreeButtonName = (e) => {
        e.persist()
        setState(ps => ({
            ...ps,
            editedConsent: {
                ...ps.editedConsent,
                disagree: e.target.value
            }
        }))
    }

    return (
        <Fragment>
            <ErrorModal error={errorUpdate} onDone={clearUpdateError} />
            {/**Activate Consent Switch */}
            {selectedProject.agreement !== null && <ActivateConsent
                activateAgreement={state.activateAgreement}
                setState={setState}
            />}

            {(state.activateAgreement || selectedProject.agreement === null) &&
                <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>
                    {/**Consent Agree Button */}
                    <Row>
                        <AgreeDisagreeButton
                            editMode={state.editMode}
                            onChange={hanldeChangeAgreeButtonName}
                            originalVlaue={state.originalConsent.agree}
                            buttonValue={state.editedConsent.agree}
                            placeholder="Agree Button Name..."
                        />
                    </Row>

                    <Row justify="space-between">
                        {/**Consent Disagree Button */}
                        <AgreeDisagreeButton
                            editMode={state.editMode}
                            onChange={hanldeChangeDisagreeButtonName}
                            originalVlaue={state.originalConsent.disagree}
                            buttonValue={state.editedConsent.disagree}
                            placeholder="Disagree Button Name..."
                        />

                        {/**Consent Control Buttons */}
                        <ConsentControlButtons
                            setState={setState}
                            state={state}
                        />
                    </Row>

                    <RichTextArea
                        setState={setState}
                        state={state}
                    />
                </div>}
        </Fragment>
    )
}

const AgreeDisagreeButton = ({ editMode, originalVlaue, buttonValue, onChange, placeholder }) => {
    return (
        <Fragment>
            {!editMode && <Col span={5}>
                <Input
                    placeholder={placeholder}
                    disabled={true}
                    value={originalVlaue}
                />
            </Col>}

            {editMode && <Col span={5}>
                <Input
                    placeholder={placeholder}
                    disabled={false}
                    value={buttonValue}
                    onChange={onChange}
                />
            </Col>}
        </Fragment>
    )
}

export default ConsentAgreement;
