import * as yup from 'yup';

export const RecordTagAttrsSchema = yup.object().shape({
    minsnr: yup
      .number()
      .nullable()
      .integer()
      .typeError('minSnr value must be an integer'),
    maxsnr: yup
      .number()
      .nullable()
      .integer()
      .test('maxSnr > minSnr', 'minSnr must not be greater than maxSnr', function (value, context) {
        const { minsnr } = context.parent;
        if (value !== null && minsnr !== null && !isNaN(minsnr) ) {
          return value >= minsnr;
        }
        return true;
      })
      .typeError('maxSnr value must be an integer'),
    loudness: yup.number().nullable().max(0).typeError('loudness value must be a number less than or equal to 0'),
    truepeak: yup.number().nullable().lessThan(0).typeError('truepeak value must be a number less than 0'),
    leadingdelay: yup.number().integer().min(0).typeError('leadingDelay value must be a number greater than 0'),
    trailingdelay: yup.number().integer().min(0).typeError('trailingDelay value must be a number greater than 0'),
  });