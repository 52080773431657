import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag, Select } from 'antd';

////////////////////////// FixedSelect

const KEY_BACKSPACE = 'Backspace';
const KEY_CLEAR_ALL = 'ClearAll';
// The following was defined as module variable and not a part of the state since we need its value
// updates to take place immediately and not asyncronously like for state updates as there are two events
// occurring right after each other like keyPressed and onChange and the state updates are not catching 
// up.
let _pressedKey = '';

const FixedSelect = (props) => {

    const [state, setState] = useState({
        internalValue: [],
    })

    // Keeping the internal state in synch with the props.value if it was set.
    useEffect(() => {
        if (props.value)
            setState(ps => ({ ...ps, internalValue: props.value }))
        //eslint-disable-next-line            
    }, [props.value])

    // Intercepting the on keydown change and storing the value of the last pressed key.
    const handleOnInputKeyDown = e => {
        _pressedKey = e.key;
        // Passing the event to the parent component if it was set.
        if (props.onInputKeyDown)
            props.onInputKeyDown(e);
    }

    // Intercepting on clear all event to set the pressed key to clear all code since the latest pressed key could be anything.
    const handleOnClear = () => {
        _pressedKey = KEY_CLEAR_ALL;
        if (props.onClear)
            props.onClear();
    }

    // Intercepting onChange and negating the remove action if the pressed key is not clear all or not backspace.
    const handleOnChange = (values, options) => {
        // Addition of items has no problems, so it is done directly.
        if (values.length >= state.internalValue.length)
            actuateOnChange(values, options);

        // Removal of elements is only allowed using backspace or clear all icon only.  
        else if (_pressedKey === KEY_BACKSPACE || _pressedKey === KEY_CLEAR_ALL) //else means values.length < state.internalValue.length
            //Previously enter was triggering on change and no filter was done on the pressed key.
            actuateOnChange(values, options);

        else {
            // Do Nothing. No change should take place.
        }
    }

    // Update the internal state and broadcast the event to the parent if it was set in props.
    const actuateOnChange = (values, options) => {
        setState(ps => ({ ...ps, internalValue: values }));
        // Passing the event to the parent component if it was set.
        if (props.onChange)
            props.onChange(values, options);
    }

    // Wraps the props tag render and overriding and extending the implementation of the Tag's onClose.
    const tagRendereWrapper = tagProps => {
        if (props.tagRender)
            return props.tagRender({
                ...tagProps,
                // Overriding and extending the implementation of the external renderer onClose 
                // since it should set the pressed key to Backspace for the item to be deleted
                // as the latest pressed key could be anything which would have negated the close
                // action if the latest pressed key is any letter other than the backspace.
                onClose: e => {
                    _pressedKey = KEY_BACKSPACE;
                    tagProps.onClose(e);
                }
            })
    }

    return (
        <Select
            {...props}
            open={false}
            value={props.value ? props.value : state.internalValue}
            onInputKeyDown={handleOnInputKeyDown}
            onChange={handleOnChange}
            onClear={handleOnClear}
            tagRender={tagRendereWrapper}
        />
    );

}

FixedSelect.propTypes = {
    tagRender: PropTypes.func.isRequired
}

////////////////////////// TagsBox

const DEFAULT_TOKEN_SEPARATORS = [",", " "]
const defaultTagRenderer = ({ value, closable, onClose }) => {
    return (
        <Tag color={'#108ee9'}
            closable={closable}
            onClose={onClose}>
            <span style={{ fontSize: '12pt' }}>{value}</span>
        </Tag>
    );
}

const TagsBox = (props) => {
    return (
        <FixedSelect
            {...props}
            mode={"tags"}
            dropdownStyle={{ display: 'none' }}
            tokenSeparators={Array.isArray(props.tokenSeparators) && props.tokenSeparators.length > 0 ? props.tokenSeparators : DEFAULT_TOKEN_SEPARATORS}
            tagRender={props.tagRender ? props.tagRender : defaultTagRenderer}
        />
    );
}

TagsBox.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array,
    tagRender: PropTypes.func,
    tokenSeparators: PropTypes.array,
}

export default TagsBox;
