import React, { useEffect } from 'react';
import { Alert, Button, Space } from 'antd';
import QRCode from 'react-qr-code';
import { useAuthContext } from '../../contexts/AuthContext';
import TwoFactorAuthTOTPVerification from './TwoFactorAuthTOTPVerification';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const TwoFactorAuthTOTP = ({ goBackToSignIn }) => {
	const { enrollTotp, finalizeTotpEnrollment } = useAuthContext();

	const [qrCodeUrl, setQrCodeUrl] = React.useState('');
	const [totpSecret, setTotpSecret] = React.useState('');
	const [secretKey, setSecretKey] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);
	const [isScanned, setIsScanned] = React.useState(false);
	const [error, setError] = React.useState('');

	useEffect(() => {
		if (!isScanned) {
			setIsLoading(true);
			enrollTotp()
				.then((res) => {
					if (res) {
						setQrCodeUrl(res?.qrCodeUrl);
						setTotpSecret(res?.secret);
						setSecretKey(res?.secret?.secretKey);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.error(err);
					setError(err.message);
					setIsLoading(false);
				});
		}
	}, [isScanned]);

	const verifyCode = async (verificationCode) => {
		try {
			await finalizeTotpEnrollment(totpSecret, verificationCode);
		} catch (err) {
			setError(err.message);
			console.error(err);
		}
	};

	return !isScanned ? (
		<Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
			{/* Display QR code	*/}
			{error && <Alert message={error} type="error" />}
			{isLoading ? (
				<Space
					direction="vertical"
					align={'center'}
					style={{ width: '100%', height: 300 }}
				>
					<h3>Loading...</h3>
				</Space>
			) : (
				<>
					<Alert
						style={{ marginBottom: 20 }}
						message={
							<>
								Scan the QR code with your authenticator app <br /> then press{' '}
								<b>Next</b> to enter the verification code...
							</>
						}
						type="info"
					/>

					<QRCode value={qrCodeUrl} />

					{/*	Display the secret key	*/}
					<p>
						Secret Key: <b>{secretKey}</b>
					</p>

					<Space
						direction="horizontal"
						style={{ width: '100%', justifyContent: 'flex-end' }}
					>
						<Button type="default">Back</Button>
						<Button type="primary" onClick={() => setIsScanned(true)}>
							Next
						</Button>
					</Space>
				</>
			)}
		</Space>
	) : (
		<TwoFactorAuthTOTPVerification
			verifyCode={verifyCode}
			goBackToSignIn={goBackToSignIn}
		/>
	);
};

export default TwoFactorAuthTOTP;
