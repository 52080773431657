import React, { useState, useCallback } from 'react';
import { Document, Page } from 'react-pdf';
import { useMediaQuery } from 'react-responsive';
import './FilePreview.scss';
import Pagination from './Pagination';

const PdfViewer = ({ file, url }) => {
	const [pdfURL, setPdfURL] = useState('');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const handleIncreasePageNum = () => {
		if (pageNumber < numPages) setPageNumber((prevState) => prevState + 1);
	};

	const handleDecreasePageNum = () => {
		if (pageNumber > 1) setPageNumber((prevState) => prevState - 1);
	};

	const getFileURL = useCallback((file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file.originFileObj);
		reader.onload = () => {
			setPdfURL(reader.result);
		};
	}, []);

	if (file && !url) {
		getFileURL(file);
	}

	return (
		<div className="pdf-container">
			<Document
				file={url || pdfURL}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadProgress={() => 'Loading file...'}
			>
				<Page
					pageNumber={pageNumber}
					width={isTabletOrMobile && window.innerWidth}
					className="pdf-page"
				/>
			</Document>

			<Pagination
				pageNumber={pageNumber}
				goToNextPage={handleIncreasePageNum}
				goToPreviousPage={handleDecreasePageNum}
				numPages={numPages}
			/>
		</div>
	);
};

export default PdfViewer;
