import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const ImportButton = ({ disabled, loading, onClick }) => {

    return (
        <Upload
            beforeUpload={() => false}//Required to stop antd default post request                                                
            onChange={onClick}
            showUploadList={false}
        >
            <Button
                type='primary'
                size='small'
                disabled={disabled}
                loading={loading}
            >
                <UploadOutlined /> Upload Adhocs
            </Button>
        </Upload>
    )
}

export default ImportButton;