import React, { Fragment, useState } from 'react';
import { Button, Space } from "antd";

import { CompletionInfoModal } from './CompletionInfo'
import { GuidelinesModal } from '../Guidelines';
import { CommentsModal } from './Comments/CommentsSection';

import '../../Styles/Button.scss';

const CompletionViewHeader = ({ contributorType }) => {

    const [state, setState] = useState({
        showGuidelines: false,
        showCompletionInfo: false,
        showComments: false,
    })

    const handleOnShowCommentsModal = (value) => setState(ps => ({ ...ps, showComments: value }))
    const handleOnShowGuidelinesModal = (value) => setState(ps => ({ ...ps, showGuidelines: value }))
    const handleOnShowCompletionInfoModal = (value) => setState(ps => ({ ...ps, showCompletionInfo: value }))

    return (
        <Fragment>

            <GuidelinesModal
                visible={state.showGuidelines}
                onDone={() => handleOnShowGuidelinesModal(false)}
            />

            <CompletionInfoModal
                visible={state.showCompletionInfo}
                onDone={() => handleOnShowCompletionInfoModal(false)}
            />

            <CommentsModal
                visible={state.showComments}
                onDone={() => handleOnShowCommentsModal(false)}
                contributorType={contributorType}
            />

            <Space direction='horizontal'>
                <Button type='link' className='header-buttons' size='small' onClick={() => handleOnShowGuidelinesModal(true)}>Guidelines</Button>
                <Button type='link' className='header-buttons' size='small' onClick={() => handleOnShowCompletionInfoModal(true)}>Completion Info</Button>
                <Button type='link' className='header-buttons' size='small' onClick={() => handleOnShowCommentsModal(true)}>Comments</Button>
            </Space>

        </Fragment>
    )
}

export default CompletionViewHeader;
