import React, { useState } from 'react';
import { Button, Tag, Modal, Spin, Alert, Space } from 'antd';
import { CheckCircleFilled, CheckOutlined } from "@ant-design/icons";
import { nanoid } from 'nanoid';

import TagsBox from '../../../Common/TagsBox';
import { usePMProjectSingleContext, CONTRIBUTORS_ASSIGNMENT_TYPE } from '../../../../contexts/PMPrjSingleContext';
import AssignmentActionsButton from './AssignmentActionsButton';
import ErrorAlert from '../../../Error/ErrorAlert';
import { validateEmail } from '../../../Common/Utils';

const PasteAssignModal = ({ show, onDone }) => {

    const {
        contributorsLoading,
        contributorsCriteria,
        contributorsAssignmentError,

        setContributorsAssignedState,
        fetchContributors,
        clearContributorAssignmentError,
    } = usePMProjectSingleContext();

    const [state, setState] = useState({
        emails: [],
        newContributorsWereAssigned: false,
        showSuccessMessage: false,
        showErrorMessage: false,
        failedAssignments: [],
    });


    const onAddedEmailsChange = (e) => {
        setState(ps => ({ ...ps, emails: e }));
    }
    const tagRender = ({ value, label, closable, onClose }) => {
        const valid = validateEmail(value);
        return (
            <Tag color={valid ? '#081f2d' : '#f50'}
                closable={closable}
                onClose={onClose}>
                <span style={{ fontSize: '12pt' }}>{label}</span>
            </Tag>
        );
    }
    const handleAssignMenuItemClick = (e) => {
        const validEmails = state.emails.filter(e => validateEmail(e));
        setContributorsAssignedState(validEmails, true, CONTRIBUTORS_ASSIGNMENT_TYPE[e.key], null, true)
            .then(failedAssignments => {
                if (failedAssignments) {
                    const failedEmails = failedAssignments.map(fa => fa.email);
                    setState(ps => ({
                        ...ps,
                        emails: ps.emails.filter(em => failedEmails.includes(em) || !validateEmail(em)),
                        showSuccessMessage: false,
                        showErrorMessage: true,
                        newContributorsWereAssigned: ps.emails.length !== failedAssignments.length,
                        failedAssignments: failedAssignments,
                    }));
                }
                else
                    setState(ps => ({
                        ...ps,
                        emails: ps.emails.filter(e => !validateEmail(e)),
                        showSuccessMessage: true,
                        showErrorMessage: false,
                        newContributorsWereAssigned: true,
                        failedAssignments: [],
                    }));
            });

    }
    const handleOnDone = () => {
        // Refreshing the current contributors when the modal is closed if there were changes in the contributors assignment.
        if (state.newContributorsWereAssigned)
            fetchContributors(contributorsCriteria);

        // Clearing the state.
        setState(ps => ({
            ...ps,
            showSuccessMessage: false,
            showErrorMessage: false,
            failedAssignments: [],
        }));

        // Clearing any error may be saved in the context.
        clearContributorAssignmentError();

        // Calling on done of the parent.
        onDone();
    }


    const footerButtons = [
        <AssignmentActionsButton
            key='assign'
            type='primary'
            icon={<CheckOutlined />}
            text='Assign As'
            disabled={contributorsLoading || state.emails.filter(em => validateEmail(em)).length === 0}
            onClickHandler={handleAssignMenuItemClick}
        />,
        <Button
            type='primary'
            size='small'
            key="done"
            disabled={contributorsLoading}
            onClick={handleOnDone}
        >
            Done
        </Button>
    ]

    return (
        <Modal
            title={
                <span style={{ fontSize: '1.1rem' }}>
                    <CheckCircleFilled style={{ color: '#1890ff', marginRight: '10px' }} />Assign Contributors With Emails
                </span>
            }
            visible={show}
            onCancel={handleOnDone}
            footer={footerButtons}
        >
            <Spin spinning={contributorsLoading}>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <TagsBox
                        style={{ width: '100%' }}
                        autoFocus={true}
                        size='large'
                        value={state.emails}
                        onChange={onAddedEmailsChange}
                        tagRender={tagRender}
                        placeholder="Paste emails here..."
                        allowClear={true}
                    />

                    <ErrorAlert error={contributorsAssignmentError} />

                    {state.showSuccessMessage &&
                        <Alert
                            message="Contributors were assigned successfully!"
                            type="success"
                            showIcon
                            closable
                            onClose={() => setState(ps => ({ ...ps, showSuccessMessage: false }))}
                        />
                    }

                    {state.showErrorMessage &&
                        <Alert
                            message={
                                <Space direction='vertical'>
                                    <h3>Failed to assign the following emails!</h3>
                                    <ul>{state.failedAssignments.map(f => <li key={nanoid()}>{`${f.email}. ${f.message}`}</li>)}</ul>
                                </Space>
                            }
                            type="error"
                            showIcon
                            closable
                            onClose={() => setState(ps => ({ ...ps, showErrorMessage: false }))}
                        />
                    }
                </Space>
            </Spin>
        </Modal>
    )
}

export default PasteAssignModal;