import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Space } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import Content from '../../AntdWrappers/Content/Content';
import ErrorAlert from '../../Error/ErrorAlert';
import LoginWithEmailPassword from './LoginWithEmailPassword';
import LxtLoadingLogo from '../../Common/LxtLoadingLogo';
import LoginWithEmailPasswordMobile from './LoginWithEmailPasswordMobile';
import { useMediaQuery } from 'react-responsive';

import roles from '../../../model/roles';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
	CONTRIBUTOR_PROJECTS_PATH,
	PM_PROJECTS_PATH,
	ROOT_PATH,
} from '../../../routes';
import '../Auth.scss';
import TwoFactorAuthSetup from '../TwoFactorAuthSetup';

const CardTitle = ({ icon, text }) => (
	<Space className="card-title-style">
		{icon} {text}
	</Space>
);

const Login = ({ location }) => {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

  const {
    user,
    isLoading,
    twoFactorAuthRequired,
    twoFactorAuthActivationRequired,
    RECAPTCHA_VERIFIER_ID,

    signInByEmailAndPassword,

    initRecaptcha,
    clearRecaptcha,

    error,
    clearError
  } = useAuthContext();


  useEffect(() => {
    initRecaptcha();
    return () => {
      clearError();
      clearRecaptcha();
    }
    //eslint-disable-next-line
  }, []);

  const cardStyle = {
    width: isTabletOrMobile ? '100%' : '450px',
    marginBottom: '20px'
  }


  const getRedirectPath = role => {
    if (location.state && location.state.referrer) {
      return location.state.referrer;
    }
    let to;
    switch (role) {
      case roles.CONTRIBUTOR:
        to = CONTRIBUTOR_PROJECTS_PATH;
        break;
      case roles.PM:
        to = PM_PROJECTS_PATH;
        break;
      default:
        to = ROOT_PATH;
    }
    return to;
  };

  // Handles sign in using email and passowrd.
  const handleSignIn = (email, password) => {
		signInByEmailAndPassword(email, password);
  }


  let twoFactorAuth = twoFactorAuthRequired || twoFactorAuthActivationRequired;

  const hasUser = Boolean(user?.role);
  const noUser = !hasUser

  return (
		<Content alignVertically>
			{/* Redirecting the user if he is logged in */}
			{hasUser && <Redirect to={getRedirectPath(user.role)} />}

			{/* Loading Logo */}
			{isLoading && <LxtLoadingLogo text="Signing In..." />}

			{/* Components for sign in. */}
			{noUser && !isLoading && !twoFactorAuth && (
				<Space align="center" direction="vertical">
					{/* Signing in with email and password */}
					{!isTabletOrMobile && (
						<Card
							style={cardStyle}
							title={
								<CardTitle text="Please Sign In" icon={<LoginOutlined />} />
							}
						>
							<LoginWithEmailPassword signInByEmailAndPassword={handleSignIn} />
						</Card>
					)}

					{/**Mobile Signin with email and password */}
					{isTabletOrMobile && (
						<LoginWithEmailPasswordMobile
							signInByEmailAndPassword={handleSignIn}
						/>
					)}

					<ErrorAlert error={error} />
				</Space>
			)}

			{/* The second factor authentication component. */}

			{/* Show enrolled 2FA options */}
			{noUser &&
				!isLoading &&
				(twoFactorAuthRequired || twoFactorAuthActivationRequired) && (
					<TwoFactorAuthSetup />
				)}

			{/* The recaptcha verifier container */}
			<div id={RECAPTCHA_VERIFIER_ID}></div>
		</Content>
	);
};

export { Login as default, CardTitle };
