import React, { useCallback, useState, useMemo } from 'react';
import DOMPurify from 'dompurify';
import { readEml } from 'eml-parse-js';

const EmlViewer = ({ file, url }) => {
	const [content, setContent] = useState('');

	const processEml = useCallback((emlContent) => {
		readEml(emlContent, (err, ReadEmlJson) => {
			const sanitizedContent = DOMPurify.sanitize(
				ReadEmlJson.html || ReadEmlJson.text,
			);
			setContent(sanitizedContent);
		});
	}, []);

	useMemo(() => {
		if (file && !url) {
			const actualFile = file instanceof Blob ? file : file.originFileObj;
			if (actualFile) {
				const reader = new FileReader();
				reader.readAsText(actualFile);
				reader.onload = () => {
					processEml(reader.result);
				};
			} else {
				console.error('Invalid file format. Expected a Blob or File.');
			}
		}
	}, [file, url, processEml]);

	useMemo(() => {
		if (url && !content) {
			fetch(url)
				.then((response) => response.text())
				.then(async (emlContent) => {
					processEml(emlContent);
				})
				.catch((error) => console.error('Error fetching EML file:', error));
		}
	}, [url, content, processEml]);

	return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default EmlViewer;
