import React, { Component } from 'react';
import ErrorPage from './ErrorPage';
import { datadogLogs } from '@datadog/browser-logs';
import {datadogRum} from "@datadog/browser-rum";

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
		};
	}

	static getDerivedStateFromError(error) {
		datadogRum.addError(error, { errorBoundary: true })

		datadogLogs.logger.error(
			error.message,
			{
				errorBoundary: true,
			},
			error,
		);

		return {
			hasError: true,
			error: {
				title: 'Oops... Something Went Wrong!',
				details: [`Message: ${error.message}`, `Trace: ${error.stack}`],
			},
		};
	}

	componentDidCatch(error, info) {
		datadogRum.addError(error, info)

		datadogLogs.createLogger('ErrorBoundary')
			.error(error.message, info, error)
	}

	render() {
		return this.state.hasError ? (
			<ErrorPage error={this.state.error} />
		) : (
			this.props.children
		);
	}
}

export default ErrorBoundary;
