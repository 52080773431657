import React from 'react';
import { Tabs } from "antd";
import { SendOutlined, TeamOutlined } from '@ant-design/icons';

import './Users.scss';

import UsersTable from './UsersTable';
import UsersSendInvitations from './UsersSendInvitations';
import ErrorBoundary from '../../Error/ErrorBoundary';
import { PMUsersContextProvider } from '../../../contexts/PMUsersContext';

const { TabPane } = Tabs;

const Users = (props) => {
    return (
        <PMUsersContextProvider>
            <UsersInternal {...props} />
        </PMUsersContextProvider>
    )
}

const AllUsersHeader = () => {
    return (
        <span> <TeamOutlined />User Management</span>
    )
}

const InviteUsersHeader = () => {
    return (
        <span> <SendOutlined />Send Invitations </span>
    )
}

const UsersInternal = () => {

    return (
        <ErrorBoundary>
            <div style={{ paddingBottom: '150px' }}>

                <Tabs>
                    <TabPane key='users' tab={<AllUsersHeader />}>
                        <UsersTable />
                    </TabPane>
                    <TabPane key='invitations' tab={<InviteUsersHeader />}>
                        <UsersSendInvitations />
                    </TabPane>
                </Tabs>

            </div>
        </ErrorBoundary>
    )
}

export default Users;
