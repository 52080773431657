import React, { useState } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import { useCallback } from 'react';
import useOptionsContext from '../../contexts/OptionsContext';
import SelectInput from './SelectInput';
import { Tag } from 'antd';


const SkillsInput = ({ user, editMode, onChangeEditMode }) => {

    const [selectedSkills, setSelectedSkills] = useState([]);

    const [isSkillsEditable, setIsSkillsEditable] = useState(false);

    const {
        skills: allSkills,
        skillsError
    } = useOptionsContext();

    const handleEditableStatus = useCallback((status) => {
        setIsSkillsEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const handleOnChange = useCallback((skill) => {
        setSelectedSkills(skill);
    }, []);

    const renderSkills = useCallback((skills) => {
        return skills.map(sk => <Tag key={sk.name} color="#081f2d">{sk.name}</Tag>);
    }, []);

    const hasSkills = Boolean(user?.skills?.length);
    const isEditable = isSkillsEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Skills:"
            value={
                (hasSkills && !isEditable) ?
                    renderSkills(user.skills) :
                    (isEditable) ?
                        <SelectInput
                            onChange={handleOnChange}
                            value={selectedSkills}
                            options={allSkills.map(sk => ({ value: sk.id, key: sk.id, label: sk.name }))}
                            optionsErr={skillsError}
                            placeholder=" Set your skills here..."
                            selectMode="multiple"
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="skills"
                    inputValue={selectedSkills?.length ? selectedSkills : null}
                    inputEditable={isSkillsEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!selectedSkills.length ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default SkillsInput;