import { useQuery } from '@tanstack/react-query';
import { queryGetLatestServiceAgreements } from '../api/serviceAgreements';

export function useGetLatestAgreement() {
	const queryKey = 'service-agreements/latest';
	const queryFn = queryGetLatestServiceAgreements;

	const result = useQuery( {
		queryKey,
		queryFn,
		refetchOnWindowFocus: false,
	});

	if (result.isSuccess) {
		return result.data;
	}
}
