/* eslint-disable import/no-anonymous-default-export */
import { featureToggle } from "../lib/featureToggles";
import { lxtBackend } from "./utils";

const routes = {

  getPMProjects: async (criteria) => {
    let url = 'pm/projects';
    let params = [];
    criteria = criteria || {};

    if (typeof criteria.search === 'string' && criteria.search.trim()) params.push(`search=${criteria.search.trim()}`);
    if (typeof criteria.skip === 'number') params.push(`skip=${criteria.skip}`);
    if (typeof criteria.limit === 'number') params.push(`limit=${criteria.limit}`);
    if (Array.isArray(criteria.statusFilter))
      criteria.statusFilter.forEach(st => params.push(`status=${st}`));

    // Adding ordering parameters.
    if (typeof criteria.orderColumn === 'string') {
      params.push(`order=${criteria.orderColumn}`);
      params.push(`asc=${criteria.orderAscending}`);
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  getPMProject: async id => lxtBackend.get(`pm/projects/${id}`),

  updatePMProject: async (id, data) => lxtBackend.patch(`pm/projects/${id}`, data),

  createProject: async data => lxtBackend.post("pm/projects", data),

  uploadCsvFile: async (id, fileFormData) => lxtBackend.post(`pm/projects/${id}/tasks/upload`, fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),

  addConsentAgreement: async (project_id, data) => lxtBackend.post(`projects/${project_id}/agreements`, data),

  updateMetaData: async (project_id) => lxtBackend.post(`pm/projects/${project_id}/tasks/trigger-metadata-update`),

  /////////////////////////////////////////////////////////////////////////////////////// Contributors

  getProjectContributors: async (projectID, role, assigned, search, skills, otherLanguages) => {

    let url = `pm/projects/${projectID}/contributors`;

    let params = [];
    params.push(`role=${role}`);
    params.push(`assigned=${assigned}`);
    if (search) params.push(`search=${search}`);
    if (skills) skills.forEach(sk => params.push(`skill=${sk}`));
    if (otherLanguages) otherLanguages.forEach(ln => params.push(`otherLanguage=${ln}`));

    let urlQuery = url + '?' + params.join('&');
    return lxtBackend.get(urlQuery);
  },

  getProjectContributorsNew: async (projectID, criteria) => {

    let url = `pm/projects/${projectID}/contributors`;

    let params = [];
    if (criteria) {
      if (typeof criteria.search === 'string' && criteria.search.trim()) params.push(`search=${criteria.search.trim()}`);
      if (typeof criteria.assignedDP === 'boolean') params.push(`assignedDP=${criteria.assignedDP}`);
      if (typeof criteria.assignedRV === 'boolean') params.push(`assignedRV=${criteria.assignedRV}`);
      if (featureToggle.isOn("adhoc-contributor-task") && typeof criteria.assignedAH === 'boolean') {
        params.push(`assignedAH=${criteria.assignedAH}`);
      }
      if (typeof criteria.isActive === 'boolean') params.push(`isActive=${criteria.isActive}`);
      if (typeof criteria.signedLatestServiceAgreement === 'boolean') params.push(`signedLatestServiceAgreement=${criteria.signedLatestServiceAgreement}`);
      if (typeof criteria.showOnlyAssigned === 'boolean') params.push(`onlyAssigned=${criteria.showOnlyAssigned}`);
      if (Array.isArray(criteria.skills)) criteria.skills.forEach(sk => params.push(`skill=${sk}`));
      if (Array.isArray(criteria.otherLanguages)) criteria.otherLanguages.forEach(ln => params.push(`otherLanguage=${ln}`));
      if (Array.isArray(criteria.nativeLanguage)) criteria.nativeLanguage.forEach(ln => params.push(`nativeLanguage=${ln}`));

      if (typeof criteria.skip === 'number') params.push(`skip=${criteria.skip}`);
      if (typeof criteria.limit === 'number') params.push(`limit=${criteria.limit}`);

      // Adding ordering parameters.
      if (typeof criteria.orderColumn === 'string') {
        params.push(`order=${criteria.orderColumn}`);
        params.push(`asc=${criteria.orderAscending}`);
      }
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  getProjectConsent: async (projectID) => lxtBackend.get(`projects/${projectID}/agreements/latest`),
  sendUserDecisionForConsnent: async (agreementID, data) => lxtBackend.post(`agreements/${agreementID}/consent`, data),

  assignProjectContributors: async (projectID, contributorsData) => lxtBackend.post(`pm/projects/${projectID}/contributors/assign`, contributorsData),

  unAssignProjectContributors: async (projectID, contributorsData) => lxtBackend.post(`pm/projects/${projectID}/contributors/unassign`, contributorsData),

  assignCompletionsOnContributer: async (projectID, userID, completionIDs, asReviewer) =>
    lxtBackend.post(`pm/projects/${projectID}/completions/assign?user_id=${userID}&role=${asReviewer ? 'rv' : 'dp'}`, { "ids": completionIDs }),

  updateCompletionsStatus: async (projectID, completionIDs, status) =>
    lxtBackend.post(`pm/projects/${projectID}/completions/change-status?status=${status}`, { "ids": completionIDs }),

  /////////////////////////////////////////////////////////////////////////////////////// Completions

  getProjectCompletions: async (projectID, criteria) => {

    let url = `pm/projects/${projectID}/completions`;

    let params = [];
    if (typeof criteria.completionID === 'string' && criteria.completionID.trim()) params.push(`completion_id=${criteria.completionID.trim()}`);
    if (typeof criteria.taskID === 'string' && criteria.taskID.trim()) params.push(`task_id=${criteria.taskID.trim()}`);
    if (Array.isArray(criteria.statuses)) criteria.statuses.forEach(st => params.push(`status=${st}`));
    if (Array.isArray(criteria.dataProcessors)) criteria.dataProcessors.forEach(dp => params.push(`search_dp=${dp}`));
    if (Array.isArray(criteria.reviewers)) criteria.reviewers.forEach(rv => params.push(`search_rv=${rv}`));
    if (criteria.skip || criteria.skip === 0) params.push(`skip=${criteria.skip}`);
    if (criteria.limit) params.push(`limit=${criteria.limit}`);

    // Adding ordering parameters.
    if (typeof criteria.orderColumn === 'string') {
      params.push(`order=${criteria.orderColumn}`);
      params.push(`asc=${criteria.orderAscending}`);
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  downloadResults: async (projectID) => lxtBackend.get(`pm/projects/${projectID}/tasks/export`, { responseType: 'blob' }),

  downloadStats: async (projectID) => lxtBackend.get(`pm/projects/${projectID}/completions/stats-export`, { responseType: 'blob' }),
  downloadHistoricalStats: async (projectID) => lxtBackend.get(`projects/${projectID}/completions/historical-stats-export`, { responseType: 'blob' }),

  /////////////////////////////////////////////////////////////////////////////////////// Contributor

  getContributorProjects: async (criteria) => {
    let url = "contributor/projects";
    let params = [];

    if (typeof criteria.search === 'string' && criteria.search.trim()) params.push(`search=${criteria.search.trim()}`);
    if (typeof criteria.skip === 'number') params.push(`skip=${criteria.skip}`);
    if (typeof criteria.limit === 'number') params.push(`limit=${criteria.limit}`);

    // Adding ordering parameters.
    if (typeof criteria.orderColumn === 'string') {
      params.push(`order=${criteria.orderColumn}`);
      params.push(`asc=${criteria.orderAscending}`);
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  getContributorAssignedCompletions: async (criteria) => {
    let url = 'contributor/completions';
    let params = [];

    if (typeof criteria.skip === 'number') params.push(`skip=${criteria.skip}`);
    if (typeof criteria.limit === 'number') params.push(`limit=${criteria.limit}`);
    if (criteria.assignmentType) params.push(`assignment_type=${criteria.assignmentType}`);

    // Adding ordering parameters.
    if (typeof criteria.orderColumn === 'string') {
      params.push(`order=${criteria.orderColumn}`);
      params.push(`asc=${criteria.orderAscending}`);
    }

    url = params.length > 0 ? `${url}?${params.join('&')}` : url;
    return lxtBackend.get(url);
  },

  getContributorSingleProject: async (projectID) => lxtBackend.get(`contributor/projects/${projectID}`),

  getUploadProcessStatus: async (processID) => lxtBackend.get(`queue-tasks/${processID}/result`),

  initDownloadSession: async (projectID) => lxtBackend.post(`pm/projects/${projectID}/background-tasks/create-archive`),
  getInitiatedProjectsUpdates: async () => lxtBackend.post(`pm/background-tasks`),
};

export default routes;