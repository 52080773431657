import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Prompt } from 'react-router-dom';
import usersApi from './../../api/users';
import { Alert } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { formatRoute } from 'react-router-named-routes';
import { LOGIN_PATH } from '../../routes';


const NavigateLink = () => {
    return (
        <h3>
            Your Email has been verified successfully, you will be logged out, please log in with your new email!
        </h3>
    )
}

const EmailVerification = () => {

    const { token } = useParams();

    const [verificationErr, setVerificationErr] = useState("");
    const [verificationSuccess, setVerificationSuccess] = useState(false);

    const {
        logout,
    } = useAuthContext();

    const sendUserToken = async () => {
        try {
            await usersApi.verifyUserEmail({
                "token": token
            });
            setVerificationSuccess(true);
            logout();
            // TODO: we need to investigate why logout is not redirecting 
            // the user to login page
            window.location.href = formatRoute(LOGIN_PATH); // this should be removed after investigation
        } catch (error) {
            setVerificationSuccess(false);
            setVerificationErr(error.response.data.detail);
        }
    }

    useEffect(() => {
        sendUserToken();
    }, []);

    return (
        <Fragment>
            <Prompt
                when={!verificationSuccess}
                message="Are you sure you want to leave?"
            />
            {verificationErr && <Alert message={verificationErr} type="error" />}
            {verificationSuccess && <NavigateLink />}
        </Fragment>
    );
};

export default EmailVerification;