import { useCallback, useState } from 'react';
import { Alert, Button, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useGetLatestAgreement } from '../query/useGetLatestAgreement';
import { RichGuidelinesDisplay } from '../../Common/RichGuidelinesEditor';
import { signLatestServiceAgreement } from '../api/serviceAgreements';
import { nanoid } from 'nanoid';

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledWarningIcon = styled(WarningOutlined)`
	color: #fa8c16;
	margin-right: 30px;
	font-size: 3rem;
`;

const StyledTitleText = styled.span`
	font-size: 1.5rem;
`;

const StyledModalContent = styled.div`
	padding: 20px;
`;

export const ServiceAgreementModal = ({ visible, onClose }) => {
	const latestAgreement = useGetLatestAgreement();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleOnAccept = useCallback(async () => {
		try {
			setIsLoading(true);
			await signLatestServiceAgreement({ agreed: true });
			onClose(true);
			setIsLoading(false);
		} catch (error) {
			console.error('Failed to sign latest service agreement');
			setError(error);
			setIsLoading(false);
		}
	}, [onClose]);

	const handleOnReject = useCallback(() => {
		onClose(false);
		signLatestServiceAgreement({ agreed: false });
	}, [onClose]);

	return (
		<Modal
			title={
				<StyledTitleContainer>
					<StyledWarningIcon />
					<StyledTitleText>Welcome to LXT Data Platform!</StyledTitleText>
				</StyledTitleContainer>
			}
			width={'50%'}
			visible={visible}
			closable={false}
			footer={[
				<Button
					type="primary"
					key="ok"
					onClick={handleOnAccept}
					disabled={isLoading}
				>
					Accept
				</Button>,
				<Button type="ghost" key="ok" onClick={handleOnReject}>
					Reject
				</Button>,
			]}
		>
			<StyledModalContent>
				{error && (
					<Alert
						message={error.title}
						description={
							Array.isArray(error.details) ? (
								error.details.map((dt) => <div key={nanoid()}>{dt}</div>)
							) : (
								<div>Unknown Error</div>
							)
						}
						showIcon
						type="error"
					/>
				)}

				<Alert
					message={
						<>
							To proceed with your work, it's essential that you agree to our
							Updated Master Service Agreement.
							<br />
							Please be aware that without accepting the agreement, you will be
							unable to work on any current or future projects.
							<br />
							We appreciate your understanding. Thank you!
						</>
					}
					type="warning"
					style={{ marginBottom: '20px' }}
				/>
				<p>
					{latestAgreement && (
						<RichGuidelinesDisplay value={latestAgreement.description} />
					)}
				</p>
			</StyledModalContent>
		</Modal>
	);
};
