import React from 'react';
import { Row, Col, Space, Typography, Divider } from 'antd';
import { RightOutlined, NotificationFilled } from '@ant-design/icons';
import { formatRoute } from "react-router-named-routes";

import ClearNotification from '../../Page/NotificationPageCard/ClearNotification';
import CardActionButton from '../../Page/NotificationPageCard/CardActionButton';

import TextHiglighter from '../../TextHiglighter';
import { COMPLETION_DP_PATH, COMPLETION_RV_PATH } from '../../../../routes';
import { ROLES_TRANSLATOR, NOT_AVAIALBLE, TYPE_DATA_PROCESSOR, TYPE_REVIEWER, NOTIFICATIONS_CARDS_COLORS } from '../../LookUpTables';
import '../NotificationsDropDown.scss'
import NotificationDate from '../../NotificationDate';

const { Text } = Typography;

const ProjectAssigned = ({ notification }) => {
    const content = notification.content;
    const projectName = <TextHiglighter text={content.project_name ? content.project_name : NOT_AVAIALBLE} />
    const role = <TextHiglighter text={content.role ? ROLES_TRANSLATOR[content.role] : NOT_AVAIALBLE} />

    const goToProject = () => {
        if (content.role === TYPE_DATA_PROCESSOR)
            window.open(formatRoute(COMPLETION_DP_PATH, { projectId: content.project_id }))
        else if (content.role === TYPE_REVIEWER)
            window.open(formatRoute(COMPLETION_RV_PATH, { projectId: content.project_id }))
    }

    return (
        <div>
            <Row gutter={[4, 16]}>
                <Col span={23}>
                    <Space direction="vertical">
                        <Text>You have been assigned to project {projectName} as {role}.</Text>
                        <NotificationDate date={notification?.createdOn} />
                    </Space>
                </Col>
                <Col span={1}>
                    <NotificationFilled style={{ color: NOTIFICATIONS_CARDS_COLORS.ProjectAssignment }} />
                </Col>

            </Row>
            <Divider style={{ margin: '8px 0' }} />
            <Row >
                <Col span={16}>
                    <CardActionButton key={content.role === TYPE_DATA_PROCESSOR ? 'goToDp' : 'goToRV'}
                        icon={<RightOutlined style={{ fontSize: "80%" }} />}
                        text={content.role === TYPE_DATA_PROCESSOR ? 'Process' : 'Review'}
                        onClick={goToProject}>
                    </CardActionButton>
                </Col>

                <Col span={8}>
                    <ClearNotification notificationID={notification.id} />
                </Col>
            </Row>
        </div>

    )
}

export default ProjectAssigned;