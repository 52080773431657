import React, { useState, useCallback, useMemo } from 'react';
import ProfileEntry from './ProfileEntry';
import InputControls from './InputControls';
import useOptionsContext from '../../contexts/OptionsContext';
import { differenceBy } from 'lodash-es'
import SelectInput from './SelectInput';

const NativeLanguageInput = ({ user, editMode, onChangeEditMode }) => {

    const [selectedNativeLang, setSelectedNativeLang] = useState(undefined);

    const [isNativeLangEditable, setIsNativeLangEditable] = useState(false);

    const {
        languages,
        languagesError,
    } = useOptionsContext();

    const filteredLanguages = useMemo(() => {
        const otherLanguages = user?.otherLanguages || [];

        return differenceBy(languages, otherLanguages, 'id')
            .map(lg => ({ value: lg.id, key: lg.id, label: lg.name }));
    }, [languages, user?.otherLanguages]);

    const handleOnChange = useCallback((langId, langObj) => {
        setSelectedNativeLang(langObj);
    }, []);

    const handleEditableStatus = useCallback((status) => {
        setIsNativeLangEditable(status);
        onChangeEditMode(status);
    }, [onChangeEditMode]);

    const renderNativeLang = useCallback((lang) => {
        return lang?.name;
    }, []);

    const hasNativeLang = Boolean(user?.nativeLanguage);
    const isEditable = isNativeLangEditable;

    return (
        <ProfileEntry
            hasBorder
            label="Native Language:"
            value={
                (hasNativeLang && !isEditable) ?
                    renderNativeLang(user?.nativeLanguage) :
                    (isEditable) ?
                        <SelectInput
                            onChange={handleOnChange}
                            value={selectedNativeLang}
                            options={filteredLanguages}
                            optionsErr={languagesError}
                            selectMode="single"
                            placeholder="Set your native language here..."
                        />
                        :
                        '--'
            }
            controls={
                <InputControls
                    user={user}
                    inputName="nativeLanguage"
                    inputValue={selectedNativeLang ? selectedNativeLang : null}
                    inputEditable={isNativeLangEditable}
                    changeEditableStatus={handleEditableStatus}
                    disableSave={!selectedNativeLang ? true : false}
                    disableEdit={editMode}
                />
            }
        />
    );
};

export default NativeLanguageInput;
