import React from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const RefreshTableButton = ({ tooltipText, tooltipPlacement, isDisabled, onRefresh }) => {
    return (
        <Tooltip title={tooltipText} placement={tooltipPlacement}>
            <Button
                type='dashed'
                size='small'
                disabled={isDisabled}
                onClick={onRefresh}>
                <RedoOutlined />
            </Button>
        </Tooltip>
    );
};

export default RefreshTableButton;