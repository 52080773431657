import {
  Mark,
  markInputRule,
  markPasteRule,
  mergeAttributes
} from "@tiptap/core";

import { readableColor } from "polished";

const mkDataTag = name => `data-textAreaLabel-${name}`;

export const inputRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~=]+))(?:==))/g;

export const TextAreaLabelHighlight = Mark.create({
  name: "TextAreaLabelHighlight",

  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },

  addAttributes() {
    return {
      alias: { default: null, renderHTML: () => null },
      color: {
        default: null,
        parseHTML: elm => elm.getAttribute(mkDataTag("color")),
        renderHTML: attr => ({
          [mkDataTag("color")]: attr.color,
          style: `background-color: ${attr.color}; color: ${readableColor(
            attr.color
          )};`
        })
      },
      place: {
        default: null,
        parseHTML: element => element.getAttribute(mkDataTag("place")),
        renderHTML: attr => ({
          [mkDataTag("place")]: attr.place || "wrap"
          // contenteditable: attr.place !== "insert"
        })
      },
      labelOpen: {
        default: null,
        parseHTML: element => element.getAttribute(mkDataTag("label-open")),
        renderHTML: attrs => ({
          [mkDataTag("label-open")]: attrs.labelOpen
        })
      },
      labelClose: {
        default: null,
        parseHTML: element => element.getAttribute(mkDataTag("label-close")),
        renderHTML: attrs => ({
          [mkDataTag("label-close")]: attrs.labelClose
        })
      },
      label: {
        default: null,
        parseHTML: element => element.getAttribute(mkDataTag("label")),
        renderHTML: attrs => {
          return {
            [mkDataTag("label")]: attrs.label,
            class: `label-highlight label-highlight-${attrs.alias}-${attrs.place}`
          };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "mark"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "mark",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addCommands() {
    return {
      putTextAreaLabelHighlight: attributes => ({ commands }) => {
        commands.setMark(this.name, attributes);
        commands.insertContent("\u200B"); // u200B => Zero Width Space
        commands.unsetMark(this.name);
        return commands.insertContent("\u200B");
      },
      setTextAreaLabelHighlight: attributes => ({ commands }) => {
        return commands.setMark(this.name, attributes);
      },
      toggleTextAreaLabelHighlight: attributes => ({ commands }) => {
        return commands.toggleMark(this.name, attributes);
      },
      unsetTextAreaLabelHighlight: () => ({ commands }) => {
        return commands.unsetMark(this.name);
      }
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type
      })
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type
      })
    ];
  }
});
