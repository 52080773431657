import React, { Fragment, useCallback } from 'react';
import { SaveButton, EditButton, CancelButton } from './HelperButtons';
import { Space, message } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import * as yup from 'yup';


const InputControls = ({ user, inputName, inputValue, inputEditable, changeEditableStatus, changeValidationErrMsg, disableSave, disableEdit }) => {

    const {
        updateUserProfile
    } = useAuthContext();

    const validateNameInputs = useCallback(() => {
        const inputLabel = inputName === "firstName" ? "First Name" : "Last Name";

        let schema = yup.string().min(3, `${inputLabel} length should be more than 2 characters!`).max(255, `${inputLabel} length should be less than 255 characters!`).matches(/^([-'a-zA-Z]\s?)+\s*$/g, `${inputLabel} can only contain Latin letters, apostrophes or dashes!`);

        try {
            schema.validateSync(inputValue);
            changeValidationErrMsg("");
            return true;
        } catch (error) {
            changeValidationErrMsg(error.message);
            return false;
        }
    }, [inputName, inputValue, changeValidationErrMsg]);

    const validateEmailInput = useCallback(() => {

        let schema = yup.string().email("Email is not valid!");

        try {
            schema.validateSync(inputValue);
            changeValidationErrMsg("");
            return true;
        } catch (error) {
            changeValidationErrMsg(error.message);
            return false;
        }

    }, [inputValue, changeValidationErrMsg]);

    const validateInputs = useCallback(() => {

        if (inputName === "firstName" || inputName === "lastName") {
            return validateNameInputs();
        } else if (inputName === "email") {
            return validateEmailInput();
        } else {
            return true;
        }

    }, [inputName, validateNameInputs, validateEmailInput]);


    const handlOnSave = useCallback(async () => {

        const isValidValue = validateInputs();

        if (!isValidValue) return false;

        changeEditableStatus(false);

        if (inputName === 'dateOfBirth') {
            await updateUserProfile({
                "dateOfBirth": inputValue.split("/").reverse().join("-")
            });
        } else if (inputName === 'nativeLanguage') {
            await updateUserProfile({
                [inputName]: inputValue.value,
            });
        } else
        {
            console.log('----',inputName,inputValue)
            await updateUserProfile({
                [inputName]: inputValue,
            });
        };

        message.success(`Your profile has been updated successfully`);

    }, [validateInputs, updateUserProfile, inputValue, changeEditableStatus, inputName]);

    const handleDisableEdit = useCallback(() => {
        changeEditableStatus(false);
    }, [changeEditableStatus]);

    const handleEnableEdit = useCallback(() => {
        changeEditableStatus(true);
    }, [changeEditableStatus]);

    const renderEditBtn = () => {
        return (
            <EditButton
                onEdit={handleEnableEdit}
                disabled={disableEdit}
            />
        )
    }

    const renderSaveAndCancelBtns = () => {
        return (
            <Space direction='horizontal' size={0}>
                <CancelButton onCancel={handleDisableEdit} />
                <SaveButton
                    onSave={handlOnSave}
                    disabled={disableSave}
                />
            </Space>
        )
    }

    return (
        <Fragment>
            {
                inputEditable ? renderSaveAndCancelBtns()
                    :
                    renderEditBtn()
            }
        </Fragment>
    );
};

export default InputControls;