import { useMutation } from '@tanstack/react-query';
import { unitAndCostAPi } from '../cost';
import { message } from 'antd';
import { ROLE_DATA_PROCESSOR } from '../../Constants';
import { downloadFile } from '../../components/Common/Utils';


export const useUnitAndCostApprove = ({ projectId }) => {
    const mutateApprove = useMutation({
        mutationFn: unitAndCostAPi.approveCost,
        mutationKey: ['unitAndCostApprove', projectId],
        onSuccess: (_, requestData) => {
            const isApproved = requestData?.requestBody?.approved;
            const isDataProcessor = requestData.role === ROLE_DATA_PROCESSOR
            const msg = `${isDataProcessor ? "Data Processing Cost" : "Review Cost"} is ${isApproved ? "approved" : "unapproved"} successfully`;

            message.success(msg)
        },
    });

    return mutateApprove;
}


export const useCostExport = ({ selectedProject, onError }) => {
    const mutateExport = useMutation({
        mutationFn: unitAndCostAPi.exportProjectCostTasks,
        mutationKey: ['unitAndCostExport', selectedProject?.id],
        onSuccess: ({ data }) => {
            downloadFile({
                file: data,
                projectId: selectedProject?.id,
                projectName: selectedProject?.name,
                fileType: "text/csv",
                fileExtension: "csv"
            })
        },
        onError
    });
    return mutateExport
}