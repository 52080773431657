import { Button, Space, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './FilePreview.scss';

const { Title } = Typography;

const Pagination = ({
	goToNextPage,
	goToPreviousPage,
	pageNumber,
	numPages,
}) => {
	return (
		<Space size="small" className="pagination-buttons">
			<Button icon={<LeftOutlined onClick={goToPreviousPage} />} />
			<Title level={5}>
				{pageNumber} / {numPages}
			</Title>
			<Button icon={<RightOutlined onClick={goToNextPage} />} />
		</Space>
	);
};

export default Pagination;
