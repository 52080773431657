import { Divider } from "antd";
import ProjectFieldDate from "../helper/ProjectFieldDate";


const ProjectDates = () => {
    return (
        <>
            <Divider>Dates</Divider>

            <ProjectFieldDate labelText='Created On' fieldName='createdOn' />
            <ProjectFieldDate
                labelText='Started On'
                fieldName='startedOn'
                editable
                thresholds={{
                    minFieldName: 'createdOn',
                    minFieldLabel: 'Created On',
                    maxFieldName: 'kickoffDate',
                    maxFieldLabel: 'Kick Off'
                }} />

            <ProjectFieldDate
                labelText='Kick Off'
                fieldName='kickoffDate'
                editable
                thresholds={{
                    minFieldName: 'startedOn',
                    minFieldLabel: 'Started On',
                    maxFieldName: 'eta',
                    maxFieldLabel: 'ETA'
                }}
            />

            <ProjectFieldDate
                labelText='ETA'
                fieldName='eta'
                editable
                thresholds={{
                    minFieldName: 'kickoffDate',
                    minFieldLabel: 'Kick Off',
                }} />

            <ProjectFieldDate
                labelText='Completed On'
                fieldName='completedOn'
                editable
                thresholds={{
                    minFieldName: 'startedOn',
                    minFieldLabel: 'Started On'
                }} />
        </>
    )
}

export default ProjectDates;