import { Divider } from "antd";
import ProjectField from "../helper/ProjectField";


const ProjectCompletionsOptions = ({ selectedProject }) => {
    return (
        <>
            <Divider>Completions</Divider>

            <ProjectField
                labelText='Completions Per Task:'
                fieldName='completionsPerTask'
                editable={selectedProject.allowCriticalFieldsEdit} />

            <ProjectField
                labelText='Show Previous Completions:'
                fieldName='showPreviousCompletions'
                editable={selectedProject.allowCriticalFieldsEdit} />

            <ProjectField
                labelText='Duplicate Completions:'
                fieldName='allowDuplicateCompletions'
                editable={selectedProject.allowCriticalFieldsEdit} />

            <ProjectField
                labelText='Different User Per Completion:'
                fieldName='diffUsersToTaskCompletions'
                editable={selectedProject.allowCriticalFieldsEdit} />

        </>
    )
}

export default ProjectCompletionsOptions;