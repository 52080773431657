import { lxtBackend } from "./utils";

export default {
    getNotifications: async (criteria) => {

        let url = `/notifications`;

        let params = [];
        if (typeof criteria.read === 'boolean') params.push(`read=${criteria.read}`);
        if (typeof criteria.cleared === 'boolean') params.push(`cleared=${criteria.cleared}`);
        if (criteria.skip || criteria.skip === 0) params.push(`skip=${criteria.skip}`);
        if (criteria.limit) params.push(`limit=${criteria.limit}`);

        url = params.length > 0 ? `${url}?${params.join('&')}` : url;
        return lxtBackend.get(url);
    },

    clearNotifications: async (ids) => {
        let url = `/notifications`;
        const params = Array.isArray(ids) ? ids.map(id => `id=${id}`) : [];
        url = params.length > 0 ? `${url}?${params.join('&')}` : url;
        return lxtBackend.patch(url, { cleared: true });
    },

    clearAllNotifications: async () => {
        let url = `/notifications`;
        return lxtBackend.patch(url, { cleared: true });
    },

    restoreNotification: async (id) => {
        let url = `/notifications?id=${id}`;
        return lxtBackend.patch(url, { cleared: false });
    }
};
