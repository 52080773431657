import CostTable from "./CostTable";

const Cost = () => {
    return (
        <>
            <CostTable />
        </>
    )
}

export default Cost;