import React from "react";
import { Result } from "antd";

function Error({ active, code, ...props }) {
  if (!active) {
    return null;
  }

  const antdCodes = [403, 404, 500];
  const status = antdCodes.includes(code) ? code : "error";

  const defaultInfo = {
    400: {
      title: "Bad request",
    },
    403: {
      title: "Sorry, you are not authorized to access this page",
    },
    404: {
      title: "Sorry, the page you visited does not exist",
    },
    500: {
      title: "Sorry, something went wrong",
    },
  };

  const defaultInfoItem = defaultInfo[code];

  let data = {
    title: "Sorry, something went wrong",
  };
  if (code !== undefined) {
    if (defaultInfoItem) {
      data = { ...defaultInfoItem, ...props };
    }
  } else {
    data = { ...data, ...props };
  }

  return <Result status={status} {...data} />;
}

export default Error;
