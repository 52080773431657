import React from 'react';
import TextAreaLabelsEditor from './TextAreaLabelsEditor';

function textAreaLabelReducer(state, payload) {
	return {
		...state,
		...payload,
	};
}

/**
 * @example
 * 	<View>
 * 		<TextAreaWithLabels name="" value="foobar baz">
 * 			<TextAreaLabel alias="Foo" value="[foo]]" background="darkred" place="before"/>
 * 		</TextAreaLabels>
 * 	</View>
 * @name  TextAreaLabels
 * @param {string} name - element identifier
 * @param {object} item - item
 */
export const TextAreaLabel = ({ children, item }) => {
	const { getValue } = item;
	const [results, setResults] = React.useState();
	const [labelsDict, dispatchLabels] = React.useReducer(
		textAreaLabelReducer,
		{},
		() => ({}),
	);

	const handleOnResultUpdate = React.useCallback((value) => {
		console.log('[TextAreaLabel]', 'OnResultUpdate', value);
		setResults(value);

		item?.setValue?.(value);
		item?.onChange();
	}, []);

	const handleOnLabelReady = React.useCallback((label) => {
		dispatchLabels({ [label.alias]: label });
	}, []);

	return (
		<>
			<TextAreaLabelsEditor
				name={item?.name}
				labels={labelsDict}
				controls={children}
				onEditorChange={handleOnResultUpdate}
				onLabelReady={handleOnLabelReady}
				value={getValue(labelsDict)}
			/>

			{process.env.NODE_ENV === "development" &&
				(<>
					<hr />
					<h5>Preview:</h5>
					{results?.text ?? 'n/a'}
				</>
				)}
		</>
	);
};
