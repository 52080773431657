import React from 'react';
import { Typography, Tooltip } from 'antd';
import { formatDateTime, formatTimeToNow } from '../Common/Utils';
const { Text } = Typography;

const NotificationDate = ({ date }) => {
    const notificationDateDifference = formatTimeToNow(date, { fallback: date ?? " " });

    return (
        <Tooltip title={formatDateTime(date)}>
            <Text type="secondary" style={{ fontSize: '0.8rem' }}>{notificationDateDifference}</Text>
        </Tooltip>
    );
};

export default NotificationDate;