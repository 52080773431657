import React from 'react';
import { observer } from 'mobx-react';
import { types, getParentOfType } from 'mobx-state-tree';

import { Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import WithStatesMixin from '../mixins/WithStates';
import NormalizationMixin from '../mixins/Normalization';
import RegionsMixin from '../mixins/Regions';
import Registry from '../core/Registry';
import { TextAreaLabelsModal } from '../tags/object/TextAreaLabels';
import { guidGenerator } from '../core/Helpers';

import styles from './TextAreaRegion/TextAreaRegion.module.scss';
import {getTimestamp} from "../utils/unique";
import {mkStringWithPrefix} from "../utils/string";

const { Paragraph } = Typography;

const Model = types
	.model('TextAreaWithLabelsRegionModel', {
		id: types.optional(types.identifier, mkStringWithPrefix('M_ID_R_', getTimestamp)),
		pid: types.optional(types.string, mkStringWithPrefix('M_PID_R_', getTimestamp)),
		type: 'textareawithlabelsregion',

		_value: types.string,
		// states: types.array(types.union(ChoicesModel)),
	})
	.views((self) => ({
		get parent() {
			return getParentOfType(self, TextAreaLabelsModal);
		},
		get regionElement() {
			return document.querySelector(`#TextAreaRegion-${self.id}`);
		},
	}))
	.actions((self) => ({
		setValue(val) {
			self._value = val;
			self.parent.onChange();
		},
	}));

const TextAreaWithLabelsRegionModel = types.compose(
	'TextAreaWithLabelsRegionModel',
	WithStatesMixin,
	RegionsMixin,
	NormalizationMixin,
	Model,
);

const HtxTextAreaWithLabelsRegionView = ({ item }) => {
	const classes = [styles.mark];
	const params = {};
	const { parent } = item;
	const { relationMode } = item.completion;

	if (relationMode) {
		classes.push(styles.relation);
	}

	if (item.selected) {
		classes.push(styles.selected);
	} else if (item.highlighted) {
		classes.push(styles.highlighted);
	}

	if (parent.editable) {
		params['editable'] = {
			onChange: (str) => {
				item.setValue(str);
			},
		};
	}

	let divAttrs = {};
	if (!parent.perregion) {
		divAttrs = {
			onMouseOver: () => {
				if (relationMode) {
					item.setHighlight(true);
				}
			},
			onMouseOut: () => {
				/* range.setHighlight(false); */
				if (relationMode) {
					item.setHighlight(false);
				}
			},
		};
	}

	return (
		<div {...divAttrs} className={styles.row} data-testid="textarea-region">
			<Paragraph
				id={`TextAreaRegion-${item.id}`}
				className={classes.join(' ')}
				{...params}
			>
				{item._value}
			</Paragraph>
			{parent.perregion && (
				<DeleteOutlined
					className={styles.delete}
					onClick={() => item.parent.remove(item)}
				/>
			)}
		</div>
	);
};

const HtxTextAreaWithLabelsRegion = observer(HtxTextAreaWithLabelsRegionView);

Registry.addTag(
	'textareawithlabelsregion',
	TextAreaWithLabelsRegionModel,
	HtxTextAreaWithLabelsRegion,
);

export { TextAreaWithLabelsRegionModel, HtxTextAreaWithLabelsRegion };
