import React, { useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const TwoFactorAuthSMSVerification = ({
	resendCode,
	verifyCode,
	goBackToSignIn,
	hasOther2FAOption,
	goToOther2FAOption,
	phoneNumber,
}) => {
	const [state, setState] = useState({
		showDeviceLostTip: false,
	});

	const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

	const onSubmitVerificationCode = (values) => {
		verifyCode(values.verificationCode);
	};

	const handleToggleSupportTip = () => {
		setState((ps) => ({
			...ps,
			showDeviceLostTip: !state.showDeviceLostTip,
		}));
	};

	const VerificationsButtons = () => {
		if (isTabletOrMobile) {
			return (
				<Form.Item style={{ textAlign: 'center' }}>
					<Space>
						<Button
							type={'link'}
							style={{ padding: 0, marginBottom: 10 }}
							onClick={() => goToOther2FAOption()}
							hidden={!hasOther2FAOption}
						>
							Other verification options
						</Button>
					</Space>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-around',
						}}
					>
						<Button
							style={{ width: '40%' }}
							type="primary"
							className="btn-success"
							htmlType="submit"
						>
							Verify
						</Button>
						<Button
							style={{ width: '40%' }}
							type="primary"
							onClick={() => resendCode()}
						>
							Resend
						</Button>
					</div>
					<Button type="link" onClick={() => goBackToSignIn()}>
						Go Back To Sign In
					</Button>
				</Form.Item>
			);
		} else {
			return (
				<Form.Item>
					<Space>
						<Button
							type={'link'}
							style={{ padding: 0, marginBottom: 10 }}
							onClick={() => goToOther2FAOption()}
							hidden={!hasOther2FAOption}
						>
							Other verification options
						</Button>
					</Space>
					<Space align="end" direction="horizontal">
						<Button type="primary" className="btn-success" htmlType="submit">
							Verify
						</Button>
						<Button type="primary" onClick={() => resendCode()}>
							Resend
						</Button>
						<Button type="dashed" onClick={() => goBackToSignIn()}>
							Go Back To Sign In
						</Button>
					</Space>
				</Form.Item>
			);
		}
	};

	return (
		<Form onFinish={onSubmitVerificationCode}>
			{phoneNumber ? (
				<p>
					An SMS is sent to your mobile with number {phoneNumber} with the
					verification code. Please enter...
				</p>
			) : (
				<p>
					An SMS is sent to your mobile with the verification code. Please
					enter...
				</p>
			)}

			<Form.Item
				name="verificationCode"
				rules={[
					{
						required: true,
						message: 'Verification code is required!',
					},
					{
						min: 6,
						message: "Verification code can't be less than 6 digits!",
					},
					{
						max: 6,
						message: "Verification code can't exceed 6 digits!",
					},
				]}
				style={{ marginBottom: 10 }}
			>
				<Input type="number" />
			</Form.Item>

			<VerificationsButtons goBackToSignIn={goBackToSignIn}/>

			{/* The info text in case the mobile phone is lost. */}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<QuestionCircleFilled onClick={() => handleToggleSupportTip()} />
			</div>
			{state.showDeviceLostTip && (
				<p>
					If you lost your device, please contact support using the email
					<a href="mailto:ldpsupport@lxt.ai">ldpsupport@lxt.ai</a>
				</p>
			)}
		</Form>
	);
};

export default TwoFactorAuthSMSVerification;
