import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete';
import './../Styles/Geospify.css';

const GeoapifySelect = ({ value = '', onChange }) => {
    return (
        <GeoapifyContext apiKey={process.env.REACT_APP_GEOAPIFY_API_KEY}>
            <GeoapifyGeocoderAutocomplete
                placeholder="Select city"
                type="city"
                lang="en"
                limit={10}
                value={value?.properties ? value.properties.formatted : value}
                placeSelect={onChange}
            />
        </GeoapifyContext>
    );
};

export default GeoapifySelect;