import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatRoute } from "react-router-named-routes";
import { useMediaQuery } from 'react-responsive'
import { Button, message, Modal, Space, Menu, Checkbox, Dropdown } from "antd";
import { ExclamationCircleOutlined, ExclamationCircleFilled, WarningFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';

import useCompletionContext from '../../../contexts/CompletionContext';
import { COMPLETION_RV_PATH } from '../../../routes';
import { COMPLETION_RV_ACTION } from '../../../Constants';


const ModificationsDetectedModal = ({ show, onKeepChanges, onDiscardChanges, onCancel }) => {
    return (
        <Modal
            width='50%'
            title={
                <span style={{ fontSize: '1.1rem' }}>
                    <WarningFilled style={{ color: '#fa8c16', marginRight: '10px', fontSize: '1.5rem' }} /> Modifications Were Detected!
                </span>
            }
            visible={show}
            onCancel={onCancel}
            footer={[
                <Button type='primary' key='keep' onClick={onKeepChanges}>Keep Modifications And Accept</Button>,
                <Button type='danger' key='discard' onClick={onDiscardChanges}>Discard Modifications And Accept</Button>,
                <Button type='dashed' key='cancel' onClick={onCancel}>Cancel</Button>
            ]}>
            Some modifications to the processor's original completion were detected. You can either keep the modifications to the final result or discard them.
        </Modal>
    )
}

const RVControlPanel = ({ beforeCommittingResult, afterCommittingResult }) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 480 })

    const {
        completion,
        isSavingDraftResult,
        isCommentAdded,
        originalCompletionSelected,

        fetchNextCompletion,
        submitCompletionRV,
        completionResultModified,
    } = useCompletionContext();


    const history = useHistory();

    const { projectId, compId } = useParams();

    const [state, setState] = useState({
        showModificationsDetectedModal: false,
        selectedRejectionReasons: [],
        reasonsMenuVisible: false,
    });

    useEffect(() => {
        // clearing the selected reasons with every completion update.
        setState(ps => ({ ...ps, selectedRejectionReasons: [] }));
        // eslint-disable-next-line
    }, [completion]);


    // Submitts the result directly without any validation.
    const sendUserAction = async (action) => {

        const rejectionKeys = action === COMPLETION_RV_ACTION.Reject && state.selectedRejectionReasons.length > 0 ?
            state.selectedRejectionReasons.map(sr => sr.key) :
            null;

        // The reviewer can't accept/reject without final successfull stopTimer call since if there is any time not sent to the backend, it won't be possible to 
        // add any more time after the completion is moved to the next phase.
        // The timer is also started again when failure occurrs since the user may stay a little longer on reviewing the completion after saving time, and consequently acceptance/rejection, fails.
        beforeCommittingResult()
            .then(() => submitCompletionRV(action, rejectionKeys))
            .then(() => {
                message.success(action === COMPLETION_RV_ACTION.Reject ? 'Completion was REJECTED successfully!' : 'Completion was ACCEPTED successfully!');

                if (afterCommittingResult)
                    afterCommittingResult();

                if (compId) {
                    // Updating the url as after completion result is saved of a completion selected from assigned tasks, 
                    // the rest of the current project's completions are iterated in regular fashion.
                    history.push(formatRoute(COMPLETION_RV_PATH, { projectId: projectId }));

                } else {
                    fetchNextCompletion(projectId, true);
                }
            })
            .catch(e => {
                console.error(e)
                //Do Nothing! error is updated in the context.
            });
    }



    // Handle reject reasons drop down menu visibility change.
    const handleRejectReasonsMenuVisibilityChange = e => setState(ps => ({ ...ps, reasonsMenuVisible: e }));

    // Accept button event handler.
    const handleAcceptButtonOnClick = () => {
        const resultModified = completionResultModified();
        if (resultModified)
            // Asking the reviewer whether to include or discard the detected modifications.
            // There is no need to ask him to confirm the action in this case.
            setState(ps => ({ ...ps, showModificationsDetectedModal: true }))
        else
            // When there are no modifications done by the reviewer, he is asked to confirm the action.
            Modal.confirm({
                title: 'Are you sure you want to ACCEPT?',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    sendUserAction(COMPLETION_RV_ACTION.Accept);
                }
            });
    }

    // Reject button event handler.
    const handleRejectButtonOnClick = () => {

        const otherIsSelected = state.selectedRejectionReasons.find(sr => sr.requiresComment) !== undefined;

        // Validating that a comment is added in case of other is selected.
        if (otherIsSelected && !isCommentAdded) {
            message.error("Rejection reason Other can't be selected without a comment!");
            return;
        }

        // Supporting the rejection of completions from the projects which have no rejection reasons.
        if (state.selectedRejectionReasons.length === 0 && state.selectedRejectionReasons.length === 0 && !isCommentAdded) {
            message.error("Completion can't be rejected without a comment!");
            return;
        }

        // Hiding the drop down menu after click.
        setState(ps => ({ ...ps, reasonsMenuVisible: false }));

        // Confirming the user action.
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: state.selectedRejectionReasons.length > 0 ? 'Are you sure you want to REJECT the completion for the following reasons?' : 'Are you sure you want to REJECT?',
            content: <ul>{state.selectedRejectionReasons.map(rk => <li key={nanoid()}>{rk.name}</li>)}</ul>,
            onOk() {
                sendUserAction(COMPLETION_RV_ACTION.Reject);
            }
        });
    };



    // Handles the modal button accept with modifications.
    const handleModalAcceptWithModifications = () => {
        // Closing the modal.
        setState(ps => ({ ...ps, showModificationsDetectedModal: false }));
        // Submitting result with the modifications included.
        sendUserAction(COMPLETION_RV_ACTION.AcceptWithModifications);
    };

    // Handles the modal button accept without modifications.
    const handleModalAcceptWithoutModifications = () => {
        // Closing the modal.
        setState(ps => ({ ...ps, showModificationsDetectedModal: false }));
        // Submitting result without the modifications.
        sendUserAction(COMPLETION_RV_ACTION.Accept);
    }

    // Handles the modal button cancel.
    const handleModalCancel = () => setState(ps => ({ ...ps, showModificationsDetectedModal: false }));



    const handleSkipReasonSelected = (rejectionReason, checked) => {
        // Adding or removing the skip reason based on the checked state.
        setState(ps => ({
            ...ps,
            selectedRejectionReasons: checked ?
                [...ps.selectedRejectionReasons, rejectionReason] :
                ps.selectedRejectionReasons.filter(sr => sr.key !== rejectionReason.key)
        }))
    }

    const handleShowSkippedForReasons = (reasons) => {

        const contents = (
            <Fragment>
                <span>Reason(s) for skipping:</span>
                <ul>{reasons.map(r => <li key={nanoid()}>{r}</li>)}</ul>
            </Fragment>
        )

        Modal.warning({
            title: 'Completion Was Skipped',
            content: contents,
            width: isTabletOrMobile ? '100%' : '40%',
        });
    }



    const rejectReasons = completion && completion.rejectReasons ? completion.rejectReasons : {};
    const skippedForReasons = completion && completion.skippedForReasons && Array.isArray(completion.skippedForReasons) ? completion.skippedForReasons : [];
    const menuOverlay = (
        <Menu>

            {Object.keys(rejectReasons).length > 0 &&
                Object.keys(rejectReasons).map(rejKey =>
                    <Menu.Item key={rejKey}>
                        <Checkbox
                            onChange={e => {
                                const selectedObject = {
                                    key: rejKey,
                                    name: rejectReasons[rejKey].name ? rejectReasons[rejKey].name : 'Reject Reason',
                                    requiresComment: rejectReasons[rejKey].requires_comment ? true : false,
                                };
                                handleSkipReasonSelected(selectedObject, e.target.checked);
                            }}>
                            {rejectReasons[rejKey].name}
                        </Checkbox>
                    </Menu.Item>
                )
            }

            <Menu.Item key={'confirm'}>
                <Button
                    type='danger'
                    size='small'
                    style={{ width: '100%' }}
                    onClick={handleRejectButtonOnClick}
                    disabled={state.selectedRejectionReasons.length === 0}
                >
                    Reject
                </Button>
            </Menu.Item>

        </Menu>
    )

    return (
        <Fragment>

            <Space>

                {/* The drop down list of the rejection reasons the reviewer can select from if they are provided by the PM. If not, the button will be hidden. */}
                {Object.keys(rejectReasons).length > 0 &&
                    <Dropdown
                        overlay={menuOverlay}
                        disabled={isSavingDraftResult || !originalCompletionSelected}
                        visible={state.reasonsMenuVisible}
                        trigger={['click']}
                        onVisibleChange={handleRejectReasonsMenuVisibilityChange}>
                        <Button
                            type="danger"
                            shape={isTabletOrMobile ? 'circle' : 'default'}
                            size={isTabletOrMobile ? 'middle' : 'small'}
                            icon={<CloseOutlined />}
                        >
                            {isTabletOrMobile ? '' : 'Reject'}
                        </Button>
                    </Dropdown>
                }

                {/* Supporting the case of no rejection reasons is provided by the PM or the project is old and contains no reasons for rejection. */}
                {Object.keys(rejectReasons).length === 0 &&
                    <Button
                        type='danger'
                        shape={isTabletOrMobile ? 'circle' : 'default'}
                        size={isTabletOrMobile ? 'middle' : 'small'}
                        onClick={handleRejectButtonOnClick}
                        disabled={isSavingDraftResult || !originalCompletionSelected}
                        icon={<CloseOutlined />}
                    >
                        {isTabletOrMobile ? '' : 'Reject'}
                    </Button>
                }

                {/* The completion acceptance button. */}
                <Button
                    type="primary"
                    className='btn-success'
                    onClick={handleAcceptButtonOnClick}
                    shape={isTabletOrMobile ? 'circle' : 'default'}
                    size={isTabletOrMobile ? 'middle' : 'small'}
                    icon={<CheckOutlined />}
                    disabled={isSavingDraftResult || !originalCompletionSelected}>
                    {isTabletOrMobile ? '' : 'Accept'}
                </Button>

                {/* The button to show the skip reasons selected by the data processor when the reasons available. */}
                {skippedForReasons.length > 0 &&
                    <Button
                        style={{ color: '#fa8c16' }}
                        type='text'
                        size='small'
                        icon={<ExclamationCircleFilled />}
                        onClick={() => handleShowSkippedForReasons(skippedForReasons)}
                        disabled={!originalCompletionSelected}>
                        Completion Was Skipped
                    </Button>
                }

            </Space>

            {/* The modal shown when the user clicks on accept and there are modification done by the reviewer is detected. */}
            <ModificationsDetectedModal
                show={state.showModificationsDetectedModal}
                onKeepChanges={handleModalAcceptWithModifications}
                onDiscardChanges={handleModalAcceptWithoutModifications}
                onCancel={handleModalCancel}
            />

        </Fragment>
    )
}

export default RVControlPanel;
